import React, { useEffect } from "react";
import { connect } from "react-redux";
import LoadMask from "../../../../Utils/LoadMask/LoadMask";
import Grid from "../../../../Utils/Grid";
import { actions } from "../../../../../../redux/modules/canal";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import moment from "moment";
import { TableHeaderColumn } from "react-bootstrap-table";

const formatearFecha = (fecha, args) => {
    return moment(fecha).format("DD/MM/YYYY");
};

export const TableRecepciones = ({
    loader,
    data,
    page,
    listar,
    getInitialValues,
}) => {
    const setIdEnviarAVaciado = (id, no__recepcion) => {
        getInitialValues(id, no__recepcion);
    };
    useEffect(() => {
        listar();
    }, []);
    return (
        <LoadMask loading={loader} blur>
            <Grid
                hover
                striped
                data={
                    data || {
                        results: [],
                        count: 0,
                    }
                }
                page={page}
                pagination
                onPageChange={listar}
            >
                <TableHeaderColumn dataField="id_recepcion">
                    ID RECEPCION
                </TableHeaderColumn>
                <TableHeaderColumn dataField="placa_vehiculo">
                    #PLACA
                </TableHeaderColumn>
                <TableHeaderColumn dataField="nombre_piloto">
                    PILOTO
                </TableHeaderColumn>
                <TableHeaderColumn dataField="finca_origen">
                    FINCA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_entrada"
                    dataFormat={formatearFecha}
                >
                    FECHA RECEPCION ORIGINAL
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="creado"
                    dataFormat={formatearFecha}
                >
                    FECHA ENTRADA A CANAL
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="peso_ingreso_origen"
                    dataFormat={(cell) => {
                        return `${cell} KG`;
                    }}
                >
                    PESO ORIGINAL DE LA RECEPCION
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataFormat={(cell) => {
                        return `${cell} KG`;
                    }}
                >
                    PESO ENTRADA A CANAL
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    isKey
                    dataAlign="center"
                    dataFormat={standardActions({
                        dar_salida: true,
                        setIdEnviarAVaciado: setIdEnviarAVaciado,
                        ver_numero_recepcion: true,
                    })}
                    width="100px"
                >
                    Acciones
                </TableHeaderColumn>
            </Grid>
        </LoadMask>
    );
};

const mapStateToProps = (state) => ({
    ...state.canal,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableRecepciones);
