import React, { Component, Fragment, useState, useEffect } from "react"
import CardForm from "../../../../Utils/Cards/CardForm"
import CardEmpty from "../../../../Utils/Cards/CardEmpty"
import Grid from "../../../../Utils/Grid"
import { PrintContainer, actions as printActions } from "Utils/Print"
import moment from "moment"
import { TableHeaderColumn } from "react-bootstrap-table"
import { Link } from "react-router-dom";
import LoadMask from "../../../../Utils/LoadMask/LoadMask"

export default class BoletaCanal extends Component {
    state = {
        fechaImpresion: moment().format('DD/MM/YYYY HH:mm:ss A'),
        namePrintComponent: 'boleta-salida-canal'
    }
    imprimirBoleta = event => {
        this.setState({
            fechaImpresion: moment().format('DD/MM/YYYY HH:mm:ss A')
        }, () => {
            printActions.print(this.state.namePrintComponent);
        });
    }

    componentDidMount() {
        this.props.getRecepcion(window.location.href.split('/')[5])
    }

    render() {
        const { recepcion_canal, loader } = this.props
        return (
            <Fragment>
                <LoadMask loading={loader} blur>
                    <CardEmpty>
                        <PrintContainer name={'boleta-salida-canal'}>
                            <div className="col-12 p-0">
                                <div className="row contenido-impresion verde-secundario d-flex justify-content-center">
                                    <div className="col-12 row">
                                        <div className="col-12 d-flex justify-content-center mt-0 mb-3">
                                            <div className="col-11 pl-2">
                                                <div className="row col-12 p-0 mt-3 d-flex justify-content-between align-items-center">
                                                    <img
                                                        className="d-inline-block align-top"
                                                        src={require('../../../../../../../assets/img/logo.png')}
                                                        alt="Logo"
                                                        height="50px"
                                                    />
                                                    <label className="p-0 m-0">ACOPIO ERSA KM. 7.5 CARRETERA A TULATE</label>
                                                    <div className="ml-4 mr-4"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center mt-0">
                                            <div className="col-11">
                                                <div className="row col-12 p-0 mt-2 justify-content-center align-items-start">
                                                    <div className="col-8 p-0 fondo">
                                                        <div className="col-12 d-flex flex-column pl-0">
                                                            <div className="d-flex align-items-center">
                                                                <img src={require('../../../../../../../assets/img/icons/recepciones_activo.png')} alt="Recepcion" className="title_img mr-2"/>
                                                                <img src={require('../../../../../../../assets/img/icons/linea.png')} alt="linea" className="title_img mr-2"/>
                                                                <h6 className="text-black mb-0">BOLETA DE SALIDA</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <span className="gris">Fecha de impresión</span>
                                                            <span className="gris">{this.state.fechaImpresion}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row col-12 p-0 pt-2">
                                                    <div className="col-8 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">FINCA</label>
                                                            <span className="gris">{recepcion_canal.finca_origen}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex align-items-baseline">
                                                            <label className="p-0 m-0">ID RECEPCION ORIGINAL</label>
                                                            <h5 className="gris bold pl-2">{recepcion_canal.id_recepcion_original}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row col-12 p-0 mt-3">
                                                    <div className="col-8 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">VEHICULO</label>
                                                            <span className="gris">{recepcion_canal.placa_vehiculo}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">PILOTO</label>
                                                            <span className="gris">{recepcion_canal.nombre_piloto}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row col-12 p-0 mt-3">
                                                    <div className="col-8 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">ENTRADA</label>
                                                            <span className="gris">{moment(recepcion_canal.fecha_entrada).format('DD/MM/YYYY HH:mm:ss A')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">SALIDA</label>
                                                            <span className="gris">{moment(recepcion_canal.fecha_salida).format('DD/MM/YY HH:mm:ss A')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center p-0">
                                            <div className="col-11 form-group np-r p-0 mt-3 mb-1">
                                                <Grid striped data={{results: [ recepcion_canal ]}} pagination={false} bordered={false} >
                                                    <TableHeaderColumn
                                                        dataField="id"
                                                        isKey
                                                        width="15px"
                                                        dataFormat={cell => ''}
                                                    />
                                                    <TableHeaderColumn
                                                        dataField="materia_prima"
                                                    >
                                                        MATERIA PRIMA
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="peso_bruto"
                                                    >
                                                        <div className="d-flex flex-column">
                                                            PESO ENTRADA
                                                            <small className="bold">(PESO BRUTO)</small>
                                                        </div>
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="peso_tara"
                                                    >
                                                        <div className="d-flex flex-column">
                                                            PESO SALIDA
                                                            <small className="bold">(PESO TARA)</small>
                                                        </div>
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="peso_neto"
                                                        width="12%"
                                                    >
                                                        PESO NETO
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="diferencia"
                                                        width="11%"
                                                    >
                                                        DIFERENCIA
                                                    </TableHeaderColumn>
                                                </Grid>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center mt-5 p-0">
                                            <div className="col-11">
                                                <div className="row col-12 p-0 d-flex align-items-center">
                                                    <div className="col-6 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <div className="d-flex flex-row align-items-end mt-0">
                                                                <label className="mb-0 mr-3">BÁSCULA</label>
                                                                <input className="espacio-firma mb-1" type="text" readOnly/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center mt-3 mb-5">
                                            <div className="col-11">
                                                <div className="row col-12 p-0 mt-3">
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <span className="gris">ERSA-F-GO-025-06</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PrintContainer>
                    </CardEmpty>
                </LoadMask>
                <div className="mb-4 col-12 p-0">
                    <div className="row mb-5">
                        <div className="col-12 row">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <Link
                                        className="btn btn-secondary mr-5"
                                        type="button"
                                        to="/canal"
                                    >
                                        REGRESAR
                                    </Link>
                                    <button type="submit" className="btn btn-primary" onClick={this.imprimirBoleta}>IMPRIMIR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )

    }
}
