import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/reportes';
import ReporteMateriaPrimaMensual from './ReporteMateriaPrimaMensual';
import _ from 'lodash';


const ms2p = (state) => {
  // Constantes definidas en backend para calcular el valor sin IVA, para algunos regímenes de finca.
  const variables_calculo_impuestos = _.get(state, 'configuracion.configuraciones.variables_calculo_impuestos', null);
  return {
    ...state.reportes,
    variables_calculo_impuestos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteMateriaPrimaMensual);
