import { connect } from 'react-redux'
import { actions } from '../../../../../../redux/modules/canal'
import BoletaCanal from './BoletaCanal.jsx'

const ms2p = state => {
    return {
        ...state.canal,
        me: state.login.me
    }
}

const md2p = {
    ...actions
}

export default connect(ms2p, md2p)(BoletaCanal)