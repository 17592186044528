import { handleActions } from 'redux-actions';
import { createReducer } from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    'transportistas',           // Store ID
    'transportista',            // Endpoint
    'EditarTransportistaForm',  // Form name
    '/transportistas',          // Resource list
);

export default handleActions(reducers, initialState);
