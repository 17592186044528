import React, { Component } from 'react'
import Card from 'Utils/Cards/Card'
import CardEmpty from 'Utils/Cards/CardEmpty'
import FiltrosHeader from '../../../Reportes/FiltrosHeader'
import Reporte from './Reporte'

class HistoricoRecepciones extends Component {
    render() {
        const {
            data_recepciones,
            fechaInicialRecepcionesChange,
            fechaFinalRecepcionesChange,
            fincaRecepcionesChange,
            pageRecepcionesChange,
            fecha_inicial_recepciones,
            fecha_final_recepciones,
            finca_recepciones,
            page_recepciones,
            listarRecepciones
        } = this.props

        let fechaInicialChange = null, fechaFinalChange = null, fincaChange = null, pageChange = null
        let fecha_inicial = null, fecha_final = null, finca = null, page = null

        fechaInicialChange = fechaInicialRecepcionesChange
        fechaFinalChange = fechaFinalRecepcionesChange
        fincaChange = fincaRecepcionesChange
        pageChange = pageRecepcionesChange
        fecha_inicial = fecha_inicial_recepciones
        fecha_final = fecha_final_recepciones
        finca = finca_recepciones
        page = page_recepciones

        return (
            <div className="mb-4 col-12">
                <Card
                    icon="historico_activo"
                    titulo="DATOS HISTÓRICOS"
                    noBorder
                    subtitulo="Recepciones"
                >
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial}
                            fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                            filtroFincaHistoricoChange={fincaChange} finca_historico_id={finca}
                            filtrar_historico={listarRecepciones}
                        />
                    </CardEmpty>
                    <div className="mb-4"/>
                    <Reporte {...this.props} pageChange={pageChange} page={page} recepciones={data_recepciones}/>
                </Card>
            </div>
        )
    }
}

export default HistoricoRecepciones