import { api } from 'api';
import moment from 'moment';

// Reporte de Pilas/Almacenes
const REPORTE_PILA_ALMACEN_LOADER        = 'REPORTE_PILA_ALMACEN_LOADER';
const REPORTE_PILA_ALMACEN_TIPO_REPORTE  = 'REPORTE_PILA_ALMACEN_TIPO_REPORTE';
const REPORTE_PILA_ALMACEN_FILTRO_FECHA_INICIAL = 'REPORTE_PILA_ALMACEN_FILTRO_FECHA_INICIAL';
const REPORTE_PILA_ALMACEN_FILTRO_FECHA_FINAL   = 'REPORTE_PILA_ALMACEN_FILTRO_FECHA_FINAL';
const REPORTE_PILA_ALMACEN_FILTRO_FINCAS        = 'REPORTE_PILA_ALMACEN_FILTRO_FINCAS';
const REPORTE_PILA_ALMACEN_FILTRO_PRODUCTOS     = 'REPORTE_PILA_ALMACEN_FILTRO_PRODUCTOS';
const REPORTE_PILA_ALMACEN_FILTRO_PILAS         = 'REPORTE_PILA_ALMACEN_FILTRO_PILAS_ALMACENES';
const REPORTE_PILA_ALMACEN_DETALLE_PAGE         = 'REPORTE_PILA_ALMACEN_DETALLE_PAGE';
const REPORTE_PILA_ALMACEN_DETALLE_DATA         = 'REPORTE_PILA_ALMACEN_DETALLE_DATA';
const REPORTE_PILA_ALMACEN_DETALLE_ITEM         = 'REPORTE_PILA_ALMACEN_DETALLE_ITEM';
const REPORTE_PILA_ALMACEN_RESUMEN_DATA         = 'REPORTE_PILA_ALMACEN_RESUMEN_DATA';

// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: REPORTE_PILA_ALMACEN_LOADER,
    loader,
});

// Reporte de Pilas/Almacenes
const bodegasTipoReporteChange = tipo_reporte => (dispatch) => {
    dispatch({type: REPORTE_PILA_ALMACEN_TIPO_REPORTE, tipo_reporte});
    // dispatch(getDataReporteBodegas());
};
// 
const bodegasFechaInicialChange = fecha_inicial => (dispatch) => {
    dispatch({type: REPORTE_PILA_ALMACEN_FILTRO_FECHA_INICIAL, fecha_inicial});
    // dispatch(getDataReporteBodegas());
};
const bodegasFechaFinalChange = fecha_final => (dispatch) => {
    dispatch({type: REPORTE_PILA_ALMACEN_FILTRO_FECHA_FINAL, fecha_final});
    // dispatch(getDataReporteBodegas());
};
const bodegasFincasChange = finca_ids => (dispatch) => {
    dispatch({type: REPORTE_PILA_ALMACEN_FILTRO_FINCAS, finca_ids});
    // dispatch(getDataReporteBodegas());
};
const bodegasProductosChange = producto_ids => (dispatch) => {
    dispatch({type: REPORTE_PILA_ALMACEN_FILTRO_PRODUCTOS, producto_ids});
    // dispatch(getDataReporteBodegas());
};
const bodegaPilasChange = pila_ids => (dispatch) => {
    dispatch({type: REPORTE_PILA_ALMACEN_FILTRO_PILAS, pila_ids});
    // dispatch(getDataReporteBodegas());
};
const bodegaDetallePageChange = detalle_page => (dispatch) => {
    dispatch({type: REPORTE_PILA_ALMACEN_DETALLE_PAGE, detalle_page});
    dispatch(getDataReporteBodegas(detalle_page));
};
const getDataReporteBodegas = () => (dispatch, getStore) => {
    const resource = getStore().reportes.reporte_pila_almacen;
    const params = {};
    params.fecha_inicial = moment(resource.fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00';
    params.fecha_final   = moment(resource.fecha_final).format("YYYY-MM-DD") + ' 23:59:59';
    params.producto_ids  = [resource.producto_ids];
    params.finca_ids     = [resource.finca_ids];
    params.pila_ids      = [resource.pila_ids];
    if (params.fecha_inicial && params.fecha_final) {
        dispatch(setLoader(true));
        switch (resource.tipo_reporte) {
            case 'DETALLE':
                params.page = resource.detalle_page;
                api.get('bodega/get_data_reporte_detalle_pilas', params).then((response) => {
                    dispatch({type: REPORTE_PILA_ALMACEN_DETALLE_DATA, detalle_data: response});
                }).catch(() => {
                }).finally(() => {
                    dispatch(setLoader(false));
                });
                break;
            case 'RESUMEN':
                api.get('bodega/get_data_reporte_resumen_pilas', params).then((response) => {
                    dispatch({type: REPORTE_PILA_ALMACEN_RESUMEN_DATA, resumen_data: response});
                }).catch(() => {
                }).finally(() => {
                    dispatch(setLoader(false));
                });
                break;
            default:
                dispatch(setLoader(false));
                break;
        }
    }
};

const getDetalleReportePilas = id => (dispatch, getStore) => {
    const resource = getStore().reportes.reporte_pila_almacen;
    const params = {};
    params.fecha_inicial = moment(resource.fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00';
    params.fecha_final   = moment(resource.fecha_final).format("YYYY-MM-DD") + ' 23:59:59';
    params.producto_ids  = [resource.producto_ids];
    params.finca_ids     = [resource.finca_ids];
    params.pila_ids      = [resource.pila_ids];
    params.id            = id
    if (params.fecha_inicial && params.fecha_final) {
        dispatch(setLoader(true))
        api.get(`bodega/get_detalle_pila/`, params)
            .then(response => {
                dispatch({
                    type: REPORTE_PILA_ALMACEN_DETALLE_ITEM,
                    detalle_item: response
                })
            })
            .catch(error => {console.log(error)})
            .finally(() => dispatch(setLoader(false)))
    }
}


const descargarReportePilas = () => (dispatch, getStore) => {
    const resource = getStore().reportes.reporte_pila_almacen;
    const params = {};
    params.fecha_inicial = moment(resource.fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00';
    params.fecha_final   = moment(resource.fecha_final).format("YYYY-MM-DD") + ' 23:59:59';
    params.producto_ids  = [resource.producto_ids];
    params.finca_ids     = [resource.finca_ids];
    params.pila_ids      = [resource.pila_ids];
    let url_descarga = '';
    let urlParams = '';
    if (params.fecha_inicial && params.fecha_final) {
        Object.keys(params).forEach(key => {
            urlParams+= `&${key}=${params[key]}`;
        });
        dispatch(setLoader(true));
        switch (resource.tipo_reporte) {
            case 'DETALLE':
                url_descarga = `/api/bodega/descargar_reporte_detalle_pilas?auth_token=${localStorage.getItem("token")}`;
                break;
            case 'RESUMEN':
                url_descarga = `/api/bodega/descargar_reporte_resumen_pilas?auth_token=${localStorage.getItem("token")}`;
                break;
        }
        url_descarga+= urlParams;
        window.location.replace(url_descarga);
        dispatch(setLoader(false));
    }
};

export const actions = {
    // Reporte de Pilas/Almacenes
    bodegasTipoReporteChange,
    bodegasFechaInicialChange,
    bodegasFechaFinalChange,
    bodegasFincasChange,
    bodegasProductosChange,
    bodegaPilasChange,
    bodegaDetallePageChange,
    getDataReporteBodegas,
    descargarReportePilas,
    getDetalleReportePilas,
};

// -----------------------------------
// Reducers
// -----------------------------------
export const reducers = {
    // Reporte de Pilas/Almacenes
    [REPORTE_PILA_ALMACEN_LOADER]: (state, { loader }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, loader,}
        };
    },
    [REPORTE_PILA_ALMACEN_TIPO_REPORTE]: (state, { tipo_reporte }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, tipo_reporte,}
        };
    },
    [REPORTE_PILA_ALMACEN_FILTRO_FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, fecha_inicial,}
        };
    },
    [REPORTE_PILA_ALMACEN_FILTRO_FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, fecha_final,}
        };
    },
    [REPORTE_PILA_ALMACEN_FILTRO_FINCAS]: (state, { finca_ids }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, finca_ids,}
        };
    },
    [REPORTE_PILA_ALMACEN_FILTRO_PRODUCTOS]: (state, { producto_ids }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, producto_ids,}
        };
    },
    [REPORTE_PILA_ALMACEN_FILTRO_PILAS]: (state, { pila_ids }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, pila_ids,}
        };
    },
    [REPORTE_PILA_ALMACEN_DETALLE_PAGE]: (state, { detalle_page }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, detalle_page,}
        };
    },
    [REPORTE_PILA_ALMACEN_DETALLE_DATA]: (state, { detalle_data }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, detalle_data,}
        };
    },
    [REPORTE_PILA_ALMACEN_DETALLE_ITEM]: (state, { detalle_item }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, detalle_item}
        }
    },
    [REPORTE_PILA_ALMACEN_RESUMEN_DATA]: (state, { resumen_data }) => {
        return {
            ...state,
            reporte_pila_almacen: {...state.reporte_pila_almacen, resumen_data,}
        };
    },
};

export const initialState = {
    // Reporte de Pilas/Almacenes
    reporte_pila_almacen: {
        loader: false,
        tipo_reporte: 'DETALLE',
        fecha_inicial: moment().startOf('month'),
        fecha_final: moment().endOf('month'),
        finca_ids: [],
        producto_ids: [],
        pila_ids: [],
        detalle_page: 1,
        detalle_data: {},
        detalle_item: {},
        resumen_data: {},
    },
};