import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';
import PasoTresForm from './PasoTresForm';
import PasoCuatroForm from './PasoCuatroForm';
import PasoCincoForm from './PasoCincoForm'
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";

export default class FincaCrear extends Component {
  state = {
      editar: false,
      step: 1
  };
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({editar: true});
        }
    }
    actualizar = (data) =>{
        this.props.editar(data, this.props.match.params.id)
    };
    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };
    componentDidMount() {
        this.props.cleanForm()
    }

    render() {
    const { crear, municipios } = this.props;
    const { updateData, loader, item } = this.props;
    const { step } = this.state;
    return (
        <div className="mb-4 col-12">
          <CardForm
              icon="configuraciones_activo"
              titulo="FINCA"
              subtitulo="Nueva">
              <div className="mb-4">
                  <ProgressBar
                      percent={step === 1 ? 10: step === 2 ? 30 : step === 3 ? 50 : step === 4 ? 70 : 90}
                      filledBackground="#035b2f"
                      stepPositions={[10,30,50,70,90]}
                  >
                      <Step transition="scale">
                          {({ accomplished, index }) => (
                              <div
                                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                              >
                                  <div className="step-number">
                                      {index + 1}

                                  </div>
                              </div>
                          )}
                      </Step>
                      <Step transition="scale" position="50%">
                          {({ accomplished, index }) => (
                              <div
                                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                              >
                                  <div className="step-number">
                                      {index + 1}

                                  </div>
                              </div>
                          )}
                      </Step>
                      <Step transition="scale">
                          {({ accomplished, index }) => (
                              <div
                                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                              >
                                  <div className="step-number">
                                      {index + 1}
                                  </div>
                              </div>
                          )}
                      </Step>
                      <Step transition="scale">
                          {({ accomplished, index }) => (
                              <div
                                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                              >
                                  <div className="step-number">
                                      {index + 1}
                                  </div>
                              </div>
                          )}
                      </Step>
                      <Step transition="scale">
                          {({ accomplished, index }) => (
                              <div
                                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                              >
                                  <div className="step-number">
                                      {index + 1}
                                  </div>
                              </div>
                          )}
                      </Step>
                  </ProgressBar>

              </div>
              <LoadMask loading={loader} blur>
                  {
                      this.state.editar ?
                          (<Fragment>
                              {step === 1 && (<PasoUnoForm onSubmit={this.nextStep} updateData={updateData}/>)}
                              {step === 2 && (<PasoDosForm onSubmit={this.nextStep} updateData={updateData}
                                                           previousStep={this.previousStep} municipios={municipios}/>)}
                              {step === 3 && (<PasoTresForm {...this.props} onSubmit={this.nextStep} updateData={updateData} previousStep={this.previousStep}/>)}
                              {step === 4 && (<PasoCuatroForm {...this.props} onSubmit={this.nextStep} updateData={updateData} previousStep={this.previousStep}/>)}
                              {step === 5 && (<PasoCincoForm onSubmit={this.actualizar} updateData={updateData} previousStep={this.previousStep}/>)}
                          </Fragment>):


                          (<Fragment>
                              {step === 1 && (<PasoUnoForm onSubmit={this.nextStep}/>)}
                              {step === 2 && (<PasoDosForm onSubmit={this.nextStep} previousStep={this.previousStep}
                                                           municipios={municipios}/>)}
                              {step === 3 && (<PasoTresForm {...this.props} onSubmit={this.nextStep} previousStep={this.previousStep}/>)}
                              {step === 4 && (<PasoCuatroForm {...this.props} onSubmit={this.nextStep} previousStep={this.previousStep}/>)}
                              {step === 5 && (<PasoCincoForm onSubmit={crear} previousStep={this.previousStep}/>)}
                          </Fragment>)
                  }
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
