import React, { Fragment } from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import {TableHeaderColumn} from 'react-bootstrap-table';
import moment from 'moment/moment';
import _ from 'lodash';
import Grid from '../../../Utils/Grid';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderKilogramo, RenderCM, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { renderLegend } from '../components';
import {standardActions} from "../../../Utils/Grid/StandardActions";
import { LATEX } from '../../../../../utility/constants';

const renderTooltip = (props) => {
    const { active, payload, label } = props;
    if (active && payload) {
        const dataKey = payload[0].dataKey ? payload[0].dataKey.replace('y_value_', '') : 'NaN';
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-0">Fecha: {label}</span>
                    <span className="mb-2">Tipo: {dataKey}</span>
                    { payload.map(item => {
                            return (<div key={item.x_value} className="d-flex flex-column">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="mr-2">Húmedos:</span>
                                <span className="mr-3"><RenderKilogramo value={item.payload.kilos_humedos} /></span>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <span className="mr-2">Secos:</span>
                                <span className="mr-3"><RenderKilogramo value={item.payload.kilos_secos} /></span>
                            </div>
                        </div>);
                    }) }
                </div>
            </CardEmpty>
        );
    }
    return null;
};

const expandComponent = (row) => {
    let _data = row.vaciados ? row.vaciados.slice() : [];
    return(
        <div className="row d-flex flex-row justify-content-center">
            <div className={`${row.ingreso && row.ingreso.tipo_materia && row.ingreso.tipo_materia.tipo === LATEX ? 'col-11' : 'col-8'} ml-5`}>
                <div className="tabla-adentro" style={{width: "100%"}}>
                    <BootstrapTable data={_data} headerStyle={ { backgroundColor: '#e24647' } }>
                        <TableHeaderColumn dataField="id" width="5%" isKey hidden/>
                        { row.ingreso && row.ingreso.tipo_materia && row.ingreso.tipo_materia.tipo === LATEX && (
                            <TableHeaderColumn dataField="cm_iniciales"
                                dataAlign="right"
                                dataFormat={cell => cell !== null ? <RenderCM value={cell} /> : '--'}
                            >
                                CMS. INICIAL
                            </TableHeaderColumn>
                        )  }
                        { row.ingreso && row.ingreso.tipo_materia && row.ingreso.tipo_materia.tipo === LATEX && (
                            <TableHeaderColumn dataField="cm_finales"
                                dataAlign="right"
                                dataFormat={cell => cell !== null ? <RenderCM value={cell} /> : '--'}
                            >
                                CMS. FINAL
                            </TableHeaderColumn>
                        )  }
                        <TableHeaderColumn dataField="peso_neto"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderKilogramo value={cell} decimalScale={2} /> : '--'}
                        >
                            PESO NETO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="bodega"
                            dataFormat={cell => cell ? cell.nombre : '--'}
                        >
                            DESTINO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="acopio"
                            dataFormat={cell => cell ? cell.nombre : 'Planta ERSA'}
                        >
                            ACOPIO
                        </TableHeaderColumn>
                        <TableHeaderColumn width="5%"/>
                    </BootstrapTable>
                </div>
            </div>
        </div>
    )
};

const Reporte = (props) => {
    const {
        pageChange, page,
        loader, reporte, setTab
    } = props;
    const TIPO_MATERIA = reporte.tipo_materia;
    // Procesamiento de la información para generar la gráfica de barras
    let fecha_inicio = null, fecha_final = null;
    let totales = {kilos_secos: 0, kilos_humedos: 0};
    switch(TIPO_MATERIA) {
        case 'LATEX':
            fecha_inicio = reporte.latex_fecha_inicial;
            fecha_final = reporte.latex_fecha_final;
            reporte.recepciones=reporte.recepciones_latex;
            break;
        case 'SOLIDO':
            fecha_inicio = reporte.solido_fecha_inicial;
            fecha_final = reporte.solido_fecha_final;
            reporte.recepciones=reporte.recepciones_solido;
            break;
    }
    let mensajeError = '';
    let diferenciaDias = 0;
    let listaObjs = [], dataChart = [], totalesMateria = {};
    if (fecha_inicio === null || fecha_final === null) {
        mensajeError = 'FECHA INICIAL O FINAL NULO';
    } else if (fecha_final.diff(fecha_inicio, 'days') < 0) {
        mensajeError = 'FILTRO DE FECHAS NO VÁLIDO';
    } else {
        diferenciaDias = fecha_final.diff(fecha_inicio, 'days');
        listaObjs = Array(diferenciaDias + 1);
        let iterFecha = moment(fecha_inicio).clone();
        for (let index = 0; index < listaObjs.length; index++) {
            listaObjs[index] = {
                fecha: iterFecha.format('D/M/YY'),
                num_dia: 'Día '+ (index + 1),
                kilos_humedos: 0,
                kilos_secos: 0,
            };
            iterFecha.add(1, 'days');
        }
        const movimientosAll = reporte.recepciones && reporte.recepciones.all_results ? reporte.recepciones.all_results : [];
        // Sumatoria de kilos secos de las recepciones
        let cpFechaInicio = moment(fecha_inicio.format("YYYY-MM-DD")+' 00:00:00');
        diferenciaDias >= 0 && movimientosAll && movimientosAll.forEach((movimiento) => {
            let cpFechaIngreso = moment(moment(movimiento.ingreso.fecha_entrada).format("YYYY-MM-DD")+' 00:00:00');
            const index = cpFechaIngreso.diff(cpFechaInicio, 'days');
            if (index < listaObjs.length && index >= 0) {
                listaObjs[index].kilos_humedos+= movimiento.peso_neto;
                listaObjs[index].kilos_secos+= movimiento.peso_neto * (movimiento.analisis_laboratorio.drc_promedio || 0);
            }
            const materiaPrima = movimiento.materia_prima;
            let materia = totalesMateria[materiaPrima.id];
            if (materia === undefined) {
                materia = {...materiaPrima, kilos_humedos: 0, kilos_secos: 0};
                totalesMateria[materiaPrima.id] = materia;
            }
            materia.kilos_humedos+= movimiento.peso_neto;
            materia.kilos_secos+= movimiento.peso_neto * (movimiento.analisis_laboratorio.drc_promedio || 0);
        });
        // Construcción de la data para el gráfico
        listaObjs.forEach(obj => {
            if (obj.kilos_secos != 0 || obj.kilos_humedos != 0) {
                const data = {
                    num_dia: obj.num_dia,
                    x_value: obj.fecha,
                    kilos_secos: obj.kilos_secos,
                    kilos_humedos: obj.kilos_humedos,
                }
                data[TIPO_MATERIA] = obj.kilos_secos;
                data[`y_value_${TIPO_MATERIA}`] = obj.kilos_humedos;
                dataChart.push(data);
                totales.kilos_secos+= obj.kilos_secos;
                totales.kilos_humedos+= obj.kilos_humedos;
            }
            
        });
        // Cálculo del peso_neto de los vaciados
        reporte.recepciones && reporte.recepciones.results.map(movimiento => {
            let peso_vaciados = 0;
            if (movimiento.vaciados && movimiento.vaciados.length > 0) {
                movimiento.vaciados.forEach(mov => peso_vaciados+= mov.peso_neto);
            } else {
                peso_vaciados = movimiento.peso_neto;
            }
            movimiento.peso_vaciados = peso_vaciados;
            return movimiento;
        });
    }

    return(
        <LoadMask loading={loader} blur>
            <div className="mb-4">
                <CardEmpty>
                    <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                        <div className="col-12 col-md-12 d-flex flex-wrap justify-content-center align-items-center">
                            <div className="col-12 col-md-2 p-0 d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                                <h6 className="m-0 mb-3 mb-md-0 bold">TOTALES (KG. HÚMEDOS)</h6>
                            </div>
                            <div className="col-12 col-md-6 col-sm-12 d-flex flex-wrap flex-column flex-md-row p-0 mb-3 mb-md-0">
                                { Object.values(totalesMateria).map(materia => {
                                    return (
                                        <div key={materia.id} className="col-md-6 col-sm-12 d-flex flex-row justify-content-between justify-content-md-between align-items-center mb-1 mb-md-2 px-1 px-md-2">
                                            <span className="mx-2 m-md-0 gris bold">{materia.nombre}</span>
                                            <h5 className="mx-2 m-md-0" style={{whiteSpace: 'nowrap'}}>{<RenderKilogramo value={materia.kilos_humedos}/>}</h5>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                    <span className="m-0 ml-0 ml-md-5 bold">TOTAL (KG. HÚMEDOS)</span>
                                    <h3 className="mb-1 ml-3 gris">{<RenderKilogramo value={totales.kilos_humedos} />}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardEmpty>
            </div>
            <div className="mb-4">
                <CardEmpty>
                    <div className="d-flex flex-column">
                        <div className="flex-fill d-flex justify-content-start">
                            { mensajeError.length === 0
                                ? <ResponsiveContainer width="85%" height="100%" minWidth={500} minHeight={300}>
                                    <BarChart width={730} height={250} data={dataChart}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="x_value" />
                                        <YAxis />
                                        <Tooltip content={renderTooltip} />
                                        <Legend content={renderLegend} align="left" verticalAlign="middle" layout="vertical" iconType="circle" title="GRÁFICA DE RECEPCIONES" />
                                        <Bar dataKey={`y_value_${TIPO_MATERIA}`} value={TIPO_MATERIA} fill={TIPO_MATERIA === 'LATEX' ? '#359d9e': '#215273'}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                                : <div className="mb-3 rojo bold">{mensajeError}</div>
                            }
                        </div>
                    </div>
                </CardEmpty>
            </div>
            <div className="mb-5">
                <CardEmpty>
                    <Grid hover striped loading={loader} data={reporte.recepciones}
                        pagination onPageChange={pageChange} page={page}
                        expandableRow={(row) => row && row.vaciados && row.vaciados.length > 0} expandComponent={expandComponent}
                        className="pb-4 pt-3"
                    >
                        <TableHeaderColumn dataField="id" isKey width="2%" dataFormat={cell => <div></div>} />
                        <TableHeaderColumn dataField="ingreso"
                            dataAlign="center"
                            width="5%"
                            dataFormat={standardActions({ver_recepcion_reporte : "ver_recepcion_reporte"})}
                        />
                        <TableHeaderColumn dataField="ingreso"
                            width="5%"
                            dataFormat={cell => cell && cell.id ? cell.id.toString().padStart(3, 0) : '--'}
                        >
                            ID
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ingreso"
                            dataFormat={cell => cell && cell.fecha_entrada ? moment(cell.fecha_entrada).format('DD/MM/YYYY') : '--'}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ingreso"
                            dataFormat={cell => cell && cell.finca && cell.finca.nombre ? cell.finca.nombre : '--'}
                        >
                            FINCA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="materia_prima"
                            dataFormat={cell => _.get(cell, 'nombre', '--')}
                        >
                            MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="peso_neto"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderKilogramo value={cell} /> : '--'}
                        >
                            KG HÚMEDOS
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="analisis_laboratorio"
                            dataAlign="right"
                            dataFormat={(cell, row) => cell && cell.drc_promedio && row.peso_neto ? <RenderKilogramo value={row.peso_neto*cell.drc_promedio} /> : '--'}
                        >
                            KG SECOS
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="vaciados"
                            dataFormat={cell => {
                                let tipoVaciado = <span className="bold">MÚLTIPLE</span>;
                                if (cell.length === 1) {
                                    tipoVaciado = _.get(cell[0], 'bodega.nombre', 'NO DEFINIDO');
                                }
                                return tipoVaciado;
                            }}
                        >
                            DESTINO
                        </TableHeaderColumn>
                        { TIPO_MATERIA === 'LATEX' && (
                            <TableHeaderColumn dataField="peso_vaciados"
                                dataAlign="right"
                                dataFormat={cell => cell ? <RenderKilogramo value={cell}/> : '--'}
                            >
                                PESO VACIADO
                            </TableHeaderColumn>
                        ) }
                        { TIPO_MATERIA === 'LATEX' && (
                            <TableHeaderColumn dataField="peso_vaciados"
                                dataAlign="right"
                                dataFormat={(cell, row) => cell ? <RenderKilogramo value={row.peso_neto - cell}/> : '--'}
                            >
                                DIFERENCIA
                            </TableHeaderColumn>
                        ) }
                        { TIPO_MATERIA === 'LATEX' && (
                            <TableHeaderColumn dataField="peso_vaciados"
                                dataAlign="right"
                                dataFormat={(cell, row) => cell ? <RenderNumberPercentage value={(row.peso_neto - cell)/row.peso_neto}/> : '--'}
                            >
                                DIFERENCIA %
                            </TableHeaderColumn>
                        ) }
                        <TableHeaderColumn dataField="id" width="2%" dataFormat={cell => <div></div>} />
                    </Grid>
                </CardEmpty>
            </div>
        </LoadMask>
    )
};

export default Reporte;
