import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from 'lodash';
import {DRC_PROMEDIO, DRC_ANTERIOR, LABORATORIO, DEFINIDO, PRECIO_DEFINIDO, PRECIO_INTROSA, PRECIO_ERSA, FLETE_COSTO_NO_DEFINIDO, LATEX, SOLIDO, TIPO_COSTO_FLETE, PAGINATION_SIZE_PER_PAGE} from '../../../utility/constants'
import moment from 'moment';

const LOADER = 'LOADER_COMPRA';
const FORCE_UPDATE_DATA = 'COMPRAS_FORCE_UPDATE_DATA';
const DATA = 'DATA_COMPRA';
const ITEM_DATA = 'ITEM_COMPRA';
const PAGE = 'PAGE_COMPRA';
const ORDERING = 'ORDERING_COMPRA';
const SEARCH = 'SEARCH_COMPRA';
const TAB_COMPRAS = 'TAB_COMPRAS';
const DATOS_DRC = 'DATOS_DRC';
const TIPO_DRC = 'TIPO_DRC';
const DATOS_ORDEN_TEMP = 'DATOS_ORDEN_TEMP';
const FILTRO_FINCA_RECEPCIONES = 'FILTRO_FINCA_RECEPCIONES';
const FILTRO_FECHA_INICIO_RECEPCIONES = 'FILTRO_FECHA_INICIO_RECEPCIONES';
const FILTRO_FECHA_FINAL_RECEPCIONES = 'FILTRO_FECHA_FINAL_RECEPCIONES';
const LISTA_INDEX_VALIDADOS = 'LISTA_INDEX_VALIDADOS';
const REPORTE_TIPO_TRANSPORTE = 'REPORTE_COSTO_FLETE_TIPO_TRANSPORTE';
const REPORTE_ERSA_PAGE          = 'REPORTE_COSTO_FLETE_ERSA_PAGE';
const REPORTE_ERSA_FECHA_INICIAL = 'REPORTE_COSTO_FLETE_ERSA_FECHA_INICIAL';
const REPORTE_ERSA_FECHA_FINAL   = 'REPORTE_COSTO_FLETE_ERSA_FECHA_FINAL';
const REPORTE_ERSA_FINCA         = 'REPORTE_COSTO_FLETE_ERSA_FINCA';
const REPORTE_ERSA_TRANSPORTISTA = 'REPORTE_COSTO_FLETE_ERSA_TRANSPORTISTA';
const REPORTE_EXTERNO_PAGE          = 'REPORTE_COSTO_FLETE_EXTERNO_PAGE';
const REPORTE_EXTERNO_FECHA_INICIAL = 'REPORTE_COSTO_FLETE_EXTERNO_FECHA_INICIAL';
const REPORTE_EXTERNO_FECHA_FINAL   = 'REPORTE_COSTO_FLETE_EXTERNO_FECHA_FINAL';
const REPORTE_EXTERNO_FINCA         = 'REPORTE_COSTO_FLETE_EXTERNO_FINCA';
const REPORTE_EXTERNO_TRANSPORTISTA = 'REPORTE_COSTO_FLETE_EXTERNO_TRANSPORTISTA';
const REPORTE_COSTO_FLETES = 'REPORTE_COSTO_FLETE_DATA';
const TIPO_FLETE_GRAFICO = 'TIPO_FLETE_GRAFICO';

var fecha = moment().startOf('week').isoWeekday(0);
var lista_arrays_validados = [];

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setReporteCostoFletes = costo_fletes => ({
    type: REPORTE_COSTO_FLETES,
    costo_fletes,
});

const setTipoFleteGrafico = tipo_flete_grafica => ({
    type: TIPO_FLETE_GRAFICO,
    tipo_flete_grafica,
});


// ------------------------------------
// Actions
// ------------------------------------

const changeTipoFleteGrafico = (tipo_flete_grafica) => (dispatch, getStore) => {
    dispatch(setTipoFleteGrafico(tipo_flete_grafica));
}

const listar = (page = 1, estado = false) => (dispatch, getStore) => {
    const resource = getStore().compras;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (resource.finca > 0) {
        params.id_finca = resource.finca;
    }
    params.fecha_inicial = moment(resource.fecha_inicio).format("YYYY-MM-DD") + " 00:00:00";
    params.fecha_final = moment(resource.fecha_final).format("YYYY-MM-DD") + " 23:59:59";   // Para incluir cualquier hora del día
    if(estado)
        params.estado = estado;
    dispatch(setLoader(true));
    api.get('compra/get_fincas_orden_compra', params).then((response) => {
        // Descripción de los pasos realizados:
        // - Acorta all_results a la cantidad de resultados devueltos por el endpoint.
        //   - Si response.count > all_results.length: Hay nuevos datos en BD. En este caso all_results mantiene su mismo tamaño.
        //   - Si response.count < all_results.length: Hay menos resultados en BD. Es decir, en memoria hay data que ya no debería aparecer y por lo tanto all_results se recorta.
        // - Si all_results.length < response.count se insertan '0' en all_results hasta que all_results.length === response.count
        // - El listado de items devueltos por el response se reemplazan en la 'pagina' en all_results en que deberían ir.
        //   En los index de la página correspondiente puede haber data o puede haber '0'; en cualquier caso se sustituye con Array.splice(start, deleteCount, item[, ...])
        //   @see https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/Array/splice
        //   Por ejemplo:
        //     page: 3
        //     response.count: 55
        //     response.results.length: 10
        //     all_results.length <== 55
        //     all_results[29 : 39] <== response.results
        const { data } = resource;
        data.count = response.count;
        data.all_results = data.all_results.slice(0, response.count);
        const faltantes = response.count - data.all_results.length;
        const start_index = (page-1)*PAGINATION_SIZE_PER_PAGE;
        faltantes >= 0 && Array(faltantes).fill(0).forEach(value => data.all_results.push(value));
        response.results.forEach((item, index) => {
            data.all_results.splice(start_index + index, 1, item);
        });
        dispatch(setData({...data}));
        dispatch(pageChange(page));
        dispatch({type: FORCE_UPDATE_DATA, force_update_data: false});
    }).catch(error => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

// El parámetro sinkilos es para evitar que regrese el total de kilos desde el backend,
// para óptimizar el tiempo de carga de los datos enn el frontend
const leer = (id, estado = false,sinkilos=false) =>  async (dispatch, getStore) => {
    dispatch(setLoader(true));
    const resource = getStore().compras;
    const params = {};
    if(estado)
        params.estado = estado;
        params.id_finca = id;
        params.fecha_inicial = moment(resource.fecha_inicio).format("YYYY-MM-DD") + " 00:00:00";
        params.fecha_final = moment(resource.fecha_final).format("YYYY-MM-DD") + " 23:59:59";   // Para incluir cualquier hora del día
        if(sinkilos){ params.sinkilos=sinkilos}

        api.get('compra/get_finca_compra', params).then( (response) => {
        dispatch(setItem(response));
        const index_validos = getStore().compras.item_rows_validos;
        var valores = _.cloneDeep(response);

        index_validos.forEach(index => valores['ingresos'][index].validado = true);
        const data = _.cloneDeep(valores)
        dispatch(initializeForm('ComprasForm', data))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const crear = (data, uuid) => async (dispatch, getStore) => {
    let ordenCompraCreada = false;
    const temp = data.ingresos;
    const detalles = [];
    const data_completa = {}
    var cont = 0;

    temp.map((ingreso, index) => {
        if (ingreso.validado) {
            detalles.push(ingreso)
            cont += 1;
        }
    })

    if (cont <= 0) {
        NotificationManager.error('Debe validar al menos una recepción.', 'ERROR');
        return true;//Habilita de nuevo el boton guardar si hay un error
    }
    // Se verifica que todos los Ingresos validados tenga un subtotal mayor a cero
    let ingresos_validos = true;
    detalles.map(movimiento => {
        if (movimiento.vehiculo.transportista === null) {
            // Sumatoria del costo de flete, si el vehículo es de la finca
            movimiento.total_dolares+= movimiento.kilos_secos*movimiento.costo_flete;
            movimiento.total_quetzales = movimiento.total_dolares*movimiento.cambio_dia;
        }
        if (!(movimiento.total_dolares && parseFloat(movimiento.total_dolares) > 0)) {
            NotificationManager.error(`El ingreso ${movimiento.id_ingreso} tiene un subtotal igual a cero`, 'ERROR');
            ingresos_validos = false;
        }
        return movimiento;
    });

    if (cont >= 1 && ingresos_validos) {
        data_completa.detalles = detalles;
        data_completa.id_finca = data.finca.id;
        data_completa.uuid = uuid;
        try {
            dispatch(setLoader(true));
            const resultado = await api.post('compra', data_completa);
            dispatch(clearIndexValidados());
            // La información de la compra generada se eliminará de la data previamente cargada de forma manual, por lo que
            // no se forzará en recargar la data. Se eliminará siempre que se haya registrado la compra de todos los ingresos.
            const { data: data_compras } = getStore().compras;
            if (data_compras && data_compras.all_results) {
                data_compras.all_results = data_compras.all_results.filter(item => {
                    // Cada item es una Finca que contiene el Array de Ingresos
                    if (item.id === data_completa.id_finca) {
                        const ingresos_validados = data_completa.detalles.map(mov => mov.id_ingreso);
                        item.ingresos = item.ingresos.filter(ingreso => !ingresos_validados.includes(ingreso.id));
                        return item.ingresos.length > 0;
                    }
                    return true;
                });
                dispatch(setData(data_compras));
                dispatch(pageChange(1));
            }
            NotificationManager.success('Orden compra generada correctamente', 'Éxito', 3000);
            ordenCompraCreada = true;
        } catch (error) {
            NotificationManager.error(_.get(error, 'detail', 'Ocurrió un error inesperado. Regrese a la vista anterior y recargue nuevamente.'), 'ERROR');
        } finally {
            dispatch(setLoader(false));
            dispatch(push('/compras'));
        }
    }
    return ordenCompraCreada;
};
const loadDataModal = (data) => (dispatch) => {
    dispatch({type: DATOS_DRC, datos_drc: {...data}})
};

const ordenCompraTemp = (data) => (dispatch, getStore) => {
    const datos_orden_temp = {data}
    dispatch({type: DATOS_ORDEN_TEMP, datos_orden_temp});
};

const clearDRCModal = () => (dispatch) => {
    const vacio = {
        drc_radioB: 30,
        defnidio: 0
    };
  dispatch({type: DATOS_DRC, datos_drc: vacio})
};

const clearIndexValidados = () => (dispatch) => {
    lista_arrays_validados.length = 0;
    dispatch({type: LISTA_INDEX_VALIDADOS, item_rows_validos: lista_arrays_validados});
};

const updateData = (data) => (dispatch, getStore) => {
    const storeForm = getStore().compras;
    const valores = _.cloneDeep(storeForm);
    valores['datos_drc'][data.index] = data;
    dispatch(initializeForm('ComprasForm', valores))
};

/**
 * Funciona para actualizar el drc final, el cual es que se guarda en MOVIMIENTO
 * @param {Es el id del ingreso al cual se le esta definiendo un DRC Final} id
 * @param {Son los fields que se mandan desde la modal para definir el precio a guardar} data
 * @param {Es el id que se asigna en el listado para presentar los datos} id_compra
 * @param {Es item que recibe la modal (datos_drc)} item_row
 */
/*
    docstring
*/
const actualizarDRCFinal = (id, data=null, id_compra, item_row=null,filtrar=false) => (dispatch, getStore) => {
    const temp = {}
    lista_arrays_validados.push(item_row.index)
    if (data.drc_radioB === DRC_ANTERIOR) {
        temp.drc_final = data.drc_anterior
    }
    if (data.drc_radioB === DRC_PROMEDIO) {
        temp.drc_final = data.drc_promedio
    }
    if (data.drc_radioB === LABORATORIO) {
        temp.drc_final = data.laboratorio
    }
    if (data.drc_radioB === DEFINIDO) {
        temp.drc_final = (data.definido/100)
    }
    temp.drc_modificaco = true
    temp.tipo_drc_final = data.drc_radioB;
    api.put(`compra/${id}/definir_drc_final`, temp).then(() => {
        NotificationManager.success(`El DRC fue actualizado correctamente.`, 'Éxito', 3000);
        dispatch({type: TIPO_DRC, tipo_drc: data.drc_radioB});
        dispatch({type: LISTA_INDEX_VALIDADOS, item_rows_validos: lista_arrays_validados});
        // Se condiciona porque al momento de validar una compra llama la acción de leer dos veces
        // la primera es está, la segunda está en actualizarPrecioFinal
        if(!filtrar){dispatch(leer(id_compra))}
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    })
};

const actualizarPrecioFinal = (id, data=null, id_compra, id_finca) => (dispatch) => {
    const temp = {}
    if (data.tipo_precioB === PRECIO_INTROSA) {
        temp.precio_kilogramo_ersa = 0
        temp.tipo_precio = PRECIO_INTROSA
    }
    if (data.tipo_precioB === PRECIO_ERSA) {
        temp.precio_kilogramo_ersa = data.precio_ersa
        temp.tipo_precio = PRECIO_ERSA
    }
    if (data.tipo_precioB === PRECIO_DEFINIDO) {
        temp.precio_kilogramo_ersa = data.precio_definido
        temp.tipo_precio = PRECIO_ERSA
    }
    temp.diferencial_introsa = data.diferencial
    temp.subtotal_dolares = data.subtotal_dolares
    temp.finca = id_finca
    temp.tipo_costo_flete = data.tipo_costo_flete;
    temp.costo_flete = data.costo_flete === '' ? 0 : parseFloat(data.costo_flete);
    api.put(`compra/${id}/definir_precio_final`, temp).then(() => {
        NotificationManager.success(`El precio fue actualizado correctamente.`, 'Éxito', 3000);
        // dispatch({type: TIPO_DRC, tipo_drc: data.drc_radioB});
        dispatch(leer(id_compra,false,true))
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    })
};

const pageChange = page => (dispatch, getStore) => {
    const { data } = getStore().compras;
    const results = data.all_results.slice((page-1)*PAGINATION_SIZE_PER_PAGE, page*PAGINATION_SIZE_PER_PAGE);
    let count = 0;
    results.forEach(value => value === 0 && count++);
    if (count > 0) {
        dispatch(listar(page));
    } else {
        data.results = results;
        dispatch(setData({...data}));
    }
    dispatch(setPage(page));
    dispatch(setData(data));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().laboratorio.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const filtroFincaChange = finca => (dispatch) => {
    dispatch({type: FILTRO_FINCA_RECEPCIONES, finca});
};

const fechaFinal = fecha_final => (dispatch) => {
    dispatch({type: FILTRO_FECHA_FINAL_RECEPCIONES, fecha_final});
};

const fechaInicio = fecha_inicio => (dispatch) => {
    dispatch({type: FILTRO_FECHA_INICIO_RECEPCIONES, fecha_inicio});
};

const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({type: TAB_COMPRAS, tab});
    if (tab === "PENDIENTES"){
        dispatch(listar(1, 'activas'))
    }else {
        dispatch(listar(1, 'finalizadas'))
    }
};
// Funciones para el Reporte de costo de fletes
const getCostoFletesParaReporte = (resetPage = true, getAllResults = true) => async (dispatch, getStore) => {
    // Construcción de los parámetros de búsqueda. Las fechas son requeridas
    const resource = getStore().compras;
    const reporte = resource.reporte_costo_fletes;
    let params = {};
    params.tipo_transporte  = reporte.tipo_transporte;
    switch(reporte.tipo_transporte) {
        case 'PROPIO':
            // Los vehículos de una finca son aquellos que NO TIENEN asignado un transportista (por lo que este filtro no aplica)
            if (!!resetPage) {
                dispatch({type: REPORTE_ERSA_PAGE, ersa_page: 1});
                params.page = 1;
            } else {
                params.page = reporte.ersa_page;
            }
            reporte.ersa_finca_id !==   -1         && (params.finca_id         = reporte.ersa_finca_id);
            // reporte.ersa_transportista_id !== -1   && (params.transportista_id = reporte.ersa_transportista_id);
            reporte.ersa_fecha_inicial !== null    && (params.fecha_inicial    = moment(reporte.ersa_fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00');
            reporte.ersa_fecha_final !== null      && (params.fecha_final      = moment(reporte.ersa_fecha_final).format("YYYY-MM-DD") + ' 23:59:59');
            break;
        case 'EXTERNO':
            if (!!resetPage) {
                dispatch({type: REPORTE_EXTERNO_PAGE, externo_page: 1});
                params.page = 1;
            } else {
                params.page = reporte.externo_page;
            }
            reporte.externo_finca_id !== -1           && (params.finca_id         = reporte.externo_finca_id);
            reporte.externo_transportista_id !== -1   && (params.transportista_id = reporte.externo_transportista_id);
            reporte.externo_fecha_inicial !== null    && (params.fecha_inicial    = moment(reporte.externo_fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00');
            reporte.externo_fecha_final !== null      && (params.fecha_final      = moment(reporte.externo_fecha_final).format("YYYY-MM-DD") + ' 23:59:59');
            break;
    }
    if (params.fecha_final && params.fecha_final) {
        // Inicio de la petición al servidor
        dispatch(setLoader(true));
        try {
            params.paginar_data = true;
            const costoFletes = await api.get('compra/get_reporte_costo_fletes', params);
            if (!!getAllResults) {
                params = _.omit(params, ['paginar_data']);
                const costoFletesAll = await api.get('compra/get_reporte_costo_fletes', params);
                costoFletes.all_results = costoFletesAll.results;
            }
            dispatch(setReporteCostoFletes({ ...reporte.costo_fletes, ...costoFletes }));
        } catch(error) {
            NotificationManager.error((error && error.detail) || 'Ocurrió un error mientras se intentaba recuperar la información para el reporte', 'ERROR', 3000);
        } finally {
            dispatch(setLoader(false));
        }
    }
}
const tipoTransporteChange = tipo_transporte => (dispatch) => {
    dispatch({type: REPORTE_TIPO_TRANSPORTE, tipo_transporte});
    //dispatch(getCostoFletesParaReporte(false));
}
const pageReporteErsaChange = (ersa_page) => (dispatch) => {
    dispatch({type: REPORTE_ERSA_PAGE, ersa_page})
    //dispatch(getCostoFletesParaReporte(false, false))
}
const fechaInicialErsaChange = (ersa_fecha_inicial) => (dispatch) => {
    dispatch({type: REPORTE_ERSA_FECHA_INICIAL, ersa_fecha_inicial});
    //dispatch(getCostoFletesParaReporte());
}
const fechaFinalErsaChange = (ersa_fecha_final) => (dispatch) => {
    dispatch({type: REPORTE_ERSA_FECHA_FINAL, ersa_fecha_final});
    //dispatch(getCostoFletesParaReporte());
}
const fincaErsaChange = (ersa_finca_id) => (dispatch) => {
    dispatch({type: REPORTE_ERSA_FINCA, ersa_finca_id});
    //dispatch(getCostoFletesParaReporte());
}
const transportistaErsaChange = (ersa_transportista_id) => (dispatch) => {
    dispatch({type: REPORTE_ERSA_TRANSPORTISTA, ersa_transportista_id});
    //dispatch(getCostoFletesParaReporte());
}
const pageReporteExternoChange = (externo_page) => (dispatch) => {
    dispatch({type: REPORTE_EXTERNO_PAGE, externo_page})
    //dispatch(getCostoFletesParaReporte(false, false))
}
const fechaInicialExternoChange = (externo_fecha_inicial) => (dispatch) => {
    dispatch({type: REPORTE_EXTERNO_FECHA_INICIAL, externo_fecha_inicial});
    //dispatch(getCostoFletesParaReporte());
}
const fechaFinalExternoChange = (externo_fecha_final) => (dispatch) => {
    dispatch({type: REPORTE_EXTERNO_FECHA_FINAL, externo_fecha_final});
    //dispatch(getCostoFletesParaReporte());
}
const fincaExternoChange = (externo_finca_id) => (dispatch) => {
    dispatch({type: REPORTE_EXTERNO_FINCA, externo_finca_id});
    //dispatch(getCostoFletesParaReporte());
}
const transportistaExternoChange = (externo_transportista_id) => (dispatch) => {
    dispatch({type: REPORTE_EXTERNO_TRANSPORTISTA, externo_transportista_id});
    //dispatch(getCostoFletesParaReporte());
}

export const actions = {
    listar,
    leer,
    crear,
    pageChange,
    searchChange,
    onSortChange,
    setTab,
    loadDataModal,
    ordenCompraTemp,
    clearDRCModal,
    updateData,
    actualizarDRCFinal,
    actualizarPrecioFinal,
    filtroFincaChange,
    fechaFinal,
    fechaInicio,
    clearIndexValidados,
    // Reporte de liquidaaciones
    tipoTransporteChange,
    pageReporteErsaChange,
    fechaInicialErsaChange,
    fechaFinalErsaChange,
    fincaErsaChange,
    transportistaErsaChange,
    pageReporteExternoChange,
    fechaInicialExternoChange,
    fechaFinalExternoChange,
    fincaExternoChange,
    transportistaExternoChange,
    getCostoFletesParaReporte,
    changeTipoFleteGrafico,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [FORCE_UPDATE_DATA]: (state, { force_update_data }) => {
        return {
            ...state,
            force_update_data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [TAB_COMPRAS]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [DATOS_DRC]: (state, { datos_drc }) => {
        return {
            ...state,
            datos_drc,
        };
    },
    [TIPO_DRC]: (state, { tipo_drc }) => {
        return {
            ...state,
            tipo_drc,
        };
    },
    [DATOS_ORDEN_TEMP]: (state, { datos_orden_temp }) => {
        return {
            ...state,
            datos_orden_temp,
        };
    },
    [FILTRO_FINCA_RECEPCIONES]: (state, {finca}) => {
        return {
            ...state,
            finca,
        };
    },
    [FILTRO_FECHA_FINAL_RECEPCIONES]: (state, {fecha_final}) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [FILTRO_FECHA_INICIO_RECEPCIONES]: (state, {fecha_inicio}) => {
        return {
            ...state,
            fecha_inicio,
        };
    },
    [LISTA_INDEX_VALIDADOS]: (state, {item_rows_validos}) => {
        return {
            ...state,
            item_rows_validos,
        };
    },
    [REPORTE_TIPO_TRANSPORTE]: (state, {tipo_transporte}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, tipo_transporte},
        };
    },
    [REPORTE_ERSA_PAGE]: (state, {ersa_page}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, ersa_page},
        };
    },
    [REPORTE_ERSA_FECHA_INICIAL]: (state, {ersa_fecha_inicial}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, ersa_fecha_inicial},
        };
    },
    [REPORTE_ERSA_FECHA_FINAL]: (state, {ersa_fecha_final}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, ersa_fecha_final},
        };
    },
    [REPORTE_ERSA_FINCA]: (state, {ersa_finca_id}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, ersa_finca_id},
        };
    },
    [REPORTE_ERSA_TRANSPORTISTA]: (state, {ersa_transportista_id}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, ersa_transportista_id},
        };
    },
    [REPORTE_EXTERNO_PAGE]: (state, {externo_page}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, externo_page},
        };
    },
    [REPORTE_EXTERNO_FECHA_INICIAL]: (state, {externo_fecha_inicial}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, externo_fecha_inicial},
        };
    },
    [REPORTE_EXTERNO_FECHA_FINAL]: (state, {externo_fecha_final}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, externo_fecha_final},
        };
    },
    [REPORTE_EXTERNO_FINCA]: (state, {externo_finca_id}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, externo_finca_id},
        };
    },
    [REPORTE_EXTERNO_TRANSPORTISTA]: (state, {externo_transportista_id}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, externo_transportista_id},
        };
    },
    [REPORTE_COSTO_FLETES]: (state, {costo_fletes}) => {
        return {
            ...state,
            reporte_costo_fletes: {...state.reporte_costo_fletes, costo_fletes},
        };
    },
    [TIPO_FLETE_GRAFICO]: (state, {tipo_flete_grafica}) => {
        return {
            ...state,
            tipo_flete_grafica,
        };
    }
};

export const initialState = {
    loader: false,
    page: 1,
    data: {
        count: 0,
        results: [],
        all_results: [],
    },
    tipo_flete_grafica: {
        value: SOLIDO, label: 'SOLIDO'
    },
    force_update_data: false,
    item: {},
    ordering: '',
    search: '',
    tab: 'PENDIENTES',
    datos_drc: {},
    datos_orden_temp: {},
    finca: -1,
    fecha_final: moment(fecha).startOf('week').isoWeekday(6),
    fecha_inicio: moment(fecha).startOf('week').isoWeekday(0),
    tipo_drc: LABORATORIO,
    item_rows_validos:lista_arrays_validados,
    // Variables para el reporte de Costo de fletes
    reporte_costo_fletes: {
        // PROPIO:  La finca proporciona su propio transporte. Negociación de costo por kg.
        // EXTERNO: Entre Ríos proporciona el transporte (vehículo de recepción con transportista). Negociación por tarifa.
        tipo_transporte: 'PROPIO',
        ersa_page: 1,
        ersa_fecha_inicial: moment().startOf('month'),
        ersa_fecha_final: moment().endOf('month'),
        ersa_finca_id: -1,
        ersa_transportista_id: -1,
        externo_page: 1,
        externo_fecha_inicial: moment().startOf('month'),
        externo_fecha_final: moment().endOf('month'),
        externo_finca_id: -1,
        externo_transportista_id: -1,
        costo_fletes: {
            count: 0,
            results: [],
            all_results: [],
        },
    }
};

export default handleActions(reducers, initialState);
