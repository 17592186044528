import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../../FiltrosHeader';
import Listado from './Listado';
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import { actions as printActions } from 'Utils/Print';

export default class Bitacora extends Component {
    componentWillMount() {
        const { getDataBitacora } = this.props;
        // getDataBitacora();
    }

    render() {
        const {
            filtroBitacoraFechaInicial,
            filtroBitacoraFechaFinal,
            filtroAccionBitacoraChange,
            filtroBitacoraUsuarios,
            pageBitacora,
            fecha_inicial_bitacora,
            fecha_final_bitacora,
            page_bitacora,
            accion_bitacora,
            usuario_bitacora,
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="BITACORA"
                    noBorder
                    subtitulo="Reporte">
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaFinalChange={filtroBitacoraFechaFinal} fechaInicialChange={filtroBitacoraFechaInicial}
                            filtroAccionesBitacoraChange={filtroAccionBitacoraChange} accion_bitacora={accion_bitacora}
                            fecha_inicial={fecha_inicial_bitacora} fecha_final={fecha_final_bitacora}
                            filtroUsuarioChange={filtroBitacoraUsuarios} usuario_id={usuario_bitacora}
                            filtrarXReporte={()=>{this.props.getDataBitacora()}}
                            labelXReporte={"Filtrar"}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Listado {...this.props} onPageChange={pageBitacora} page={page_bitacora}></Listado>
                </Card>
            </div>
        )
    }
}
