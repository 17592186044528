import React, {Component} from 'react';
import classNames from 'classnames';
import AsyncSelect from 'react-select/lib/Async';
import { api } from 'api';
import Card from 'Utils/Cards/Card';
import Reporte from './Reporte';

let listaFincas = [];

const getFincas = (params) => {
    return api.get('finca', { params, no_paginar: true, })
        .then((data) => { listaFincas = data ? data.results : []; return listaFincas })
        .catch(() => []);
};
const getFincaByID = (idFinca) => {
    let fincaSelec = null;
    listaFincas.forEach(finca => finca.id === idFinca && (fincaSelec = finca));
    return fincaSelec;
};

export default class ReporteUbicacionFincas extends Component {
    componentWillMount() {
        const { getReporteUbicacionFincas } = this.props;
        getReporteUbicacionFincas();
    }

    render() {
        const {
            reporte_ubicacion_fincas: { finca_id }, ubicacionFincaChange,
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="FINCAS"
                    subtitulo="Ubicación geográfica"
                    noBorder>
                    <div className="d-flex flex-row justify-content-start align-items-end">
                        <div className="d-flex flex-row justify-content-start align-items-center col-12 col-md-4 col-xl-3">
                            <label className="p-0 mr-3 mb-0">Filtrar</label>
                            <div className="p-0 flex-1">
                                <AsyncSelect
                                    className={classNames('react-select-container')}
                                    backspaceRemovesVaslue={false}
                                    isSearchable={true}
                                    isClearable
                                    placeholder="Todas"
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={getFincas}
                                    onChange={selected => { ubicacionFincaChange(selected ? selected.id : -1); }}
                                    value={getFincaByID(finca_id)}
                                    getOptionValue={(option) => (option['id'])}
                                    getOptionLabel={(option) => (option['nombre'])}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} />
                </Card>
            </div>
        )
    }
}
