import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import {LATEX, SOLIDO, DRC} from '../../../utility/constants'
import moment from 'moment';

const LOADER = 'LOADER_LABORATORIO';
const DATA = 'DATA_LABORATORIO';
const ITEM_DATA = 'ITEM_LABORATORIO';
const PAGE = 'PAGE_LABORATORIO';
const ORDERING = 'ORDERING_LABORATORIO';
const SEARCH = 'SEARCH_LABORATORIO';
const TIPOS_VEHICULOS_LABORATORIO = 'TIPOS_VEHICULOS_LABORATORIO';
const PRODUCTOS_LATEX = 'PRODUCTOS_LATEX';
const PRODUCTOS_SOLIDO = 'PRODUCTOS_SOLIDO';
const DRC_TEMPORAL = 'DRC_TEMPORAL';
const VFA_TEMPORAL = 'VFA_TEMPORAL';
const TAB_LABORATORIO = 'TAB_LABORATORIO';
const FINALIZADOS_SOLIDO_PAGE = 'LABORATORIO_DRC_VALIDADOS_SOLIDO_PAGE';
const FINALIZADOS_LATEX_PAGE = 'LABORATORIO_DRC_VALIDADOS_LATEX_PAGE';
const FINALIZADOS_SOLIDO_FECHA_INICIAL = 'LABORATORIO_DRC_VALIDADOS_SOLIDO_FECHA_INICIAL';
const FINALIZADOS_SOLIDO_FECHA_FINAL = 'LABORATORIO_DRC_VALIDADOS_SOLIDO_FECHA_FINAL';
const FINALIZADOS_LATEX_FECHA_INICIAL = 'LABORATORIO_DRC_VALIDADOS_LATEX_FECHA_INICIAL';
const FINALIZADOS_LATEX_FECHA_FINAL = 'LABORATORIO_DRC_VALIDADOS_LATEX_FECHA_FINAL';

// CONSTANTES DE LOS REPORTES
const REPORTE_TIPO_MATERIA = 'REPORTE_TIPO_MATERIA';
// LATEX
const FILTRO_REPORTE_FECHA_INICIAL_LATEX = 'FILTRO_REPORTE_FECHA_INICIAL_LATEX';
const FILTRO_REPORTE_FECHA_FINAL_LATEX = 'FILTRO_REPORTE_FECHA_FINAL_LATEX';
const FILTRO_REPORTE_MATERIA_PRIMA_LATEX = 'FILTRO_REPORTE_MATERIA_PRIMA_LATEX';
const FILTRO_REPORTE_FINCA_LATEX = 'FILTRO_REPORTE_FINCA_LATEX';
const REPORTE_PAGE_LATEX = 'REPORTE_PAGE_LATEX';
// SOLIDO
const FILTRO_REPORTE_FECHA_INICIAL_SOLIDO = 'FILTRO_REPORTE_FECHA_INICIAL_SOLIDO';
const FILTRO_REPORTE_FECHA_FINAL_SOLIDO = 'FILTRO_REPORTE_FECHA_FINAL_SOLIDO';
const FILTRO_REPORTE_MATERIA_PRIMA_SOLIDO = 'FILTRO_REPORTE_MATERIA_PRIMA_SOLIDO';
const FILTRO_REPORTE_FINCA_SOLIDO = 'FILTRO_REPORTE_FINCA_SOLIDO';
const REPORTE_PAGE_SOLIDO = 'REPORTE_PAGE_SOLIDO';
// GRAFICA
const REPORTE_GRAFICA = 'REPORTE_GRAFICA';

//SEPARANDO LA DATA DE CADA VISTA DE LABORATORIO
const DATA_SOLIDO_ANALISIS = "DATA_SOLIDO_ANALISIS";
const DATA_SOLIDO_VALIDAR = "DATA_SOLIDO_VALIDAR";
const DATA_SOLIDO_HISTORICO = "DATA_SOLIDO_HISTORICO";
const DATA_SOLIDO_DRC = "DATA_SOLIDO_DRC";
const DATA_LATEX_ANALISIS = "DATA_LATEX_ANALISIS";
const DATA_LATEX_VALIDAR = "DATA_LATEX_VALIDAR";
const DATA_LATEX_HISTORICO = "DATA_LATEX_HISTORICO";

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setDataSolidoAnalisis = data_solido_analisis => ({
    type: DATA_SOLIDO_ANALISIS,
    data_solido_analisis,
});

const setDataSolidoValidar = data_solido_validar => ({
    type: DATA_SOLIDO_VALIDAR,
    data_solido_validar,
});

const setDataSolidoDRC = data_solido_drc => ({
    type: DATA_SOLIDO_DRC,
    data_solido_drc,
});

const setDataSolidoHistorico = data_solido_historico => ({
    type: DATA_SOLIDO_HISTORICO,
    data_solido_historico,
});

const setDataLatexAnalisis = data_latex_analisis => ({
    type: DATA_LATEX_ANALISIS,
    data_latex_analisis,
});

const setDataLatexValidar = data_latex_validar => ({
    type: DATA_LATEX_VALIDAR,
    data_latex_validar,
});

const setDataLatexHistorico = data_latex_historico => ({
    type: DATA_LATEX_HISTORICO,
    data_latex_historico,
});

// ------------------------------------
// Actions
// ------------------------------------
const listar = (page = 1, estado = false) => (dispatch, getStore) => {
    const resource = getStore().laboratorio;
    const params = {};
    page !== -1 && (params.page = page);
    params.ordering = resource.ordering;
    params.search = resource.search;
    const tab = resource.tab;
    params.tipo_materia = LATEX;
    if (tab === "SÓLIDOS") {
        params.tipo_materia = SOLIDO
    }
    switch(estado) {
        case 'validar':         // Análisis pendientes de validar
            params.no_paginar = true;
            params.estado = estado;
            break;
        case 'finalizados':     // Análisis finalizados
            params.estado = estado;
            if (tab === 'SÓLIDOS' && resource.fecha_inicial_finalizados_solido && resource.fecha_final_finalizados_solido) {
                params.fecha_inicial = moment(resource.fecha_inicial_finalizados_solido).format("YYYY-MM-DD") + ' 00:00:00';
                params.fecha_final = moment(resource.fecha_final_finalizados_solido).format("YYYY-MM-DD") + ' 23:59:59';
            } else if (tab === 'LÁTEX' && resource.fecha_inicial_finalizados_latex && resource.fecha_final_finalizados_latex) {
                params.fecha_inicial = moment(resource.fecha_inicial_finalizados_latex).format("YYYY-MM-DD") + ' 00:00:00';
                params.fecha_final = moment(resource.fecha_final_finalizados_latex).format("YYYY-MM-DD") + ' 23:59:59';
            }
            break;
        case false:             // Ingresos pendientes de Análisis
            params.no_paginar = true;
            break;
    }
    dispatch(setLoader(true));
    api.get('laboratorio', params).then((response) => {
        switch(tab){
            case 'SÓLIDOS':{
                switch(estado){
                    case false: dispatch(setDataSolidoAnalisis(response));
                        break;
                    case 'validar': dispatch(setDataSolidoValidar(response));
                        break;
                    case 'finalizados': dispatch(setDataSolidoHistorico(response));
                        break;
                }
            }
                break;
            case 'LÁTEX':{
                switch(estado){
                    case false: dispatch(setDataLatexAnalisis(response));
                        break;
                    case 'validar': dispatch(setDataLatexValidar(response));
                        break;
                    case 'finalizados' :dispatch(setDataLatexHistorico(response));
                        break;
                }
            }
                break;
        }
        dispatch(setPage(page))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarPendientesDRC = () => dispatch => {
    dispatch(setLoader(true));
    api.get('laboratorio/listar_pendientes_drc')
        .then(response => {
            dispatch({
                type: DATA_SOLIDO_DRC,
                data_solido_drc: {
                    results: response,
                    count: response.length
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        })
}

const leer = (id, estado = false) => (dispatch, getStore) => {
    const store = getStore();
    const tab = store.laboratorio.tab;
    const params = {}
    params.tipo_materia = LATEX;
    if (tab === "SÓLIDOS") {
        params.tipo_materia = SOLIDO
    }
    if(estado)
        params.estado = estado;
    dispatch(setLoader(true));
    api.get(`${'laboratorio'}/${id}`, params).then((response) => {
        dispatch(setItem(response));
        if (tab === 'SÓLIDOS') {
            dispatch(initializeForm('LaboratorioSolidoForm', response));
        }
        else {
            dispatch(initializeForm('LaboratorioForm', response));
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    data.vehiculo = data.vehiculo.id;
    data.finca = data.finca.id;
    data.piloto = data.piloto.id;
    data.tipo_materia = data.tipo_materia.id;

    dispatch(setLoader(true));
    api.post('laboratorio', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push('/ingresos'));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => async (dispatch, getStore) => {
    const store = getStore();
    const tab = store.laboratorio.tab;
    const params = {}

    console.log("DATA:", data);
    params.tipo_materia = LATEX;
    if (tab === "SÓLIDOS") {
        params.tipo_materia = SOLIDO
        // Se verifica que se haya proporcionado los datos de los contaminantes seleccionados
        const _datos_contaminantes = [];
        data.datos_contaminantes && data.datos_contaminantes.forEach(contaminante => {
            contaminante.material_contaminante && contaminante.nivel_contaminacion && _datos_contaminantes.push(contaminante);
        });
        data.datos_contaminantes = _datos_contaminantes;
    }
    dispatch(setLoader(true));
    data.fecha_analisis = moment(data.fecha_analisis).format("YYYY-MM-DD");
    data.drc_final = data.drc_promedio
    try {
        await api.put(`${'laboratorio'}/${id}`, data, params)
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
    } catch(err) {
        NotificationManager.error('Error en la edición', 'ERROR', 3000);
    } finally {
        dispatch(setLoader(false));
    }
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'laboratorio'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().laboratorio.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const getProductosLatex = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get('materia_prima', {search: "Latex", no_paginar: true}).then((data) => {
        const productos = data.results;
        if (productos.length > 0){
            //Mutar cada producto pra que tenga value y label
            productos.forEach((producto) =>{
                producto['value'] = producto.id;
                producto['label'] = producto.nombre
            })
        }
        dispatch({type: PRODUCTOS_LATEX, productos_latex: productos})
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getProductosSolido = () => (dispatch) => {
    dispatch(setLoader(true));
        api.get('materia_prima', {search: "Sólido", no_paginar: true}).then((data) => {
        const productos = data.results;
        if (productos.length > 0){
            //Mutar cada producto pra que tenga value y label
            productos.forEach((producto) =>{
                producto['value'] = producto.id;
                producto['label'] = producto.nombre
            })
        }
        dispatch({type: PRODUCTOS_SOLIDO, productos_solido: productos})
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const initialLoad = () => (dispatch) => {
  dispatch(getProductosLatex());
  dispatch(getProductosSolido());
};

const setDRCTemp = (key, value) => (dispatch, getStore) => {
    const store = getStore().laboratorio;
    const drc_temporal = _.cloneDeep(store.drc_temporal);
    drc_temporal[key] = value;
    dispatch({type: DRC_TEMPORAL, drc_temporal});
};

const setVFATemp = (key, value) => (dispatch, getStore) => {
    const store = getStore().laboratorio;
    const vfa_temporal = _.cloneDeep(store.vfa_temporal);
    vfa_temporal[key] = value;
    dispatch({type: VFA_TEMPORAL, vfa_temporal});
};

const clearDRCTemp = () => (dispatch) => {
    const vacio = {
        peso_seco: "",
        peso_plato: "",
        peso_humedo: ""
    };
  dispatch({type: DRC_TEMPORAL, drc_temporal: vacio})
};

const clearVFATemp = () => (dispatch) => {
    const vacio = {
        ml_bureta: "",
        peso: ""
    };
  dispatch({type: VFA_TEMPORAL, vfa_temporal: vacio})
};

const loadDataModal = (data) => (dispatch) => {
  dispatch({type: DRC_TEMPORAL, drc_temporal: {...data}})
  dispatch(establecerLaboratorista(data.laboratorista, data));
};

const loadDataModal2 = (data) => (dispatch) => {
    dispatch({type: VFA_TEMPORAL, vfa_temporal: {...data}})
    dispatch(establecerLaboratorista2(data.laboratorista, data));
  };

const updateData = (data, key) => (dispatch, getStore) => {
    const store = getStore();
    const tab = store.laboratorio.tab;
    if (tab === "SÓLIDOS") {
        const storeForm = getStore().form.LaboratorioSolidoForm;
        const valores = _.cloneDeep(storeForm.values);
        valores[`datos_${key}`][data.index] = data;
        dispatch(initializeForm('LaboratorioSolidoForm', valores))
    }
    else {
        const storeForm = getStore().form.LaboratorioForm;
        const valores = _.cloneDeep(storeForm.values);
        valores[`datos_${key}`][data.index] = data;
        dispatch(initializeForm('LaboratorioForm', valores))
    }
};

const establecerLaboratorista = (id, dato_actual) => (dispatch, getStore) => {
    const temp = getStore().laboratorio.drc_temporal;
    api.get("user").then(data => {
        const laboratorista = data.results;
        if (laboratorista.length > 0) {
            const usuario = _.find(laboratorista, {id: dato_actual.laboratorista.id ? dato_actual.laboratorista.id : dato_actual.laboratorista});
            temp.laboratorista = usuario;
            dispatch({type: DRC_TEMPORAL, drc_temporal: temp})
        }
    });
}

const establecerLaboratorista2 = (id, dato_actual) => (dispatch, getStore) => {
    const temp = getStore().laboratorio.vfa_temporal;
    api.get("user").then(data => {
        const laboratorista = data.results;
        if (laboratorista.length > 0) {
            const usuario = _.find(laboratorista, {id: dato_actual.laboratorista.id ? dato_actual.laboratorista.id : dato_actual.laboratorista});
            temp.laboratorista = usuario;
            dispatch({type: VFA_TEMPORAL, vfa_temporal: temp})
        }
    });
}

const setTab = (tab, estado = false, page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({type: TAB_LABORATORIO, tab});
    dispatch(listar(page, estado))
    //   if (tab === "LÁTEX"){
    //     //   dispatch(listar(1, 'activas'))
    //   }else {
    //       dispatch(listar(page, estado))
    //     //   dispatch(listar(1, 'finalizadas'))
    //   }
  };

const enviarVaciado = (id) => (dispatch, getStore) => {
    const tab = getStore().laboratorio.tab;
    dispatch(setLoader(true));
    api.post('laboratorio/marcar_vaciado', {id}).then(data => {
         NotificationManager.success('Ingreso Listo para vaciar', 'Éxito', 3000);
         dispatch(push(`/laboratorio/analisis_${tab === 'LÁTEX' ? 'latex' : 'solido'}`))
    }).catch(() => {
         NotificationManager.error('Error al marcar el ingreso para vacido', 'ERROR', 3000);
    }).finally(()=> {
        dispatch(setLoader(false));
    })
};

const enviarACoagulo = (dataAnalisis) => (dispatch, getStore) => {
    // Sólamente los análisis de látex pueden ser enviados a coágulo
    dispatch(setLoader(true));
    const data = { justificacion_anulado: `Recepción enviada a coágulo desde laboratorio # ${dataAnalisis.id}`, };
    api.put(`laboratorio/${dataAnalisis.ingreso.id}/enviar_coagulacion`, data)
        .then(response => {
            NotificationManager.success('Ingreso anulado por envío a coágulo', 'Éxito', 3000);
            dispatch(push(`/laboratorio/analisis_latex`));
        }).catch((error) => {
            NotificationManager.error(error && error.detail ? error.detail : 'Error al enviar el ingreso a coágulo', 'ERROR', 3000);
        }).finally(()=> {
            dispatch(setLoader(false));
        });
};

const validar_drc = (id, id_ingreso, id_movimiento) => (dispatch, getStore) => {
    const tab = getStore().laboratorio.tab;
    const data = {}
    dispatch(setLoader(true));
    data.id_ingreso = id_ingreso;
    data.id_movimiento = id_movimiento;
    api.post(`laboratorio/${id}/validar_drc`, data).then(data => {
        NotificationManager.success('DRC validado correctamente', 'Éxito', 3000);
        dispatch(push(`/laboratorio/validar_drc_${tab === 'LÁTEX' ? 'latex' : 'solido'}`))
    }).catch(()=> {
         NotificationManager.error('Error al validar DRC', 'ERROR', 3000);
    }).finally(()=>{
        dispatch(setLoader(false));
    })
};
const rechazar_drc = (id) => (dispatch, getStore) => {
    const tab = getStore().laboratorio.tab;
    dispatch(setLoader(true));
    api.post(`laboratorio/${id}/rechazar_drc`).then(data => {
        NotificationManager.success('El análisis se a enviado de nuevo a laboratorio ', 'Éxito', 3000);
        dispatch(push(`/laboratorio/validar_drc_${tab === 'LÁTEX' ? 'latex' : 'solido'}`))
    }).catch(()=> {
         NotificationManager.error('Error al enviar de nuevo a laboratorio', 'ERROR', 3000);
    }).finally(()=>{
        dispatch(setLoader(false));
    })
};

const enviar_a_validacion = (id) => (dispatch, getStore) => {
    const tab = getStore().laboratorio.tab;
    dispatch(setLoader(true));
    api.post(`laboratorio/${id}/enviar_a_validacion`).then(data => {
        NotificationManager.success('Análisis enviado para volver a validarse', 'Éxito', 3000);
        dispatch(push(`/laboratorio/analisis_${tab === 'LÁTEX' ? 'latex' : 'solido'}`))
    }).catch(()=> {
         NotificationManager.error('Error al enviar a validación', 'ERROR', 3000);
    }).finally(()=>{
        dispatch(setLoader(false));
    })
};

/**
 * ----- ANULAR INGRESO -----
 * Con este método se envía a anular el ingreso / recepción siempre y cuando aún no tenga ninguno de sus pesos
 * registrados (movimientos)
 * @param id, es el id del ingreso
 * @param data, es la data donde irá la justificación de la anulación
 * @returns Status de la petición 200 si anulación con éxito, 400 si fallo
 */
const anularIngreso = (id, data=null) => (dispatch) => {
    api.put(`recepcion/${id}/anular_ingreso`, data).then(() => {
        dispatch(push('/ingresos'));
        NotificationManager.success(`La recepción No. ${id} fue anulada.`, 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    })
};

const pageFinalizadosSolidoChange = page_finalizados_solido => (dispatch) => {
    dispatch({type: FINALIZADOS_SOLIDO_PAGE, page_finalizados_solido});
    dispatch(listar(page_finalizados_solido, 'finalizados'));
}
const pageFinalizadosLatexChange = page_finalizados_latex => (dispatch) => {
    dispatch({type: FINALIZADOS_LATEX_PAGE, page_finalizados_latex});
    dispatch(listar(page_finalizados_latex, 'finalizados'));
}
const fechaInicialFinalizadosSolidoChange = fecha_inicial_finalizados_solido => (dispatch) => {
    dispatch({type: FINALIZADOS_SOLIDO_FECHA_INICIAL, fecha_inicial_finalizados_solido})
    dispatch(listar(-1, 'finalizados'));
}
const fechaFinalFinalizadosSolidoChange = fecha_final_finalizados_solido => (dispatch) => {
    dispatch({type: FINALIZADOS_SOLIDO_FECHA_FINAL, fecha_final_finalizados_solido})
    dispatch(listar(-1, 'finalizados'));
}
const fechaInicialFinalizadosLatexChange = fecha_inicial_finalizados_latex => (dispatch) => {
    dispatch({type: FINALIZADOS_LATEX_FECHA_INICIAL, fecha_inicial_finalizados_latex})
    dispatch(listar(-1, 'finalizados'));
}
const fechaFinalFinalizadosLatexChange = fecha_final_finalizados_latex => (dispatch) => {
    dispatch({type: FINALIZADOS_LATEX_FECHA_FINAL, fecha_final_finalizados_latex})
    dispatch(listar(-1, 'finalizados'));
}

const listarDataReportes = (page = 1) => async (dispatch, getStore) => {
    const resource = getStore().laboratorio;
    const params = {};
    // params.page = resource.page_reporte;
    // params.fecha_inicial = moment(resource.fecha_inicio_reporte).format("YYYY-MM-DD");
    // params.fecha_fin = moment(resource.fecha_fin_reporte).format("YYYY-MM-DD");

    params.tipo_materia = resource.tipo_materia_reporte === 'LATEX' ? LATEX : SOLIDO;
    
    switch(resource.tipo_materia_reporte) {
        case 'LATEX':
            params.page = resource.page_reporte_latex;
            resource.fecha_inicio_reporte_latex !== null && (params.fecha_inicial = moment(resource.fecha_inicio_reporte_latex).format("YYYY-MM-DD") + ' 00:00:00');
            resource.fecha_fin_reporte_latex !== null   && (params.fecha_fin = moment(resource.fecha_fin_reporte_latex).format("YYYY-MM-DD") + ' 23:59:59');
            resource.finca_id_reporte_latex !== -1        && (params.finca_id = resource.finca_id_reporte_latex);
            resource.materia_prima_id_reporte_latex !== -1      && (params.materia_prima_id = resource.materia_prima_id_reporte_latex);
            break;
        case 'SOLIDO':
            params.page = resource.page_reporte_solido;
            resource.fecha_inicio_reporte_solido !== null && (params.fecha_inicial = moment(resource.fecha_inicio_reporte_solido).format("YYYY-MM-DD") + ' 00:00:00');
            resource.fecha_fin_reporte_solido !== null   && (params.fecha_fin = moment(resource.fecha_fin_reporte_solido).format("YYYY-MM-DD") + ' 23:59:59');
            resource.finca_id_reporte_solido !== -1        && (params.finca_id = resource.finca_id_reporte_solido);
            resource.materia_prima_id_reporte_solido !== -1      && (params.materia_prima_id = resource.materia_prima_id_reporte_solido);
            dispatch(graficaReporte({value: DRC, label: "Analisis de DRC"}))
            break;
    }

    if (params.fecha_inicial && params.fecha_fin) {
        dispatch(setLoader(true));
        try {
            const analisis_laboratorio_all = await api.get('laboratorio/get_data_reporte_laboratorio', params);
            params.paginar_data = true;
            const analisis_laboratorio = await api.get('laboratorio/get_data_reporte_laboratorio', params);
            analisis_laboratorio.all_results = analisis_laboratorio_all.results;
            analisis_laboratorio.all_count = analisis_laboratorio_all.count;
            dispatch(setData(analisis_laboratorio));
        } catch(error) {
        } finally {
            dispatch(setLoader(false));
        }
    }
};

const listarDataReportesProveedores = (page = 1) => async (dispatch, getStore) => {
    const resource = getStore().laboratorio;
    const user = getStore().login
    const usuario = user.me
    const params = {};
    const proveedor_id = user.proveedor

    // params.page = resource.page_reporte;
    // params.fecha_inicial = moment(resource.fecha_inicio_reporte).format("YYYY-MM-DD");
    // params.fecha_fin = moment(resource.fecha_fin_reporte).format("YYYY-MM-DD");

    params.tipo_materia = LATEX;

    if (usuario.proveedor) {
        params.proveedor = true
        params.id_usuario = usuario.id
    }

    if (proveedor_id) {
        params.proveedor_id = proveedor_id
    }

    // * Esta data de reportes será solamente para látex
    params.page = resource.page_reporte_latex;
    resource.fecha_inicio_reporte_latex !== null && (params.fecha_inicial = moment(resource.fecha_inicio_reporte_latex).format("YYYY-MM-DD") + ' 00:00:00');
    resource.fecha_fin_reporte_latex !== null   && (params.fecha_fin = moment(resource.fecha_fin_reporte_latex).format("YYYY-MM-DD") + ' 23:59:59');
    resource.finca_id_reporte_latex !== -1        && (params.finca_id = resource.finca_id_reporte_latex);
    resource.materia_prima_id_reporte_latex !== -1      && (params.materia_prima_id = resource.materia_prima_id_reporte_latex);

    if (params.fecha_inicial && params.fecha_fin) {

        dispatch(setLoader(true));
        try {
            const analisis_laboratorio_all = await api.get('laboratorio/get_data_reporte_laboratorio_proveedores', params);
            console.log("Analisis laboratorio all:", analisis_laboratorio_all);
            params.paginar_data = true;
            const analisis_laboratorio = await api.get('laboratorio/get_data_reporte_laboratorio_proveedores', params);
            console.log("Analisis laboratorio:", analisis_laboratorio);
            analisis_laboratorio.all_results = analisis_laboratorio_all.results;
            analisis_laboratorio.all_count = analisis_laboratorio_all.count;
            dispatch(setData(analisis_laboratorio));
        } catch(error) {
        } finally {
            dispatch(setLoader(false));
        }
    }
}

const reiniciarReporteProveedores = () => dispatch => {
    dispatch(setData({results: [], count: 0}))
}

const tipoMateriaChange = (tipo_materia_reporte) => (dispatch) => {
    dispatch({type: REPORTE_TIPO_MATERIA, tipo_materia_reporte})
    dispatch(listarDataReportes())
}

const tipoMateriaChangeProveedores = tipo_materia_reporte => dispatch => {
    dispatch({type: REPORTE_TIPO_MATERIA, tipo_materia_reporte})
}

// LATEX
const pageReporteChangeLatex = (page_reporte_latex) => (dispatch) => {
    dispatch({type: REPORTE_PAGE_LATEX, page_reporte_latex});
    dispatch(listarDataReportes());
}

const pageReporteChangeLatexProveedores = page_reporte_latex => dispatch => {
    dispatch({type: REPORTE_PAGE_LATEX, page_reporte_latex});
    dispatch(listarDataReportesProveedores());
}

const filtroFechaInicioLatex = (fecha_inicio_reporte_latex, page_reporte_latex=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FECHA_INICIAL_LATEX, fecha_inicio_reporte_latex});
    dispatch({type: REPORTE_PAGE_LATEX, page_reporte_latex});
    // dispatch(listarDataReportes());
};

const filtroFechaInicioLatexProveedores = (fecha_inicio_reporte_latex, page_reporte_latex=1) => dispatch => {
    dispatch({type: FILTRO_REPORTE_FECHA_INICIAL_LATEX, fecha_inicio_reporte_latex});
    dispatch({type: REPORTE_PAGE_LATEX, page_reporte_latex});
    // dispatch(listarDataReportesProveedores());
}

const filtroFechaFinLatex = (fecha_fin_reporte_latex, page_reporte_latex=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FECHA_FINAL_LATEX, fecha_fin_reporte_latex});
    dispatch({type: REPORTE_PAGE_LATEX, page_reporte_latex});
    // dispatch(listarDataReportes());
};

const filtroFechaFinLatexProveedores = (fecha_fin_reporte_latex, page_reporte_latex=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FECHA_FINAL_LATEX, fecha_fin_reporte_latex});
    dispatch({type: REPORTE_PAGE_LATEX, page_reporte_latex});
    // dispatch(listarDataReportesProveedores());
};

const filtroFincaLatex = (finca_id_reporte_latex) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FINCA_LATEX, finca_id_reporte_latex});
    dispatch({type: REPORTE_PAGE_LATEX, page_reporte_latex});
    // dispatch(listarDataReportes());
};

const filtroFincaLatexProveedores = (finca_id_reporte_latex, page_reporte_latex=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FINCA_LATEX, finca_id_reporte_latex});
    dispatch({type: REPORTE_PAGE_LATEX, page_reporte_latex});
    // dispatch(listarDataReportesProveedores());
};

const filtroMateriaPrimaLatex = (materia_prima_id_reporte_latex, page_reporte_latex=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_MATERIA_PRIMA_LATEX, materia_prima_id_reporte_latex});
    dispatch({type: REPORTE_PAGE_LATEX, page_reporte_latex});
    // dispatch(listarDataReportes());
};

const filtroMateriaPrimaLatexProveedores = (materia_prima_id_reporte_latex, page_reporte_latex=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_MATERIA_PRIMA_LATEX, materia_prima_id_reporte_latex});
    dispatch({type: REPORTE_PAGE_LATEX, page_reporte_latex});
    // dispatch(listarDataReportesProveedores());
};

// SOLIDO
const pageReporteChangeSolido = (page_reporte_solido) => (dispatch) => {
    dispatch({type: REPORTE_PAGE_SOLIDO, page_reporte_solido});
    // dispatch(listarDataReportes());
}

const filtroFechaInicioSolido = (fecha_inicio_reporte_solido, page_reporte_solido=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FECHA_INICIAL_SOLIDO, fecha_inicio_reporte_solido});
    dispatch({type: REPORTE_PAGE_SOLIDO, page_reporte_solido});
    // dispatch(listarDataReportes());
};

const filtroFechaFinSolido = (fecha_fin_reporte_solido, page_reporte_solido=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FECHA_FINAL_SOLIDO, fecha_fin_reporte_solido});
    dispatch({type: REPORTE_PAGE_SOLIDO, page_reporte_solido});
    // dispatch(listarDataReportes());
};

const filtroFincaSolido = (finca_id_reporte_solido, page_reporte_solido=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FINCA_SOLIDO, finca_id_reporte_solido});
    dispatch({type: REPORTE_PAGE_SOLIDO, page_reporte_solido});
    // dispatch(listarDataReportes());
};

const filtroMateriaPrimaSolido = (materia_prima_id_reporte_solido, page_reporte_solido=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_MATERIA_PRIMA_SOLIDO, materia_prima_id_reporte_solido});
    dispatch({type: REPORTE_PAGE_SOLIDO, page_reporte_solido});
    // dispatch(listarDataReportes());
};

const filtrarData = () => dispatch => {
    dispatch(listarDataReportes());
}

const filtrarDataProveedores = () => dispatch => {
    dispatch(listarDataReportesProveedores());
}

// GRAFICA
const graficaReporte = grafica_reporte => (dispatch) => {
    dispatch({type: REPORTE_GRAFICA, grafica_reporte});
    // dispatch(listarDataReportes());
};

export const actions = {
    leer,
    crear,
    editar,
    setTab,
    listar,
    listarPendientesDRC,
    eliminar,
    setDRCTemp,
    setVFATemp,
    updateData,
    initialLoad,
    validar_drc,
    clearVFATemp,
    clearDRCTemp,
    onSortChange,
    rechazar_drc,
    searchChange,
    anularIngreso,
    enviarVaciado,
    enviarACoagulo,
    loadDataModal,
    loadDataModal2,
    enviar_a_validacion,
    establecerLaboratorista,
    establecerLaboratorista2,
    pageFinalizadosSolidoChange,
    pageFinalizadosLatexChange,
    fechaInicialFinalizadosSolidoChange,
    fechaFinalFinalizadosSolidoChange,
    fechaInicialFinalizadosLatexChange,
    fechaFinalFinalizadosLatexChange,
    // Actions de reportes
    listarDataReportes,
    listarDataReportesProveedores,
    reiniciarReporteProveedores,
    tipoMateriaChange,
    tipoMateriaChangeProveedores,
    // LATEX
    pageReporteChangeLatex,
    pageReporteChangeLatexProveedores,
    filtroFechaInicioLatex,
    filtroFechaInicioLatexProveedores,
    filtroFechaFinLatex,
    filtroFechaFinLatexProveedores,
    filtroFincaLatex,
    filtroFincaLatexProveedores,
    filtroMateriaPrimaLatex,
    filtroMateriaPrimaLatexProveedores,
    // SOLIDO
    pageReporteChangeSolido,
    filtroFechaInicioSolido,
    filtroFechaFinSolido,
    filtroFincaSolido,
    filtroMateriaPrimaSolido,
    // FILTRADO
    filtrarData,
    filtrarDataProveedores,
    // GRAFICA
    graficaReporte,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [TIPOS_VEHICULOS_LABORATORIO]: (state, { tipos_laboratorio }) => {
        return {
            ...state,
            tipos_laboratorio,
        };
    },
    [PRODUCTOS_LATEX]: (state, { productos_latex }) => {
        return {
            ...state,
            productos_latex,
        };
    },
    [PRODUCTOS_SOLIDO]: (state, { productos_solido }) => {
        return {
            ...state,
            productos_solido,
        };
    },
    [DRC_TEMPORAL]: (state, { drc_temporal }) => {
        return {
            ...state,
            drc_temporal,
        };
    },
    [VFA_TEMPORAL]: (state, { vfa_temporal }) => {
        return {
            ...state,
            vfa_temporal,
        };
    },
    [TAB_LABORATORIO]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [FINALIZADOS_SOLIDO_PAGE]: (state, { page_finalizados_solido }) => {
        return {
            ...state,
            page_finalizados_solido,
        };
    },
    [FINALIZADOS_LATEX_PAGE]: (state, { page_finalizados_latex }) => {
        return {
            ...state,
            page_finalizados_latex,
        };
    },
    [FINALIZADOS_SOLIDO_FECHA_INICIAL]: (state, { fecha_inicial_finalizados_solido }) => {
        return {
            ...state,
            fecha_inicial_finalizados_solido,
        };
    },
    [FINALIZADOS_SOLIDO_FECHA_FINAL]: (state, { fecha_final_finalizados_solido }) => {
        return {
            ...state,
            fecha_final_finalizados_solido,
        };
    },
    [FINALIZADOS_LATEX_FECHA_INICIAL]: (state, { fecha_inicial_finalizados_latex }) => {
        return {
            ...state,
            fecha_inicial_finalizados_latex,
        };
    },
    [FINALIZADOS_LATEX_FECHA_FINAL]: (state, { fecha_final_finalizados_latex }) => {
        return {
            ...state,
            fecha_final_finalizados_latex,
        };
    },
    // REDUCER DE REPORTES
    [REPORTE_TIPO_MATERIA]: (state, { tipo_materia_reporte }) => {
        return {
            ...state,
            tipo_materia_reporte,
        };
    },
    // LATEX
    [REPORTE_PAGE_LATEX]: (state, { page_reporte_latex }) => {
        return {
            ...state,
            page_reporte_latex,
        };
    },
    [FILTRO_REPORTE_FECHA_INICIAL_LATEX]: (state, { fecha_inicio_reporte_latex }) => {
        return {
            ...state,
            fecha_inicio_reporte_latex,
        };
    },
    [FILTRO_REPORTE_FECHA_FINAL_LATEX]: (state, { fecha_fin_reporte_latex }) => {
        return {
            ...state,
            fecha_fin_reporte_latex,
        };
    },
    [FILTRO_REPORTE_FINCA_LATEX]: (state, { finca_id_reporte_latex }) => {
        return {
            ...state,
            finca_id_reporte_latex,
        };
    },
    [FILTRO_REPORTE_MATERIA_PRIMA_LATEX]: (state, { materia_prima_id_reporte_latex }) => {
        return {
            ...state,
            materia_prima_id_reporte_latex,
        };
    },
    // SOLIDO
    [REPORTE_PAGE_SOLIDO]: (state, { page_reporte_solido }) => {
        return {
            ...state,
            page_reporte_solido,
        };
    },
    [FILTRO_REPORTE_FECHA_INICIAL_SOLIDO]: (state, { fecha_inicio_reporte_solido }) => {
        return {
            ...state,
            fecha_inicio_reporte_solido,
        };
    },
    [FILTRO_REPORTE_FECHA_FINAL_SOLIDO]: (state, { fecha_fin_reporte_solido }) => {
        return {
            ...state,
            fecha_fin_reporte_solido,
        };
    },
    [FILTRO_REPORTE_FINCA_SOLIDO]: (state, { finca_id_reporte_solido }) => {
        return {
            ...state,
            finca_id_reporte_solido,
        };
    },
    [FILTRO_REPORTE_MATERIA_PRIMA_SOLIDO]: (state, { materia_prima_id_reporte_solido }) => {
        return {
            ...state,
            materia_prima_id_reporte_solido,
        };
    },
    // GRAFICA
    [REPORTE_GRAFICA]: (state, { grafica_reporte }) => {
        return {
            ...state,
            grafica_reporte,
        };
    },
    [DATA_SOLIDO_ANALISIS]: (state, { data_solido_analisis }) => {
        return {
            ...state,
            data_solido_analisis,
        };
    },
    [DATA_SOLIDO_VALIDAR]: (state, { data_solido_validar }) => {
        return {
            ...state,
            data_solido_validar,
        };
    },
    [DATA_SOLIDO_HISTORICO]: (state, { data_solido_historico }) => {
        return {
            ...state,
            data_solido_historico,
        };
    },
    [DATA_SOLIDO_DRC]: (state, { data_solido_drc }) => {
        return {
            ...state,
            data_solido_drc,
        }
    },
    [DATA_LATEX_ANALISIS]: (state, { data_latex_analisis }) => {
        return {
            ...state,
            data_latex_analisis,
        };
    },
    [DATA_LATEX_VALIDAR]: (state, { data_latex_validar }) => {
        return {
            ...state,
            data_latex_validar,
        };
    },
    [DATA_LATEX_HISTORICO]: (state, { data_latex_historico }) => {
        return {
            ...state,
            data_latex_historico,
        };
    },
};

export const initialState = {
    data_solido_analisis:{
        results: [],
        count: 0,
    },
    data_solido_validar:{
        results: [],
        count: 0,
    },
    data_solido_historico:{
        results: [],
        count: 0,
    },
    data_solido_drc:{
        results: [],
        count: 0,
    },
    data_latex_analisis:{
        results: [],
        count: 0,
    },
    data_latex_validar:{
        results: [],
        count: 0,
    },
    data_latex_historico:{
        results: [],
        count: 0,
    },
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    productos_latex: [],
    productos_solido: [],
    item: {},
    page: 1,
    ordering: '',
    search: '',
    tipos_laboratorio: {},
    tab: 'LÁTEX',
    drc_temporal: {},
    vfa_temporal: {},
    page_finalizados_solido: 1,
    page_finalizados_latex: 1,
    fecha_inicial_finalizados_solido: moment().startOf('month'),
    fecha_final_finalizados_solido: moment().endOf('month'),
    fecha_inicial_finalizados_latex: moment().startOf('month'),
    fecha_final_finalizados_latex: moment().endOf('month'),
    // Estado inicial del reporte
    tipo_materia_reporte: 'LATEX',
    // LATEX
    page_reporte_latex: 1,
    fecha_inicio_reporte_latex: moment().startOf('month'),
    fecha_fin_reporte_latex: moment().endOf('month'),
    finca_id_reporte_latex: -1,
    materia_prima_id_reporte_latex: -1,
    // SOLIDO
    page_reporte_solido: 1,
    fecha_inicio_reporte_solido: moment().startOf('month'),
    fecha_fin_reporte_solido: moment().endOf('month'),
    finca_id_reporte_solido: -1,
    materia_prima_id_reporte_solido: -1,
    grafica_reporte: {value: DRC, label: "Analisis de DRC"},
};

export default handleActions(reducers, initialState);
