import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import { actions as printActions } from 'Utils/Print';

export default class ReporteMateriaPrimaAnual extends Component {
    componentWillMount() {
        const { getDataReporteMateriaPrimaAnual } = this.props;
        getDataReporteMateriaPrimaAnual();
    }

    imprimirReporte = (event) => {
        printActions.print('reporte-materia-prima-anual');
    }

    render() {
        const {
            mpAnualTipoMateriaChange,
            mpAnualAnioChange,
            descargarReporteMateriaPrimaAnual,
            reporte_mp_anual: reporte,
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="MATERIA PRIMA ANUAL"
                    noBorder
                    subtitulo="Reporte">
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            filtroTipoMateriaChange={mpAnualTipoMateriaChange} tipo_materia={reporte.tipo_materia}
                            filtroAnioChange={mpAnualAnioChange} anio_reporte={reporte.anio}
                            botonImprimir={this.imprimirReporte} botonExportar={descargarReporteMateriaPrimaAnual}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props}></Reporte>
                </Card>
            </div>
        )
    }
}
