import React from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import {Field, FieldArray, formValueSelector, reduxForm,} from 'redux-form';
import _ from 'lodash';
import {renderField, renderNumber, SelectField} from '../../../../Utils/renderField/renderField';
import { RenderNumber } from '../../../../Utils/renderField/renderReadField';
import validador from './validate'
import Alerta from "../../../../Utils/Alerta/Alerta";
import Modal from 'react-responsive-modal';
import CardEmpty from "../../../../Utils/Cards/CardEmpty";

const renderRecipientes = ({fields, meta: {error, submitFailed}, getDatoRecipiente, recipientes, getDatoDiferencia, eliminarRecipiente, openModal}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "5%"}}/>
                        <th style={{width: "14%"}} className="text-xs">DESTINO</th>
                        <th style={{width: "13%"}} className="text-xs text-center">LÍMITE</th>
                        <th style={{width: "15%"}} className="text-xs text-center">CAPACIDAD DISPONIBLE</th>
                        <th style={{width: "13%"}} className="text-xs text-center">ALTURA INICIAL</th>
                        <th style={{width: "13%"}} className="text-xs text-center">ALTURA FINAL</th>
                        <th style={{width: "13%"}} className="text-xs text-center">DIFERENCIA</th>
                        <th style={{width: "13%"}} className="text-xs text-center">PESO DE MATERIAL</th>
                    </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div>
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                    {fields.map((recipiente, index) => {
                        let todos = fields.getAll();
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        const prod_actual = fields.get(index); //Obtiene el recipiente actual
                        return (
                            <tr key={index}>
                                <td className="text-center sin-borde-top" style={{width: "5%"}}>
                                    {index > 0 && (
                                        <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                             title="Borrar" className="action_img"
                                             onClick={() => {
                                                 eliminarRecipiente(fields, index)
                                             }}
                                        />
                                    )}
                                </td>
                                <td className="sin-borde-top bold" style={{width: "14%"}}>
                                    <Field name={`${recipiente}.id`} component={SelectField}
                                           options={recipientes}
                                           onCambio={(e) => getDatoRecipiente(e, index)}
                                           valueKey={'id'}
                                           className="form-control"/>
                                </td>
                                <td className="sin-borde-top text-center bold" style={{width: "13%"}}>
                                    <Field name={`${recipiente}.altura_maxima`}
                                           type="number"
                                           disabled
                                           suffix=" cms"
                                           decimalScale={2}
                                           component={renderNumber}
                                           parse={val => parseFloat(val)}/>
                                </td>
                                <td className="sin-borde-top text-center bold" style={{width: "15%"}}>
                                    <Field name={`${recipiente}.altura_disponible`}
                                           type="number"
                                           disabled
                                           suffix=" cms"
                                           decimalScale={2}
                                           component={renderNumber}
                                           parse={val => parseFloat(val)}/>
                                </td>
                                <td className="sin-borde-top text-center" style={{width: "13%"}}>
                                    <Field name={`${recipiente}.altura_inicial`}
                                           type="number"
                                           disabled
                                           suffix=" cms"
                                           decimalScale={2}
                                           component={renderNumber}
                                           parse={val => parseFloat(val)}/>
                                </td>
                                <td className="sin-borde-top text-center" style={{width: "13%"}}>
                                    <Field name={`${recipiente}.altura_final`}
                                           type="number"
                                           suffix=" cms"
                                           decimalScale={2}
                                           onCambio={(e) => getDatoDiferencia(e, index)}
                                           component={renderNumber}
                                           parse={val => parseFloat(val)}/>

                                </td>
                                <td className="sin-borde-top text-center bold" style={{width: "13%"}}>
                                    <Field name={`${recipiente}.diferencia`}
                                           type="number"
                                           disabled
                                           decimalScale={2}
                                           suffix=" cms"
                                           component={renderNumber}
                                           parse={val => parseFloat(val)}/>

                                </td>
                                <td style={{width: "13%"}} className="sin-borde-top text-center">
                                    <Field name={`${recipiente}.peso_diferencia`}
                                           type="number"
                                           disabled
                                           decimalScale={4}
                                           suffix=" kgs"
                                           component={renderNumber}
                                           parse={val => parseFloat(val)}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            {/*<div className="d-flex justify-content-center mt-2">*/}
            {/*    <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({})}>*/}
            {/*        AGREGAR RECIPIENTE DESTINO*/}
            {/*    </button>*/}
            {/*    /!*<Alerta*!/*/}
            {/*    /!*    danger*!/*/}
            {/*    /!*    texto={`La cantidad de centímetros a sacar de la pila de origen es mayor a la cantidad de existencia`}/>*!/*/}
            {/*</div>*/}
        </div>
    </div>
);

let SalidaForm = props => {
    const {handleSubmit, open, crearSalida, getDatoRecipiente, openModal, closeModal, recipientes, getDatoDiferencia, eliminarRecipiente, item} = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row col-12">
                <div className="my-3 w-100 d-flex align-items-center justify-content-center">
                    <div>
                        <div className="mb-3">
                            <span className="verde-primario text-sm">INGRESAR DESTINO DE SALIDA</span>
                        </div>
                        <img src={require('assets/img/icons/linea.png')} alt="Linea"
                             className="title_img mr-2"/>
                        <span className="text-xs mr-2">EXISTENCIA INICIAL DE MATERIA PRIMA</span>
                        <span className="text-sm bold"><RenderNumber value={item.altura_utilizada} decimalScale={2} /></span>
                        <span className="text-xs bold"> CM</span>
                    </div>
                </div>
            </div>
            <div className="form-group grid-container">
                <FieldArray
                    name="recipientes"
                    recipientes={recipientes}
                    component={renderRecipientes}
                    getDatoDiferencia={getDatoDiferencia}
                    getDatoRecipiente={getDatoRecipiente}
                    eliminarRecipiente={eliminarRecipiente}/>
                <div className="row col-md-12 p-0 form-group grid-container d-flex align-items-center flex-column">
                    <div className="col-md-7">
                        <CardEmpty noShadow>
                            <div className="col-12 pl-5 pb-3">
                                <div>
                                    <span className="verde-primario text-sm">SALIDA {item.nombre}</span>
                                </div>
                                <div className="row">
                                    <span htmlFor="altura_final_origen" className="col-md-6 pt-2 bold text-xs">EXISTENCIA FINAL DE
                                        MATERIA PRIMA</span>
                                    <div className="col-md-5">
                                        <Field name="altura_final_origen"
                                               type="number"
                                               decimalScale={2}
                                               suffix=" cms"
                                               component={renderNumber}
                                               parse={val => parseFloat(val)}/>
                                    </div>
                                </div>
                            </div>

                        </CardEmpty>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to={item && item.id ? `/pila/${item.id}` : '/pilas'}>CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GENERAR SALIDA</button>
                </div>
            </div>
        </form>
    )
};
SalidaForm = reduxForm({
    form: 'SalidaForm',
    asyncBlurFields: [],
    validate: validador,
    initialValues: {
        id_origen: 0,
        recipientes: [{}],
        altura_inicio_origen: 0,
        altura_final_origen: 0,
    },
})(SalidaForm);

const selector = formValueSelector('SalidaForm');
SalidaForm = connect((state, props) => {
    const form = state.form.SalidaForm;
    let cubicaciones_obj_salida = undefined;
    if (_.get(props.item, 'cubicaciones', null) !== null) {
        cubicaciones_obj_salida = {};
        _.get(props.item, 'cubicaciones', []).forEach(cubicacion => cubicaciones_obj_salida[cubicacion.cm] = cubicacion.kilogramo);
    }
    let initialValues = {
        id_origen: props.item.id,
        recipientes: [{}],
        cubicaciones_obj_salida,
        altura_final_origen: undefined,
        altura_inicio_origen: props.item.altura_utilizada,
    };
    return {initialValues}
})(SalidaForm);

export default SalidaForm
