import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import moment from "moment";

const estadoFormater = (cell, row) => {
    if (cell === 10)
        return <span className="verde-secundario">{row.estado_text}</span>;
    else
        return <span className="rojo">{row.estado_text}</span>
};

const LaboratoriosLatex = props => {
    const { dataLatex, onPageChange, onSortChange, loader} = props;
    return(
        <Grid hover striped data={dataLatex} loading={loader} onPageChange={onPageChange}
            onSortChange={onSortChange} page={1} pagination={false}
            className="pb-3"
        >
            <TableHeaderColumn
                dataField="id"
                dataAlign="center"
                isKey
                dataSort
                dataFormat={standardActions({ ver: "analisis_latex", editar: "analisis_latex"})}
            />
            <TableHeaderColumn
                dataField="estado"
                dataSort
                dataFormat={estadoFormater}
            >
                ESTADO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="ingreso"
                dataSort
                dataFormat={cell => cell ? cell.id : ""}
            >
                ID INGRESO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="movimiento"
                dataSort
                dataFormat={cell => cell ? cell.materia_prima.nombre : ""}
            >
                MATERIA PRIMA
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="ingreso"
                dataSort
                dataFormat={cell => cell ? cell.piloto.nombre : ""}
            >
                PILOTO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="ingreso"
                dataSort
                dataFormat={cell => cell ? cell.finca.nombre : ""}
            >
                FINCA
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="ingreso"
                dataSort
                dataFormat={cell => cell && cell.fecha_entrada ? moment(cell.fecha_entrada).format('DD/MM/YYYY hh:mm A') : ""}
            >
                FECHA
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="ingreso"
                dataSort
                dataFormat={cell => (cell && cell.acopio) ? cell.acopio.nombre : 'Planta ERSA'}
            >
                LUGAR
            </TableHeaderColumn>
        </Grid>
    )
};
export default LaboratoriosLatex;
