import React, { Component } from 'react';

import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './MateriaPrimaForm';

export default class MateriaPrimaCrear extends Component {
    state = {
        editar: false,
    };

    componentWillMount() {
        const { leer, match, getMateriasCoagulo } = this.props;
        getMateriasCoagulo();
        if (match.params.id) {
            leer(match.params.id);
            this.setState({ editar: true });
        }
    }

    crear = (data) => {
        const { crear } = this.props;
        crear({
            ...data,
            tipo_materia_prima: data.tipo_materia_prima.id,
            es_materia_prima: data.es_materia_prima ? true : false,
            es_producto_final: data.es_materia_prima ? false : true,
            es_contable: data.es_contable ? true : false, // modificar cuando se implemente el campo en el formulario
            es_subproducto: data.es_materia_prima ? data.es_subproducto ? true : false : false, // si no es materia prima, no puede ser subproducto
        });
    }

    actualizar = (data) => {
        const { editar, match } = this.props;
        editar(
            match.params.id,
            {
                ...data,
                tipo_materia_prima: data.tipo_materia_prima.id,
                es_materia_prima: data.es_materia_prima ? true : false,
                es_producto_final: data.es_materia_prima ? false : true,
                es_contable: data.es_contable ? true : false, // modificar cuando se implemente el campo en el formulario
                es_subproducto: data.es_materia_prima ? data.es_subproducto ? true : false : false, // si no es materia prima, no puede ser subproducto
            }
        );
    };

    render() {
        const { updateData, loader } = this.props;
        const { editar } = this.state;

        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="configuraciones_activo"
                    titulo="MATERIA PRIMA"
                    subtitulo={editar ? "Editar" : "Nuevo" }>
                    <LoadMask loading={loader} blur>
                        {editar ? (
                            <Form
                                editando
                                updateData={updateData}
                                onSubmit={this.actualizar}
                            />
                        ) : (
                            <Form
                                onSubmit={this.crear}
                                initialValues={{es_materia_prima: true, es_producto_final: false, es_contable: true, creando: true, es_subproducto: false}}
                            />
                        )}
                    </LoadMask>
                </CardForm>
            </div>
        );
    }
}
