import { handleActions } from 'redux-actions';
import { createReducer } from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    'acopios',           // Store ID
    'acopio',            // Endpoint
    'EditarAcopioForm',  // Form name
    '/acopios',          // Resource list
);

export default handleActions(reducers, initialState);
