import React, {Component} from 'react';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import FiltrosHeader from '../FiltrosHeader';
import Card from '../../../Utils/Cards/Card';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import ReporteDetalle from './ReporteDetalle';
import ReporteResumen from './ReporteResumen';
import { actions as printActions } from 'Utils/Print';

export default class ReportePilaAlmacen extends Component {
    componentWillMount() {
        const {
            reporte_pila_almacen: reporte,
            bodegasTipoReporteChange, getDataReporteBodegas,
        } = this.props;
        // getDataReporteBodegas();
    }

    imprimirReporte = (event) => {
        printActions.print('reporte-resumen-pilas');
    }

    render() {
        const {
            reporte_pila_almacen: reporte,
            bodegasTipoReporteChange,
            bodegasFechaInicialChange, bodegasFechaFinalChange,
            bodegasFincasChange, bodegasProductosChange, bodegaPilasChange,
            descargarReportePilas, getDataReporteBodegas, loader
        } = this.props;
        let fechaInicialChange = null, fechaFinalChange = null, fincasChange = null, productosChange = null, pilasChange = null;
        let fecha_inicial = null, fecha_final = null, finca_ids = null, producto_ids = null, pila_ids = null;
        fecha_inicial = reporte.fecha_inicial;
        fecha_final   = reporte.fecha_final;
        finca_ids     = reporte.finca_ids;
        producto_ids  = reporte.producto_ids;
        pila_ids      = reporte.pila_ids;
        fechaInicialChange      = bodegasFechaInicialChange;
        fechaFinalChange        = bodegasFechaFinalChange;
        fincasChange            = bodegasFincasChange;
        productosChange         = bodegasProductosChange;
        pilasChange           = bodegaPilasChange;
        return(
            <div className="mb-4 col-12">
                <Card icon="reportes_activo"
                    titulo="PILAS"
                    subtitulo="Reporte"
                    noBorder>
                    <Tabs
                        activeKey={reporte.tipo_reporte}
                        tabBarPosition="top"
                        onChange={tipo_reporte => {bodegasTipoReporteChange(tipo_reporte)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}>
                        <TabPane tab="DETALLE" key="DETALLE">
                            <div style={{minHeight: '350px'}}>
                                <CardEmpty>
                                    <FiltrosHeader
                                        fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial} fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                                        filtroFincasChange={fincasChange} fincas_ids={finca_ids}
                                        filtroMateriasLatexChange={productosChange} materias_latex_ids={producto_ids}
                                        filtroPilasChange={pilasChange} pilas_ids={pila_ids}
                                        filtrarBodegas={getDataReporteBodegas}
                                        botonExportar={descargarReportePilas}
                                        loader={loader}
                                    />
                                </CardEmpty>
                                <div className="mb-4"></div>
                                <ReporteDetalle {...this.props} />
                            </div>
                        </TabPane>
                        <TabPane tab="RESUMEN" key="RESUMEN">
                            <div style={{minHeight: '350px'}}>
                                <CardEmpty>
                                    <FiltrosHeader
                                        fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial} fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                                        filtroFincasChange={fincasChange} fincas_ids={finca_ids}
                                        filtroMateriasLatexChange={productosChange} materias_latex_ids={producto_ids}
                                        filtroPilasChange={pilasChange} pilas_ids={pila_ids}
                                        filtrarBodegas={getDataReporteBodegas}
                                        botonImprimir={this.imprimirReporte} botonExportar={descargarReportePilas}
                                        loader={loader}
                                    />
                                </CardEmpty>
                                <div className="mb-4"></div>
                                <ReporteResumen {...this.props} />
                            </div>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        )
    }
}
