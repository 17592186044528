import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import {Link} from "react-router-dom";
import {TableHeaderColumn} from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import {Field} from "redux-form";
import {renderField, renderNumber} from "../../../Utils/renderField";
import { RenderGramos, RenderKilogramo, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
const ver_img = require('assets/img/icons/ver.png');
import moment from 'moment/moment';
import { NotificationManager } from 'react-notifications';
import EstadisticasDRC from './EstadisticasDRC';

export default class AnalisisVer extends Component {
  state = {
      ver_info: false,
      ver_opciones: false,
      url_salir: '/laboratorio/analisis_solido',
  };
    componentWillMount(){
        if (this.props.match.url.includes('laboratorio/validar_drc_solido')){
            this.setState({ver_opciones: true, url_salir: '/laboratorio/validar_drc_solido'});
            this.props.leer(this.props.match.params.id, 'validar');
        } else if(this.props.match.url.includes('laboratorio/drc_validados_solido')) {
            this.setState({url_salir: '/laboratorio/drc_validados_solido'});
            this.props.leer(this.props.match.params.id, 'finalizados');
        }
        else
            this.props.leer(this.props.match.params.id);
    }
    toogleInfo = () => {
        this.setState({ver_info: !this.state.ver_info})
    };
    send_to_validation = (id, ingreso, movimiento)=>{
        const { item, validar_drc } = this.props;
        console.log(item)
        if (item && item.datos_drc){
            if (item.datos_drc.length>0){
                validar_drc(id,ingreso, movimiento);
            }else{
                NotificationManager.error('Producto no tiene datos de drc','ERROR',2000);
            }
        }
    }
    render() {
    const { crear, item, loader, validar_drc, rechazar_drc } = this.props;
    return (
        <div className="mb-4 col-12">
          <CardForm
              icon="laboratorio_activo"
              titulo="ANÁLISIS"
              subtitulo="Detalle"
              col="12"
          >
              <LoadMask loading={loader} blur>
                  <div className="d-flex justify-content-center mb-3">
                        <button className="btn btn-outline-info" onClick={e => {e.preventDefault(); this.toogleInfo()}}>
                            VER DETALLE DE RECEPCIÓN
                                <img src={ver_img} alt="Ver" className="action_img"/>
                        </button>
                </div>
                {(item.ingreso && item.ingreso.finca && this.state.ver_info) && (
                    <div className="d-flex  justify-content-center mb-4">
                        <div className="col-8">
                            <div className="row col-12">
                                <div className="col-12 d-flex justify-content-between align-items-baseline">
                                    <h6 className="mb-4">DATOS GENERALES DE RECEPCIÓN</h6>
                                </div>
                            </div>
                            <div className="row col-md-12 p-0">
                                <div className="col-md-6 p-0">
                                    <div className="col-12 d-flex flex-column">
                                        <label className="p-0 m-0">FINCA</label>
                                        <span className="mb-1">{item.ingreso.finca.nombre}</span>
                                        <label className="p-0 m-0" >VEHÍCULO</label>
                                        <span className="mb-1">{item.ingreso.vehiculo.nombre}</span>
                                        <label className="p-0 m-0">LUGAR</label>
                                        <span className="mb-1">{item.ingreso.acopio ? item.ingreso.acopio.nombre : 'Planta ERSA'}</span>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="col-12 d-flex flex-column">
                                        <label className="p-0 m-0">PILOTO</label>
                                        <span className="mb-1">{item.ingreso.piloto.nombre}</span>
                                        <label className="p-0 m-0">TIPO DE MATERIA</label>
                                        <span className="mb-1">{item.ingreso.tipo_materia.nombre}</span>
                                        <label className="p-0 m-0">FECHA Y HORA DE INGRESO</label>
                                        <span className="mb-1">{moment(item.ingreso.fecha_entrada).format('DD/MM/YYYY hh:mm A')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {item.ingreso && (
                    <div className="d-flex justify-content-between align-items-end verde-secundario mb-3">
                        <div className="d-flex align-items-end"></div>
                        <div className="d-flex align-items-baseline">
                            <span className="bold mr-2">ID RECEPCIÓN</span>
                            <h4 className="gris bold mr-3">{item.ingreso.id}</h4>
                            <span className="bold mr-2">PRODUCTO</span>
                            <h4 className="gris bold">{item.movimiento.materia_prima.nombre}</h4>
                        </div>
                    </div>
                )}
                <div className="row my-3">
                    <div className="col-12">
                        <CardEmpty noShadow >
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-wrap align-items-center">
                                    <label className="verde-secundario bold mr-2">FECHA DE INICIO DE LABORATORIO:</label>
                                    <span className="mb-2">{item && item.fecha_analisis ? moment(item.fecha_analisis).format('DD/MM/YYYY') : ""}</span>
                                </div>
                                <div className="d-flex flex-wrap align-items-center">
                                    <label className="verde-secundario bold mr-2">HORA DE INICIO DE LABORATORIO:</label>
                                    <span className="mb-2">{item && item.hora_analisis ? item.hora_analisis : ""}</span>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <CardEmpty noShadow>
                            <div className="row col-12 m-0 py-3 mb-3">
                                <div className="row col-12 mb-3">
                                    <div className="col-1"></div>
                                    <div className="col-11">
                                        <h6 className="">RESULTADOS DE DRC</h6>
                                    </div>

                                </div>
                                <div className="row col-12 text-center d-flex align-items-center">
                                    <div className="col-3">
                                        <div>
                                            <span className="verde-secundario bold">PROMEDIO</span>
                                        </div>
                                        <div>
                                            <RenderNumberPercentage value={item.drc_promedio}/>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div>
                                            <span className="verde-secundario bold">MÍNIMO</span>
                                        </div>
                                        <div>
                                            <RenderNumberPercentage value={item.drc_minimo}/>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div>
                                            <span className="verde-secundario bold">MÁXIMO</span>
                                        </div>
                                        <div>
                                            <RenderNumberPercentage value={item.drc_maximo}/>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div>
                                            <span className="verde-secundario bold">DIFERENCIA</span>
                                        </div>
                                        <div>
                                            <RenderNumberPercentage value={item.drc_diferencia}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                        <div className="mb-3"></div>
                        <CardEmpty noShadow noPadding >
                            <EstadisticasDRC />
                        </CardEmpty>
                        <div className="mb-3"></div>
                        <CardEmpty noShadow >
                            <div className="row col-12 m-0 py-3">
                                <div className="col-12 responsive-table-fix">
                                    <div className="d-flex justify-content-between my-2">
                                        <h6 className="">DATOS DRC</h6>
                                    </div>
                                    <div className="table-responsive table-no-border-fix mt-3">
                                        <table className="table table-striped table-bordered table-hover">
                                            <div className="react-bs-container-header">
                                                <thead className="table-header">
                                                    <tr>
                                                        <th/>
                                                        <th>PESO MUESTRA</th>
                                                        <th>PESO CREPA</th>
                                                        <th>PH1</th>
                                                        <th>PH2</th>
                                                        <th>PH3</th>
                                                        <th>PS1</th>
                                                        <th>PS2</th>
                                                        <th>PS3</th>
                                                        <th>DRC1</th>
                                                        <th>DRC2</th>
                                                        <th>DRC3</th>
                                                        <th>DRC PROMEDIO</th>
                                                        <th>HORA INGRESO</th>
                                                        <th>HORA SALIDA</th>
                                                        <th>LABORATORISTA</th>
                                                        <th/>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.datos_drc && item.datos_drc.map((pago, index) => {
                                                        const dato_actual = item.datos_drc[index]; // Dato actual
                                                        return (
                                                            <tr key={index} >
                                                                <td/>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <RenderKilogramo value={dato_actual.peso_muestra} />
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <RenderKilogramo value={dato_actual.peso_crepa} />
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <RenderGramos value={dato_actual.peso_humedo_1} />
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <RenderGramos value={dato_actual.peso_humedo_2} />
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <RenderGramos value={dato_actual.peso_humedo_3} />
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <RenderGramos value={dato_actual.peso_seco_1} />
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <RenderGramos value={dato_actual.peso_seco_2} />
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <RenderGramos value={dato_actual.peso_seco_3} />
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    {dato_actual.drc_promedio_1 ? <RenderNumberPercentage value={parseFloat(dato_actual.drc_promedio_1)} /> : '--'}
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    {dato_actual.drc_promedio_2 ? <RenderNumberPercentage value={parseFloat(dato_actual.drc_promedio_2)} /> : '--'}
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    {dato_actual.drc_promedio_3 ? <RenderNumberPercentage value={parseFloat(dato_actual.drc_promedio_3)} /> : '--'}
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    {dato_actual.drc_promedio_total ? <RenderNumberPercentage value={parseFloat(dato_actual.drc_promedio_total)} /> : '--'}
                                                                </td>



                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <span>{dato_actual.hora_ingreso} </span>
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <span>{dato_actual.hora_salida} </span>
                                                                </td>
                                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                                    <span>{dato_actual.laboratorista_nombre ? dato_actual.laboratorista_nombre : dato_actual.laboratorista.nombre} </span>
                                                                </td>
                                                                <td/>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </div>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                        <div className="mb-3"></div>
                        <CardEmpty noShadow >
                            <div className="d-flex justify-content-between my-2">
                                <h6 className="">DATOS CONTAMINANTES</h6>
                            </div>
                            <Grid data={item.datos_contaminantes ? {results: item.datos_contaminantes} : {results: []}} loading={loader} pagination={false}  >
                                <TableHeaderColumn
                                    width="30%"
                                />
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    hidden
                                />
                                <TableHeaderColumn
                                    dataField="material_contaminante"
                                    dataSort
                                    dataFormat={cell => cell.nombre}
                                    width="30%"
                                >
                                    CONTAMINANTE
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nivel_contaminacion_text"
                                    dataSort
                                    dataFormat={cell =>cell}
                                    width="30%"
                                >
                                    NIVEL DE CONTAMINACION
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="20%"
                                />
                            </Grid>

                        </CardEmpty>
                    </div>
                </div>
                <div className="row px-3 pt-3">
                    <div className="col-12">
                        <h6 className="">OBSERVACIONES</h6>
                        <div className="row ml-3">
                            <span>{item ? item.observaciones : ""}</span>
                        </div>
                    </div>
                </div>
                {/* <div className="text-right">
                    <span className="bold gris">ERSA-F-LL-007-07</span>
                </div> */}
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5 px-5" to={this.state.url_salir}>SALIR</Link>
                        {this.state.ver_opciones && (
                            <Fragment>
                                <button type="button" className="btn btn-primary mr-5"
                                        onClick={event => {event.preventDefault(); this.send_to_validation(this.props.match.params.id, item.ingreso.id, item.movimiento.id)}}>
                                    VALIDAR RESULTADOS
                                </button>
                                <button type="button" className="btn btn-secondary"
                                onClick={event => {event.preventDefault(); rechazar_drc(this.props.match.params.id)}}>
                                    RECHAZAR RESULTADOS
                                </button>
                            </Fragment>
                        )}
                </div>
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
