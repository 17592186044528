import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/compras';
import ComprasCrear from './ComprasCrear';

const mstp = state => {
    return {...state.compras}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ComprasCrear)
