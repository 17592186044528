import React, {Component} from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import Card from '../../../Utils/Cards/Card';
import Grid from '../../../Utils/Grid';
import { standardActions } from '../../../Utils/Grid/StandardActions';

const estadoFormater = (cell, row) => {
    let texto = <span>{row.estado_text}</span>;
    console.log(row.estado_text);
    (row.estado_text === 'Volver a analizar') ?
        texto = <span className="rojo">{row.estado_text}</span>
        :
        (row.estado_text === 'Pendiente de análisis') ?
            texto = <span className="verde-secundario">{row.estado_text}</span>
            :
            texto = <span className="verde-secundario">{row.estado_text}</span>;
    return texto;
};

export default class AnalisisPilasListar extends Component{
    componentWillMount() {
        const { setTab } = this.props;
        setTab('ACTIVAS');  // Llama a listar()
    }

    render() {
        const { data_pilas_analisis:data, loader } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="laboratorio_activo"
                    titulo="ANÁLISIS"
                    subtitulo="De pilas/tanques"
                >
                    <Grid hover striped data={data} loading={loader} pagination={false}
                        className="pb-3"
                    >
                        <TableHeaderColumn dataField="id"
                            dataAlign="center"
                            isKey
                            width='20%'
                            dataFormat={standardActions({ver: "analisis_pilas", editar: this.props.leerEdicion})}
                        />
                        <TableHeaderColumn dataField="estado"
                            dataFormat={estadoFormater}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="movimiento"
                            dataFormat={cell => cell && cell.bodega ? cell.bodega.nombre : '--'}
                        >
                            BODEGA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="creado"
                            dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                        >
                            FECHA
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}