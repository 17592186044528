import React, {Component} from 'react'
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import TabBar from "rc-tabs/lib/TabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tabs, {TabPane} from "rc-tabs";
import Pendientes from "./Pendientes";
import Finalizadas from "./Finalizadas";
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import { FiltrosFecha } from '../../../Utils/Filters';

export default class ProduccionesList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar, setTab, tab } = this.props;
        const { data, loader, page } = this.props;
        const {
            finalizadas_fecha_inicial, finalizadas_fecha_final, finalizadasFechaInicialChange, finalizadasFechaFinalChange
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="produccion_activo"
                    titulo="PRODUCCIONES"
                    subtitulo="Solido"
                    noBorder
                >
                    <Tabs
                        activeKey={tab}
                        tabBarPosition="top"
                        onChange={tab =>{setTab(tab)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="PRODUCCIÓN" key="PENDIENTES">
                            <CardEmpty noShadow>
                                <Header to="produccion/crear" textBtn="CREAR PRODUCCIÓN" search={undefined} {...this.props} />
                                <Pendientes dataPendientes={data} {...this.props} />
                            </CardEmpty>
                        </TabPane>
                        <TabPane tab="FINALIZADAS" key="FINALIZADAS">
                            <CardEmpty noShadow>
                                <FiltrosFecha {...this.props}
                                    fecha_inicial={finalizadas_fecha_inicial} fechaInicioChange={finalizadasFechaInicialChange}
                                    fecha_final={finalizadas_fecha_final} fechaFinChange={finalizadasFechaFinalChange}
                                />
                                <Finalizadas dataFinalizados={data} {...this.props}/>
                            </CardEmpty>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        )
    }
}
