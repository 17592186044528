import { connect } from 'react-redux';
import {actions} from '../../../../../../redux/modules/canal';
import CanalSalida from './CanalSalida';

const mstp = state => {
    return {...state.canal,me: state.login.me }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(CanalSalida)
