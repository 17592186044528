import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/propietarios/propietarios';
import PropietarioCrear from './PropietarioCrear';

const mstp = state => {
    return {...state.propietarios}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PropietarioCrear)
