import React, { Component } from 'react';
import CardEmpty from '../Utils/Cards/CardEmpty';
import Grid from '../Utils/Grid';
import {RenderKilogramo, RenderCurrency, RenderDollar} from '../Utils/renderField/renderReadField';
import FiltrosDashboard from '../Home/FiltrosDashboard';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,PieChart, Pie, Cell } from 'recharts';
import { renderLegend } from '../App/Reportes/components';
import moment from 'moment/moment';
import { SOLIDO } from '../../../utility/constants';
import NumberFormat from 'react-number-format';

const DashboardProveedores = (props) => {
    const {
        dashboardProveedorMesSelectedChange,
        dashboardProveedorFincaChange,
        dashboard_proveedores: dashboard,
        loader,
        me,
    } = props;
    let mes_selected  = dashboard.mes_selected;
    let fecha_inicial = dashboard.fecha_inicial;
    let fecha_final   = dashboard.fecha_final;
    let finca_id      = dashboard.finca_id;
    let mensajeError = '';
    if (fecha_inicial === null || fecha_final === null) {
        mensajeError = 'FECHA INICIAL O FINAL NULO';
    } else if (fecha_final.diff(fecha_inicial, 'days') < 0) {
        mensajeError = 'FILTRO DE FECHAS NO VÁLIDO';
    } else {
        //
    }
    const lista_recepciones = dashboard && dashboard.data && dashboard.data.ingresos ? dashboard.data.ingresos : [];
    const total_recepciones = lista_recepciones.length;
    let TOTAL_KILOS = {
        LATEX: {
            kilos_humedos: 0,
            kilos_secos: 0,
        },
        SOLIDO: {
            kilos_humedos: 0,
            kilos_secos: 0,
        },
    };
    // Se calcula el total de kilos_humedos y kilos_secos de todos los productos de todas los ingresos
    lista_recepciones.forEach(ingreso => {
        ingreso.productos && ingreso.productos.forEach(producto => {
            if (producto.tipo_materia.tipo === SOLIDO) {
                TOTAL_KILOS.SOLIDO.kilos_humedos+= producto.peso_neto;
                TOTAL_KILOS.SOLIDO.kilos_secos+= producto.peso_neto*(producto.analisis_laboratorio ? producto.analisis_laboratorio.drc_promedio : 0);
            } else {
                TOTAL_KILOS.LATEX.kilos_humedos+= producto.peso_neto;
                TOTAL_KILOS.LATEX.kilos_secos+= producto.peso_neto*(producto.analisis_laboratorio ? producto.analisis_laboratorio.drc_promedio : 0);
            }
        });
    });
    const total_kilos_humedos = TOTAL_KILOS.LATEX.kilos_humedos + TOTAL_KILOS.SOLIDO.kilos_humedos;
    const total_kilos_secos = TOTAL_KILOS.LATEX.kilos_secos + TOTAL_KILOS.SOLIDO.kilos_secos;
    // Precios vigentes de productos
    const mensaje_proveedores  = dashboard.data.configuraciones ? dashboard.data.configuraciones.mensaje_proveedores : ''
    const configuraciones = dashboard && dashboard.data && dashboard.data.configuraciones ? dashboard.data.configuraciones : {};
    let dataPrecios = [];
    if (configuraciones.latex && configuraciones.chipa_primera && configuraciones.chipa_segunda && configuraciones.coagulo && configuraciones.hilacha) {
        dataPrecios[0] = { "nombre": "Latex", "precio": configuraciones.latex }
        dataPrecios[1] = { "nombre": "Chipa 1", "precio": configuraciones.chipa_primera }
        dataPrecios[2] = { "nombre": "Chipa 2", "precio": configuraciones.chipa_segunda }
        dataPrecios[3] = { "nombre": "Coagulo", "precio": configuraciones.coagulo }
        dataPrecios[4] = { "nombre": "Hilacha", "precio": configuraciones.hilacha }
    }
    // Gráfica de Recepciones
    const dataChartRecepciones = [{
        solido_kilos_humedos: TOTAL_KILOS.SOLIDO.kilos_humedos, solido_kilos_secos: TOTAL_KILOS.SOLIDO.kilos_secos,
        latex_kilos_humedos:  TOTAL_KILOS.LATEX.kilos_humedos,  latex_kilos_secos:  TOTAL_KILOS.LATEX.kilos_secos,
    }];
    // Gráfica de NH3 promedio (sólo aplica a látex)
    let total_nh3 = 0, count_nh3 = 0, promedio_nh3 = 0;
    lista_recepciones.forEach(ingreso => {
        ingreso.productos && ingreso.productos.forEach(producto => {
            if (producto.tipo_materia.tipo !== SOLIDO && producto.analisis_laboratorio.nh3 !== null) {
                total_nh3+= producto.analisis_laboratorio.nh3;
                count_nh3++;
            }
        });
    });
    count_nh3 > 0 && (promedio_nh3 = parseFloat((total_nh3 / count_nh3) * 100));
    const dataChartHN3 = [{ promedio_nh3 }];
    // Gráfica de VFA promedio (sólo aplica a látex)
    let total_vfa = 0, count_vfa = 0, promedio_vfa = 0;
    lista_recepciones.forEach(ingreso => {
        ingreso.productos && ingreso.productos.forEach(producto => {
            if (producto.tipo_materia.tipo !== SOLIDO && producto.analisis_laboratorio.vfa !== null) {
                total_vfa+= producto.analisis_laboratorio.vfa;
                count_vfa++;
            }
        });
    });
    count_vfa > 0 && (promedio_vfa = (total_vfa / count_vfa) * 100);
    const dataChartVFA = [{ promedio_vfa }];
    // Gráfica de DRC promedio
    let total_drc_solido = 0, count_drc_solido = 0, promedio_drc_solido = 0;
    let total_drc_latex = 0, count_drc_latex = 0, promedio_drc_latex = 0;
    lista_recepciones.forEach(ingreso => {
        ingreso.productos && ingreso.productos.forEach(producto => {
            if (producto.tipo_materia.tipo === SOLIDO) {
                if (producto.analisis_laboratorio.drc_promedio  !== null) {
                    total_drc_solido+= producto.analisis_laboratorio.drc_promedio;
                    count_drc_solido++;
                }
            } else {
                if (producto.analisis_laboratorio.drc_promedio  !== null) {
                    total_drc_latex+= producto.analisis_laboratorio.drc_promedio;
                    count_drc_latex++;
                }
            }
        });
    });
    count_drc_solido > 0 && (promedio_drc_solido = (total_drc_solido / count_drc_solido) * 100);
    count_drc_latex > 0 && (promedio_drc_latex = (total_drc_latex / count_drc_latex) * 100);
    const dataChartDRC = [{ promedio_drc_solido, promedio_drc_latex }];
    // Gráfico de Liquidaciones
    const lista_liquidciones = dashboard && dashboard.data && dashboard.data.ordenes_compra ? dashboard.data.ordenes_compra : [];
    let total_liquidacion_latex = 0, total_liquidacion_solido = 0;
    lista_liquidciones.forEach(liquidacion => {
        liquidacion.detalles && liquidacion.detalles.forEach(detalle => {
            if (detalle.materia_prima.tipo_materia_prima.tipo === SOLIDO) {
                total_liquidacion_solido+= detalle.total_quetzales;
            } else {
                total_liquidacion_latex+= detalle.total_quetzales;
            }
        });
    });
    const dataChartLiquidaciones = [{ total_liquidacion_solido, total_liquidacion_latex }];
    // Insumos pendientes de Liquidar. La API los devuelve clasificados por Finca, pero se necesita mostrar todos
    const lista_insumos_sae = dashboard && dashboard.data && dashboard.data.insumos_sae ? dashboard.data.insumos_sae : [];
    const insumos_sae_results_obj = {};
    let totalQ_insumos_sae = 0;
    lista_insumos_sae.forEach(porFinca => {
        porFinca.insumos && porFinca.insumos.forEach(insumo_sae => {
            insumos_sae_results_obj[insumo_sae.uuid] = insumo_sae;
        });
    });
    const insumos_sae_results = Object.values(insumos_sae_results_obj);
    insumos_sae_results.forEach(insumo_sae => {
        totalQ_insumos_sae+= insumo_sae.importe;
    });

    const dataPieRecepciones = [
        {
            name: "solido_kilos_humedos", value: TOTAL_KILOS.SOLIDO.kilos_humedos,
        },
        {
            name: "latex_kilos_humedos", value : TOTAL_KILOS.LATEX.kilos_humedos,
        },
    ];

    const COLORS = ["#359d9e", "#215273"];

    console.log("dashboard", me.proveedor_admin);

    const CustomLegend = () => {
        return (
            <div className="recharts-legend-wrapper d-flex flex-column align-items-start mr-5">
                <span className="mb-3 bold mr-3">GRÁFICA RECEPCIONES</span>
                <ul className="recharts-default-legend pl-0">
                <li className={`recharts-legend-item legend-item mb-2`} key={`itemLATEX1`} style={{display: 'block'}}>
                    <svg className="recharts-surface" width="16" height="16" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
                        <path fill='#359d9e' cx="16" cy="16" type="circle" className="recharts-symbols" transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
                    </svg>
                    <span className="recharts-legend-item-text">SÓLIDOS</span>
                </li>
                <li className={`recharts-legend-item legend-item mb-2`} key={`itemSÓLIDOS1`} style={{display: 'block'}}>
                    <svg className="recharts-surface" width="16" height="16" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
                        <path fill='#215273' cx="16" cy="16" type="circle" className="recharts-symbols" transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
                    </svg>
                    <span className="recharts-legend-item-text">LÁTEX</span>
                </li>
                </ul>
            </div>
        );
    };

    return (
        <div className="col-12 row">
            <div className="d-flex align-items-baseline ml-3 col-12">
                <img src={require(`assets/img/icons/dashboard_activo.png`)} alt="Usuario" className="title_img mr-2" />
                <img src={require('assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2" />
                <h2 className="mr-2 titulo">{"DASHBOARD"}</h2>
                <span className="subtitulo">{me.proveedor_admin ? "PROPIETARIOS" : "PROVEEDORES"}</span>
            </div>
            {/* FILTROS Y TOTALIZADOS */}
            <div className="col-12 row mb-2">
                <div className="col-12 col-md-6">
                    <CardEmpty noShadow>
                        <FiltrosDashboard {...props}
                            filtroMesChange={dashboardProveedorMesSelectedChange} mes={mes_selected}
                            filtroFincaProveedorChange={dashboardProveedorFincaChange} finca_proveedor_id={finca_id} proveedor_id = {props.proveedor}
                        />
                    </CardEmpty>
                </div>
                <div className="col-12 col-md-6">
                    <CardEmpty noShadow>
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between col-12 p-0">
                                <div className="mb-3 mt-3">
                                    <img src={require(`assets/img/icons/recepciones.png`)} alt="Recepcion" className="dashboard_img mr-2"/>
                                </div>
                                <div className="mb-3 mt-3 d-flex flex-column text-right">
                                    <span className="bold verde-secundario">TOTAL RECEPCIONES</span>
                                    <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{total_recepciones}</h3>
                                </div>
                                <div className="fondo-totales mr-2 mb-2">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <label className="gris mr-3">Kg. Humedos</label>
                                        <span className="text-md verde-secundario"><RenderKilogramo value={total_kilos_humedos}/></span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-end">
                                        <label className="gris mr-3">Kg. Secos</label>
                                        <span className="text-md verde-secundario"><RenderKilogramo value={total_kilos_secos}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardEmpty>
                </div>
            </div>
            {/* PRECIOS VIGENTES Y GRÁFICO DE RECEPCIONES */}
            <div className="col-12 row mb-2">
                <div className="col-12 col-md-6">
                    <CardEmpty noShadow>
                        <div className="col-12 p-0" style={{minHeight: 250}}>
                            <div className="d-flex align-items-center col-12 mb-3 p-0">
                                <img src={require(`assets/img/icons/liquidaciones.png`)} alt="Usuario" className="dashboard_img mr-4" />
                                <div className="d-flex flex-wrap flex-column">
                                    <span className="bold verde-secundario">COMPRA HULE NATURAL, PRECIOS VIGENTES</span>
                                    <span className="bold verde-secundario"><p className="p-0 m-0" >{mensaje_proveedores}</p>
                                    </span>
                                </div>
                            </div>
                            <Grid hover striped loading={loader} data={{ 'results': dataPrecios }} pagination={false}
                                className="pb-4"
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    hidden
                                />
                                <TableHeaderColumn width="10" />
                                <TableHeaderColumn
                                    dataField="nombre"
                                    dataSort
                                    width="40"
                                    dataFormat={(cell) => <span className="bold black text-sm">{cell}</span>}
                                >
                                    MATERIA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="precio"
                                    dataSort
                                    dataAlign="right"
                                    width="40"
                                    dataFormat={(cell) => <span className="bold black text-sm"><RenderDollar value={cell} /></span>}
                                >
                                    PRECIOS
                                </TableHeaderColumn>
                                <TableHeaderColumn width="10" />
                            </Grid>
                        </div>
                    </CardEmpty>
                </div>
                <div className="col-12 col-md-6">
                    <CardEmpty noShadow>
                        <div className="d-flex flex-column">
                            <div className="flex-fill d-flex justify-content-center">
                                { mensajeError.length === 0
                                    ? <ResponsiveContainer width="85%" height="100%" minWidth={450} minHeight={250}>
                                        <PieChart width={730} height={250} data={dataChartRecepciones}>
                                            {/* <Tooltip content={dataPieRecepciones}/> */}
                                            <Pie
                                                isAnimationActive={false}
                                                data={dataPieRecepciones}
                                                dataKey="value"
                                                label={({
                                                    cx,
                                                    cy,
                                                    midAngle,
                                                    innerRadius,
                                                    outerRadius,
                                                    value,
                                                  }) => {
                                                    const RADIAN = Math.PI / 180;
                                                    // eslint-disable-next-line
                                                    const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                                    // eslint-disable-next-line
                                                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                                    // eslint-disable-next-line
                                                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                                    return (
                                                        <text
                                                            x={x}
                                                            y={y}
                                                            fill="#454545"
                                                            textAnchor={x > cx ? "start" : "end"}
                                                            dominantBaseline="central"
                                                        >
                                                            {value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " kg"}
                                                        </text>
                                                    );
                                                  }}
                                            >
                                                {dataPieRecepciones.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Legend
                                                content={<CustomLegend/>}
                                                align="left"
                                                verticalAlign="middle"
                                                layout="vertical"
                                                iconType="circle"
                                            />
                                        </PieChart>
                                    </ResponsiveContainer>
                                    : <div className="mb-3 rojo bold">{mensajeError}</div>
                                }
                            </div>
                        </div>
                    </CardEmpty>
                </div>
            </div>
            {/* GRÁFICO NH3 Y DRC */}
            <div className="col-12 row mb-2">
                <div className="col-12 col-md-6">
                    <CardEmpty noShadow>
                        <div className="d-flex flex-column">
                            <div className="flex-fill d-flex justify-content-center">
                                { mensajeError.length === 0
                                    ? <ResponsiveContainer width="85%" height="100%" minWidth={450} minHeight={250}>
                                        <BarChart width={730} height={250} data={dataChartVFA}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis domain = {[0]} />
                                            <YAxis unit=" %" />
                                            {/* <Tooltip content={renderTooltip} /> */}
                                            <Legend content={renderLegend} align="left" verticalAlign="middle" layout="vertical" iconType="circle" title="GRÁFICA VFA" />
                                            {/* <Bar dataKey={`kilos_humedos`} value={'LÁTEX'} fill={`url(#colorLatex)`} barSize={30} /> */}
                                            <Bar dataKey={`promedio_vfa`} value={'LÁTEX'} fill={'#359d9e'} barSize={30} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                    : <div className="mb-3 rojo bold">{mensajeError}</div>
                                }
                            </div>
                        </div>
                    </CardEmpty>
                </div>
                <div className="col-12 col-md-6">
                    <CardEmpty noShadow>
                        <div className="d-flex flex-column">
                            <div className="flex-fill d-flex justify-content-center">
                                { mensajeError.length === 0
                                    ? <ResponsiveContainer width="85%" height="100%" minWidth={450} minHeight={250}>
                                        <BarChart width={730} height={250} data={dataChartHN3} >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis domain = {[0]} />
                                            <YAxis unit=" %" />
                                            {/* <Tooltip content={renderTooltip} /> */}
                                            <Legend content={renderLegend} align="left" verticalAlign="middle" layout="vertical" iconType="circle" title="GRÁFICA NH3" />
                                            {/* <Bar dataKey={`kilos_humedos`} value={'LÁTEX'} fill={`url(#colorLatex)`} barSize={30} /> */}
                                            <Bar dataKey={`promedio_nh3`} value={'LÁTEX'} fill={'#359d9e'} barSize={30} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                    : <div className="mb-3 rojo bold">{mensajeError}</div>
                                }
                            </div>
                        </div>
                    </CardEmpty>
                </div>
            </div>
            {/* GRÁFICA LIQUIDACIONES, CONTACTO , TOTALIZADO FACTURAS E INSUMOS PENDIENTES DE LIQUIDAR */}
            <div className="col-12 row mb-2">
                <div className="col-12 col-md-6">
                    <div className="mb-2">
                        <CardEmpty noShadow>
                            <div className="d-flex flex-column">
                                <div className="flex-fill d-flex justify-content-center">
                                    { mensajeError.length === 0
                                        ? <ResponsiveContainer width="85%" height="100%" minWidth={450} minHeight={250}>
                                            <BarChart width={730} height={250} data={dataChartDRC}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis domain = {[0]} />
                                                <YAxis unit=" %" />
                                                {/* <Tooltip content={renderTooltip} /> */}
                                                <Legend content={renderLegend} align="left" verticalAlign="middle" layout="vertical" iconType="circle" title="GRÁFICA DRC" />
                                                <Bar dataKey={`promedio_drc_latex`} value={'LÁTEX'} fill={'#359d9e'} barSize={30} />
                                                <Bar dataKey={`promedio_drc_solido`} value={'SÓLIDOS'} fill={'#215273'} barSize={30} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        : <div className="mb-3 rojo bold">{mensajeError}</div>
                                    }
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                    <div className="mb-2">
                        <CardEmpty noShadow>
                            <div className="col-12 row">
                                <div className="col-12 col-md-4" />
                                <div className="col-12 col-md-8 pr-0"><h6 className="bold verde-secundario">CONTACTO</h6></div>
                            </div>
                            <div className="col-12 row pr-0 mb-3 d-flex flex-wrap align-items-center">
                                <div className="col-12 col-md-4 pl-4 pr-4 pb-3">
                                    <img src={require(`assets/img/logo.png`)} alt="Usuario" className="img-fluid" />
                                </div>
                                <div className="col-12 col-md-8 pr-0">
                                    <div className="d-flex flex-wrap flex-column">
                                        <h6>PLANTA DE PROCESAMIENTO ENTRE RÍOS, S.A</h6>
                                        <span className="bold gris small mb-2">FINCA ENTRE RÍOS, KM. 175 CARRETERA A EL TULATE, CUYOTENANGO, SUCHITEPÉQUEZ</span>
                                        <span className="bold gris small">SERVICIO PROVEEDORES, TEL: 5412-6044</span>
                                        <span className="bold gris small">BODEGA INSUMOS, TEL: 5412-1840</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 row mb-3 pr-0 d-flex flex-wrap align-items-center">
                                <div className="col-12 col-md-4 p-0">
                                    <img src={require(`assets/img/img_acopio.jpg`)} alt="Usuario" className="img-acopio-fluid" />
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="d-flex flex-wrap flex-column">
                                        <h6>CENTRO DE ACOPIO EL CASTAÑO</h6>
                                        <span className="bold gris small mb-2">KM. 237 CRUCE A TILAPA, ALDEA LAS PALMAS, COATEPEQUE, QUETZALTENANGO</span>
                                        <span className="bold gris small">TEL: 5412-2378</span>
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="mb-2">
                        <CardEmpty noShadow>
                            <div className="d-flex flex-column">
                                <div className="flex-fill d-flex justify-content-center">
                                    { mensajeError.length === 0
                                        ? <ResponsiveContainer width="85%" height="100%" minWidth={450} minHeight={250}>
                                            <BarChart width={730} height={250} data={dataChartLiquidaciones}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis domain = {[0]}/>
                                                <YAxis unit=" Q." />
                                                {/* <Tooltip content={renderTooltip} /> */}
                                                <Legend content={renderLegend} align="left" verticalAlign="middle" layout="vertical" iconType="circle" title="GRÁFICA LIQUIDACIONES" />
                                                <Bar dataKey={`total_liquidacion_latex`} value={'LÁTEX'} fill={'#359d9e'} barSize={30} stackId="a"/>
                                                <Bar dataKey={`total_liquidacion_solido`} value={'SÓLIDOS'} fill={'#215273'} barSize={30} stackId="a"/>
                                            </BarChart>
                                        </ResponsiveContainer>
                                        : <div className="mb-3 rojo bold">{mensajeError}</div>
                                    }
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                    <div className="mb-2">
                        <CardEmpty noShadow>
                            <div className="col-12 p-0">
                                <div className="d-flex align-items-center justify-content-start col-12 p-0">
                                    <div className="mb-3">
                                        <img src={require(`assets/img/icons/liquidaciones.png`)} alt="Liquidacion" className="dashboard_img mr-2"/>
                                    </div>
                                    <div className="mb-3 mt-3 d-flex flex-column text-right">
                                        <div className="d-flex flex-row justify-content-lg-start align-items-start">
                                            <span className="bold verde-secundario">TOTALIZADO FACTURAS</span>
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-end mt-2">
                                            <div className="col-4 d-flex justify-content-start px-0">
                                                <span className="gris">No. Facturas</span>
                                            </div>
                                            <div className="col-2 d-flex justify-content-start pl-0">
                                                <h5 className="bold verde-secundario mb-0">{lista_liquidciones.length}</h5>
                                            </div>
                                            <div className="col-4 d-flex justify-content-start px-0">
                                                <span className="gris">Total Facturas</span>
                                            </div>
                                            <div className="col-2 d-flex justify-content-start px-0">
                                                <h5 className="bold verde-secundario mb-0" style={{ whiteSpace: 'nowrap' }}><RenderCurrency value={dataChartLiquidaciones[0].total_liquidacion_solido + dataChartLiquidaciones[0].total_liquidacion_latex} /></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                    <div className="mb-2">
                        <CardEmpty noShadow>
                            <div className="col-12 p-0">
                                <div className="d-flex align-items-center justify-content-start col-12 p-0">
                                    <div className="mb-3">
                                        <img src={require(`assets/img/icons/liquidaciones.png`)} alt="Liquidacion" className="dashboard_img mr-2"/>
                                    </div>
                                    <div className="mb-3 mt-3 d-flex flex-column text-right">
                                        <div className="d-flex flex-row justify-content-lg-start align-items-start">
                                            <span className="bold verde-secundario">INSUMOS PENDIENTES DE LIQUIDAR</span>
                                        </div>
                                        <div className="d-flex flex-row justify-content-start align-items-end mt-2">
                                            <div className="col-4 d-flex justify-content-start px-0">
                                                <span className="gris" style={{ whiteSpace: 'nowrap' }}>Total Insumos</span>
                                            </div>
                                            <div className="col-2 d-flex justify-content-start">
                                                <h5 className="bold verde-secundario mb-0" style={{ whiteSpace: 'nowrap' }}><RenderCurrency value={totalQ_insumos_sae} /></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Grid hover striped loading={loader} data={{ 'results': insumos_sae_results }} pagination={false} className="pb-4" >
                                    <TableHeaderColumn dataField="id"
                                        isKey
                                        hidden
                                    />
                                    <TableHeaderColumn width="10" />
                                    <TableHeaderColumn dataField="refer"
                                        dataSort
                                        width="40"
                                    >
                                        INSUMO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="importe"
                                        dataSort
                                        dataAlign="right"
                                        width="40"
                                        dataFormat={(cell) => <RenderCurrency value={cell} />}
                                    >
                                        TOTAL
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="10" />
                                </Grid>
                            </div>
                        </CardEmpty>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DashboardProveedores;
