import React, { Component } from 'react'
import Card from 'Utils/Cards/Card'
import CardEmpty from 'Utils/Cards/CardEmpty'
import FiltrosHeader from '../../../Reportes/FiltrosHeader'
import Reporte from './Reporte'

class HistoricoLiquidaciones extends Component {
    render() {
        const {
            data_liquidaciones,
            fechaInicialLiquidacionesChange,
            fechaFinalLiquidacionesChange,
            fincaLiquidacionesChange,
            pageLiquidacionesChange,
            fecha_inicial_liquidaciones,
            fecha_final_liquidaciones,
            finca_liquidaciones,
            page_liquidaciones,
            listarLiquidaciones
        } = this.props

        let fechaInicialChange = null, fechaFinalChange = null, fincaChange = null, pageChange = null
        let fecha_inicial = null, fecha_final = null, finca = null, page = null

        fechaInicialChange = fechaInicialLiquidacionesChange
        fechaFinalChange = fechaFinalLiquidacionesChange
        fincaChange = fincaLiquidacionesChange
        pageChange = pageLiquidacionesChange
        fecha_inicial = fecha_inicial_liquidaciones
        fecha_final = fecha_final_liquidaciones
        finca = finca_liquidaciones
        page = page_liquidaciones

        return (
            <div className="mb-4 col-12">
                <Card
                    icon="historico_activo"
                    titulo="DATOS HISTÓRICOS"
                    noBorder
                    subtitulo="Liquidaciones"
                >
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial}
                            fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                            filtroFincaHistoricoChange={fincaChange} finca_historico_id={finca}
                            filtrar_historico={listarLiquidaciones}
                        />
                    </CardEmpty>
                    <div className="mb-4"/>
                    <Reporte {...this.props} pageChange={pageChange} page={page} liquidaciones={data_liquidaciones}/>
                </Card>
            </div>
        )
    }
}

export default HistoricoLiquidaciones