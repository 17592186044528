import React, { Component } from 'react'
import Tabs, { TabPane } from 'rc-tabs';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Card from '../../../Utils/Cards/Card';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Pendientes from './Pendientes';
import Liquidadas from './Liquidadas';
import ListarCompras from './ListarCompras';

export default class IngresosList extends Component{
    componentWillMount() {
        const { data_pendientes, data_liquidadas, listar } = this.props;
        (data_pendientes.force_update_data || data_liquidadas.force_update_data) && listar();
    }

    render() {
        const { tab, setTab } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="liquidaciones_activo"
                    titulo="LIQUIDACIONES"
                    subtitulo="Compras"
                    noBorder>
                    <Tabs
                        activeKey={tab}
                        tabBarPosition="top"
                        onChange={tab =>{setTab(tab)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="PENDIENTES" key="PENDIENTES">
                            <CardEmpty>
                                <Pendientes {...this.props} />
                            </CardEmpty>
                            <div className="mt-4"></div>
                            <CardEmpty>
                                <ListarCompras {...this.props} />
                            </CardEmpty>
                        </TabPane>
                        <TabPane tab="LIQUIDADAS" key="LIQUIDADAS">
                            <CardEmpty>
                                <Liquidadas {...this.props}/>
                            </CardEmpty>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        )
    }
}
