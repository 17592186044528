import React, { Component } from 'react'
import Grid from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import Card from 'Utils/Cards/Card'
import Header from 'Utils/Grid/Header';

export default class AcopiosList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { onSortChange, eliminar, pageChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="ACOPIOS"
                    subtitulo="Entre Ríos">
                    <Header to="acopio/crear" textBtn="CREAR ACOPIO" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onSortChange={onSortChange}
                          pagination page={page} onPageChange={pageChange}>
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='15%'
                            dataFormat={standardActions({ editar: 'acopio', eliminar})}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="direccion"
                            dataSort
                        >
                            DIRECCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="telefono"
                            dataSort
                        >
                            TELÉFONO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
