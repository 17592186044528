import React, {Component} from 'react'
import Card from "Utils/Cards/Card"
import HistoricosSolidos from './HistoricosSolidos';
import { FiltrosFecha } from '../../../Utils/Filters';
import CardEmpty from '../../../Utils/Cards/CardEmpty';


export default class IngresosLaboratorioList extends Component{
    componentWillMount() {
        const { setTab, page } = this.props;
        setTab('SÓLIDOS', 'finalizados', page);
    }

    render() {
        const { data_solido_historico:data } = this.props;
        const {
            fecha_inicial_finalizados_solido, fecha_final_finalizados_solido,
            fechaInicialFinalizadosSolidoChange, fechaFinalFinalizadosSolidoChange,
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="laboratorio_activo"
                    titulo="FINALIZADOS"
                    subtitulo="De análisis Sólido"
                >
                    <FiltrosFecha {...this.props}
                        fecha_inicial={fecha_inicial_finalizados_solido} fecha_final={fecha_final_finalizados_solido}
                        fechaInicioChange={fechaInicialFinalizadosSolidoChange} fechaFinChange={fechaFinalFinalizadosSolidoChange}
                    />
                    <HistoricosSolidos dataSolidos={data} {...this.props}/>
                </Card>
            </div>
        )
    }
}
