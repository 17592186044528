import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, SelectField } from '../../../Utils/renderField/renderField';
import { tipo_pago } from '../../../../../utility/constants';

let TipoPagoForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <div className="row  col-md-12 p-0">
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">NOMBRE DE TIPO PAGO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="grupo" className="m-0">GRUPO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="grupo" component={SelectField} options={tipo_pago} className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/tipos_pago">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
TipoPagoForm = reduxForm({
    form: 'TipoPagoForm',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
            'grupo': validators.exists()('Campo requerido.'),
        })
    }
})(TipoPagoForm);

export default TipoPagoForm
