import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/proveedores';
import UsuariosList from './Proveedores';


const ms2p = (state) => {
  return {
    ...state.proveedores,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(UsuariosList);
