import React, {Component} from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import {Link} from "react-router-dom";
import {RenderKilogramo} from '../../../Utils/renderField/renderReadField';
import ProductosForm from './ProductosForm';
import DatosForm from "./DatosForm";

export default class PesoEditar extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, datos: {}}
    }

    componentWillMount() {
        this.props.initialLoad();
        this.props.leer(this.props.match.params.id);
    }

    registrarFormater = (cell, row) => {
        if (row.peso_bruto)
            return <Link className="btn btn-outline-info py-0" to={`/ingreso/${row.id}/pesar/registrar`}>
                REGISTRAR PESO
            </Link>;
        else
            return ""
    };

    openModalAnular = () => {
        this.setState({open: true})

    };

    closeModalAnular = () => {
        this.setState({open: false})

    };

    anularRecepcion = (data) => {
        this.props.anularIngreso(this.props.item.id, data);
        this.closeModalAnular()
    };

    render() {
        const {updateData, loader, item, productos_solido, productos_latex, actualizarProductos, anularIngreso} = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="recepciones_activo"
                    titulo="BÁSCULA"
                    subtitulo="Recepción"
                    col="12">
                    <LoadMask loading={loader} blur>
                        {item.finca && (
                            <div className="form-group grid-container d-flex align-items-center flex-column">
                                <div className="col-12 col-lg-8">
                                    <div className="d-flex justify-content-between align-items-baseline">
                                        <h6 className="mb-4">DATOS GENERALES DE RECEPCIÓN</h6>
                                        <label>ID RECEPCIÓN <span className="gris text-md">{item.id ? item.id.toString().padStart(5, 0) : ''.padStart(5, 0)}</span></label>
                                    </div>
                                    <DatosForm item={item}/>
                                </div>
                                <ProductosForm
                                    item={item}
                                    open={this.state.open}
                                    onSubmit={actualizarProductos}
                                    openModal={this.openModalAnular}
                                    closeModal={this.closeModalAnular}
                                    anularRecepcion={this.anularRecepcion}
                                    registrarFormater={this.registrarFormater}
                                    opciones={item.tipo_materia.tipo === 10 ? productos_latex : productos_solido}
                                    {...this.props}
                                />
                            </div>)}
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
