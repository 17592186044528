import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { actions } from "../../../../../redux/modules/anual_proveedores";

/**
 * Se tendran los siguiente filtros:
 * Año: Solo se puede seleccionar un año en específico
 * Productos: Se pueden seleccionar varios productos
 * Finca: Se pueden seleccionar varios fincas que pertenezcan al proveedor con la sesión iniciada
 * para estos filtros se usaran el componente "import Select from 'react-select';"
 * y para el form se usara react-hook-form
 */
export const Filtros = (props) => {
    const {
        anio,
        productos,
        fincas,
        select_anio,
        select_producto,
        select_finca,
        setSelectFinca,
        setSelectAnio,
        setSelectProducto,
        obtener_data,
        obtener_data_excel,
        loader_excel,
        handlePrint,
    } = props;

    const {
        formState: { errors },
        control,
        register,
        handleSubmit,
    } = useForm();

    const onSubmit = (data) => obtener_data();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="pb-3">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="form-group">
                        <label htmlFor="anio">Año</label>
                        <Controller
                            name="anio"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isMulti
                                    options={anio}
                                    placeholder="Seleccione un año"
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={(value) => {
                                        setSelectAnio(value);
                                    }}
                                    isClearable
                                    value={select_anio}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="form-group">
                        <label htmlFor="productos">Productos</label>
                        <Controller
                            name="productos"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Select
                                    className={classNames(
                                        "react-select-container"
                                    )}
                                    {...field}
                                    isMulti
                                    options={productos}
                                    placeholder="Seleccione los productos"
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={(value) => {
                                        setSelectProducto(value);
                                    }}
                                    isClearable
                                    value={select_producto}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="form-group">
                        <label htmlFor="fincas">Fincas</label>
                        <Controller
                            name="fincas"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Select
                                    className={classNames(
                                        "react-select-container"
                                    )}
                                    {...field}
                                    isMulti
                                    options={fincas}
                                    placeholder="Seleccione las fincas"
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={(value) => {
                                        setSelectFinca(value);
                                    }}
                                    isClearable
                                    value={select_finca}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="row justify-content-md-end">
                <div className="col-12 col-md-6 ">
                    <div className="row justify-content-md-end ">
                        <div className="col-12 col-md-4">
                            <button
                                type="submit"
                                className="btn btn-primary w-100 text-nowrap "
                            >
                                Generar reporte
                            </button>
                        </div>
                        <div className="col-12 col-md-4 ">
                            <button
                                className="btn btn-primary w-100 text-nowrap "
                                onClick={(e) => {
                                    e.preventDefault();
                                    obtener_data_excel();
                                }}
                                disabled={loader_excel}
                            >
                                {loader_excel
                                    ? "Obteniendo excel..."
                                    : "Descargar excel"}
                            </button>
                        </div>
                        <div className="col-12 col-md-4">
                            <button
                                className="btn btn-primary w-100 text-nowrap "
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePrint();
                                }}
                            >
                                Imprimir
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => ({
    ...state.anual_proveedores,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
