import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/analisis_bodegas';
import PendientesValidacion from './PendientesValidacion';


const ms2p = (state) => {
  return {
    ...state.analisis_bodegas,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PendientesValidacion);
