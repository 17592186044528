import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './TipoVehiculoForm';
import TipoVehiculoForm from './TipoVehiculoForm';

export default class TipoVehiculoCrear extends Component {
  state = {
      editar: false,
  };
  componentWillMount(){
      if(this.props.match.params.id){
          this.props.leer(this.props.match.params.id);
          this.setState({editar: true});
      }
  }
    actualizar = (data) =>{
        this.props.editar(this.props.match.params.id, data)
    };
  render() {
    const { crear, editar } = this.props;
    const { updateData, loader } = this.props;
    return (
        <div className="mb-4 col-12">
          <CardForm
              icon="configuraciones_activo"
              titulo="TIPO VEHÍCULO"
              subtitulo="Nuevo">
              <LoadMask loading={loader} blur>
                  {
                      this.state.editar ?
                          <TipoVehiculoForm
                              onSubmit={this.actualizar}
                              updateData={updateData}
                              editando={true} />
                          :
                          <Form onSubmit={crear}/>
                  }
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
