import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    RenderKilogramo,
    RenderNumberPercentage,
} from "../Utils/renderField/renderReadField";
import { actions } from "../../../redux/modules/produccion_submateria";
import CardEmpty from "../Utils/Cards/CardEmpty";
import Grid from "../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";

export const ProduccionSubmateria = ({
    obtenerData,
    tipo_materia_dashboard,
    anio_latex,
    anio_solido,
    data = {
        results: [],
    },
}) => {
    if (!tipo_materia_dashboard && !data && data.results === undefined) {
        return null;
    }

    useEffect(() => {
        if (tipo_materia_dashboard === "SOLIDO") {
            if (anio_solido && anio_solido.value) {
                obtenerData(tipo_materia_dashboard, anio_solido.value);
            }
        }
    }, [tipo_materia_dashboard, anio_solido]);

    useEffect(() => {
        if (tipo_materia_dashboard === "LATEX") {
            if (anio_latex && anio_latex.value) {
                obtenerData(tipo_materia_dashboard, anio_latex.value);
            }
        }
    }, [tipo_materia_dashboard, anio_latex]);

    let totalKilosSecosComprados = 0;
    let totalKilosSecosProducidos = 0;
    let totalDiferenciaKilosSecos = 0;
    let totalPorcentajeKilosSecos = 0;

    const data_results = data.results || [];

    data_results.forEach((kilos) => {
        totalKilosSecosComprados += kilos.kg_ingresados;
        totalKilosSecosProducidos += kilos.kg_producidos;
        totalDiferenciaKilosSecos += kilos.diferencia;
    });

    totalPorcentajeKilosSecos =
        totalKilosSecosProducidos / totalKilosSecosComprados - 1 || 0;

    return (
        <div className="col-12 col-md-6">
            <CardEmpty noShadow>
                <div className="col-12">
                    <div className="d-flex align-items-center col-12 mb-3 p-0">
                        <span className="bold verde-secundario">
                            DIFERENCIA KG SUBMATERIAS
                        </span>
                    </div>
                </div>
                <div className="inicio-tabla form-group np-r p-0 mb-1">
                    <Grid
                        hover
                        striped
                        bordered={false}
                        loading={false}
                        data={
                            data || {
                                results: [],
                            }
                        }
                        pagination={false}
                    >
                        <TableHeaderColumn dataField="id" isKey hidden />
                        <TableHeaderColumn dataField="mes">
                            MES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="kg_ingresados"
                            dataFormat={(cell) => (
                                <RenderKilogramo value={cell} />
                            )}
                        >
                            KG INGRESADOS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="kg_producidos"
                            dataFormat={(cell) => (
                                <RenderKilogramo value={cell} />
                            )}
                        >
                            KG PRODUCIDOS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="diferencia"
                            dataFormat={(cell) => (
                                <RenderKilogramo value={cell} />
                            )}
                        >
                            DIFERENCIA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="porcentaje"
                            dataFormat={(cell) => {
                                if (cell < 0) {
                                    return (
                                        <span className="rojo">
                                            <RenderNumberPercentage
                                                value={cell}
                                            />
                                        </span>
                                    );
                                }
                                return <RenderNumberPercentage value={cell} />;
                            }}
                        >
                            PORCENTAJE
                        </TableHeaderColumn>
                    </Grid>
                    <div className="react-bs-table-container total-final">
                        <div className="react-bs-table">
                            <div className="react-bs-container-header table-header-wrapper">
                                <table className="table table-hover table-bordered">
                                    <tbody>
                                        <tr>
                                            <th className="text-left">TOTAL</th>
                                            <th className="text-left">
                                                <RenderKilogramo
                                                    value={
                                                        totalKilosSecosComprados
                                                    }
                                                />
                                            </th>
                                            <th className="text-left">
                                                <RenderKilogramo
                                                    value={
                                                        totalKilosSecosProducidos
                                                    }
                                                />
                                            </th>
                                            <th className="text-left">
                                                <RenderKilogramo
                                                    value={
                                                        totalDiferenciaKilosSecos
                                                    }
                                                />
                                            </th>
                                            <th className="text-left">
                                                <RenderNumberPercentage
                                                    value={
                                                        totalPorcentajeKilosSecos
                                                    }
                                                />
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </CardEmpty>
        </div>
    );
};

const mapStateToProps = (state) => ({
    ...state.pdsubmateria,
    anio_solido: state.recepciones.anio_dashboard_solido,
    anio_latex: state.recepciones.anio_dashboard_latex,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProduccionSubmateria);
