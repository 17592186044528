import { connect } from 'react-redux';

import TipoMateriaPrimaCrear from './TipoMateriaPrimaCrear';
import { actions } from '../../../../../redux/modules/tipos_materia_prima';

const mstp = state => ({
    ...state.tipos_materia_prima,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(TipoMateriaPrimaCrear);
