import { connect } from 'react-redux';

import MateriaPrimaCrear from './MateriaPrimaCrear';
import { actions } from '../../../../../redux/modules/materia_prima';

const mstp = state => ({
    ...state.materia_prima,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(MateriaPrimaCrear);
