import React, { Component } from 'react'
import Card from '../../../Utils/Cards/Card';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import ClienteForm from './ClienteForm';

class ClienteCrear extends Component {
    state = {
        editar: false,
        mensaje: 'Agregar Cliente'
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
                mensaje: 'Editar Cliente'
            });
        }
    }

    actualizar = data => {
        this.props.editar(this.props.match.params.id, data);
    }

    render() {
        const { crear, update } = this.props;
        const { updateData, loader } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="CLIENTES"
                    subtitulo=""
                    noBorder
                >
                    <LoadMask
                        loading={loader}
                        blur
                    >
                        <section className="d-flex justify-content-center mt-4">
                            <div
                                className="card p-3"
                                style={{width: '90%'}}
                            >
                                { this.state.editar ?
                                    <ClienteForm onSubmit={this.actualizar} editando={true} mensaje={this.state.mensaje}/>
                                    :
                                    <ClienteForm onSubmit={crear} editando={false} mensaje={this.state.mensaje}/>
                                }
                            </div>
                        </section>
                    </LoadMask>
                </Card>
            </div>
        )
    }
}

export default ClienteCrear;