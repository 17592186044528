import { handleActions } from "redux-actions";
import { api } from "api";

const OBTENER_LISTA = "OBTENER_LISTA_CATALOGO_PROVEEDORES_RECEPCIONES";
const LOADER = "LOADER_CATALOGO_PROVEEDORES";
const SET_PAGE = "SET_PAGE_CATALOGO_PROVEEDORES";
const SET_ESTADO = "SET_ESTADO_CATALOGO_PROVEEDORES";
const SET_LOADER_EXCEL = "SET_LOADER_EXCEL_CATALOGO_PROVEEDORES";
const SET_PARAMS_USED = "SET_PARAMS_USED_CATALOGO_PROVEEDORES";

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setParamsUsed = (params_used) => ({
    type: SET_PARAMS_USED,
    params_used,
});


const setLoaderExcel = (loader_excel) => ({
    type: SET_LOADER_EXCEL,
    loader_excel,
});


const setEstado = (estado) => ({
    type: SET_ESTADO,
    estado,
});

const setData = (data) => ({
    type: OBTENER_LISTA,
    data,
});

const setPage = (page) => ({
    type: SET_PAGE,
    page,
});

const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [OBTENER_LISTA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SET_ESTADO]: (state, { estado }) => {
        return {
            ...state,
            estado,
        };
    },
    [SET_LOADER_EXCEL]: (state, { loader_excel }) => {
        return {
            ...state,
            loader_excel,
        };
    },
    [SET_PARAMS_USED]: (state, { params_used }) => {
        return {
            ...state,
            params_used,
        };
    }
};

const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    estado: null,
    loader_excel: false,
    params_used: {
        estado_propietario: null,
    },
};

export const listar = (page=1) => (dispatch, getStore) => {
    //si queremos tomar una nueva pagina con el estado actual del filtro
    const resource = getStore().cdp;
    const params = { page };
    if(typeof(resource.estado) == "boolean"){
        params['estado_propietario'] = resource.estado;
    }
    api.get("catalogo_proveedores", params)
        .then((response) => {
            dispatch(setData(response));
            dispatch(setPage(page));
        })
        .catch((e) => {
            console.log(e);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const generarReporte = (data) => (dispatch, getStore) => {
    //Tomamos la tada del filtro para pasarsela a los params y obtener la primera pagina
    const params = {};
    const params_used = {};
    if(data.estado_propietario && typeof(data.estado_propietario.value) == "boolean"){
        params['estado_propietario'] = data.estado_propietario.value
        params_used['estado_propietario'] = data.estado_propietario.value;
        dispatch(setEstado(data.estado_propietario.value));
    }
    params['page'] = 1;
    api.get("catalogo_proveedores", params)
        .then((response) => {
            dispatch(setData(response));
            dispatch(setPage(1));
            dispatch(setParamsUsed(params_used));
        })
        .catch((e) => {
            console.log(e);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const obtener_data_excel = () => (dispatch, getStore) => {
    dispatch(setLoaderExcel(true));

    let endpoint = `/api/catalogo_proveedores/obtener_reporte_excel/?`;

    const { params_used } = getStore().cdp;

    //recorremos los parametros para agregarlos al endpoint de la api
    Object.keys(params_used).forEach((key) => {
        if (params_used[key] != null && params_used[key] != undefined && params_used[key].length > 0) {
            endpoint += `&${key}=${params[key].join(",")}`;
        }
    });

    fetch(endpoint, {
        method: "GET",
        headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    })
        .then((response) => {
            if (response.status === 200) {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = "CatalogoProveedores.xlsx";
                    a.click();
                });
            }
        })
        .catch(() => {
            NotificationManager.error(
                "Ocurrió un error al exportar el reporte",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoaderExcel(false));
        });
};


export const actions = {
    listar,
    generarReporte,
    obtener_data_excel,
};

export default handleActions(reducers, initialState);
