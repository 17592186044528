import React, { Fragment } from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import {TableHeaderColumn} from 'react-bootstrap-table';
import moment from 'moment/moment';
import Grid from '../../../Utils/Grid';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderKilogramo, RenderCurrency, RenderNumber, RenderDollar } from '../../../Utils/renderField/renderReadField';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { SOLIDO, LATEX } from '../../../../../utility/constants';
import { renderLegend } from '../components';
import {standardActions} from "../../../Utils/Grid/StandardActions";

const GENERAL = {};
GENERAL[SOLIDO] = 'SOLIDO';
GENERAL[LATEX] = 'LATEX';

const renderTooltip = (props) => {
    const { active, payload, label } = props;
    if (active && payload) {
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-2">Fecha: {label}</span>
                    { payload.map(item => {
                        const dataKey = item.dataKey.replace('y_value_', '');
                        return (<div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="mr-2">{dataKey}:</span>
                                <span className="mr-3"><RenderKilogramo value={item.payload[dataKey].kilos_secos} /></span>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <span className="mr-2">TOTAL:</span>
                                <span className="mr-3"><RenderCurrency value={item.payload[dataKey].total_quetzales} /></span>
                            </div>
                        </div>);
                    }) }
                </div>
            </CardEmpty>
        );
    }
    return null;
};

const Reporte = (props) => {
    const {
        pageChange, page,
        loader, reporte,
    } = props;
    let mensajeError = '';
    let diferenciaDias = 0;
    let listaObjs = [], dataChart = [];
    let fecha_inicial = null, fecha_final = null;
    let totales = {kilos_secos_solido: 0,kilos_secos_latex:0, total_quetzales: 0, total_dolares: 0};
    fecha_inicial = moment(moment(reporte.fecha_inicial).format("YYYY-MM-DD")+' 00:00:00');
    fecha_final = moment(moment(reporte.fecha_final).format("YYYY-MM-DD")+' 00:00:00');
    if (fecha_inicial === null || fecha_final === null) {
        mensajeError = 'FECHA INICIAL O FINAL NULO';
    } else if (fecha_final.diff(fecha_inicial, 'days') < 0) {
        mensajeError = 'FILTRO DE FECHAS NO VÁLIDO';
    } else {
        // Procesamiento de la información para generar la gráfica de barras
        diferenciaDias = fecha_final.diff(fecha_inicial, 'days');
        listaObjs = Array(diferenciaDias + 1);
        let iterFecha = moment(fecha_inicial).clone();
        // Construcción del array con los datos para el gráfico, inicialmente todos con cero
        for (let index = 0; index < listaObjs.length; index++) {
            listaObjs[index] = {
                fecha: iterFecha.format('D/M/YY'),
                num_dia: 'Día '+ (index + 1),
                kilos_secos: 0,
                total_quetzales: 0,
                total_dolares: 0,
            };
            listaObjs[index][GENERAL[SOLIDO]] = {kilos_secos: 0, total_quetzales: 0, total_dolares: 0};
            listaObjs[index][GENERAL[LATEX]] = {kilos_secos: 0, total_quetzales: 0, total_dolares: 0};
            iterFecha.add(1, 'days');
        }
        // Cuando la data esté realmente cargada
        const liquidacionesAll = reporte.ordenes_liquidadas.all_results;
        let cpFechaInicio = moment(fecha_inicial.format("YYYY-MM-DD")+' 00:00:00');
        diferenciaDias >= 0 && liquidacionesAll && liquidacionesAll.forEach((liquidacion) => {
            let cpFechaOrden = moment(moment(liquidacion.creado).format("YYYY-MM-DD")+' 00:00:00');
            const index = cpFechaOrden.diff(cpFechaInicio, 'days');
            // La Orden compra puede tener varios detalles y pueden ser SÓLIDO o LÁTEX, por lo que hay que 'filtrarlos'
            // Sumatoria del valor monetario del tipo de materia seleccionado
            if (index < listaObjs.length && index >= 0) {
                listaObjs[index].total_quetzales+= liquidacion.total_quetzales;
                listaObjs[index].total_dolares+= liquidacion.total_dolares;
                listaObjs[index].kilos_secos+= liquidacion.kilos_secos;
                liquidacion.detalles.forEach(detalle => {
                    let materia = listaObjs[index][GENERAL[detalle.materia_prima.tipo_materia_prima.tipo]];
                    if (materia !== undefined && materia !== null) {
                        materia.kilos_secos+= detalle.kilos_secos;
                        materia.total_quetzales+= detalle.total_quetzales;
                        materia.total_dolares+= detalle.total_dolares;
                    }
                });
            }
        });
        listaObjs.forEach(obj => {
            if (obj[GENERAL[SOLIDO]].kilos_secos != 0 || obj[GENERAL[LATEX]].kilos_secos != 0) {
                const data = {
                    ...obj,
                    num_dia: obj.num_dia,
                    x_value: obj.fecha,
                    kilos_secos: obj.kilos_secos,
                }
                data[GENERAL[SOLIDO]] = {...obj[GENERAL[SOLIDO]]};
                data[GENERAL[LATEX]] = {...obj[GENERAL[LATEX]]};
                data[`y_value_${GENERAL[SOLIDO]}`] = obj[GENERAL[SOLIDO]].kilos_secos;
                data[`y_value_${GENERAL[LATEX]}`] = obj[GENERAL[LATEX]].kilos_secos;
                dataChart.push(data);
                totales.kilos_secos_solido += obj[GENERAL[SOLIDO]].kilos_secos;
                totales.kilos_secos_latex += obj[GENERAL[LATEX]].kilos_secos;
                totales.total_quetzales+= obj.total_quetzales;
                totales.total_dolares+= obj.total_dolares;
            }
        });
        
    }

    return(
        <LoadMask loading={loader} blur>
            <div className="mb-4">
                <CardEmpty>
                    <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                        <div className="col-12 col-md-12 d-flex flex-wrap justify-content-center align-items-center">
                            <div className="col-12 col-md-2 p-0 d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                                <h6 className="m-0 mb-3 mb-md-0 bold">TOTALES</h6>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                    <span className="m-0 ml-0 ml-md-5 bold">Kg. secos (Sólido):</span>
                                    <h5 className="mx-2 m-md-0 gris" style={{whiteSpace: 'nowrap'}}>{<RenderKilogramo value={totales.kilos_secos_solido}/>}</h5>
                                </div>
                                <div className="col-md-12 col-sm-12 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                    <span className="m-0 ml-0 ml-md-5 bold">Kg. secos (Látex):</span>
                                    <h5 className="mx-2 m-md-0 gris" style={{whiteSpace: 'nowrap'}}>{<RenderKilogramo value={totales.kilos_secos_latex}/>}</h5>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-sm-12 d-flex flex-wrap flex-column flex-md-row p-0 mb-3 mb-md-0">
                                <div className="col-md-8 col-sm-12 d-flex flex-row justify-content-between justify-content-md-between align-items-center mb-1 mb-md-2 px-1 px-md-2">
                                    <span className="m-0 ml-0 ml-md-5 bold">TOTAL: </span>
                                    <h3 className="mb-1 ml-3 gris">{<RenderCurrency value={totales.total_quetzales} />}</h3>
                                </div>
                                <div className="col-md-8 col-sm-12 d-flex flex-row justify-content-between justify-content-md-between align-items-center mb-1 mb-md-2 px-1 px-md-2">
                                    <span className="m-0 ml-0 ml-md-5 bold">TOTAL: </span>
                                    <h3 className="mb-1 ml-3 gris">{<RenderDollar value={totales.total_dolares} />}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardEmpty>
            </div>
            <div className="mb-4">
                <CardEmpty>
                    <div className="d-flex flex-column">
                        <div className="flex-fill d-flex justify-content-center">
                            { mensajeError.length === 0 ? (
                                <ResponsiveContainer width="85%" height="100%" minWidth={500} minHeight={300}>
                                    <BarChart width={730} height={250} data={dataChart}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="x_value" />
                                        <YAxis />
                                        <Tooltip content={renderTooltip} />
                                        <Legend content={renderLegend} align="left" verticalAlign="middle" layout="vertical" iconType="circle" title="GRÁFICA DE LIQUIDACIONES" />
                                        <Bar dataKey={`y_value_${GENERAL[SOLIDO]}`} value={'SOLIDO'} fill={'#215273'} />
                                        <Bar dataKey={`y_value_${GENERAL[LATEX]}`} value={'LATEX'} fill={'#359d9e'} />
                                    </BarChart>
                                </ResponsiveContainer>
                            ) : (
                                <div className="mb-3 rojo bold">{mensajeError}</div>
                            ) }
                        </div>
                    </div>
                </CardEmpty>
            </div>
            {/* TABLA CON LOS RESÚMENES */}
            <div className="mb-5">
                <CardEmpty>
                    <Grid hover striped loading={loader} data={reporte.ordenes_liquidadas}
                        pagination onPageChange={pageChange} page={page}
                        className="pb-4 pt-3"
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            // width='20%'
                            dataSort
                            dataFormat={standardActions({ ver_liquidacion_reporte: "ver_liquidacion_reporte"})}
                        />
                        <TableHeaderColumn
                            dataField="id"
                            dataFormat={cell => cell ? cell.toString().padStart(3, 0) : '--'}
                        >
                            ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataFormat={cell => cell ? moment(cell).format('DD/MM/YYYY') : '--'}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="finca"
                            dataFormat={cell => cell ? cell.nombre : '--'}
                        >
                            FINCA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="kilos_secos"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderKilogramo value={cell} /> : '--'}
                        >
                            KILOS SECOS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total_quetzales"
                            dataAlign="right"
                            dataFormat={cell => cell
                                ? <div className="row justify-content-end">
                                    <div className="col-xl-3 col-sm-2"></div>
                                    <div className="col-xl-9 col-sm-10 text-right">{
                                        <div className="d-flex justify-content-between">
                                            <span>Q.</span>{<RenderNumber value={cell} decimalScale={2} />}
                                        </div>
                                    }</div>
                                </div>
                                : '--'
                            }
                        >
                            TOTAL LIQUIDACIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total_dolares"
                            dataAlign="right"
                            dataFormat={cell => cell
                                ? <div className="row justify-content-end">
                                    <div className="col-xl-3 col-sm-2"></div>
                                    <div className="col-xl-9 col-sm-10 text-right">{
                                        <div className="d-flex justify-content-between">
                                            <span>$.</span>{<RenderNumber value={cell} decimalScale={2} />}
                                        </div>
                                    }</div>
                                </div>
                                : '--'
                            }
                        >
                            TOTAL LIQUIDACIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            width="5%"
                            dataFormat={cell => <div></div>}
                        >
                        </TableHeaderColumn>
                    </Grid>
                </CardEmpty>
            </div>
        </LoadMask>
    )
};

export default Reporte;
