import { handleActions } from 'redux-actions';
import { createReducer } from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "tipos_materia_prima",
    "tipo_materia_prima",
    "TiposMateriaPrimaForm",
    "/tipos_materia_prima",
);

export default handleActions(reducers, initialState);
