import React, { Component } from 'react'
import Card from 'Utils/Cards/Card';
import CardEmpty from 'Utils/Cards/CardEmpty';
import LoadMask from 'Utils/LoadMask/LoadMask';
import "react-step-progress-bar/styles.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Grid from "Utils/Grid";
import ReajusteForm from "./ReajusteForm";
import Modal from "react-responsive-modal";
import { formatoPorcentaje, RenderKilogramo, RenderNumber, RenderNumberPercentage } from "../../../../Utils/renderField/renderReadField";
import { ProgressBar } from "react-step-progress-bar";
import moment from "moment";
import Link from "react-router-dom/Link";
import Swal from 'sweetalert2';
const imgAlerta = require('assets/img/icons/alerta_roja.png');

export default class PilaVer extends Component {
    state = {
        editar: false,
        step: 1,
        open: false,
    };

    componentWillMount() {
        this.props.obtenerBodega(this.props.match.params.id, "pila");
        this.props.getProductosPila(this.props.match.params.id, 1);
    }

    // crearActualizarAnalisis = () => {
    //     this.props.crearActualizarAnalisis(this.props.item)
    // };
    openModal = () => {
        this.setState({ open: true })
    };
    closeModal = () => {
        this.setState({ open: false })
    };

    expandComponent = (row) => {
        let data = row && row.fincas ? row.fincas : [];
        let _data = _.cloneDeep(data);
        return (
            <div className=" tabla-adentro">
                <BootstrapTable
                    headerStyle={{ backgroundColor: '#e24647' }}
                    data={_data}>
                    <TableHeaderColumn
                        hidden
                        isKey
                        dataField="id">
                        Id
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad_total"
                        dataFormat={cell => <RenderKilogramo value={cell} />}
                    >
                        INGRESADO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="finca"
                        className='text-center'
                        dataFormat={cell => cell.nombre}>
                        FINCA
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    };

    desactivarPila = () => {
        const { item } = this.props;
        let mensaje = item.bloqueado
            ? 'Tras confirmar esta acción la Pila podrá recibir más recepciones.'
            : 'Tras confirmar esta acción la Pila ya no recibirá más recepciones. Adicionalmente se creará un nuevo análisis de laboratorio para dicha pila.';
        mensaje += '\n\n¿Desea continuar?';
        const SwalMod = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-dark mr-5',
                content: 'd-flex justify-content-center mb-4',
                title: '',
                image: 'blink-danger',
            },
            buttonsStyling: false,
            focusConfirm: false,
            focusCancel: false,
        });
        SwalMod.fire({
            title: '¡ADVERTENCIA!',
            text: mensaje,
            imageUrl: imgAlerta,
            imageAlt: 'Alerta',
            showCancelButton: true,
            confirmButtonText: 'CONFIRMAR',
            cancelButtonText: 'CANCELAR',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.setBloqueoPila(this.props.match.params.id, !item.bloqueado);
            }
        });
    };
    reajustarInventario = (data) => {
        this.setState({open: false});
        this.props.reajustarInventarioLatex(this.props.match.params.id, data);
    }

    render() {
        const { item, loader, reajustar, productos_pila, getProductosPila } = this.props;
        return (
            <div className="mb-4 col-12">
                <Modal open={this.state.open} onClose={this.closeModal}>
                    <div className="pb-4 titulo-modal d-flex justify-content-center">
                        <h2>REAJUSTE EN {item && item.nombre ? item.nombre.toUpperCase() : ''}</h2>
                    </div>
                    <ReajusteForm onSubmit={this.reajustarInventario} closeModal={this.closeModal} itemBodega={item} />
                </Modal>
                <Card
                    icon="bodega_activo"
                    titulo={item ? (item.nombre ? item.nombre.toUpperCase() : "") : ""}
                    col="12"
                    subtitulo="Inventario"
                    noBorder>
                    <LoadMask loading={loader} blur>
                        <CardEmpty>
                            <div className=" row col-md-12 col-sm-12 d-flex flex-column flex-sm-row align-items-center justify-content-between mb-3">
                                <div className="row col-md-6 col-sm-12 d-flex flex-wrap">
                                    <Link className="btn btn-primary mt-2 mr-4" to={`salida/`}>CREAR SALIDA</Link>
                                    <button className="btn btn-secondary mt-2 mr-4" onClick={this.openModal}>REAJUSTE</button>
                                    {/* { !item.bloqueado && !item.ultimo_analisis && (
                                        <button className="btn btn-outline-info-normal mt-2 mr-4" onClick={this.crearActualizarAnalisis}>HACER ANÁLISIS</button>
                                    ) } */}
                                </div>
                                <div className="row col-md-6 col-sm-12 d-flex justify-content-end mb-1 mt-sm-2">
                                    <div className="d-flex align-items-center justify-content-md-end justify-content-sm-center col-sm-12 col-md-12 p-0">
                                        <h6 className="mr-2 mb-0 mt-2">{item.acopio ? item.acopio.nombre : 'Planta ERSA'}</h6>
                                        <img src={require('assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2 ml-3" />
                                        <span className="text-lg ml-3">{item.materia_prima && item.materia_prima.nombre ? item.materia_prima.nombre.toUpperCase() : '--'}</span>
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                        <div className="mt-3"></div>
                        <CardEmpty>
                            <div className="col-md-12 col-sm-12 d-flex flex-column flex-sm-row align-items-center justify-content-between">
                                <div className="col-sm-5 col-md-4 col-sm-12 d-flex flex-wrap align-items-center px-0">
                                    <span className="gris bold mr-3">ESTADO:</span>
                                    <button className={`btn btn-outline-${item.bloqueado ? 'danger' : 'success'}-normal gris mb-1 mr-4`} onClick={this.desactivarPila}>{item.bloqueado ? 'INACTIVA' : 'ACTIVA'}</button>
                                </div>
                                <div className="col-sm-7 col-md-8 col-sm-12 d-flex justify-content-end mb-1 mt-sm-2 px-0">
                                    <div className="d-flex align-items-center justify-content-md-end justify-content-sm-center col-sm-12 col-md-12 p-0">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex justify-content-between">
                                                <span className="mr-2"> ÚLTIMO ANÁLISIS</span>
                                                { item && item.analisis_laboratorio
                                                    ? <span className="gris bold"> {moment(item.analisis_laboratorio.creado).format("DD/MM/YYYY HH:mm A")}</span>
                                                    : <span className="rojo bold"> PENDIENTE</span>
                                                }
                                            </div>
                                            { item.analisis_laboratorio && (
                                                <div className="d-flex justify-content-between">
                                                    <span className="mr-2"> ÚLTIMA ACTUALIZACIÓN</span>
                                                    <span className="gris bold"> {moment(item.analisis_laboratorio.modificado).format("DD/MM/YYYY HH:mm A")}</span>
                                                </div>
                                            ) }
                                        </div>
                                        <img src={require('assets/img/icons/linea.png')} alt="Linea"
                                            className="title_img mr-2 ml-3" />
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="d-flex">
                                                <span className="mr-2">DRC</span>
                                                { item && item.analisis_laboratorio && item.analisis_laboratorio.drc_promedio > 0
                                                    ? <span className="gris bold text-nowrap"><RenderNumberPercentage value={item.analisis_laboratorio.drc_promedio} decimalScale={2}/></span>
                                                    : <span className="rojo bold"><RenderNumberPercentage value={0} decimalScale={2}/></span>
                                                }
                                            </div>
                                            { item && item.analisis_laboratorio && item.analisis_laboratorio.drc_promedio > 0 && (
                                                <small className="text-wrap">{item.analisis_laboratorio.estado_text}</small>
                                            ) }
                                        </div>
                                        <img src={require('assets/img/icons/linea.png')} alt="Linea"
                                            className="title_img mr-2 ml-3" />
                                        <span className="mr-2"> VFA</span>
                                        { item && item.analisis_laboratorio && item.analisis_laboratorio.vfa > 0
                                            ? <span className="gris bold text-nowrap"><RenderNumberPercentage value={item.analisis_laboratorio.vfa} decimalScale={3}/></span>
                                            : <span className="rojo bold"><RenderNumberPercentage value={0} decimalScale={3}/></span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 d-flex align-items-center justify-content-between flex-wrap my-3">
                                <div>
                                    <CardEmpty noShadow noPadding>
                                        <div className="py-1 px-2">
                                            {/* <div className="d-flex justify-content-between align-items-center">
                                                <span className="gris text-xs mr-2">PESO TOTAL KILOS HÚMEDOS </span>
                                                <span className="azul text-qts">{
                                                    <RenderNumber value={item.peso_total && item.peso_total > 0 ? item.peso_total : 0}
                                                        decimalScale={4} />
                                                }
                                                    <span className="gris text-sm"> KG</span>
                                                </span>
                                            </div> */}
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="gris text-xs mr-2">PESO TOTAL KILOS SECOS </span>
                                                {
                                                    item && item.analisis_laboratorio && item.analisis_laboratorio.drc_promedio > 0 && item.peso_cubicacion > 0
                                                        ?
                                                        <span className="azul text-qts">{
                                                            <RenderNumber
                                                                value={item.peso_cubicacion ? item.peso_cubicacion * item.analisis_laboratorio.drc_promedio : 0}
                                                                decimalScale={4} />
                                                        }
                                                            <span className="gris text-sm"> KG</span>
                                                        </span>
                                                        :
                                                        <span className="rojo text-qts">{
                                                            <RenderNumber value={0}
                                                                decimalScale={4} />
                                                        }
                                                            <span className="rojo text-sm"> KG</span>
                                                        </span>
                                                }

                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="gris text-xs mr-2">PESO TOTAL CUBICACIÓN </span>
                                                <span className="text-qts">{item.altura_utilizada > item.altura_maxima && <span className="rojo">{'> '}</span>}{<RenderNumber
                                                    value={item.peso_cubicacion} decimalScale={4} />}
                                                    <span className="gris text-sm"> KG</span>
                                                </span>
                                            </div>
                                        </div>
                                    </CardEmpty>
                                </div>
                                <div>
                                    <div>
                                        <span className="text-xs">CAPACIDAD UTILIZADA</span>
                                    </div>
                                    <div>
                                        <span className="gris text-qts">{item.altura_utilizada > item.altura_maxima && <span className="rojo">{"> "}</span>}<RenderNumber value={item.peso_cubicacion} /></span>
                                        <span> KG</span>
                                        <img src={require('assets/img/icons/linea.png')} alt="Linea"
                                            className="title_img mx-3 mb-2" />
                                        <span className="gris text-qts"> <RenderNumber value={item.altura_utilizada} decimalScale={2} /></span>
                                        <span> CM</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center progress-container">
                                    <div className="flex-1">
                                        <div className="text-center">
                                            <span className="text-xs">CAPACIDAD TOTAL</span>
                                            <span className="text-qts"> {item.altura_maxima}</span>
                                            <span className="text-xs"> CMS</span>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <ProgressBar
                                                className="progress-er"
                                                percent={item.porcentaje ? item.porcentaje : 0}
                                                height={30}
                                                filledBackground="#215273"
                                            />
                                            {(item.porcentaje >= 15) && (
                                                <span style={{ marginLeft: `${item.porcentaje - 15}%` }}
                                                    className="text-in-progress">
                                                    {`${item.altura_utilizada} cm`}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <h4 className="align-self-end m-0 text-qts">&nbsp;{`${item.porcentaje}%`}</h4>
                                </div>
                                <div className="mr-3">
                                    <div>
                                        <span className="text-xs">CAPACIDAD DISPONIBLE</span>
                                    </div>
                                    <div>
                                        <span className="gris text-qts">{<RenderNumber
                                            value={item.peso_capacidad - item.peso_cubicacion} />}</span>
                                        <span> KG</span>
                                        <img src={require('assets/img/icons/linea.png')} alt="Linea"
                                            className="title_img mx-3 mb-2" />
                                        <span className="gris text-qts">
                                            <RenderNumber value={(item.altura_maxima - item.altura_utilizada < 0) ? 0 : item.altura_maxima - item.altura_utilizada} decimalScale={2} />
                                        </span>
                                        <span> CM</span>
                                    </div>
                                </div>
                            </div>
                            <Grid data={productos_pila.data}
                                loading={loader}
                                page={productos_pila.page} onPageChange={(page) => getProductosPila(this.props.match.params.id, page)}
                                expandableRow={() => false}
                                expandComponent={this.expandComponent}
                            >
                                <TableHeaderColumn width="5%" />
                                <TableHeaderColumn
                                    dataField="porcentaje"
                                    dataFormat={formatoPorcentaje}
                                >
                                    INGRESO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    hidden
                                />
                                <TableHeaderColumn
                                    dataField="cantidad_total"
                                    dataAlign="right"
                                    dataFormat={cell => cell ? <span><RenderKilogramo value={cell} decimalScale={3} /> / cms</span> : '--'}
                                >
                                    CANTIDAD
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="finca"
                                    dataFormat={cell => cell ? <span>{cell.nombre} </span> :
                                        <span>(Entrada de pilas) </span>}
                                >
                                    FINCA
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn
                                    dataField="creado"
                                    dataAlign="center"
                                    dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                                >
                                    FECHA DE INGRESO
                                </TableHeaderColumn> */}
                            </Grid>
                        </CardEmpty>
                    </LoadMask>
                </Card>
            </div>
        )
    }
}
