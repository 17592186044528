import React from 'react';
import { connect } from 'react-redux';
import {validate, validators} from 'validate-redux-form';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import Alerta from "../../../../Utils/Alerta/Alerta";
import {renderField} from '../../../../Utils/renderField/renderField';

let AnularRecepcionForm = props => {
    const {handleSubmit, closeModal, canSubmit } = props;
    const required = value => ((value === undefined && canSubmit) && 'Required');
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <Alerta texto="Para anular una recepción la justificación es requerida." danger/>
                <div className="row  col-md-8 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="justificacion" className="m-0">INGRESA JUSTIFICACIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="justificacion_anulado" component={renderField} type="text" className="form-control"/>
                            {!canSubmit && (<span className="invalid-force">Campo requerido</span>)}
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-1">
                    <button className="btn btn-outline-dark mr-5"
                        onClick={e => {
                            e.preventDefault();
                            closeModal();
                        }}>
                        CANCELAR
                    </button>
                    <button type="submit" disabled={!canSubmit} className="btn btn-primary">ANULAR</button>
                </div>
            </div>
        </form>
    )
};
AnularRecepcionForm = reduxForm({
    form: 'anularRecepcionForm',
})(AnularRecepcionForm);

const selector = formValueSelector('anularRecepcionForm');
AnularRecepcionForm = connect(state => {
    const justificacion = selector(state, 'justificacion_anulado');
    let canSubmit = false;
    (justificacion !== undefined && justificacion !== "") && (canSubmit = true)
    return {
        justificacion,
        canSubmit,
    }
})(AnularRecepcionForm);
export default AnularRecepcionForm
