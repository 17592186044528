import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import _ from 'lodash';
import Grid from '../../../Utils/Grid';
import { RenderKilogramo, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';

const expandComponentCompras = (row) => {
    let _data = row.ingresos ? row.ingresos.slice() : [];
    return(
        <div className="row">
            <div className="col-11 ml-5">
                <div className="tabla-adentro" style={{width: "100%"}}>
                    <BootstrapTable
                        headerStyle={ { backgroundColor: '#e24647' } }
                        data={_data}>
                        <TableHeaderColumn width="5%"/>
                        <TableHeaderColumn isKey hidden dataField="id">
                            Id
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="fecha_entrada"
                            width="15%"
                            dataFormat={cell => cell ? moment(cell).format('DD/MM/YYYY') : '---'}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="analisis"
                            width="10%"
                            dataFormat={cell => cell ? <RenderNumberPercentage value={cell.drc_promedio} /> : '---' }
                        >
                            DRC
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="id"
                            width="15%"
                        >
                            ID RECEPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="materia_prima"
                            width="20%"
                            dataFormat={cell => cell ? cell.nombre : '---'}
                        >
                            MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="piloto"
                            width="20%"
                            dataFormat={cell => cell ? cell.nombre : '---'}
                        >
                            PILOTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="vehiculo"
                            width="10%"
                            dataFormat={cell => cell ? cell.placa : '---'}
                        >
                            PLACA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="acopio"
                            width="12%"
                            dataFormat={cell => cell ? cell.nombre : 'Planta ERSA'}
                        >
                            LUGAR
                        </TableHeaderColumn>
                        <TableHeaderColumn width="5%"/>
                    </BootstrapTable>
                </div>
            </div>
        </div>
    )
};

const ListarCompras = props => {
    const { data_compras, pageChangeCompras } = props;
    const { loader, page } = data_compras;
    return(
        <div>
            <div className="d-flex align-items-baseline ml-3 mt-2 mb-2">
                <img src={require(`assets/img/icons/compras_activo.png`)} alt="Usuario" className="title_img mr-2"/>
                <img src={require('assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2"/>
                <h2 className="mr-2">{"COMPRAS"}</h2>
                <span className="subtitulo">{"Liquidaciones"}</span>
            </div>
            <Grid hover striped loading={loader} data={data_compras}
                pagination page={page} onPageChange={pageChangeCompras}
                expandableRow={()=>true}
                expandComponent={expandComponentCompras}
                className="pb-3"
                noShowLoader noShowLoaderInfo
            >
                <TableHeaderColumn dataField="id"
                    isKey
                    width="5%"
                    dataFormat={cell => null}
                />
                <TableHeaderColumn
                    dataField="ingresos"
                    dataFormat={
                        cell => {
                            let kilos_totales = 0;
                            cell && cell.forEach(ingreso => kilos_totales+= _.get(ingreso, 'movimiento.peso_neto', 0));
                            return <RenderKilogramo value={kilos_totales} />
                        }
                    }
                >
                    KILOS INGRESADOS
                </TableHeaderColumn>
                <TableHeaderColumn dataField="nombre">
                    FINCA
                </TableHeaderColumn>
            </Grid>
        </div>
    )
};
export default ListarCompras;
