import React, {Component} from 'react'
import Grid from "Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import {RenderCM, formatoPorcentaje} from "Utils/renderField/renderReadField"
import { formatActivo } from '../../../../Utils/renderField/renderReadField';

export default class AlmacenesList extends Component{
    componentWillMount() {
        const { get_pilas, page_pilas } = this.props;
        get_pilas(page_pilas);
    }

    render() {
        const { get_pilas: onPageChange, onSortChange, eliminar } = this.props;
        const { pilas, loader, page_pilas } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="bodega_activo"
                    titulo="PILAS"
                    subtitulo="Látex">
                    <Grid hover striped data={pilas} loading={loader} onPageChange={onPageChange}
                          onSortChange={onSortChange} page={page_pilas} >
                        <TableHeaderColumn
                            dataField="id"
                            isKey
                            dataAlign="center"
                            width='10%'
                            dataFormat={standardActions({ver_bodega: "pila"})}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="materia_prima"
                            dataFormat={cell => cell ? cell.nombre : '--'}
                        >
                            MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="altura_maxima"
                            dataAlign="right"
                            dataFormat={(cell,row) => <RenderCM value={cell} />}
                        >
                            ALTURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="altura_utilizada"
                            dataAlign="right"
                            dataFormat={cell => <RenderCM value={cell}/>}
                        >
                            UTILIZADO CM
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="porcentaje"
                            dataFormat={formatoPorcentaje}
                        >
                            UTILIZADO %
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataField="id"
                            width='8%'
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="acopio"
                            dataFormat={(cell) => cell ? cell.nombre : 'Planta ERSA'}
                        >
                            LUGAR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="bloqueado"
                            dataAlign="center"
                            dataFormat={cell => formatActivo(!cell)}
                        >
                            ACTIVA
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
