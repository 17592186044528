import React, { Component } from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import {
    renderField,
    AsyncSelectField,
    renderDatePicker,
    renderTimeField,
    renderNumber,
    renderNumberKG
} from '../../../Utils/renderField/renderField'
import { Link } from 'react-router-dom'
import { validate, validators } from 'validate-redux-form'
import { api } from '../../../../../utility/api'
import CardEmpty from '../../../Utils/Cards/CardEmpty'
import Alerta from '../../../Utils/Alerta/Alerta'
import { connect } from 'react-redux'
import { FINALIZADO } from '../../../../../utility/constants'
import moment from 'moment'

const getClientes = search => {
    return api.get('cliente', {search}).then(data => {
        if (data) return data.results
        return []
    }).catch(() => {
        return []
    })
}

const getProductos = search => {
    return api.get('materia_prima', {search}).then(data => {
        if (data) return data.results
        return []
    }).catch(() => {
        return []
    })
}

const getPilotos = search => {
    return api.get('piloto', {search}).then(data => {
        if (data) return data.results
        return []
    }).catch (() => {
        return []
    })
}


class DespachoEditarForm extends Component {
    state = {
        cliente: 0,
        peso_tara_equipo: 0,
        peso_tara_empaque: 0,
        peso_bruto_despacho: 0,
        peso_neto: 0,
    }

    componentDidMount() {
        const { item } = this.props
        this.setState({
            peso_tara_equipo: item.peso_tara_equipo,
            peso_tara_empaque: item.peso_tara_empaque,
            peso_bruto_despacho: item.peso_bruto_despacho,
            peso_neto: item.peso_neto
        })
    }
    
    render() {
        const { handleSubmit, finalizar } = this.props
        const { item, peso_manual, peso, peso_neto, configuraciones } = this.props
        return (
            <form onSubmit={handleSubmit} className="container">
                <h4>DATOS GENERALES DE DESPACHO</h4>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="fecha">FECHA</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="fecha"
                                component={renderDatePicker}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="hora">HORA</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="hora"
                                component={renderTimeField}
                            />
                        </div>
                    </div>
                </section>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="cliente">CLIENTE</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="cliente"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getClientes}
                                onChange={e => {
                                    this.setState({cliente: e.id})
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="piloto">PILOTO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="piloto"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getPilotos}
                            />
                        </div>
                    </div>
                </section>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="producto">PRODUCTO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                valueKey="id"
                                name="producto"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getProductos}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="no_placa_cabezal">NO. PLACA DE CABEZAL</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="no_placa_cabezal"
                                component={renderField}
                            />
                        </div>
                    </div>
                </section>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="codigo_contenedor">CÓDIGO DE CONTENEDOR</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="codigo_contenedor"
                                component={renderField}
                            />
                        </div>
                    </div>
                </section>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="peso_tara_equipo">PESO DE ENTRADA DEL EQUIPO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="peso_tara_equipo"
                                suffix="Kg"
                                component={renderNumber}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="peso_tara_empaque">PESO TARA DE EMPAQUE (opcional)</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="peso_tara_empaque"
                                type="text"
                                component={renderNumber}
                                decimalScale={0}
                                suffix="Kg"
                                disabled={true}
                            />
                        </div>
                    </div>
                </section>
                <section className="d-flex mt-2">
                    <div className="col-md-6 p-0">
                        <CardEmpty noShadow noPadding>
                            <div className="col-12 pt-2">
                                <label htmlFor="peso_bruto_despacho">PESO DE SALIDA DEL EQUIPO</label>
                            </div>
                            <div className="col-md-12 form-group">
                                {peso_manual === false && (
                                    <Field name="peso_bruto_despacho" type="text" component={renderNumber} decimalScale={0}
                                        suffix="Kg"
                                        disabled={true}
                                        input={{value: peso}}
                                    />
                                )}
                                {peso_manual === true && (
                                    <Field name="peso_bruto_despacho" type="text" component={renderNumber} decimalScale={0}
                                        suffix=" Kg" parse={value => parseFloat(value)}
                                        onChange={value => {
                                            let numero = "0"
                                            Object.entries(value).forEach(([key, value]) => {
                                                if (typeof(value) === "string") {
                                                    numero = numero + value
                                                }
                                            })
                                            this.setState({peso_bruto_despacho: parseInt(numero)})
                                        }}/>
                                )}
                            </div>
                        </CardEmpty>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 mt-4 form-group d-flex align-content-center justify-content-center">
                            <Alerta texto={
                                configuraciones.peso_ingreso_manual
                                    ? 'El peso se está tomando manualmente. Para tomarlo de báscula vaya a Configuración.'
                                    : 'El peso se está tomando automáticamente de Báscula. Para ingresarlo manualmente se debe ir a Configuración.'
                            }/>
                        </div>
                    </div>
                </section>
                <section className="d-flex mt-2">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="peso_neto">PESO NETO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="peso_neto"
                                component={renderNumberKG}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="lote">LOTE</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="lote"
                                component={renderField}
                            />
                        </div>
                    </div>
                </section>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="carta_porte">N&#9900; DE CARTA DE PORTE</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="carta_porte"
                                component={renderField}
                            />
                        </div>
                    </div>
                </section>
                <div className="buttons-box" style={{marginTop: '5%'}}>
                    <Link className="btn btn-outline-dark mr-5" to="/despacho">REGRESAR</Link>
                    <button type="submit" className="btn btn-primary mr-5">GUARDAR</button>
                </div>
            </form>
        )
    }
}
DespachoEditarForm = reduxForm({
    form: 'despachoEditarForm'
})(DespachoEditarForm)

const selector = formValueSelector('despachoEditarForm')
DespachoEditarForm = connect(state => {
    const form = state.form.despachoEditarForm;
    const peso_bascula = state.despachos;
    let peso = 0

    function getNumbersInString(string) {
        var tmp = string.split("")
        var map = tmp.map(current => {
            if (!isNaN(parseInt(current))) {
                return current
            }
        })
        var numbers = map.filter(value => {
            return value != undefined
        })
        return numbers.join("")
    }

    if (peso_bascula.peso_bascula) {
        if (peso_bascula.peso_bascula) {
            peso = getNumbersInString(peso_bascula.peso_bascula)
            if (form && form.values) {
                form.values.peso_bruto_despacho = peso
            }
        }
    }


    let peso_neto = 0;

    if (form && form.values) {
        const values = form.values;
        peso_neto = values.peso_bruto_despacho - values.peso_tara_equipo - values.peso_tara_empaque
        values.peso_neto = peso_neto
    }

    return {
        peso,
        peso_neto
    }
})(DespachoEditarForm)

export default DespachoEditarForm