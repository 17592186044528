import React, { Component, useEffect } from "react";
import Card from "Utils/Cards/Card";
import FiltrosHeader from "../FiltrosHeader";
import TabBar from "rc-tabs/lib/TabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tabs, { TabPane } from "rc-tabs";
import Reporte from "./Reporte";
import CardEmpty from "../../../Utils/Cards/CardEmpty";

const ReporteLiquidacionesProveedores = (props) => {
    const {
        getLiquidacionesParaReporte,
        reporte,
        pageReporteChange,
        fechaInicialReporteChange,
        fechaFinalReporteChange,
        fincaReporteChange,
        proveedor,
        me
    } = props;

    let page = reporte.page;
    let fecha_inicial = reporte.fecha_inicial;
    let fecha_final = reporte.fecha_final;
    let finca_id = reporte.finca_id;
    let pageChange = pageReporteChange;
    let fechaInicialChange = fechaInicialReporteChange;
    let fechaFinalChange = fechaFinalReporteChange;
    let fincaChange = fincaReporteChange;

    useEffect(() => {
        // me.proveedor_admin &&
        //     (getLiquidacionesParaReporte(true))
        me.proveedor_admin &&
            (fincaReporteChange(-1))
    }, [proveedor]);

    return (
        <div className="mb-4 col-12">
            <Card
                icon="reportes_activo"
                titulo="LIQUIDACIONES"
                noBorder
                subtitulo="Reporte"
            >
                <CardEmpty>
                    <FiltrosHeader
                        {...props}
                        fechaInicialChange={fechaInicialChange}
                        fecha_inicial={fecha_inicial}
                        fechaFinalChange={fechaFinalChange}
                        fecha_final={fecha_final}
                        filtroFincaProveedoresChange={fincaChange}
                        finca_proveedores_id={finca_id}
                        filtrarXReporte={() => {
                            getLiquidacionesParaReporte(true)
                        }}
                        labelXReporte={"Filtrar"}
                    />
                </CardEmpty>
                <div className="mb-4"></div>
                <Reporte
                    {...props}
                    pageChange={pageChange}
                    page={page}
                ></Reporte>
            </Card>
        </div>
    );
};

export default ReporteLiquidacionesProveedores;