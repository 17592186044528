import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";
import CardEmpty from "Utils/Cards/CardEmpty";
import {Link} from "react-router-dom";
import {RenderNumber, RenderKilogramo, RenderNumberPercentage} from "Utils/renderField/renderReadField"
import {TableHeaderColumn} from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import {Field} from "redux-form";
import {renderField, renderNumber} from "../../../Utils/renderField";
import { RenderML, RenderGramos } from '../../../Utils/renderField/renderReadField';
const ver_img = require('assets/img/icons/ver.png');
import moment from 'moment/moment';

import { NotificationManager } from 'react-notifications';
export default class AnalisisVer extends Component {
    state = {
        ver_info: false,
        ver_opciones: false,
        url_salir: '/laboratorio/analisis_latex',
    };
    componentWillMount(){
        if (this.props.match.url.includes('validar_drc_latex')){
            this.setState({ver_opciones: true, url_salir: '/laboratorio/validar_drc_latex'});
            this.props.leer(this.props.match.params.id, 'validar');
        } else if(this.props.match.url.includes('drc_validados_latex')) {
            this.setState({url_salir: '/laboratorio/drc_validados_latex'});
            this.props.leer(this.props.match.params.id, 'finalizados');
        }
        else
            this.props.leer(this.props.match.params.id);
    }
    toogleInfo = () => {
        this.setState({ver_info: !this.state.ver_info})
    };

    send_to_validation = (id, ingreso, movimiento)=>{
        const { item, validar_drc } = this.props;
        if (item && item.datos_vfa && item.datos_drc){
            if (item.datos_drc.length>0 && item.datos_vfa.length>0){
                validar_drc(id,ingreso, movimiento);
            }else{
                NotificationManager.error('Producto no tiene datos de drc o vfa','ERROR',2000);
            }
        }
    }
    render() {
    const { crear, item, loader, validar_drc, rechazar_drc } = this.props;
    return (
        <div className="mb-4 col-12">
          <CardForm
              icon="laboratorio_activo"
              titulo="ANÁLISIS"
              subtitulo="Detalle"
              col="12"
          >
              <LoadMask loading={loader} blur>
                  <div className="d-flex justify-content-center mb-3">
                        <button className="btn btn-outline-info" onClick={e => {e.preventDefault(); this.toogleInfo()}}>
                            VER DETALLE DE RECEPCIÓN
                            <img src={ver_img} alt="Ver" className="action_img ml-2"/>
                        </button>
                </div>
                {(item.ingreso && item.ingreso.finca && this.state.ver_info) && (
                    <div className="d-flex  justify-content-center mb-4">
                        <div className="col-8">
                            <div className="row col-12">
                                <div className="col-12 d-flex justify-content-between align-items-baseline">
                                    <h6 className="mb-4">DATOS GENERALES DE RECEPCIÓN</h6>
                                </div>
                            </div>
                            <div className="row col-md-12 p-0">
                                <div className="col-md-6 p-0">
                                    <div className="col-12 d-flex flex-column">
                                        <label className="p-0 m-0">FINCA</label>
                                        <span className="mb-1">{item.ingreso.finca.nombre}</span>
                                        <label className="p-0 m-0" >VEHÍCULO</label>
                                        <span className="mb-1">{item.ingreso.vehiculo.nombre}</span>
                                        <label className="p-0 m-0">LUGAR</label>
                                        <span className="mb-1">{item.ingreso.acopio ? item.ingreso.acopio.nombre : 'Planta ERSA'}</span>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="col-12 d-flex flex-column">
                                        <label className="p-0 m-0">PILOTO</label>
                                        <span className="mb-1">{item.ingreso.piloto.nombre}</span>
                                        <label className="p-0 m-0">TIPO DE MATERIA</label>
                                        <span className="mb-1">{item.ingreso.tipo_materia.nombre}</span>
                                        <label className="p-0 m-0">FECHA Y HORA DE INGRESO</label>
                                        <span className="mb-1">{moment(item.ingreso.fecha_entrada).format('DD/MM/YYYY hh:mm A')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {item.ingreso && (
                    <div className="d-flex justify-content-between align-items-end verde-secundario mb-3">
                        <div className="d-flex align-items-end"></div>
                        <div className="d-flex align-items-baseline">
                            <span className="bold mr-2">ID RECEPCIÓN</span>
                            <h4 className="gris bold mr-3">{item.ingreso.id}</h4>
                            <span className="bold mr-2">PRODUCTO</span>
                            <h4 className="gris bold">{item.movimiento.materia_prima.nombre}</h4>
                        </div>
                    </div>
                )}
                  <div className="row my-3">
                      <div className="col-12">
                           <CardEmpty noShadow >
                               <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-wrap align-items-center">
                                      <label className="verde-secundario bold mr-2">FECHA DE INICIO DE LABORATORIO:</label>
                                      <span className="mb-2">{item && item.fecha_analisis ? moment(item.fecha_analisis).format('DD/MM/YYYY') : ""}</span>
                                  </div>
                                  <div className="d-flex flex-wrap align-items-center">
                                      <label className="verde-secundario bold mr-2">HORA DE INICIO DE LABORATORIO:</label>
                                      <span className="mb-2">{item && item.hora_analisis ? item.hora_analisis : ""}</span>
                                  </div>
                              </div>
                           </CardEmpty>
                      </div>
                  </div>
                  <div className="row my-3">
                      <div className="col-12">
                           <CardEmpty noShadow >
                               <div className="d-flex justify-content-between my-2">
                                  <h6 className="">DATOS DRC</h6>
                                  <div className="d-flex align-items-center">
                                      <span className="verde-secundario bold mr-2">DRC PROMEDIO:</span>
                                      <RenderNumberPercentage value={item.drc_promedio}/>
                                  </div>
                              </div>
                               <Grid data={item.datos_tsc ? {results: item.datos_drc} : {}} loading={loader} pagination={false}  >
                                   <TableHeaderColumn
                                       dataField="peso_seco"
                                       dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                   >
                                       PESO SECO
                                   </TableHeaderColumn>
                                   <TableHeaderColumn
                                       dataField="id"
                                       isKey
                                       hidden
                                   />
                                   <TableHeaderColumn
                                       dataField="peso_plato"
                                       dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                   >
                                       PESO PLATO
                                   </TableHeaderColumn>
                                   <TableHeaderColumn
                                       dataField="peso_humedo"
                                       dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                   >
                                       PESO HÚMEDO
                                   </TableHeaderColumn>
                                   <TableHeaderColumn
                                       dataField="total"
                                       dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderNumberPercentage value={cell}/></span>}
                                   >
                                       DRC
                                   </TableHeaderColumn>
                                   <TableHeaderColumn
                                       dataField="hora_ingreso"
                                       dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{cell}</span>}
                                   >
                                       HORA INGRESO
                                   </TableHeaderColumn>
                                   <TableHeaderColumn
                                       dataField="hora_salida"
                                       dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{cell}</span>}
                                   >
                                       HORA SALIDA
                                   </TableHeaderColumn>
                                   <TableHeaderColumn
                                       dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{row.laboratorista_nombre ? row.laboratorista_nombre : row.laboratorista.nombre}
                                        </span>}
                                   >
                                       LABORATORISTA
                                   </TableHeaderColumn>
                               </Grid>

                           </CardEmpty>
                      </div>
                  </div>
                  <div className="row my-4">
                      <div className="col-12">
                          <CardEmpty noShadow >
                              <div className="d-flex justify-content-between my-2">
                                  <h6 className="">DATOS TSC</h6>
                                  <div className="d-flex align-items-center">
                                      <span className="verde-secundario bold mr-2">TSC PROMEDIO:</span>
                                      <RenderNumberPercentage value={item.tsc_promedio}/>
                                  </div>
                              </div>
                              <Grid data={item.datos_tsc ? {results: item.datos_tsc} : {}} loading={loader} pagination={false}  >
                                  <TableHeaderColumn
                                      dataField="peso_seco"
                                      dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                  >
                                      PESO SECO
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                      dataField="id"
                                      isKey
                                      hidden
                                  />
                                  <TableHeaderColumn
                                      dataField="peso_plato"
                                      dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                  >
                                      PESO PLATO
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                      dataField="peso_humedo"
                                      dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                  >
                                      PESO HÚMEDO
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                      dataField="total"
                                      dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderNumberPercentage value={cell}/></span>}
                                  >
                                      TSC
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                       dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{row.laboratorista_nombre ? row.laboratorista_nombre : row.laboratorista.nombre}</span>}
                                   >
                                       LABORATORISTA
                                   </TableHeaderColumn>
                              </Grid>

                          </CardEmpty>
                      </div>
                  </div>

                  <div className="row my-4">
                      <div className="col-12">
                          <CardEmpty noShadow >
                              <div className="d-flex justify-content-between my-2">
                                  <h6 className="">DATOS VFA</h6>
                                  <div className="d-flex align-items-center">
                                      <span className="verde-secundario bold mr-2">VFA PROMEDIO:</span>
                                      <RenderNumberPercentage value={item.vfa} decimalScale={3}/>
                                  </div>
                              </div>
                              <Grid data={item.datos_vfa ? {results: item.datos_vfa} : {}} loading={loader} pagination={false}  >
                                  <TableHeaderColumn
                                      dataField="ml_bureta"
                                      dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderML value={cell} /></span>}
                                  >
                                      ML. BURETA
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                      dataField="id"
                                      isKey
                                      hidden
                                  />
                                  <TableHeaderColumn
                                      dataField="peso"
                                      dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderNumber value={cell} decimalScale={4}/></span>}
                                  >
                                      NORMALIDAD
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                      dataField="total"
                                      dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderNumberPercentage value={cell/100} decimalScale={3}/></span>}
                                  >
                                      VFA
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                       dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{row.laboratorista_nombre ? row.laboratorista_nombre : row.laboratorista.nombre}</span>}
                                   >
                                       LABORATORISTA
                                   </TableHeaderColumn>
                              </Grid>

                          </CardEmpty>
                      </div>
                  </div>
                  <div className="row my-4">
                      <div className="col-12">
                          <CardEmpty noShadow >
                              <div className="d-flex justify-content-between my-2">
                                  <h6 className="">DATOS NH3</h6>
                                  <div className="d-flex align-items-center">
                                      <span className="verde-secundario bold mr-2">NH3:</span>
                                      <RenderNumberPercentage value={item.nh3}/>
                                  </div>
                              </div>
                              <Grid data={item.datos_nh3 ? {results: item.datos_nh3} : {}} loading={loader} pagination={false}  >
                                  <TableHeaderColumn
                                      dataField="ml_bureta"
                                      dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderML value={cell} /></span>}
                                  >
                                      ML. BURETA
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                      dataField="id"
                                      isKey
                                      hidden
                                  />
                                  <TableHeaderColumn
                                      dataField="peso"
                                      dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                  >
                                      PESO MUESTRA
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                      dataField="total"
                                      dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderNumberPercentage value={cell}/></span>}
                                  >
                                      NH3
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                       dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{row.laboratorista_nombre ? row.laboratorista_nombre : row.laboratorista.nombre}</span>}
                                   >
                                       LABORATORISTA
                                   </TableHeaderColumn>
                              </Grid>

                          </CardEmpty>
                      </div>
                  </div>
                  <div className="row my-4">
                      <div className="col-12">
                          <CardEmpty noShadow>
                              <h6 className="">MARCHAMOS</h6>
                              <div className="row ml-3">
                                      {item.marchamos && (
                                          item.marchamos.map((marchamo, index) => {
                                              return(
                                                  <span key={index} className="mx-2">{marchamo}</span>
                                              )
                                          })
                                      )}
                              </div>
                          </CardEmpty>
                      </div>
                  </div>
                  <div className="row px-3 pt-3">
                      <div className="col-12">
                            <h6 className="">OBSERVACIONES</h6>
                            <div className="row ml-3">
                                <span>{item ? item.observaciones : ""}</span>
                            </div>
                      </div>
                  </div>
                    <div className="text-right">
                        <span className="bold gris">ERSA-F-LL-007-07</span>
                    </div>
                  <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5 px-5" to={this.state.url_salir}>SALIR</Link>
                      {this.state.ver_opciones && (
                          <Fragment>
                              <button type="button" className="btn btn-primary mr-5" 
                                      onClick={event => {event.preventDefault(); this.send_to_validation(this.props.match.params.id, item.ingreso.id, item.movimiento.id)}}>
                                  VALIDAR RESULTADOS
                              </button>
                              <button type="button" className="btn btn-secondary"
                                onClick={event => {event.preventDefault(); rechazar_drc(this.props.match.params.id)}}>
                                    RECHAZAR RESULTADOS
                                </button>
                          </Fragment>
                      )}
                </div>

              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
