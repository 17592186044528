import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment/moment';
import Grid from '../../../Utils/Grid';
import { HeaderLiquidaciones } from '../../../Utils/Grid/Headers';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import { RenderNumberPercentage, RenderKilogramo } from '../../../Utils/renderField/renderReadField';

const expandComponent = (row) => {
    let _data = row.detalles ? row.detalles.slice() : [];
    return(
        <div className="row d-flex justify-content-center">
            <div className="col-11">
                <div className="tabla-adentro" style={{width: '100%'}}>
                    <BootstrapTable
                        headerStyle={ { backgroundColor: '#e24647' } }
                        data={_data}>
                        <TableHeaderColumn width="5%"/>
                        <TableHeaderColumn isKey hidden dataField="id">
                            ID DETALLE
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ingreso"
                            width="15%"
                            dataFormat={cell => cell.id}
                        >
                            ID RECEPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ingreso"
                            width="10%"
                            dataFormat={cell => moment(cell.fecha).format("DD/MM/YYYY")}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="drc_compra"
                            width="12%"
                            dataAlign="right"
                            dataFormat={cell => <RenderNumberPercentage value={cell}/>}
                        >
                            DRC COMPRA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="materia_prima"
                            width="20%"
                            dataFormat={cell => cell.nombre}
                        >
                            MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="kilos_secos"
                            width="20%"
                            dataAlign="right"
                            dataFormat={cell => <RenderKilogramo value={cell}/>}
                        >
                            KILOS SECOS
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="acopio"
                            dataFormat={cell => cell ? cell.nombre : 'Planta ERSA'}
                        >
                            LUGAR
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        </div>
    )
};

const Pendientes = props => {
    const { data_pendientes: data, totales_pendientes, pageChangePendientes: pageChange, data_compras } = props;
    const { loader, page } = data;
    const { fecha_inicio_liq, fecha_final_liq } = props;
    const { filtroFincaChangeLiq, fechaInicioLiq, fechaFinalLiq } = props;
    const { id_perfil_finca_pend } = props;
    const { filtroFincaTipoPagoPendChange, filtroFincaPerfilPendChange, filtroFincaRegimenFiscalPendChange, listar, listarCompras, getTotalesParaLiquidaciones } = props;
    return(
        <div style={{minHeight: '450px'}}>
            <HeaderLiquidaciones {...props} search={undefined}
                dataTotales={totales_pendientes}
                filtroFincaChange={filtroFincaChangeLiq}
                fecha_inicial={fecha_inicio_liq} fecha_final={fecha_final_liq}
                fechaInicialChange={fechaInicioLiq} fechaFinalChange={fechaFinalLiq}
                filtroFincaTipoPagoChange={filtroFincaTipoPagoPendChange} filtroFincaPerfilChange={filtroFincaPerfilPendChange} filtroFincaRegimenFiscalChange={filtroFincaRegimenFiscalPendChange}
                id_perfil_finca={id_perfil_finca_pend}
                disableButton={loader || data_compras.loader} getDataLiquidaciones={() => {
                    listar();
                    listarCompras();
                    getTotalesParaLiquidaciones();
                }}
            />
            <Grid hover striped loading={loader} data={data}
                pagination page={page} onPageChange={pageChange}
                expandableRow={()=>true} expandComponent={expandComponent}
                noShowLoaderInfo
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    // width='20%'
                    dataSort
                    dataFormat={standardActions({ generar_liquidacion: "liquidacion"})}
                />
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                >
                    ID COMPRA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="creado"
                    dataSort
                    dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="finca"
                    dataSort
                    dataFormat={cell => cell ? cell.nombre : ""}
                >
                    FINCA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="detalles"
                    dataSort
                    dataAlign="right"
                    dataFormat={
                        cell =>{
                            var kilos_totales = 0;
                            if (cell) {
                                for (let i = 0; i < cell.length; i++) {
                                    kilos_totales += cell[i].kilos_secos;
                                }
                                return <RenderKilogramo value={kilos_totales}/>
                            }
                        }
                    }
                >
                    KILOS SECOS
                </TableHeaderColumn>
                <TableHeaderColumn width="5%"/>
            </Grid>
        </div>
    )
};
export default Pendientes;
