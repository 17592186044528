import React from 'react';
import Select from "react-select";
import classNames from "classnames";
import { analisis_laboratorio, reporte_anual } from '../../../../utility/constants';

export const renderLegend = (props) => {
    const { payload, title, seleccionGrafica, graficaReporte, grafica_reporte, tipo_materia_reporte, graficaComparativaDRC, graficaComparativaVFA } = props;
    return (
        <div className="recharts-legend-wrapper d-flex flex-column align-items-start mr-5">
            {seleccionGrafica && tipo_materia_reporte === 'LATEX' && (
                <div className="col-12 m-0 p-0 mb-3">
                    <span className="bold">{"TIPO DE GRAFICA"}</span>
                    <Select
                        className={classNames('react-select-container')}
                        backspaceRemovesValue={false}
                        analisi
                        isSearchable={false}
                        options={analisis_laboratorio}
                        placeholder="Elegir analisis a visualizar"
                        onChange={(e) => {graficaReporte(e ? e : null); }}
                        value={grafica_reporte}
                    />
                </div>
            )}
            <span className="mb-3 bold mr-3">{title}</span>
            <ul className="recharts-default-legend pl-0">
                {
                    graficaComparativaDRC && (
                        <li className={`recharts-legend-item mb-2`} style={{display: 'block'}}>
                            <svg className="recharts-surface" width="16" height="16" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
                                <path fill="#ad1717" cx="16" cy="16" type="circle" className="recharts-symbols" transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
                            </svg>
                            <span className="recharts-legend-item-text">NH3 FUERA DEL LÍMITE</span>
                        </li>
                    )
                }
                {
                    graficaComparativaVFA && (
                        <li className={`recharts-legend-item mb-2`} style={{display: 'block'}}>
                            <svg className="recharts-surface" width="16" height="16" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
                                <path fill="#db1d1d" cx="16" cy="16" type="circle" className="recharts-symbols" transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
                            </svg>
                            <span className="recharts-legend-item-text">VFA FUERA DEL LÍMITE</span>
                        </li>
                    )
                }
                {
                    payload.map((entry, index) => {
                        return (
                            <li className={`recharts-legend-item legend-item-${index} mb-2`} key={`item-${index}`} style={{display: 'block'}}>
                                <svg className="recharts-surface" width="16" height="16" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
                                    <path fill={entry.color} cx="16" cy="16" type="circle" className="recharts-symbols" transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
                                </svg>
                                <span className="recharts-legend-item-text">{entry.payload.value}</span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );
}

export const renderLegendReporteAnual = props => {
    const { payload, title, seleccionGrafica, graficaReporte, grafica_reporte } = props;
    return (
        <div className="recharts-legend-wrapper d-flex flex-column align-items-start mr-5">
            {seleccionGrafica && (
                <div className="col-12 m-0 p-0 mb-3">
                    <span className="bold">{"TIPO DE GRAFICA"}</span>
                    <Select
                        className={classNames('react-select-container')}
                        backspaceRemovesValue={false}
                        analisi
                        isSearchable={false}
                        options={reporte_anual}
                        placeholder="Elegir datos para mostrar"
                        onChange={e => {graficaReporte(e ? e : null)}}
                        value={grafica_reporte}
                    />
                </div>
            )}
            <span className="mb-3 bold mr-3">{title}</span>
            <ul className="recharts-default-legend pl-0">
                {
                    payload.map((entry, index) => (
                        <li className={`recharts-legend-item legend-item-${index} mb-2`} key={`item-${index}`} style={{display: 'block'}}>
                            <svg className="recharts-surface" width="16" height="16" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
                                <path fill={entry.color} cx="16" cy="16" type="circle" className="recharts-symbols" transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
                            </svg>
                            <span className="recharts-legend-item-text">{entry.payload.value}</span>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export const renderLegendCustom = props => {
    const { payload, title, seleccionGrafica, graficaReporte, grafica_reporte, optionsCustom } = props;
    return (
        <div className="recharts-legend-wrapper d-flex flex-column align-items-start mr-5">
            {seleccionGrafica && (
                <div className="col-12 m-0 p-0 mb-3">
                    <span className="bold">{"TIPO DE GRAFICA"}</span>
                    <Select
                        className={classNames('react-select-container')}
                        backspaceRemovesValue={false}
                        analisi
                        isSearchable={false}
                        options={optionsCustom}
                        placeholder="Elegir datos para mostrar"
                        onChange={e => {graficaReporte(e ? e : null)}}
                        value={grafica_reporte}
                    />
                </div>
            )}
            <span className="mb-3 bold mr-3">{title}</span>
            <ul className="recharts-default-legend pl-0">
                {
                    payload.map((entry, index) => (
                        <li className={`recharts-legend-item legend-item-${index} mb-2`} key={`item-${index}`} style={{display: 'block'}}>
                            <svg className="recharts-surface" width="16" height="16" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
                                <path fill={entry.color} cx="16" cy="16" type="circle" className="recharts-symbols" transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
                            </svg>
                            <span className="recharts-legend-item-text">{entry.payload.value}</span>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}