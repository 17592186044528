import _ from "lodash";
import Swal from 'sweetalert2';

 const validador = (values, props) => {
     const errors = {};
    // if(!values.no_produccion)
    //     errors.no_produccion = 'Campo requerido.';
    if(!values.fecha_inicio)
        errors.fecha_inicio = 'Campo requerido.';
    if(!values.producto)
        errors.producto = 'Campo requerido.';


    //  const ProductosArrayErrors = [];
    //  if(!values.productos || values.productos.length <= 0){
    //      errors.errorProd = 'Se debe de agregar por lo menos un producto.'
    //  }else {
    //      values.productos.forEach((producto, productoIndex) => {
    //          const productoErrors = {};
    //          if(producto.materia_prima){
    //              const existe = _.find(values.productos, (p) => { return parseInt(p.materia_prima) === parseInt(producto.materia_prima)
    //                  && p.index !== productoIndex });
    //              if(existe){
    //                  productoErrors.materia_prima = 'El producto ya fue seleccionado';
    //                  ProductosArrayErrors[productoIndex] = productoErrors
    //              }
    //          }
    //          if ( !producto.materia_prima) {
    //              productoErrors.materia_prima = 'Campo requerido';
    //              ProductosArrayErrors[productoIndex] = productoErrors
    //          }
    //          if(Number(producto.peso_finca) <= 0){
    //              productoErrors.peso_finca = "Debe ser una cantidad mayor a 0.";
    //              ProductosArrayErrors[productoIndex] = productoErrors
    //          }
    //          if(!producto.peso_finca){
    //              productoErrors.peso_finca = 'Campo requerido.';
    //              ProductosArrayErrors[productoIndex] = productoErrors
    //          }
    //          if( !producto.peso_finca_unidad){
    //              productoErrors.peso_finca_unidad = 'Campo requerido';
    //              ProductosArrayErrors[productoIndex] = productoErrors
    //          }
    //      });
    //      if (ProductosArrayErrors.length) {
    //          errors.productos = ProductosArrayErrors
    //      }
    //  }

    return errors;
}

export default validador
