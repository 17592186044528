import React from 'react';
import { Link } from 'react-router-dom';
import { Field, FieldArray, reduxForm, } from 'redux-form';
import {
    renderField,
    renderCurrency,
    renderDatePicker
} from '../../../Utils/renderField/renderField';
import {RenderCurrency, RenderDollar,RenderKilogramo } from 'Utils/renderField/renderReadField';
import CardEmpty from "Utils/Cards/CardEmpty";
import moment from "moment/moment";
import { FACTURA_ESPECIAL, EXPORTADOR, FLETE_INTERNO_COSTO_POR_KG, PRODUCCION_INTERNA} from "../../../../../utility/constants";
import { RenderCambio, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';

export const renderDatosCompras = ({ fields, openModal, item, loadDataModal, ordenCompraTemp, tipo_drc, finca_nombre}) => {
    return (
        <div className="col-12">
            <div className="react-bs-table-container">
                <div className="react-bs-table">
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <th style={{ width: "10%" }}>ID INGRESO</th>
                                    <th style={{ width: "9%" }}>FECHA</th>
                                    <th style={{ width: "14%" }}>MATERIA PRIMA</th>
                                    <th style={{ width: "12%" }} className="text-right">KILOS HUMEDOS</th>
                                    <th style={{ width: "9%" }} className="text-right">DRC</th>
                                    <th style={{ width: "11%" }} className="text-right">KILOS SECOS</th>
                                    <th style={{ width: "8%" }} className="text-right">PRECIO</th>
                                    <th style={{ width: "9%" }} className="text-right">SUBTOTAL $.</th>
                                    <th style={{ width: "8%" }} className="text-right">CAMBIO</th>
                                    <th style={{ width: "10%" }} className="text-right">SUBTOTAL Q.</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <table className="table table-striped table-bordered table-hover">
                <tbody>
                    {fields.map((pago, index) => {
                        const dato_actual = fields.get(index); // Pago actual
                        let todos = fields.getAll();
                        // El precio_venta (precio unitario) debe incluir el precio unitario de flete
                        const { precio_venta=0, costo_flete=0, tipo_costo_flete=0 } = dato_actual;
                        const total_precio_venta = precio_venta + (tipo_costo_flete === FLETE_INTERNO_COSTO_POR_KG ? costo_flete : 0);
                        return (
                            <tr key={index} >
                                <td style={{ width: "10%" }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <span>{dato_actual && dato_actual.ingreso ? dato_actual.ingreso.id : '--'} </span>
                                </td>
                                <td style={{ width: "9%", paddingRight: 0 }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <span>{dato_actual && dato_actual.ingreso ? moment(dato_actual.ingreso.fecha_entrada).format('DD/MM/YYYY') : '--'} </span>
                                </td>
                                <td style={{ width: "14%", }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <span>{dato_actual && dato_actual.materia_prima ? dato_actual.materia_prima.nombre : '--'} </span>
                                </td>
                                <td style={{ width: "12%" }}
                                    className={`text-right ${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <RenderKilogramo value={
                                        dato_actual && dato_actual.kilos_humedos ? (dato_actual.kilos_humedos.toFixed(4)) : 0
                                    } />
                                </td>
                                <td style={{ width: "9%" }}
                                    className={`text-right ${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <RenderNumberPercentage value={dato_actual.drc_compra} />
                                </td>
                                <td style={{ width: "11%" }}
                                    className={`text-right ${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <RenderKilogramo value={
                                        dato_actual && dato_actual.kilos_secos ? (dato_actual.kilos_secos.toFixed(4)) : 0
                                    } />
                                </td>
                                <td style={{ width: "8%" }}
                                    className={`text-right ${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <RenderDollar value={total_precio_venta} decimalScale={3} />
                                </td>
                                <td style={{ width: "9%" }}
                                    className={`text-right ${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <RenderDollar value={dato_actual && dato_actual.total_dolares ? dato_actual.total_dolares.toFixed(2) : 0} />
                                </td>
                                <td style={{ width: "8%" }}
                                    className={`text-right ${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <RenderCambio value={
                                        (dato_actual.cambio_dia)
                                    } />
                                </td>
                                <td style={{ width: "10%" }}
                                    className={`text-right ${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <RenderCurrency value={dato_actual && dato_actual.total_quetzales ? dato_actual.total_quetzales.toFixed(2) : 0} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
};

export const renderInsumos = ({fields}) => {
    return (
        <div className="col-12 text-center">
            <div className="react-bs-table-container">
                <div className="react-bs-table">
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <th style={{ width: "30%" }}>FECHA</th>
                                    <th style={{ width: "40%" }}>NO. FACTURA</th>
                                    <th style={{ width: "30%" }}>MONTO</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <table className="table table-striped table-bordered table-hover">
                <tbody>
                    {fields.map((pago, index) => {
                        const dato_actual = fields.get(index); // Pago actual
                        let todos = fields.getAll();
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        return (
                            <tr key={index}>
                                <td style={{ width: "30%" }}>
                                    <span>{dato_actual ? moment(dato_actual.fecha_aplicacion).format("DD/MM/YYYY") : "" } </span>
                                </td>
                                <td style={{ width: "40%" }}>
                                    <span>{dato_actual ? dato_actual.no_factura : "" } </span>
                                </td>
                                <td style={{ width: "30%" }}>
                                    <RenderCurrency value={dato_actual ? dato_actual.importe : 0} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
};

let LiquidacionesForm = props => {
    const { handleSubmit, item, regresar, me } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="d-flex  justify-content-center mb-4">
                    <div className="col-8">
                        <div className="row col-12">
                                <h6 className="mb-4">DATOS GENERALES DE LA LIQUIDACIÓN</h6>
                        </div>
                        <div className="row col-12 col-md-12 p-0">
                            <div className="col-md-6 p-0">
                                <div className="col-12 d-flex flex-column">
                                    <label className="p-0 m-0">ID LIQUIDACIÓN</label>
                                    <span className="mb-1">{item ? item.id : " "}</span>
                                    <label className="p-0 m-0">FECHA DE LIQUIDACIÓN</label>
                                    <span className="mb-1">{item ? moment(item.fecha_liquidacion).format("DD/MM/YYYY") : ""}</span>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-12 d-flex flex-column">
                                    <label className="p-0 m-0">FINCA</label>
                                    <span className="mb-1">{item ? (item.finca ? item.finca.nombre : " ") : " "}</span>
                                    <label className="p-0 m-0">RÉGIMEN</label>
                                    <span className="mb-1">{item ? (item.finca ? item.finca.regimen_fiscal_nombre : " ") : " "}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <FieldArray name="detalles" component={renderDatosCompras} {...props} />
                    </div>
                </div>

                {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                                PARTE DE TOTALES
                >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div className="row">
                    <div className="col-12">
                        <CardEmpty noShadow noPadding>
                            <div className="row col-12 m-0 py-3">
                                <div className="row col-1"></div>
                                <div className="row col-10 p-0">
                                    <h6 className="">TOTALES</h6>
                                </div>
                            </div>
                            <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mb-4">
                                <div className="row col-12 col-md-10 d-flex flex-wrap justify-content-center align-items-end">
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="retencion_peq_contribuyente" className="m-0">RETENCIÓN PEQ. CONTRIBUYENTE</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="retencion_peq_contribuyente" component={renderCurrency} disabled type="text" className="form-control" decimalScale={2} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="retencion_iva" className="m-0">RETENCIÓN IVA</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="retencion_iva" component={renderCurrency} disabled type="text" className="form-control" decimalScale={2} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="retencion_isr" className="m-0">RETENCIÓN ISR</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="retencion_isr" component={renderCurrency} disabled type="text" className="form-control" decimalScale={2} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                                <div className="row col-12 col-md-8 d-flex flex-wrap justify-content-center align-items-end">
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="kilos_humedos" className="m-0">KILOS HUMEDOS</label>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                        <h6 className="mb-1 ml-3 gris"><b>{item.kilos_humedos + " kilos"}</b></h6>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="kilos_secos" className="m-0">KILOS SECOS</label>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <h6 className="mb-1 ml-3 gris"><b>{item.kilos_secos + " kilos"}</b></h6>
                                            </div>
                                        </div>
                                </div>
                            </div>

                            <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                                <div className="col-12 col-md-8 d-flex flex-wrap justify-content-center align-items-end">
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="total_dolares" className="m-0">TOTAL DOLARES</label>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <h6 className="mb-1 ml-3 gris"><b>{<RenderDollar value={item.total_dolares}/>}</b></h6>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="total_quetzales" className="m-0">TOTAL QUETZALES</label>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <h6 className="mb-1 ml-3 gris"><b>{<RenderCurrency value={item.total_quetzales}/>}</b></h6>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="total_liquidar" className="m-0">TOTAL A LIQUIDAR</label>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <h6 className="mb-1 ml-3 gris"><b>{<RenderCurrency value={item.total_liquidar} />}</b></h6>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row mb-3">
                    {item && item.finca && item.finca.regimen_fiscal !== FACTURA_ESPECIAL && item.finca.regimen_fiscal !== EXPORTADOR &&(
                        <div className="col-12 col-lg-8 mt-4">
                            <CardEmpty noShadow >
                                <div className="col-12 text-center pr-0">
                                    <h6 className="">VENTAS DE SAE</h6>
                                </div>
                                <div className="col-12 pr-0">
                                    <FieldArray name="insumos_liquidados" component={renderInsumos} {...props} />
                                </div>
                                <div className="col-12 text-right pr-0">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="total_insumos" className="m-0">TOTAL DE INSUMOS</label>
                                    </div>
                                    <div className="col-md-12 mt-2 mb-3">
                                        <h6 className="mb-1 ml-3 gris"><b>{<RenderCurrency value={item ? (item.total_insumos ? item.total_insumos : 0) : 0} />}</b></h6>
                                    </div>
                                </div>
                            </CardEmpty>
                        </div>
                    )}
                    {item && item.finca && (item.finca.regimen_fiscal === FACTURA_ESPECIAL || item.finca.regimen_fiscal === EXPORTADOR || item.finca.regimen_fiscal === PRODUCCION_INTERNA) &&(
                        <div className="col-12 col-lg-12 mt-4">
                            <CardEmpty noShadow >
                                <div className="col-12 text-center pr-0">
                                    <h6 className="">VENTAS DE SAE</h6>
                                </div>
                                <div className="col-12 pr-0">
                                    <FieldArray name="insumos_liquidados" component={renderInsumos} {...props} />
                                </div>
                                <div className="col-12 text-right pr-0">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="total_insumos" className="m-0">TOTAL DE INSUMOS</label>
                                    </div>
                                    <div className="col-md-12 mt-2 mb-3">
                                        <h6 className="mb-1 ml-3 gris"><b>{<RenderCurrency value={item ? (item.total_insumos ? item.total_insumos : 0) : 0} />}</b></h6>
                                    </div>
                                </div>
                            </CardEmpty>
                        </div>
                    )}
                    {!me.proveedor && item && item.finca && item.finca.regimen_fiscal !== FACTURA_ESPECIAL && item.finca.regimen_fiscal !== EXPORTADOR && item.finca.regimen_fiscal !== PRODUCCION_INTERNA &&(
                        <div className="col-12 col-lg-4 mt-4">
                            <CardEmpty noShadow>
                                <div className="col-12 d-flex justify-content-center mb-2 mt-2 m-0">
                                    <div className="col-12 col-md-12">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="fecha_factura" className="m-0">FECHA DE FACTURA</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field
                                                name="fecha_factura"
                                                component={renderDatePicker}
                                                todayButton="Hoy"
                                                disabled={item.posee_factura === true ||
                                                    (item ? (item.finca ? item.finca.regimen_fiscal === FACTURA_ESPECIAL || item.finca.regimen_fiscal === EXPORTADOR: "") : "") ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center mb-2 mt-2 m-0">
                                    <div className="col-12 col-md-12">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="serie" className="m-0">SERIE DE FACTURA</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="serie" component={renderField} type="text" className="form-control"
                                                disabled={item.posee_factura === true ||
                                                    (item ? (item.finca ? item.finca.regimen_fiscal === FACTURA_ESPECIAL || item.finca.regimen_fiscal === EXPORTADOR: "") : "") ? true : false}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center mb-2 mt-2 m-0">
                                    <div className="col-12 col-md-12">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="numero_factura" className="m-0">NO. FACTURA</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="numero_factura" component={renderField} type="text" className="form-control"
                                                disabled={item.posee_factura === true ||
                                                    (item ? (item.finca ? item.finca.regimen_fiscal === FACTURA_ESPECIAL || item.finca.regimen_fiscal === EXPORTADOR: "") : "") ? true : false}/>
                                        </div>
                                    </div>
                                </div>

                            </CardEmpty>
                        </div>
                    )}
                </div>
                <div className="buttons-box mt-5">
                    <button type="button" className="btn btn-outline-dark mr-5 px-5" onClick={(e) => {
                        e.preventDefault();
                        regresar();
                    }}>REGRESAR</button>
                    {!me.proveedor && (item.posee_factura === false && item.finca && item.finca.regimen_fiscal !== FACTURA_ESPECIAL && item.finca.regimen_fiscal !== EXPORTADOR && item.finca.regimen_fiscal !== PRODUCCION_INTERNA) && (
                        <button type="submit" className="btn btnprimary mr-5">
                            GUARDAR
                        </button>
                    )}
                    <Link className="btn btn-outline-info mr-5" to={`/liquidacion/${item.id}/boleta_liquidacion`}>
                        RE-IMPRIMIR
                    </Link>
                </div>
            </div>
        </form>
    )
};

LiquidacionesForm = reduxForm({
    form: 'LiquidacionesForm'
})(LiquidacionesForm);

export default LiquidacionesForm
