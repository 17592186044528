import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/clientes';
import ClientesList from './ClientesList';

const ms2p = state => {
    return {
        ...state.clientes,
    }
}

const md2p = { ...actions };

export default connect(ms2p,md2p)(ClientesList);