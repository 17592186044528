import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/roles/roles';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import RolesCrear from './RolesCrear';

const mstp = state => {
    return {...state.roles}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(RolesCrear)
