import { handleActions } from "redux-actions";
import { api } from "api";

const OBTENER_DATA = "OBTENER_DATA_LABORATORIO_ESTADISTICAS";
const LOADER = "LOADER_LABORATORIO_ESTADISTICAS";

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});


const setData = (data) => ({
    type: OBTENER_DATA,
    data,
});


const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [OBTENER_DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

const initialState = {
    loader: false,
    data: {},
};

export const obtener_data = () => (dispatch, getStore) => {
    const id_laboratorio = window.location.href.split("/")[6];
    api.get("laboratorio/obtener_estadisticas_drc",  {id_laboratorio})
        .then((response) => {
            dispatch(setData(response));
        })
        .catch((error) => {
            console.log("error", error);
        })
};

export const actions = {
    obtener_data,
};

export default handleActions(reducers, initialState);
