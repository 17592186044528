import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/bodega';
import CrearBodega from './CrearBodega';


const ms2p = (state) => {
  return {
    ...state.bodegas,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearBodega);
