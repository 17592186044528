import React from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import { RenderKilogramo, RenderCurrency, RenderDollar } from '../../../Utils/renderField/renderReadField';
import { PrintContainer } from 'Utils/Print';

const Resumen = (props) => {
    const {
        reporte_mp_mensual: reporte, loader,
    } = props;
    // Procesamiento de la data y adaptación al formato requerido por los componentes
    const plantillaTotales = {
        kilos_secos: 0,
        kilos_humedos: 0,
        total_quetzales: 0,
        total_dolares: 0,
    };
    let dataReporte = reporte && reporte.data ? reporte.data : [];
    dataReporte = dataReporte.map(dataPerfilFinca => {
        dataPerfilFinca.totales = {...plantillaTotales};
        dataPerfilFinca.materias.map((materia, index) => {
            Object.keys(dataPerfilFinca.totales).forEach(property => {
                dataPerfilFinca.totales[property]+= materia.totales[property] !== undefined && materia.totales[property] !== null ? materia.totales[property] : 0;
            });
            materia.perfil_nombre = index === 0 ? dataPerfilFinca.nombre : '';
        });
        return dataPerfilFinca;
    });
    // Cálculo de los totales generales
    let TOTALES_GENERAL = {...plantillaTotales};
    dataReporte.forEach(dataPerfilFinca => {
        Object.keys(TOTALES_GENERAL).forEach(property => {
            TOTALES_GENERAL[property]+= dataPerfilFinca.totales[property];
        });
    });

    return (
        <LoadMask loading={loader} blur>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <PrintContainer name='reporte-materia-prima-mensual'>
                        <div className="no-mostrar text-center">
                            <h2 className="azul">REPORTE DE MATERIA PRIMA MENSUAL</h2>
                        </div>
                        <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla impresion-horizontal">
                            { dataReporte.map((dataPerfil, index) => (
                                <div className=" col-12 form-group np-r p-0 mb-5 inicio-tabla">
                                    <div className="react-bs-table-container">
                                        <div className="react-bs-table">
                                            <div className="react-bs-container-header table-header-wrapper">
                                                <table className="table table-hover table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "10%" }}>PERFIL</th>
                                                            <th style={{ width: "18%" }}>PRODUCTO</th>
                                                            <th style={{ width: "12%" }} className="text-right">KG. HÚMEDOS</th>
                                                            <th style={{ width: "12%" }} className="text-right">KG. SECOS</th>
                                                            <th style={{ width: "12%" }} className="text-right">TOTAL $.</th>
                                                            <th style={{ width: "12%" }} className="text-right">TOTAL Q.</th>
                                                            <th style={{ width: "12%" }} className="text-right">PRECIO UNITARIO SÓLIDO</th>
                                                            <th style={{ width: "12%" }} className="text-right">PRECIO UNITARIO LÁTEX</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table table-striped table-bordered table-hover mb-0">
                                        <tbody>
                                            { dataPerfil.materias.map((materia, index) => (
                                                <tr>
                                                    <td style={{ width: "10%" }}>{materia.perfil_nombre}</td>
                                                    <td style={{ width: "18%" }}>{materia.nombre}</td>
                                                    <td style={{ width: "12%" }} className="text-right"><RenderKilogramo value={materia.totales.kilos_humedos} /></td>
                                                    <td style={{ width: "12%" }} className="text-right"><RenderKilogramo value={materia.totales.kilos_secos} /></td>
                                                    <td style={{ width: "12%" }} className="text-right"><RenderDollar value={materia.totales.total_dolares} /></td>
                                                    <td style={{ width: "12%" }} className="text-right"><RenderCurrency value={materia.totales.total_quetzales} /></td>
                                                    <td style={{ width: "12%" }} className="text-right">{ index === (dataPerfil.materias.length - 1) && (<RenderDollar value={dataPerfil.precios_unitario['SOLIDO'].precio_unitario_dolares} />) }</td>
                                                    <td style={{ width: "12%" }} className="text-right">{ index === (dataPerfil.materias.length - 1) && (<RenderDollar value={dataPerfil.precios_unitario['LATEX'].precio_unitario_dolares} />) }</td>
                                                </tr>
                                            )) }
                                        </tbody>
                                    </table>
                                    <div className={`react-bs-table-container ${index !== (dataReporte.length - 1) ? 'total-final' : 'total'}`}>
                                        <div className="react-bs-table">
                                            <div className="react-bs-container-header table-header-wrapper">
                                                <table className="table table-hover table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "28%" }} className="text-left">TOTAL {dataPerfil && dataPerfil.nombre ? dataPerfil.nombre.toUpperCase() : ''}</th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderKilogramo value={dataPerfil.totales['kilos_humedos']}/></th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderKilogramo value={dataPerfil.totales['kilos_secos']}/></th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderDollar value={dataPerfil.totales['total_dolares']}/></th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataPerfil.totales['total_quetzales']}/></th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataPerfil.precios_unitario['SOLIDO'].precio_unitario_quetzales}/></th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataPerfil.precios_unitario['LATEX'].precio_unitario_quetzales}/></th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    { index === (dataReporte.length - 1) && (
                                        <div className="react-bs-table-container total-final">
                                            <div className="react-bs-table">
                                                <div className="react-bs-container-header table-header-wrapper">
                                                    <table className="table table-hover table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th style={{ width: "28%" }} className="text-left">TOTAL GENERAL</th>
                                                                <th style={{ width: "12%" }} className="text-right"><RenderKilogramo value={TOTALES_GENERAL['kilos_humedos']}/></th>
                                                                <th style={{ width: "12%" }} className="text-right"><RenderKilogramo value={TOTALES_GENERAL['kilos_secos']}/></th>
                                                                <th style={{ width: "12%" }} className="text-right"><RenderDollar value={TOTALES_GENERAL['total_dolares']}/></th>
                                                                <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={TOTALES_GENERAL['total_quetzales']}/></th>
                                                                <th style={{ width: "12%" }} className="text-right"></th>
                                                                <th style={{ width: "12%" }} className="text-right"></th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) }
                                </div>
                            )) }
                        </div>
                    </PrintContainer>
                </CardEmpty>
            </div>
        </LoadMask>
    )
};

export default Resumen;
