import { handleActions } from "redux-actions";
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { addLeadingSlash } from "history/PathUtils";
import Swal from "sweetalert2";
import moment from "moment";

const SET_LISTA_RECEPCIONES = 'SET_LISTA_RECEPCIONES';
const SET_RECEPCION = 'SET_RECEPCION';
const SET_LOADER = 'SET_LOADER';

const setListaRecepciones = recepciones => ({
    type: SET_LISTA_RECEPCIONES,
    recepciones
})

const setRecepcion = data => ({
    type: SET_RECEPCION,
    data
})

const setLoader = loader => ({
    type: SET_LOADER,
    loader
})

const listarRecepciones = () => dispatch => {
    let dataRecepciones = []

    dispatch(setLoader(true));
    api.get('recepcion/listar_recepciones_completas')
        .then(response => {
            for(let entry of response) {
                dataRecepciones.push({value: entry.id, label: entry.id})
            }
            dispatch(setListaRecepciones(dataRecepciones));
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        })
}

const getRecepcion = id => dispatch => {
    let recepcion = {}
    api.get(`recepcion/${id}`)
        .then(response => {
            recepcion = {
                finca: response.finca.nombre,
                piloto: response.piloto.nombre,
                vehiculo: response.vehiculo.nombre,
                materia_prima: response.tipo_materia.nombre,
                peso: response.peso_ingreso,
                recepcion: response.acopio ? response.acopio.nombre : 'Planta ERSA',
                estado_ingreso: response.estado_ingreso,
                observaciones: response.observaciones ? response.observaciones : '',
                tipo_materia: response.tipo_materia.tipo,
                fecha_entrada: response.fecha_entrada,
                productos: []
            }
            for(let producto of response.productos) {
                let entrada = {
                    materia_prima: producto.materia_prima_nombre,
                    peso: producto.peso_finca,
                    peso_kg: producto.peso_finca_kg,
                    peso_bruto: producto.peso_bruto ? producto.peso_bruto : 0,
                }

                if(producto.vaciados) {
                    entrada.vaciados = producto.vaciados
                }

                if(producto.vaciados && producto.vaciados.length > 0) {
                    let destinos = '';
                    producto.vaciados.forEach(movimiento => {
                        destinos.length > 0 && (destinos += ', ');
                        destinos += movimiento.bodega.nombre;
                    });
                    entrada.pilas_bodegas = destinos;
                }
                else {
                    entrada.pilas_bodegas = entrada.bodega ? entrada.bodega.nombre : '';
                }

                recepcion.productos.push(entrada);
            }
            dispatch(setRecepcion(recepcion))
        })
        .catch(error => {
            console.log(error);
        })
}

const anularRecepcion = (id, data = null, justificacion = '') => dispatch => {
    // Se llama a la API para anular la recepción
    // * Tomar en cuenta que las recepciones pueden tener producto ya ingresado a 
    // * pilas, bodegas y/o almacenes

    let requestData = {
        productos: data.productos,
        justificacion: justificacion
    };
    let params = {quitar_inventario: false}
    
    if(moment(data.fecha_entrada).format('D/MM/YYYY') == moment().format('D/MM/YYYY')) {
        params.quitar_inventario = true
    }

    dispatch(setLoader(true));
    api.put(`recepcion/${id}/anular_recepcion/`, requestData, params)
        .then(response => {
            Swal.fire('Recepción anulada', '', 'info')
            dispatch(push('/ingresos'));
        })
        .catch(error => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, 'ERROR', 3000)
            }
            else {
                NotificationManager.error('No se pudo anular la recepción', 'ERROR', 3000)
            }
            console.log(error);
        })
        .finally(() => {dispatch(setLoader(false))})
}

export const actions = {
    listarRecepciones,
    getRecepcion,
    anularRecepcion
};

export const reducers = {
    [SET_LISTA_RECEPCIONES]: (state, { recepciones }) => {
        return {
            ...state,
            recepciones
        }
    },
    [SET_RECEPCION]: (state, { data }) => {
        return {
            ...state,
            data
        }
    },
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader
        }
    }
};

export const initialState = {
    loader: false,
    recepciones: [],
    data: {}
};

export default handleActions(reducers, initialState);