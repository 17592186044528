import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import LiquidacionesForm from './LiquidacionesForm';

export default class LiquidacionesCrear extends Component {
    state = {
        enableOne: false
    };
    componentWillMount(){
        this.setState({ 
        title: 'CARGANDO...', 
        message: 'Obteniendo la información', });
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            // this.setState({id_compra: this.props.match.params.id})
        }
    }
    crearLiquidacion = (data) => {
        this.setState({ title: 'ESPERE...', message: 'Se están completando las operaciones', });
        if (!this.state.enableOne){
            this.setState({enableOne:true})
            if (this.props.crear(data)){
                this.setState({enableOne:false})
            }
        }
    };

    render() {
        const { updateData, loader, configuraciones, datos_drc, crear, fecha_final_liq, fecha_inicio_liq} = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="liquidaciones_activo"
                    titulo="LIQUIDACIONES"
                    subtitulo="Compras"
                    col="12"
                >
                    <LoadMask loading={loader} blur
                        title={this.state.title} message={this.state.message}
                    >
                        <LiquidacionesForm
                            onSubmit={this.crearLiquidacion}
                            updateData={updateData}
                            {...this.props}
                            {...this.state}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
