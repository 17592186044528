import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import LaboratorioForm from './LaboratorioForm';
import AnularModal from "../../Ingresos/Pesar/Anular/AnularModal";

export default class LaboratorioCrear extends Component {
    state= {
        open: false,
        open_drc: false,
        open_tsc: false,
        open_vfa: false,
        open_nh3: false,
        ver_info: false
    };
    componentWillMount(){
        // this.props.initialLoad();
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
        }
    }
    actualizar = (data) =>{
        // Se recupera el registro para no crear un duplicado al volver a llamar a 'ediar()'
        this.props.editar(this.props.match.params.id, data).then(() => this.props.leer(this.props.match.params.id));
    };
    actualizarYEnviarVaciado = (data) => {
        this.props.editar(this.props.match.params.id, data).then(()=>this.props.enviarVaciado(this.props.item.id));
    };
    actualizarYEnviarCoagulo = (data) => {
        this.props.editar(this.props.match.params.id, data).then(() => this.props.enviarACoagulo(this.props.item));
    }

    actualizarYEnviarValidacion = (data) => {
        this.props.editar(this.props.match.params.id, data).then(()=>this.props.enviar_a_validacion(this.props.item.id));
    };

    openModal = (tipo) => {
        if (tipo === "anular_recepcion"){
            this.setState({open:  true})
        }
        if (tipo === "drc"){
            this.setState({open_drc:  true})
        }
        if(tipo === "tsc"){
            this.setState({open_tsc: true})
        }
        if(tipo === "vfa"){
            this.setState({open_vfa: true})
        }
        if(tipo === "nh3"){
            this.setState({open_nh3: true})
        }
    };

    closeModal = (tipo) => {
        this.setState({open:  false});
        this.setState({open_drc:  false});
        this.setState({open_tsc: false});
        this.setState({open_vfa: false});
        this.setState({open_nh3: false});
    };
    toogleInfo = () => {
        this.setState({ver_info: !this.state.ver_info})
    };

    anularRecepcion = (data) => {
        const id_anulacion = this.props.item.ingreso ? this.props.item.ingreso.id : this.props.item.id;
        this.props.anularIngreso(id_anulacion, data);
        this.closeModal()
    };

    render() {
        const { updateData, loader, configuraciones } = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="laboratorio_activo"
                    titulo="LABORATORIO"
                    subtitulo="Materia prima"
                    col="12"
                >
                    <LoadMask loading={loader} blur>
                        <AnularModal open={this.state.open} closeModal={this.closeModal} item={this.props.item} anularRecepcion={this.anularRecepcion}/>
                        <LaboratorioForm
                            onSubmit={this.actualizar}
                            updateData={updateData}
                            editando={true}
                            {...this.props}
                            {...this.state}
                            anularRecepcion={this.anularRecepcion}
                            openModal={this.openModal}
                            closeModal={this.closeModal}
                            toogleInfo={this.toogleInfo}
                            user={this.props.user}
                            actualizarYEnviarVaciado={this.actualizarYEnviarVaciado}
                            enviarRecepcionACoagulo={this.actualizarYEnviarCoagulo}
                            actualizarYEnviarValidacion={this.actualizarYEnviarValidacion}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
