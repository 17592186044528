import React, { Fragment } from 'react';
import moment from 'moment';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderKilogramo } from '../../../Utils/renderField/renderReadField';
import { PrintContainer } from 'Utils/Print';

const ReporteResumen = (props) => {
    const {
        reporte_pila_almacen: reporte,
    } = props;
    let data = reporte.resumen_data && reporte.resumen_data.results ? reporte.resumen_data.results : [];
    // Procesamiento de la data para la construcción del Reporte Resumen
    const plantillaTotales = {
        kg_secos_recepciones: 0,
        kg_secos_pila: 0,
        diferencia: 0,
    };
    let dataReporte = {};
    data.forEach(pila => {
        let info_pila = dataReporte[pila.id];
        if (info_pila === undefined) {
            info_pila = {
                id: pila.id,
                nombre: pila.nombre,
                lista_analisis: [],
                totales: {...plantillaTotales},
            };
        }
        let info_analisis = {
            fecha: pila.analisis_laboratorio.modificado,
            kg_secos_recepciones: pila.kg_secos_recepciones,
            kg_secos_pila: pila.kg_secos_pila,
            diferencia: pila.kg_secos_recepciones - pila.kg_secos_pila,
        };
        Object.keys(info_pila.totales).forEach(property => {
            info_pila.totales[property]+= info_analisis[property] !== undefined && info_analisis[property] !== null ? info_analisis[property] : 0;
        });
        info_pila.lista_analisis.push(info_analisis);
        dataReporte[pila.id] = info_pila;
    });
    // Sumatoria de totales
    let TOTALES_GENERAL = {...plantillaTotales};
    Object.keys(dataReporte).forEach(dataPila => {
        Object.keys(TOTALES_GENERAL).forEach(property => {
            TOTALES_GENERAL[property]+= dataReporte[dataPila].totales[property];
        });
    });

    return (
        <LoadMask loading={reporte.loader} blur>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <PrintContainer name='reporte-resumen-pilas'>
                        <div className="no-mostrar text-center">
                            <h2 className="azul">REPORTE DE PILAS (RESUMEN)</h2>
                        </div>
                        <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla impresion-horizontal px-5">
                            <div className="col-12 form-group np-r p-0 mb-5 inicio-tabla">
                                {/* ENCABEZADO */}
                                <div className="react-bs-table-container">
                                    <div className="react-bs-table">
                                        <div className="react-bs-container-header table-header-wrapper">
                                            <table className="table table-hover table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "25%" }}>PILA</th>
                                                        <th style={{ width: "15%" }}>FECHA</th>
                                                        <th style={{ width: "20%" }} className="text-right">KG. SECOS</th>
                                                        <th style={{ width: "20%" }} className="text-right">KG. SECOS PILA</th>
                                                        <th style={{ width: "20%" }} className="text-right">DIFERENCIA</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* CUERPO DE UNA PILA */}
                                { Object.keys(dataReporte).map(idPila => (
                                    <Fragment>
                                        <table className="table table-striped table-bordered table-hover mb-0">
                                            <tbody>
                                                { dataReporte[idPila].lista_analisis.map(analisisPila => (
                                                    <tr>
                                                        <td style={{ width: "25%" }}>{dataReporte[idPila].nombre}</td>
                                                        <td style={{ width: "15%" }}>{moment(analisisPila.fecha).format('DD-MM-YYYY')}</td>
                                                        <td style={{ width: "20%" }} className="text-right"><RenderKilogramo value={analisisPila.kg_secos_recepciones} /></td>
                                                        <td style={{ width: "20%" }} className="text-right"><RenderKilogramo value={analisisPila.kg_secos_pila} /></td>
                                                        <td style={{ width: "20%" }} className="text-right"><RenderKilogramo value={analisisPila.diferencia} /></td>
                                                    </tr>
                                                )) }
                                            </tbody>
                                        </table>
                                        <div className="react-bs-table-container total">
                                            <div className="react-bs-table">
                                                <div className="react-bs-container-header table-header-wrapper">
                                                    <table className="table table-bordered table-hover mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <th style={{ width: "25%" }} className="text-left">TOTAL {dataReporte[idPila].nombre ? dataReporte[idPila].nombre.toUpperCase() : ''}</th>
                                                                <th style={{ width: "15%" }} className="text-right"></th>
                                                                <th style={{ width: "20%" }} className="text-right"><RenderKilogramo value={dataReporte[idPila].totales['kg_secos_recepciones']}/></th>
                                                                <th style={{ width: "20%" }} className="text-right"><RenderKilogramo value={dataReporte[idPila].totales['kg_secos_pila']}/></th>
                                                                <th style={{ width: "20%" }} className="text-right"><RenderKilogramo value={dataReporte[idPila].totales['diferencia']}/></th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )) }
                                {/* TOTALES */}
                                <div className="react-bs-table-container total-final">
                                    <div className="react-bs-table">
                                        <div className="react-bs-container-header table-header-wrapper">
                                            <table className="table table-hover table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "25%" }} className="text-left">TOTAL GENERAL</th>
                                                        <th style={{ width: "15%" }} className="text-right"></th>
                                                        <th style={{ width: "20%" }} className="text-right"><RenderKilogramo value={TOTALES_GENERAL['kg_secos_recepciones']}/></th>
                                                        <th style={{ width: "20%" }} className="text-right"><RenderKilogramo value={TOTALES_GENERAL['kg_secos_pila']}/></th>
                                                        <th style={{ width: "20%" }} className="text-right"><RenderKilogramo value={TOTALES_GENERAL['diferencia']}/></th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PrintContainer>
                </CardEmpty>
            </div>
        </LoadMask>
    )
};

export default ReporteResumen;
