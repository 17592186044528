import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tipos_materia_prima';
import TiposMateriaPrima from './TiposMateriaPrima';


const ms2p = state => ({
    ...state.tipos_materia_prima,
});

const md2p = { ...actions };

export default connect(ms2p, md2p)(TiposMateriaPrima);
