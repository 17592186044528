import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import {renderField, renderFieldRadio, renderSwitch, renderCurrency, renderNumber} from '../../Utils/renderField/renderField';
import moment from 'moment';
import CardEmpty from '../../Utils/Cards/CardEmpty';

const validarData = (data = {}) => {
    if (data) {
        if (data.obtenido_desde_internet === false) {
            return false;
        }
        const fechaHoy = moment(moment().format("YYYY-MM-DD")+' 00:00:00');
        const fechaCreate = moment(moment(data.creado).format("YYYY-MM-DD")+' 00:00:00');
        const fechaUpdate = moment(moment(data.modificado).format("YYYY-MM-DD")+' 00:00:00');
        let difCreate = fechaHoy.diff(fechaCreate, 'days');
        let difUpdate = fechaHoy.diff(fechaUpdate, 'days');
        if (difCreate > 1) {
            return false;
        }
        return difUpdate === 0;
    }
    return false;
}

let ConfiguracionForm = props => {
    const { handleSubmit, configuraciones, precios_ingreso_manual } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row col-md-12 p-0">
                    <div className="col-12 d-flex justify-content-between">
                        <h6 className="mb-3 ml-3">ESTADO DE PESO MANUAL PLANTA ERSA Y ACOPIO</h6>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="username" className="m-0">INGRESO DE PESO MANUAL PLANTA ERSA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <div className="d-flex justify-content-around">
                                <Field
                                    label="NO"
                                    type="radio"
                                    value={false}
                                    name="peso_ingreso_manual"
                                    parse={val => val === 'true'}
                                    component={renderFieldRadio}
                                />
                                <Field
                                    label="SÍ"
                                    type="radio"
                                    value={true}
                                    name="peso_ingreso_manual"
                                    parse={val => val === 'true'}
                                    component={renderFieldRadio}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="comentario_peso_manual" className="m-0">COMENTARIO DE PESO MANUAL PLANTA ERSA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="comentario_peso_manual" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-12 mt-3"></div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="username" className="m-0">INGRESO DE PESO MANUAL ACOPIO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <div className="d-flex justify-content-around">
                                <Field
                                    label="NO"
                                    type="radio"
                                    value={false}
                                    name="peso_ingreso_manual_acopio"
                                    parse={val => val === 'true'}
                                    component={renderFieldRadio}
                                />
                                <Field
                                    label="SÍ"
                                    type="radio"
                                    value={true}
                                    name="peso_ingreso_manual_acopio"
                                    parse={val => val === 'true'}
                                    component={renderFieldRadio}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="comentario_peso_manual_acopio" className="m-0">COMENTARIO DE PESO MANUAL ACOPIO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="comentario_peso_manual_acopio" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                    <Fragment>
                        <div className="row col-12 mt-5">
                            <div className="col-12 d-flex justify-content-between align-configuracioness-baseline">
                                <h6 className="mb-3">FECHA ESTIMADA DE PAGO</h6>
                            </div>
                        </div>
                        <div className="row  col-md-12 p-0">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="puesto" className="m-0">DIAS PARA FECHA ESTIMADA DE PAGO DE BOLETA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <div className="ml-4">
                                        <Field name="fecha_estimada_pago"
                                            component={renderNumber}
                                            type="text" className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
            
                <div className="row col-md-12 p-0 mt-3">
                    <div className="col-md-12 p-0">
                        <CardEmpty noShadow>
                        <div className="col-md-12 col-sm-12 d-flex justify-content-center align-items-center mb-3">
                            <label htmlFor="username" className="m-0 mr-3">PRECIOS Y TIPO DE CAMBIO MANUAL</label>
                            <Field name="precios_ingreso_manual" component={renderSwitch} type="text" className="form-control" />
                        </div>
                        </CardEmpty>
                    </div>
                </div>
                {configuraciones.precios_solido &&(
                    <Fragment>
                        <div className="row col-12 mt-5">
                            <div className="col-12 d-flex justify-content-between align-configuracioness-baseline">
                                <h6 className="mb-3">PRECIOS DE INTROSA PARA CHIPA</h6>
                            </div>
                        </div>
                        <div className="row  col-md-12 p-0">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="puesto" className="m-0">PRECIO POR KILOGRAMO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <div className="ml-4">
                                        <Field name="precios_solido.precio_kilogramo"
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                            type="text" className="form-control"
                                            disabled={!precios_ingreso_manual}
                                        />
                                        <small
                                            className={validarData(configuraciones.precios_solido) ? 'gris' : 'rojo'}
                                        >Última actualización: {moment(configuraciones.precios_solido.modificado).format('DD/MM/YYYY HH:mm:ss')}</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="email" className="m-0">PRECIO POR LIBRA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <div className="ml-4">
                                        <Field name="precios_solido.precio_libra"
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                            type="text" className="form-control"
                                            disabled={!precios_ingreso_manual}
                                        />
                                        <small
                                            className={validarData(configuraciones.precios_solido) ? 'gris' : 'rojo'}
                                        >Última actualización: {moment(configuraciones.precios_solido.modificado).format('DD/MM/YYYY HH:mm:ss')}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
                {configuraciones.precios_latex &&(
                    <Fragment>
                        <div className="row col-12 mt-3">
                            <div className="col-12 d-flex justify-content-between align-items-baseline">
                                <h6 className="mb-3">PRECIOS DE INTROSA PARA LÁTEX</h6>
                            </div>
                        </div>
                        <div className="row  col-md-12 p-0">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="puesto" className="m-0">PRECIO POR KILOGRAMO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <div className="ml-4">
                                        <Field name="precios_latex.precio_kilogramo"
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                            type="text" className="form-control"
                                            disabled={!precios_ingreso_manual}
                                        />
                                        <small
                                            className={validarData(configuraciones.precios_latex) ? 'gris' : 'rojo'}
                                        >Última actualización: {moment(configuraciones.precios_latex.modificado).format('DD/MM/YYYY HH:mm:ss')}</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="email" className="m-0">PRECIO POR LIBRA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <div className="ml-4">
                                        <Field name="precios_latex.precio_libra"
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                            type="text" className="form-control"
                                            disabled={!precios_ingreso_manual}
                                        />
                                        <small
                                            className={validarData(configuraciones.precios_latex) ? 'gris' : 'rojo'}
                                        >Última actualización: {moment(configuraciones.precios_latex.modificado).format('DD/MM/YYYY HH:mm:ss')}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
                {configuraciones.cambio_dolar &&(
                    <Fragment>
                        <div className="row col-12 mt-3">
                            <div className="col-12 d-flex justify-content-between align-items-baseline">
                                <h6 className="mb-3">TIPO DE CAMBIO</h6>
                            </div>
                        </div>
                        <div className="row  col-md-12 p-0 mb-3">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="puesto" className="m-0">DÓLAR</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <div className="ml-4">
                                        <Field name="cambio_dolar.cambio_dolar"
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                            type="text" className="form-control"
                                            disabled={!precios_ingreso_manual}
                                        />
                                        <small
                                            className={validarData(configuraciones.cambio_dolar) ? 'gris' : 'rojo'}
                                        >Última actualización: {moment(configuraciones.cambio_dolar.modificado).format('DD/MM/YYYY HH:mm:ss')}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
                <CardEmpty noShadow>
                    <div className="row col-12">
                        <div className="col-12 d-flex justify-content-between align-configuracioness-baseline">
                            <h6 className="mb-3">PRECIOS DE MATERIA PRIMA PARA PROVEEDORES</h6>
                        </div>
                    </div>
                    <div className="d-flex flex-column pb-4">
                        <div className="w-100 px-3">
                            <label htmlFor="mensaje_proveedores" className="m-0 p-0">
                                MENSAJE PARA PROVEEDORES
                            </label>
                        </div>
                        <div className="w-100 px-4">
                        <Field name="mensaje_proveedores" component={renderField} className='form-control'/>
                        </div>
                    </div>
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="latex" className="m-0">PRECIO DE LATEX</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <div className="ml-4">
                                    <Field name="latex"
                                        component={renderCurrency} prefix="$ " decimalScale={5}
                                        type="text" className="form-control"
                                    />
                                    <small
                                        className="gris"
                                    >Última actualización: {moment(configuraciones.modificado).format('DD/MM/YYYY HH:mm:ss')}</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="chipa_primera" className="m-0">PRECIO DE CHIPA DE PRIMERA</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <div className="ml-4">
                                    <Field name="chipa_primera"
                                        component={renderCurrency} prefix="$ " decimalScale={5}
                                        type="text" className="form-control"
                                    />
                                    <small
                                        className="gris"
                                    >Última actualización: {moment(configuraciones.modificado).format('DD/MM/YYYY HH:mm:ss')}</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="chipa_segunda" className="m-0">PRECIO DE CHIPA DE SEGUNDA</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <div className="ml-4">
                                    <Field name="chipa_segunda"
                                        component={renderCurrency} prefix="$ " decimalScale={5}
                                        type="text" className="form-control"
                                    />
                                    <small
                                        className="gris"
                                    >Última actualización: {moment(configuraciones.modificado).format('DD/MM/YYYY HH:mm:ss')}</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="coagulo" className="m-0">PRECIO DE COAGULO</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <div className="ml-4">
                                    <Field name="coagulo"
                                        component={renderCurrency} prefix="$ " decimalScale={5}
                                        type="text" className="form-control"
                                    />
                                    <small
                                        className="gris"
                                    >Última actualización: {moment(configuraciones.modificado).format('DD/MM/YYYY HH:mm:ss')}</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="hilacha" className="m-0">PRECIO DE HILACHA</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <div className="ml-4">
                                    <Field name="hilacha"
                                        component={renderCurrency} prefix="$ " decimalScale={5}
                                        type="text" className="form-control"
                                    />
                                    <small
                                        className="gris"
                                    >Última actualización: {moment(configuraciones.modificado).format('DD/MM/YYYY HH:mm:ss')}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardEmpty>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
ConfiguracionForm = reduxForm({
    form: 'ConfiguracionForm',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    }
})(ConfiguracionForm);

const selector = formValueSelector('ConfiguracionForm');
ConfiguracionForm = connect(state => {
    const form = state.form.ConfiguracionForm;
    let precios_ingreso_manual = false;
    if (form && form.values) {
        precios_ingreso_manual = form.values.precios_ingreso_manual;
    }
    return {
        precios_ingreso_manual,
    }
})(ConfiguracionForm);

export default ConfiguracionForm
