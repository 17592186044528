import { createAction, handleActions } from "redux-actions";
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import moment from "moment";
import Swal from "sweetalert2";

const LOADER = "LOADER_PROVEEDORES_ANUALES";
const LOADER_INIT = "LOADER_INIT_PROVEEDORES_ANUALES";
const DATA = "DATA_PROVEEDORES_ANUALES";
const PAGE = "PAGE_PROVEEDORES_ANUALES";
const ANIO = "ANIO_PROVEEDORES_ANUALES";
const PRODUCTOS = "PRODUCTOS_PROVEEDORES_ANUALES";
const FINCA = "FINCA_PROVEEDORES_ANUALES";
const SELECT_ANIO = "SELECT_ANIO_PROVEEDORES_ANUALES";
const SELECT_PRODUCTO = "SELECT_PRODUCTO_PROVEEDORES_ANUALES";
const SELECT_FINCA = "SELECT_FINCA_PROVEEDORES_ANUALES";
const PARAMSUSED = "PARAMSUSED_PROVEEDORES_ANUALES";
const LOAD_EXCEL = "LOAD_EXCEL_PROVEEDORES_ANUALES";
const LOAD_PDF = "LOAD_PDF_PROVEEDORES_ANUALES";
const DATA_FULL_TO_PDF = "DATA_FULL_TO_PDF_PROVEEDORES_ANUALES";

// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setLoaderReportExcel = (loader_excel) => ({
    type: LOAD_EXCEL,
    loader_excel,
});

const setLoaderReportPdf = (loader_pdf) => ({
    type: LOAD_PDF,
    loader_pdf,
});


const setLoaderInit = (loader_init) => ({
    type: LOADER_INIT,
    loader_init,
});

const setData = (data) => ({
    type: DATA,
    data,
});

const setDataFullToPdf = (data_full_to_pdf) => ({
    type: DATA_FULL_TO_PDF,
    data_full_to_pdf,
});

const setPage = (page) => ({
    type: PAGE,
    page,
});

const setAnio = (anio) => ({
    type: ANIO,
    anio,
});

const setProductos = (productos) => ({
    type: PRODUCTOS,
    productos,
});

const setFinca = (fincas) => ({
    type: FINCA,
    fincas,
});

const setSelectAnio = (select_anio) => ({
    type: SELECT_ANIO,
    select_anio,
});

const setSelectProducto = (select_producto) => ({
    type: SELECT_PRODUCTO,
    select_producto,
});

const setSelectFinca = (select_finca) => ({
    type: SELECT_FINCA,
    select_finca,
});
const setParamsUsed = (params) => ({
    type: PARAMSUSED,
    params,
});

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ANIO]: (state, { anio }) => {
        return {
            ...state,
            anio,
        };
    },
    [PRODUCTOS]: (state, { productos }) => {
        return {
            ...state,
            productos,
        };
    },
    [FINCA]: (state, { fincas }) => {
        return {
            ...state,
            fincas,
        };
    },
    [LOADER_INIT]: (state, { loader_init }) => {
        return {
            ...state,
            loader_init,
        };
    },
    [SELECT_ANIO]: (state, { select_anio }) => {
        return {
            ...state,
            select_anio,
        };
    },
    [SELECT_PRODUCTO]: (state, { select_producto }) => {
        return {
            ...state,
            select_producto,
        };
    },
    [SELECT_FINCA]: (state, { select_finca }) => {
        return {
            ...state,
            select_finca,
        };
    },
    [PARAMSUSED]: (state, { params }) => {
        return {
            ...state,
            params,
        };
    },
    [LOAD_EXCEL] : (state, { loader_excel }) => {
        return {
            ...state,
            loader_excel,
        };
    },
    [LOAD_PDF] : (state, { loader_pdf }) => {
        return {
            ...state,
            loader_pdf,
        };
    },
    [DATA_FULL_TO_PDF] : (state, { data_full_to_pdf }) => {
        return {
            ...state,
            data_full_to_pdf,
        };
    },
};

export const initialState = {
    loader_init: true,
    loader: false,
    page: 1,
    data: {
        count: 0,
        results: [],
    },
    select_anio: null,
    select_producto: null,
    select_finca: null,
    anio: [],
    productos: [],
    fincas: [],
    params: {
        anio: null,
        producto: null,
        finca: null,
    },
    loader_excel: false,
    loader_pdf: false,
    data_full_to_pdf: {
        results: [],
        count: 0,
    }
};

export default handleActions(reducers, initialState);

// ------------------------------------
// Actions
// ------------------------------------
const obtenerDataInicial = () => (dispatch, getStore) => {
    let propietario_id = undefined;

    const me = getStore().login.me;

    if (me.propietario) {
        propietario_id = me.propietario.id;
    } else {
        if (me.proveedor_admin) {
            try{
                const proveedor = getStore().login.proveedor;
                propietario_id = proveedor ? proveedor : "all";
            } catch (error) {
                // Lo regresamos a la pagina principal
                Swal.fire({
                    title: "Error",
                    text: "Error",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                }).then((result) => {
                    if (result.value) {
                        dispatch(push("/"));
                    }
                });
                dispatch(push("/"));
                return;
            }
        }
        else{
            // Lo regresamos a la pagina principal
            Swal.fire({
                title: "Error",
                text: "No tienes un propietario asociado",
                icon: "error",
                confirmButtonText: "Aceptar",
            }).then((result) => {
                if (result.value) {
                    dispatch(push("/"));
                }
            });
            dispatch(push("/"));
            return;
        }
    }

    // hacemos un get para el obtener las opciones de anio, otro para obtener las fincas y otro para obtener los productos, todas estas promesas las metemos en una sola promesa y hasta que se carguen todas las opciones se muestra el componente (loader_init)
    Promise.all([
        api.get(`anual_proveedores/obtener_anios`, { propietario_id }),
        api.get(`anual_proveedores/obtener_fincas`, { propietario_id }),
        api.get(`anual_proveedores/obtener_productos`, { propietario_id }),
    ])
        .then((response) => {
            dispatch(setAnio(response[0]));
            dispatch(setFinca(response[1]));
            dispatch(setProductos(response[2]));
        })
        .catch((error) => {
            console.log("error: ", error);
            NotificationManager.error(
                "Ocurrió un error al obtener los datos iniciales",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoaderInit(false));
        });
};

const obtener_data =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const me = getStore().login.me;
        const { select_anio, select_producto, select_finca } =
            getStore().anual_proveedores;

        let propietario_id = undefined;

        if (me.propietario) {
            propietario_id = me.propietario.id;
        } else {
            if (me.proveedor_admin) {
                try{
                    const proveedor = getStore().login.proveedor;
                    propietario_id = proveedor ? proveedor : "all";
                } catch (error) {
                    // Lo regresamos a la pagina principal
                    Swal.fire({
                        title: "Error",
                        text: "Error",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    }).then((result) => {
                        if (result.value) {
                            dispatch(push("/"));
                        }
                    });
                    dispatch(push("/"));
                    return;
                }
            }
            else{
                // Lo regresamos a la pagina principal
                Swal.fire({
                    title: "Error",
                    text: "No tienes un propietario asociado",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                }).then((result) => {
                    if (result.value) {
                        dispatch(push("/"));
                    }
                });
                dispatch(push("/"));
                return;
            }
        }

        const params = {
            propietario_id,
        };

        if (select_anio != null && select_anio.length > 0) {
            params.anio = select_anio.map((anio) => anio.value);
        }
        if (select_producto != null && select_producto.length > 0) {
            params.producto = select_producto.map((producto) => producto.value);
        }
        if (select_finca != null && select_finca.length > 0) {
            params.finca = select_finca.map((finca) => finca.value);
        }

        params.page = page;
        dispatch(setParamsUsed(params));

        api.get(`anual_proveedores/obtener_reporte_apfp`, params)
            .then((response) => {
                dispatch(setData(response));
                dispatch(setPage(page));
            })
            .catch((error) => {
                console.log("error: ", error);
                NotificationManager.error(
                    "Ocurrió un error al obtener los datos",
                    "ERROR",
                    0
                );
            })
            .finally(() => {
                dispatch(setLoader(false));
            });

        if (page == 1) {
            obtener_data_full_to_pdf()(dispatch, getStore);
        }
    };

const obtener_data_excel = () => (dispatch, getStore) => {
    dispatch(setLoaderReportExcel(true));
    const me = getStore().login.me;
    let propietario_id = undefined;

    if (me.propietario) {
        propietario_id = me.propietario.id;
    } else {
        if (me.proveedor_admin) {
            try{
                const proveedor = getStore().login.proveedor;
                propietario_id = proveedor ? proveedor : "all";
            } catch (error) {
                // Lo regresamos a la pagina principal
                Swal.fire({
                    title: "Error",
                    text: "Error",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                }).then((result) => {
                    if (result.value) {
                        dispatch(push("/"));
                    }
                });
                dispatch(push("/"));
                return;
            }
        }
        else{
            // Lo regresamos a la pagina principal
            Swal.fire({
                title: "Error",
                text: "No tienes un propietario asociado",
                icon: "error",
                confirmButtonText: "Aceptar",
            }).then((result) => {
                if (result.value) {
                    dispatch(push("/"));
                }
            });
            dispatch(push("/"));
            return;
        }
    }

    let endpoint = `/api/anual_proveedores/obtener_reporte_excel/`;
    endpoint += `?propietario_id=${propietario_id}`;

    const { params } = getStore().anual_proveedores;

    //recorremos los parametros para agregarlos al endpoint de la api
    Object.keys(params).forEach((key) => {
        if (params[key] != null && params[key] != undefined && params[key].length > 0) {
            endpoint += `&${key}=${params[key].join(",")}`;
        }
    });

    fetch(endpoint, {
        method: "GET",
        headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    })
        .then((response) => {
            if (response.status === 200) {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = "ReporteAnualProveedor.xlsx";
                    a.click();
                });
            }
        })
        .catch(() => {
            NotificationManager.error(
                "Ocurrió un error al exportar el reporte",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoaderReportExcel(false));
        });
};


const obtener_data_full_to_pdf = () => (dispatch, getStore) => {
    dispatch(setLoaderReportPdf(true));
    const me = getStore().login.me;
    let propietario_id = undefined;

    if (me.propietario) {
        propietario_id = me.propietario.id;
    } else {
        if (me.proveedor_admin) {
            try{
                const proveedor = getStore().login.proveedor;
                propietario_id = proveedor ? proveedor : "all";
            } catch (error) {
                // Lo regresamos a la pagina principal
                Swal.fire({
                    title: "Error",
                    text: "Error",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                }).then((result) => {
                    if (result.value) {
                        dispatch(push("/"));
                    }
                });
                dispatch(push("/"));
                return;
            }
        }
        else{
            // Lo regresamos a la pagina principal
            Swal.fire({
                title: "Error",
                text: "No tienes un propietario asociado",
                icon: "error",
                confirmButtonText: "Aceptar",
            }).then((result) => {
                if (result.value) {
                    dispatch(push("/"));
                }
            });
            dispatch(push("/"));
            return;
        }
    }

    const { params } = getStore().anual_proveedores;
    
    api.get(`anual_proveedores/obtener_reporte_apfp_to_pdf`, params)
    .then((response) => {
        dispatch(setDataFullToPdf(response));
    })
    .catch((error) => {
        console.log("error: ", error);
        NotificationManager.error(
            "Ocurrió un error al obtener los datos",
            "ERROR",
            0
        );
    })
    .finally(() => {
        dispatch(setLoaderReportPdf(false));
    });
    
};

export const actions = {
    obtenerDataInicial,
    setSelectFinca,
    setSelectAnio,
    setSelectProducto,
    obtener_data,
    obtener_data_excel,
    obtener_data_full_to_pdf,
};
