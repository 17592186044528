import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { ViewGrafica } from "./ViewGrafica";
import { actions } from "../../../../../redux/modules/laboratorio_estadisticas";

const TableEstadisticar = (props) => {
    const { Xmedia, Xmediana_intervalos, Xmoda, R, A, N, K, DatoXmin, DatoXmax } =
        props;

    if (!props || !Xmedia || !Xmediana_intervalos || !Xmoda || !R || !A || !N || !DatoXmin || !DatoXmax || !K) {
        return null;
    }

    return (
        <div className="row px-3">
            <table className="table table-bordered border-right-0 border-left-0 border-top-0 border-bottom-0 rounded-3">
                <thead>
                    <tr>
                        <th scope="col">Moda</th>
                        <th scope="col">Media</th>
                        <th scope="col">Mediana</th>
                        <th scope="col">K</th>
                        <th scope="col">N</th>
                        <th scope="col">A</th>
                        <th scope="col">R</th>
                        <th scope="col">Xmin</th>
                        <th scope="col">Xmax</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{!!!Xmoda ? "No hay moda" : Xmoda}</td>
                        <td>{!!!Xmedia ? "No hay media" : Xmedia}</td>
                        <td>
                            {!!!Xmediana_intervalos
                                ? "No hay mediana"
                                : Xmediana_intervalos}
                        </td>
                        <td>{!!!K ? "No hay K" : K}</td>
                        <td>{!!!N ? "No hay N" : N}</td>
                        <td>{!!!A ? "No hay A" : A}</td>
                        <td>{!!!R ? "No hay R" : R}</td>
                        <td>{!!!DatoXmin ? "No hay DatoXmin" : DatoXmin}</td>
                        <td>{!!!DatoXmax ? "No hay DatoXmax" : DatoXmax}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export const EstadisticasDRC = (props) => {
    const { obtener_data, data } = props;
    const [showSecondChart, setShowSecondChart] = useState(false);
    useEffect(() => {
        obtener_data();
    }, []);

    const { datos_laboratorio, datos_historicos } = data;
    const { data_graphic } = datos_laboratorio || [];
    const { Xmedia } = datos_laboratorio || 0;
    const data_graphic_historicos =
        (datos_historicos && datos_historicos.data_graphic) || [];
    const Xmedia_historicos =
        (datos_historicos && datos_historicos.Xmedia) || 0;

    const fecha_analisis_historico = datos_historicos && datos_historicos.fecha_analisis || "-- No se encontro la fecha --";
    const fecha_analisis_ = datos_laboratorio && datos_laboratorio.fecha_analisis || "-- No se encontro la fecha --";
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h5 className="mt-3">Estadisticas DRC: </h5>
                    <hr
                        style={{
                            color: "black",
                            backgroundColor: "black",
                            height: "0.5px",
                            borderColor: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            marginTop: "0px",
                        }}
                    />
                </div>
            </div>
            <TableEstadisticar {...datos_laboratorio} />
            <div className="row">
                <div className="col-12">
                    <ViewGrafica data={data_graphic} media={Xmedia} fecha_analisis={fecha_analisis_}/>
                    <button
                        className="btn btn-primary mb-3"
                        onClick={() => setShowSecondChart(!showSecondChart)}
                    >
                        {showSecondChart
                            ? "Ocultar datos historicos"
                            : "Mostrar datos historicos"}
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {showSecondChart && (
                        <Fragment>
                            <TableEstadisticar {...datos_historicos} />
                            <ViewGrafica
                                data={data_graphic_historicos}
                                media={Xmedia_historicos}
                                fecha_analisis={fecha_analisis_historico}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    ...state.laboratorio_estadisticas,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EstadisticasDRC);
