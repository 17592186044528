import React, {Component, Fragment} from 'react'
import CardForm from '../../../Utils/Cards/CardForm';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import "react-step-progress-bar/styles.css";
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import {Link} from "react-router-dom";
import {RenderKilogramo, RenderNumber, RenderNumberPercentage, RenderGramos, RenderML} from "../../../Utils/renderField/renderReadField"
import {TableHeaderColumn} from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";

const ver_img = require('assets/img/icons/ver.png');

export default class AnalisisBodegaVer extends Component {
    state = {
        ver_info: false,
        ver_opciones: false,
    };

    componentWillMount() {
        if (this.props.match.url.includes('validar_drc_pilas')) {
            this.setState({ver_opciones: true});
            this.props.leer(this.props.match.params.id, this.props.item, 'validar');
        } else if (this.props.match.url.includes('drc_validados_pilas')) {
            this.props.leer(this.props.match.params.id, this.props.item, 'finalizados');
        } else {
            this.props.leer(this.props.match.params.id);
        }
    }

    toogleInfo = () => {
        this.setState({ver_info: !this.state.ver_info})
    };

    render() {
        const { item, item_bodega, loader, validar_drc, rechazar_drc } = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="laboratorio_activo"
                    titulo="ANÁLISIS DE PILA/TANQUE"
                    subtitulo="Detalle"
                    col="12"
                >
                    <LoadMask loading={loader} blur>
                        {(item && item.movimiento) && (
                            <div className="d-flex justify-content-end align-items-center verde-secundario mb-3">
                                <div className="d-flex align-items-baseline">
                                    <span className="bold mr-2">TIPO BODEGA </span>
                                    <h4 className="gris bold mr-3">{item_bodega.tipo_text}</h4>
                                    <span className="bold mr-2">NOMBRE</span>
                                    <h4 className="gris bold mr-3">{item_bodega.nombre}</h4>
                                    <span className="bold mr-2">PRODUCTO</span>
                                    <h4 className="gris bold">{item.movimiento.materia_prima.nombre}</h4>
                                </div>
                            </div>
                        )}
                        <div className="row my-3">
                            <div className="col-12">
                                <CardEmpty noShadow>
                                    <div className="d-flex justify-content-between my-2">
                                        <h6 className="">DATOS DRC</h6>
                                        <div className="d-flex align-items-center">
                                            <span className="verde-secundario bold mr-2">DRC PROMEDIO:</span>
                                            <RenderNumberPercentage value={item.drc_promedio}/>
                                        </div>
                                    </div>
                                    <Grid data={item.datos_tsc ? {results: item.datos_drc} : {}} loading={loader}
                                          pagination={false}>
                                        <TableHeaderColumn
                                            dataField="peso_seco"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                        >
                                            PESO SECO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            hidden
                                        />
                                        <TableHeaderColumn
                                            dataField="peso_plato"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                        >
                                            PESO PLATO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="peso_humedo"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                        >
                                            PESO HUMEDO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderNumberPercentage value={cell}/></span>}
                                        >
                                            DRC
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="hora_ingreso"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{cell}</span>}
                                        >
                                            HORA INGRESO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="hora_salida"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{cell}</span>}
                                        >
                                            HORA SALIDA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{row.laboratorista_nombre ? row.laboratorista_nombre : row.laboratorista.nombre}</span>}
                                        >
                                            LABORATORISTA
                                        </TableHeaderColumn>
                                    </Grid>

                                </CardEmpty>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-12">
                                <CardEmpty noShadow>
                                    <div className="d-flex justify-content-between my-2">
                                        <h6 className="">DATOS TSC</h6>
                                        <div className="d-flex align-items-center">
                                            <span className="verde-secundario bold mr-2">TSC PROMEDIO:</span>
                                            <RenderNumberPercentage value={item.tsc_promedio}/>
                                        </div>
                                    </div>
                                    <Grid data={item.datos_tsc ? {results: item.datos_tsc} : {}} loading={loader} pagination={false}  >
                                        <TableHeaderColumn
                                            dataField="peso_seco"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                        >
                                            PESO SECO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            hidden
                                        />
                                        <TableHeaderColumn
                                            dataField="peso_plato"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                        >
                                            PESO PLATO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="peso_humedo"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                        >
                                            PESO HÚMEDO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderNumberPercentage value={cell}/></span>}
                                        >
                                            TSC
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{row.laboratorista_nombre ? row.laboratorista_nombre : row.laboratorista.nombre}</span>}
                                        >
                                            LABORATORISTA
                                        </TableHeaderColumn>
                                    </Grid>

                                </CardEmpty>
                            </div>
                        </div>

                        <div className="row my-4">
                            <div className="col-12">
                                <CardEmpty noShadow>
                                    <div className="d-flex justify-content-between my-2">
                                        <h6 className="">DATOS VFA</h6>
                                        <div className="d-flex align-items-center">
                                            <span className="verde-secundario bold mr-2">VFA PROMEDIO:</span>
                                            <RenderNumberPercentage value={item.vfa} decimalScale={3}/>
                                        </div>
                                    </div>
                                    <Grid data={item.datos_vfa ? {results: item.datos_vfa} : {}} loading={loader} pagination={false}  >
                                        <TableHeaderColumn
                                            dataField="ml_bureta"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderML value={cell} /></span>}
                                        >
                                            ML. BURETA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            hidden
                                        />
                                        <TableHeaderColumn
                                            dataField="peso"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderNumber value={cell} decimalScale={4}/></span>}
                                        >
                                            NORMALIDAD
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderNumberPercentage value={cell/100} decimalScale={3}/></span>}
                                        >
                                            VFA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{row.laboratorista_nombre ? row.laboratorista_nombre : row.laboratorista.nombre}</span>}
                                        >
                                            LABORATORISTA
                                        </TableHeaderColumn>
                                    </Grid>

                                </CardEmpty>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-12">
                                <CardEmpty noShadow>
                                    <div className="d-flex justify-content-between my-2">
                                        <h6 className="">DATOS NH3</h6>
                                        <div className="d-flex align-items-center">
                                            <span className="verde-secundario bold mr-2">NH3:</span>
                                            <RenderNumberPercentage value={item.nh3}/>
                                        </div>
                                    </div>
                                    <Grid data={item.datos_nh3 ? {results: item.datos_nh3} : {}} loading={loader} pagination={false}  >
                                        <TableHeaderColumn
                                            dataField="ml_bureta"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderML value={cell} /></span>}
                                        >
                                            ML. BURETA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            hidden
                                        />
                                        <TableHeaderColumn
                                            dataField="peso"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderGramos value={cell} /></span>}
                                        >
                                            PESO MUESTRA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total"
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}><RenderNumberPercentage value={cell}/></span>}
                                        >
                                            NH3
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataFormat={(cell,row) => <span className={row.activo === false ? "tachado" : ""}>{row.laboratorista_nombre ? row.laboratorista_nombre : row.laboratorista.nombre}</span>}
                                        >
                                            LABORATORISTA
                                        </TableHeaderColumn>
                                    </Grid>

                                </CardEmpty>
                            </div>
                        </div>
                        <div style={{textAlign: 'end'}}>ERSA-F-LL-007-07</div>

                        <div className="buttons-box mt-5">
                            <button className="btn btn-outline-dark mr-5 px-5" onClick={() => this.props.history.goBack()}>SALIR</button>
                            {this.state.ver_opciones && (
                                <Fragment>
                                    <button type="button" className="btn btn-primary mr-5"
                                            onClick={event => {
                                                event.preventDefault();
                                                validar_drc(this.props.match.params.id)
                                            }}>
                                        VALIDAR RESULTADOS
                                    </button>
                                    <button type="button" className="btn btn-secondary"
                                            onClick={event => {
                                                event.preventDefault();
                                                rechazar_drc(this.props.match.params.id)
                                            }}>
                                        RECHAZAR RESULTADOS
                                    </button>
                                </Fragment>
                            )}
                        </div>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
