import { handleActions } from "redux-actions";
import { api } from "api";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const OBTENER_LISTA = "OBTENER_LISTA_CANAL_REPORTE";
const LOADER = "LOADER_CANAL_REPORTE";
const SET_PAGE = "SET_PAGE_CANAL_REPORTE";
const SET_LOADER_EXCEL = "SET_LOADER_EXCEL_CANAL_REPORTE";
const SET_PARAMS_USED = "SET_PARAMS_USED_CANAL_REPORTE";
const SELECT_FECHA_INICIAL = "SELECT_FECHA_INICIAL_CANAL_REPORTE";
const SELECT_FECHA_FINAL = "SELECT_FECHA_FINAL_CANAL_REPORTE";

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setParamsUsed = (params_used) => ({
    type: SET_PARAMS_USED,
    params_used,
});

const setLoaderExcel = (loader_excel) => ({
    type: SET_LOADER_EXCEL,
    loader_excel,
});

const setData = (data) => ({
    type: OBTENER_LISTA,
    data,
});

const setSelectFechaInicial = (select_fecha_inicial) => ({
    type: SELECT_FECHA_INICIAL,
    select_fecha_inicial,
});

const setSelectFechaFinal = (select_fecha_final) => ({
    type: SELECT_FECHA_FINAL,
    select_fecha_final,
});
const setPage = (page) => ({
    type: SET_PAGE,
    page,
});

const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [OBTENER_LISTA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SET_LOADER_EXCEL]: (state, { loader_excel }) => {
        return {
            ...state,
            loader_excel,
        };
    },
    [SET_PARAMS_USED]: (state, { params_used }) => {
        return {
            ...state,
            params_used,
        };
    },
    [SELECT_FECHA_INICIAL]: (state, { select_fecha_inicial }) => {
        return {
            ...state,
            select_fecha_inicial,
        };
    },
    [SELECT_FECHA_FINAL]: (state, { select_fecha_final }) => {
        return {
            ...state,
            select_fecha_final,
        };
    },
};

const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    loader_excel: false,
    params_used: {},
    select_fecha_inicial: moment().startOf("month"),
    select_fecha_final: moment().endOf("month"),
};

export const generarReporte = (page) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    //Tomamos la tada del filtro para pasarsela a los params y obtener la primera pagina
    const { select_fecha_inicial, select_fecha_final } =
        getStore().canal_reporte;
    const params = {};
    const params_used = {};
    // para las fechas validamos el objeto Moment
    if (select_fecha_inicial != null) {
        params.fecha_inicial = select_fecha_inicial.format("YYYY-MM-DD");
    }
    if (select_fecha_final != null) {
        params.fecha_final = select_fecha_final.format("YYYY-MM-DD");
    }

    params["page"] = page || 1;
    api.get("canal/reporte_canal", params)
        .then((response) => {
            dispatch(setData(response));
            if (page) {
                dispatch(setPage(page));
            } else {
                dispatch(setPage(1));
            }
            dispatch(setParamsUsed(params_used));
        })
        .catch((e) => {
            console.log(e);
            NotificationManager.error(
                "Ocurrio un error al obtener los datos",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const obtener_data_excel = () => (dispatch, getStore) => {
    dispatch(setLoaderExcel(true));

    let endpoint = `/api/canal/obtener_reporte_excel/?`;

    const { params_used } = getStore().canal_reporte;

    //recorremos los parametros para agregarlos al endpoint de la api
    Object.keys(params_used).forEach((key) => {
        if (
            params_used[key] != null &&
            params_used[key] != undefined &&
            params_used[key].length > 0
        ) {
            endpoint += `&${key}=${params[key].join(",")}`;
        }
    });

    fetch(endpoint, {
        method: "GET",
        headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    })
        .then((response) => {
            if (response.status === 200) {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = "Canal_Reporte.xlsx";
                    a.click();
                });
            }
        })
        .catch(() => {
            NotificationManager.error(
                "Ocurrió un error al exportar el reporte",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoaderExcel(false));
        });
};

export const actions = {
    generarReporte,
    setSelectFechaFinal,
    setSelectFechaInicial,
    // obtener_data_excel, // falta construir el endpoint
};

export default handleActions(reducers, initialState);
