import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './ProveedorForm';
import { ProveedorUpdateForm } from './ProveedorForm';

export default class ProveedorCrear extends Component {
    state = {
        editar: false,
        mensaje: 'Agregar usuario',
        password: false,
    };
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
            });
        }
    }
    actualizar = (data) =>{
        this.props.editar(data, this.props.match.params.id)
    };
  render() {
    const { crear, update } = this.props;
    const { updateData, loader } = this.props;
    return (
      <div className="mb-4 col-12">
        <CardForm
          icon="configuraciones_activo"
          titulo="PROVEEDOR"
          subtitulo="Nuevo">
          <LoadMask loading={loader} blur>
            {
              this.state.editar ?
                <ProveedorUpdateForm
                  mostrar_pass={this.state.password}
                  onSubmit={this.actualizar}
                  updateData={updateData}
                  editando={true}
                />
                :
                <Form onSubmit={crear}/>
            }
          </LoadMask>
        </CardForm>
      </div>
    )
  }
}
