import { api } from "api";
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { renderField, renderSwitch, renderDatePicker, AsyncSelectField } from '../../../Utils/renderField/renderField';
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import Alerta from "../../../Utils/Alerta/Alerta";
import DatePicker from "react-datepicker";
import moment from "moment";
import validador from './validate';


let productos_deascativados_id = [];

const getProductos = (search) => {
    return api.get("materia_prima/productos_produccion", { search }).
        then((data) => {
            if (data){
                data.results.map((producto) => {
                    if (producto.es_contable === false){
                        productos_deascativados_id.push(producto.id);
                    }
                });
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
    });
};

const getSupervisores = (search) => {
    return api.get("user/listado_supervisores", { search }).
        then((data) => {
            if (data){
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
    });
};

const renderTurnos = ({fields, updateData, fechaProduccion, fecha_inicio}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "10%"}} />
                        <th className="text-center" style={{width: "30%"}}>SUPERVISOR</th>
                        <th className="text-center" style={{width: "25%"}}>FECHA</th>
                        <th className="text-center" style={{width: "25%"}}>TURNO</th>
                        <th style={{width: "10%"}} />
                    </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div>
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                    {fields.map((turno, index) => {
                        let todos = fields.getAll();
                        const dato_actual = fields.get(index); // Pago actual
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        return(
                        <tr key={index}>
                            <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                     title="Borrar" className="action_img"
                                     onClick={() =>{
                                        fields.remove(index)
                                        // if (dato_actual.hasOwnProperty('id')) {
                                        //     dato_actual.activo = false;
                                        //     updateData(dato_actual, 'turnos');
                                        // } else {
                                        // }
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top" style={{width: "30%"}}>
                                <Field
                                    valueKey="id"
                                    name={`${turno}.supervisor`}
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getSupervisores}
                                />
                            </td>
                            <td style={{width: "25%"}}>
                                <Field
                                    name={`${turno}.fecha_turno`}
                                    component={renderDatePicker}
                                    todayButton="Hoy"
                                />
                            </td>
                            <td style={{width: "25%"}}>
                                <Field
                                    name={`${turno}.turno`}
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </td>
                            <td style={{width: "10%"}}></td>
                        </tr>
                    )})}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-center mt-2">
                <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({})}>
                    AGREGAR TURNO
                </button>
            </div>
        </div>
    </div>
);

let RegistroForm = props => {
    const { handleSubmit, fecha_inicio_form, fechaProduccion, no_produccion, editando, numeroProduccion, producto_valido } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <div className="col-12 col-lg-8 d-flex flex-wrap justify-content-between align-items-baseline">
                    <h6 className="mb-3 ml-5">DATOS DE REGISTRO</h6>
                    <label className="mb-3 mr-5">NO. PRODUCCIÓN <span className="gris text-md">{no_produccion}</span></label>
                </div>
                <div className="col-12 col-lg-8 mb-3">
                    <CardEmpty noShadow>
                        <div className="row col-12 p-0 m-0">
                            <div className="col-12 col-lg-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="numero_produccion" className="m-0">NO. PRODUCCIÓN</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="numero_produccion"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fecha_inicio_produccion" className="m-0">FECHA DE INICIO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="fecha_inicio_produccion"
                                        component={renderDatePicker}
                                        todayButton="Hoy"
                                        disabled={editando ? true : false}
                                        onCambio={(value) => numeroProduccion(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </CardEmpty>
                </div>
                <div className="col-12 col-lg-8 mb-2">
                    <div className="row col-12 p-0 m-0 px-3">
                        <div className="col-12 col-lg-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="materia_prima" className="m-0">PRODUCTO A PRODUCIR</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="materia_prima"
                                    valueKey="id"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getProductos}
                                    disabled={editando ? true : false}
                                />
                            </div>
                        </div>
                        {!producto_valido ? (<React.Fragment>
                            &nbsp;
                        <div
                            className="px-4 text-wrap text-center mb-3"
                            style={{
                                color: 'red',
                                fontSize: '1.2em',
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            &nbsp; NOTA: Este producto no se tomara en cuenta en kilos produccidos
                        </div>
                        </React.Fragment>) : null}
                        <div className="col-12 col-lg-6 p-0"></div>
                    </div>
                </div>
                <div className="row col-12 col-lg-10">
                    <div className="col-12">
                        <div className="row col-12 m-0 py-3">
                            <h6 className="">TURNOS DE SUPERVISOR</h6>
                            <FieldArray name="turnos_supervisores" component={renderTurnos} {...props} />
                        </div>
                    </div>
                </div>
                {editando && (
                    <div className="row col-12 col-lg-10 d-flex flex-wrap justify-content-center mt-3">
                        <Alerta danger texto={`Recuerde guardar para continuar.`}/>
                    </div>
                )}
                <div className="mt-3">
                    {editando && (
                        <Link className="btn btn-outline-dark mr-5" to="/producciones">REGRESAR</Link>
                    )}
                    {!editando &&(
                        <Link className="btn btn-outline-dark mr-5" to="/producciones">CANCELAR</Link>
                    )}
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
RegistroForm = reduxForm({
    form: 'RegistroForm',
    // validate: data => {
    //     console.log("DATA--", data)
    //     if(data !== undefined){
    //         return validate(data, {
    //             'numero_produccion': validators.exists()('Campo requerido.'),
    //             'fecha_inicio_produccion': validators.exists()('Campo requerido.'),
    //             'materia_prima': validators.exists()('Campo requerido.'),
    //         })
    //     }
    // },
    // validate: validador,
    initialValues: {
        fecha_inicio_produccion: moment(),
        nuevo: true,
    },
})(RegistroForm);

const selector = formValueSelector('RegistroForm');
RegistroForm = connect(state => {
    const form = state.form.RegistroForm;
    const produccion = state.producciones;

    let no_produccion = "";
    let producto_valido = true;

    if (form && form.values) {
        const values = form.values;
        if (values.nuevo) {
            if (produccion.no_produccion) {
                values.numero_produccion = produccion.no_produccion;
            }
        }

        if (values.numero_produccion) {
            no_produccion = values.numero_produccion;
        }
        if (values.materia_prima) {
            productos_deascativados_id.includes(values.materia_prima.id) ? producto_valido = false : producto_valido = true;
        }
    }
    return {
        producto_valido,
        no_produccion,
    }
})(RegistroForm);

export default RegistroForm
