import React, { Component } from 'react';
import Card from '../../../Utils/Cards/Card';
import LaboratoristaForm from './LaboratoristaForm';
import LoadMask from '../../../Utils/LoadMask/LoadMask';

class LaboratoristaCrear extends Component {
    state = {
        editar: false,
        mensaje: 'Agregar laboratorista'
    }

    componentDidMount() {
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
            });
        }
    }

    actualizar = data => {
        this.props.editar(this.props.match.params.id, data);
    }
    
    render() {
        const { crear, update } = this.props;
        const { updateData, loader } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="LABORATORISTAS"
                    subtitulo=""
                    noBorder
                >
                    <LoadMask
                        loading={loader}
                        blur
                    >
                        <section className="d-flex justify-content-center mt-4">
                            <div
                                className="card p-3"
                                style={{width: '90%'}}
                            >
                                { this.state.editar ?
                                    <LaboratoristaForm onSubmit={this.actualizar} editando={true}/>
                                    :
                                    <LaboratoristaForm onSubmit={crear} editando={false}/>
                                }
                            </div>
                        </section>
                    </LoadMask>
                </Card>
            </div>
        )
    }
}

export default LaboratoristaCrear;