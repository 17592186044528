import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import { tipo_pago } from '../../../../../utility/constants';

export default class TiposPagoList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="TIPOS DE PAGO"
                    subtitulo="Del sistema">
                    <Header to="tipo_pago/crear" textBtn="CREAR TIPO PAGO" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange}
                          onSortChange={onSortChange} page={page} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='20%'
                            dataFormat={standardActions({ editar: "tipo_pago", eliminar})}
                        />
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                            width='20%'
                        >
                            NOMBRE TIPO DE PAGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            width='10%'
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="grupo"
                            width='20%'
                            dataSort
                            dataFormat={cell => {
                                if (cell) {
                                    const nombre = tipo_pago.filter((item) => item.value === cell)
                                    return nombre[0].label
                                }
                                else{
                                    return "--"
                                }
                            }}
                        >
                            GRUPO
                        </TableHeaderColumn>
                        <TableHeaderColumn width='30%'/>
                    </Grid>
                </Card>
            </div>
        )
    }
}
