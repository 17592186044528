import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// imprime un elemento por medio de su
// nombre unico
export const print = name => {
    // clonar el elemento y colocarlo dentro del root print
    const el = document.getElementById("PRINT-" + name);
    const clon = el.cloneNode(true);

    const rootEl = document.getElementById("PRINT-ROOT-CONTAINER");
    rootEl.appendChild(clon);

    // imprimir
    const oldTitle = document.title;
    document.title = name;
    window.print();

    // limpiar elemento en otro hilo despues de imprimir
    // evita re-imprimir si se imprime manualmente la pagina.
    // Comentar para debug de @media print

    window.setTimeout(() => {
        rootEl.innerHTML = ''
        document.title = oldTitle;
    }, 50)
}

/**
 * Devuelve una promesa que en su resolve contiene jsPDF().output() resultado de convertir la vista a un PDF.
 * @param {*} name: string: Se genera el id del DOM como `PRINT-${name}`
 * @return: Promise
 */
export const exportToPDF = name => {
    // html2canvas tiene el inconveniente de que no puede generar el canvas de elementos que estén fuera de la vista de
    // la ventana, por lo que las soluciones propuestas son:
    //   [1] Especificar a html2canvas que genere el canvas desde la posición inicial de la ventana
    //   [2] Setear el scrollY de la ventana a cero
    // Referencia: 
    //   https://stackoverflow.com/questions/36213275/html2canvas-does-not-render-full-div-only-what-is-visible-on-screen#answer-59841384
    return new Promise((resolve, reject) => {
        window.scrollTo(0, 0);  // [1]
        const pdf = new jsPDF('portrait', 'mm', 'a4', 1);
        const metrics = [name].map(async (key) => {
            const input = document.getElementById(`PRINT-${key}`);
            let canvas = await html2canvas(input, {
                scrollY: -window.scrollY,   // [2]
            });
            const imgData = canvas.toDataURL('image/PNG');
            const width = pdf.internal.pageSize.width;
            const height = width * (canvas.height / canvas.width);
            // pdf.scaleFactor = 2;
            pdf.addImage(imgData, 'PNG', 0, 25, width, height);
        });
        Promise.all(metrics).then(() => {
            resolve(pdf.output());
        }).catch(error => {
            console.log('Error al intentar convertir a PDF:',error);
            reject(error);
        });
    });
}