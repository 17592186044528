import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';
import BajaForm from './BajaForm';
import Modal from 'react-responsive-modal';

const eliminar_img = require('assets/img/icons/borrar.png');
const ver_img = require('assets/img/icons/ver.png');
const editar_img = require('assets/img/icons/editar.png');
const activar_img = require('assets/img/icons/activar.png');
const desactivar_img = require('assets/img/icons/desactivar.png');
const alerta = require('assets/img/icons/alerta_roja.png');

class Acciones extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false};
        this.editar = this.editar.bind(this);
    }

    eliminar = (id) => {
        return () => {
            const SwalMod = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  cancelButton: 'btn btn-outline-dark mr-5',
                  content: 'd-flex justify-content-center mb-4',
                  title: 'text-azul-alerta',
                  image: 'blink-danger',
                },
                buttonsStyling: false,
                focusConfirm: false,
                focusCancel: false,
            })
            SwalMod.fire({
                title: '¿ELIMINAR?',
                text: 'Esta acción no puede ser revertida',
                imageUrl: alerta,
                imageAlt: 'Alerta',
                showCancelButton: true,
                confirmButtonText: 'ELIMINAR',
                cancelButtonText: 'CANCELAR',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };
    openModal = (activar) => {
        this.setState({open: true, activar})
    };
    closeModal = () => {
        this.setState({open: false})
    };
    onSubmit = (data) => {
        if (this.state.activar) {
            this.props.activar(this.props.id)
        } else {
            this.props.desactivar(this.props.id, data)
        }
    };

    editar(id, row) {
        return () => {
            this.props.editar(id, row);
        };
    }

    ver(id, row) {
        return () => {
            this.props.ver(id, row);
        };
    }

    render() {
        const {id, row, ver, editar, eliminar, desactivar} = this.props;

        return (
            <Fragment>
                <Modal open={this.state.open} onClose={this.closeModal}>
                    <div className="pb-4 titulo-modal d-flex justify-content-center">
                        {this.state.activar ? (
                            <h2>REACTIVAR LA FINCA</h2>
                        ) : (
                            <h2>DAR DE BAJA/DESACTIVAR FINCA</h2>
                        )}
                    </div>
                    <BajaForm onSubmit={this.onSubmit} closeModal={this.closeModal} activar={this.state.activar}/>
                </Modal>
                <div className="d-flex justify-content-center">
                    {(desactivar !== undefined) && (
                        <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={e => {
                            e.preventDefault();
                            this.openModal(!!row.de_baja)
                        }}><img
                            src={row.de_baja ? activar_img : desactivar_img} alt="De baja" className="action_img"
                            title={`${this.state.activar ? 'Activar' : 'Desactivar'}`}/></a>
                    )}
                    {(eliminar !== undefined) && (
                        <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}}
                           onClick={this.eliminar(id)}><img
                            src={eliminar_img} alt="Eliminar" className="action_img"/></a>
                    )}
                    {(editar !== undefined) && ((typeof editar) === "string") && (
                        <Link className="text-warning" to={`${editar}/${id}/editar`}><img src={editar_img} alt="Editar"
                                                                                          className="action_img"/></Link>
                    )}
                    {(editar !== undefined) && ((typeof editar) !== "string") && (
                        <a className="text-warning" onClick={this.editar(id, row)}>
                            <img src={editar_img} alt="Editar" className="action_img"/>
                        </a>
                    )}
                    {(ver !== undefined) && ((typeof editar) === "string") && (
                        <Link to={`${ver}/${id}/`} className="px-2"><img src={ver_img} alt="Ver"
                                                                         className="action_img"/></Link>
                    )}
                    {(ver !== undefined) && ((typeof ver) !== "string") && (
                        <a className="text-warning" onClick={this.editar(id, row)}>
                            <img src={ver_img} alt="Editar" className="action_img"/>
                        </a>
                    )}
                </div>
            </Fragment>
        );
    }
}

Acciones.propTypes = {};

export function customActions(acciones) {
    return (cell, row) => {
        return (<Acciones id={cell} row={row} {...acciones}/>)
    };
}
