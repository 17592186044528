import { handleActions } from "redux-actions"
import { api } from "api"
import { NotificationManager } from "react-notifications"
import moment from "moment"

// Constantes Globales
const LOADER = 'LOADER_HISTORICO'

// Constantes Recepciones
const DATA_RECEPCIONES_HISTORICO = 'DATA_RECEPCIONES_HISTORICO'
const PAGE_RECEPCIONES_HISTORICO = 'PAGE_RECEPCIONES_HISTORICO'
const FECHA_INICIAL_RECEPCIONES = 'FECHA_INICIAL_RECEPCIONES'
const FECHA_FINAL_RECEPCIONES = 'FECHA_FINAL_RECEPCIONES'
const FINCA_RECEPCIONES_HISTORICO = 'FINCA_RECEPCIONES_HISTORICO'
const DETALLES_RECEPCION_HISTORICO = 'DETALLES_RECEPCION_HISTORICO'
const ITEM_RECEPCIONES_HISTORICO = 'ITEM_RECEPCIONES_HISTORICO'

// Constantes liquidaciones
const DATA_LIQUIDACIONES_HISTORICO = 'DATA_LIQUIDACIONES_HISTORICO'
const PAGE_LIQUIDACIONES_HISTORICO = 'PAGE_LIQUIDACIONES_HISTORICO'
const FECHA_INICIAL_LIQUIDACIONES = 'FECHA_INICIAL_LIQUIDACIONES'
const FECHA_FINAL_LIQUIDACIONES = 'FECHA_FINAL_LIQUIDACIONES'
const FINCA_LIQUIDACIONES_HISTORICO = 'FINCA_LIQUIDACIONES_HISTORICO'
const RECEPCIONES_LIQUIDACION_HISTORICO = 'RECEPCIONES_LIQUIDACION_HISTORICO'
const ITEM_LIQUIDACIONES_HISTORICO = 'ITEM_LIQUIDACIONES_HISTORICO'

// Setters Globales
const setLoader = loader => ({
    type: LOADER,
    loader
})

// Setters de Recepciones

const setDataRecepciones = data_recepciones => ({
    type: DATA_RECEPCIONES_HISTORICO,
    data_recepciones
})

const setPageRecepciones = page_recepciones => ({
    type: PAGE_RECEPCIONES_HISTORICO,
    page_recepciones
})

const setFechaInicialRecepciones = fecha_inicial_recepciones => ({
    type: FECHA_INICIAL_RECEPCIONES,
    fecha_inicial_recepciones
})

const setFechaFinalRecepciones = fecha_final_recepciones => ({
    type: FECHA_FINAL_RECEPCIONES,
    fecha_final_recepciones
})

const setFincaRecepciones = finca_recepciones => ({
    type: FINCA_RECEPCIONES_HISTORICO,
    finca_recepciones
})

const setItemRecepciones = item_recepcion => ({
    type: ITEM_RECEPCIONES_HISTORICO,
    item_recepcion
})

const setDetallesRecepcion = detalles_recepcion => ({
    type: DETALLES_RECEPCION_HISTORICO,
    detalles_recepcion
})

// Setters de Liquidaciones

const setDataLiquidaciones = data_liquidaciones => ({
    type: DATA_LIQUIDACIONES_HISTORICO,
    data_liquidaciones
})

const setPageLiquidaciones = page_liquidaciones => ({
    type: PAGE_LIQUIDACIONES_HISTORICO,
    page_liquidaciones
})

const setFechaInicialLiquidaciones = fecha_inicial_liquidaciones => ({
    type: FECHA_INICIAL_LIQUIDACIONES,
    fecha_inicial_liquidaciones
})

const setFechaFinalLiquidaciones = fecha_final_liquidaciones => ({
    type: FECHA_FINAL_LIQUIDACIONES,
    fecha_final_liquidaciones
})

const setFincaLiquidaciones = finca_liquidaciones => ({
    type: FINCA_LIQUIDACIONES_HISTORICO,
    finca_liquidaciones
})

const setItemLiquidaciones = item_liquidacion => ({
    type: ITEM_LIQUIDACIONES_HISTORICO,
    item_liquidacion
})

const setRecepcionesLiquidacion = recepciones_liquidacion => ({
    type: RECEPCIONES_LIQUIDACION_HISTORICO,
    recepciones_liquidacion
})

// Acciones de Recepciones

const listarRecepciones = () => (dispatch, getStore) => {
    const resource = getStore().historico
    const params = {}
    params.page = resource.page_recepciones
    params.fecha_inicial = resource.fecha_inicial_recepciones.format('YYYY-MM-DD')
    params.fecha_final = resource.fecha_final_recepciones.format('YYYY-MM-DD')
    if (resource.finca_recepciones !== -1) {
        params.finca = resource.finca_recepciones
    }

    dispatch(setLoader(true))
    api.get('recepciones_historico', params)
        .then(response => {
            dispatch(setDataRecepciones(response))
        })
        .catch(error => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, 'ERROR', 3000)
            }
        })
        .finally(() => dispatch(setLoader(false)))
}

const leerRecepcion = id => async dispatch => {
    dispatch(setLoader(true))
    try {
        const recepcion = await api.get(`recepciones_historico/${id}`)
        const detalles = await api.get(`recepciones_historico/${id}/detalle`)
        dispatch(setItemRecepciones(recepcion))
        dispatch(setDetallesRecepcion(detalles))
    } catch(error) {
        if (error && error.detail) {
            NotificationManager.error(error.detail, 'ERROR', 3000)
        }
    } finally {
        dispatch(setLoader(false))
    }
}

const fechaInicialRecepcionesChange = fecha_inicial_recepciones => dispatch => {
    dispatch(setFechaInicialRecepciones(fecha_inicial_recepciones))
}

const fechaFinalRecepcionesChange = fecha_final_recepciones => dispatch => {
    dispatch(setFechaFinalRecepciones(fecha_final_recepciones))
}

const fincaRecepcionesChange = finca_recepciones => dispatch => {
    dispatch(setFincaRecepciones(finca_recepciones))
}

const pageRecepcionesChange = page_recepciones => dispatch => {
    dispatch(setPageRecepciones(page_recepciones))
    dispatch(listarRecepciones())
}

// Acciones de Liquidaciones
const listarLiquidaciones = () => (dispatch, getStore) => {
    const resource = getStore().historico
    const params = {}
    params.page = resource.page_liquidaciones
    params.fecha_inicial = resource.fecha_inicial_liquidaciones.format('YYYY-MM-DD')
    params.fecha_final = resource.fecha_final_liquidaciones.format('YYYY-MM-DD')
    if (resource.finca_liquidaciones !== -1) {
        params.finca = resource.finca_liquidaciones
    }

    dispatch(setLoader(true))
    api.get('liquidaciones_historico', params)
        .then(response => {
            dispatch(setDataLiquidaciones(response))
        })
        .catch(error => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, 'ERROR', 3000)
            }
        })
        .finally(() => dispatch(setLoader(false)))
}

const leerLiquidacion = id => async dispatch => {
    dispatch(setLoader(true))
    try {
        const liquidacion = await api.get(`liquidaciones_historico/${id}`)
        const recepciones = await api.get(`liquidaciones_historico/${id}/detalle`)
        dispatch(setItemLiquidaciones(liquidacion))
        dispatch(setRecepcionesLiquidacion(recepciones))
    } catch(error) {
        if (error && error.detail) {
            NotificationManager.error(error.detail, 'ERROR', 3000)
        }
    } finally {
        dispatch(setLoader(false))
    }
}

const fechaInicialLiquidacionesChange = fecha_inicial_liquidaciones => dispatch => {
    dispatch(setFechaInicialLiquidaciones(fecha_inicial_liquidaciones))
}

const fechaFinalLiquidacionesChange = fecha_final_liquidaciones => dispatch => {
    dispatch(setFechaFinalLiquidaciones(fecha_final_liquidaciones))
}

const fincaLiquidacionesChange = finca_liquidaciones => dispatch => {
    dispatch(setFincaLiquidaciones(finca_liquidaciones))
}

const pageLiquidacionesChange = page_liquidaciones => dispatch => {
    dispatch(setPageLiquidaciones(page_liquidaciones))
    dispatch(listarLiquidaciones())
}

export const actions = {
    // Recepciones
    listarRecepciones,
    fechaInicialRecepcionesChange,
    fechaFinalRecepcionesChange,
    fincaRecepcionesChange,
    pageRecepcionesChange,
    leerRecepcion,
    // Liquidaciones
    listarLiquidaciones,
    fechaInicialLiquidacionesChange,
    fechaFinalLiquidacionesChange,
    fincaLiquidacionesChange,
    pageLiquidacionesChange,
    leerLiquidacion
}

export const reducers = {
    // Reducers Globales
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader
        }
    },
    // Reducers de Recepciones
    [DATA_RECEPCIONES_HISTORICO]: (state, { data_recepciones }) => {
        return {
            ...state,
            data_recepciones
        }
    },
    [PAGE_RECEPCIONES_HISTORICO]: (state, { page_recepciones }) => {
        return {
            ...state,
            page_recepciones
        }
    },
    [FECHA_INICIAL_RECEPCIONES]: (state, { fecha_inicial_recepciones }) => {
        return {
            ...state,
            fecha_inicial_recepciones
        }
    },
    [FECHA_FINAL_RECEPCIONES]: (state, { fecha_final_recepciones }) => {
        return {
            ...state,
            fecha_final_recepciones
        }
    },
    [FINCA_RECEPCIONES_HISTORICO]: (state, { finca_recepciones }) => {
        return {
            ...state,
            finca_recepciones
        }
    },
    [ITEM_RECEPCIONES_HISTORICO]: (state, { item_recepcion }) => {
        return {
            ...state,
            item_recepcion
        }
    },
    [DETALLES_RECEPCION_HISTORICO]: (state, { detalles_recepcion }) => {
        return {
            ...state,
            detalles_recepcion
        }
    },
    // Reducers de Liquidaciones
    [DATA_LIQUIDACIONES_HISTORICO]: (state, { data_liquidaciones }) => {
        return {
            ...state,
            data_liquidaciones
        }
    },
    [PAGE_LIQUIDACIONES_HISTORICO]: (state, { page_liquidaciones }) => {
        return {
            ...state,
            page_liquidaciones
        }
    },
    [FECHA_INICIAL_LIQUIDACIONES]: (state, { fecha_inicial_liquidaciones }) => {
        return {
            ...state,
            fecha_inicial_liquidaciones
        }
    },
    [FECHA_FINAL_LIQUIDACIONES]: (state, { fecha_final_liquidaciones }) => {
        return {
            ...state,
            fecha_final_liquidaciones
        }
    },
    [FINCA_LIQUIDACIONES_HISTORICO]: (state, { finca_liquidaciones }) => {
        return {
            ...state,
            finca_liquidaciones
        }
    },
    [ITEM_LIQUIDACIONES_HISTORICO]: (state, { item_liquidacion }) => {
        return {
            ...state,
            item_liquidacion
        }
    },
    [RECEPCIONES_LIQUIDACION_HISTORICO]: (state, { recepciones_liquidacion }) => {
        return {
            ...state,
            recepciones_liquidacion
        }
    }
}

export const initialState = {
    loader: false,
    fecha_inicial_recepciones: moment().startOf('month'),
    fecha_final_recepciones: moment().endOf('month'),
    finca_recepciones: -1,
    page_recepciones: 1,
    data_recepciones: {
        count: 0,
        results: []
    },
    item_recepcion: {},
    detalles_recepcion: {
        count: 0,
        results: []
    },
    fecha_inicial_liquidaciones: moment().startOf('month'),
    fecha_final_liquidaciones: moment().endOf('month'),
    finca_liquidaciones: -1,
    page_liquidaciones: 1,
    data_liquidaciones: {
        count: 0,
        results: []
    },
    item_liquidacion: {},
    recepciones_liquidacion: {
        count: 0,
        results: []
    }
}

export default handleActions(reducers, initialState)