import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/perfiles/perfiles';
import PerfilFincaCrear from './PerfilFincaCrear';

const mstp = state => {
    return {...state.perfiles}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PerfilFincaCrear)
