import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import Alerta from '../../../../Utils/Alerta/Alerta';
import { renderNumberCM, renderTextArea } from '../../../../Utils/renderField/renderField';

let ReajusteForm = props => {
    const { handleSubmit, itemBodega } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <Alerta texto="Para reajustar, la justificación es requerida." />
                <div className="row col-12 col-md-8 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="" className="m-0">MATERIA</label>
                        </div>
                        <div className="col-md-12 form-group text-center">
                            <h4>{itemBodega && itemBodega.materia_prima ? itemBodega.materia_prima.nombre : '--'}</h4>
                        </div>
                    </div>
                </div>
                <div className="row col-12 col-md-8 p-0">
                    <div className="col-6 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="cm_inicial" className="m-0">CM. ACTUAL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="cm_inicial" component={renderNumberCM} decimalScale={0} type="text" className="form-control" disabled />
                        </div>
                    </div>
                    <div className="col-6 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="cm_final" className="m-0">CM. FINAL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="cm_final" component={renderNumberCM} decimalScale={0} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="justificacion" className="m-0">INGRESAR JUSTIFICACIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="justificacion" component={renderTextArea} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12 row buttons-box mt-1 d-flex justify-content-center">
                    <button className="btn btn-outline-dark mr-md-5" onClick={(e)=> {e.preventDefault(); props.closeModal()}}>CANCELAR</button>
                    <button type="submit" className="btn btn-primary">ACTUALIZAR</button>
                </div>
            </div>
        </form>
    )
};
ReajusteForm = reduxForm({
    form: 'reajusteLatexForm',
    validate: data => {
        return validate(data, {
            // 'cm_inicial': validators.exists()('Campo requerido.'),
            'cm_final': validators.exists()('Campo requerido.'),
            'justificacion': validators.exists()('Campo requerido.'),
        })
    },
    initialValues: {
        cm_inicial: 0,
    }
})(ReajusteForm);

ReajusteForm = connect(state => {
    const item = state.bodegas.item;
    const form = state.form.reajusteLatexForm;
    if (form && form.values) {
        form.values.cm_inicial = item.altura_utilizada;
    }
    return {}
})(ReajusteForm);

export default ReajusteForm
