import React, { Component } from 'react';
import {Link, NavLink} from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemState
} from 'react-accessible-accordion';

const configuracion = require("assets/img/icons/configuraciones.png");
const configuracion_activo = require("assets/img/icons/configuraciones_activo.png");
const dashboard = require("assets/img/icons/dashboard.png");
const dashboard_activo = require("assets/img/icons/dashboard_activo.png");
const recepciones = require("assets/img/icons/recepciones.png");
const recepciones_activo = require("assets/img/icons/recepciones_activo.png");
const despachos = require("assets/img/icons/despachos.png");
const despachos_activo = require("assets/img/icons/despachos_activo.png");
const produccion = require("assets/img/icons/produccion.png");
const produccion_activo = require("assets/img/icons/produccion_activo.png");
const laboratorio = require("assets/img/icons/laboratorio.png");
const laboratorio_activo = require("assets/img/icons/laboratorio_activo.png");
const liquidaciones = require("assets/img/icons/liquidaciones.png");
const liquidaciones_activo = require("assets/img/icons/liquidaciones_activo.png");
const compras = require("assets/img/icons/compras.png"); // Se deben de solicitar iconos para compras
const compras_activo = require("assets/img/icons/compras_activo.png"); // Se debe de solicitar iconos para compras
const reportes = require("assets/img/icons/reportes.png");
const reportes_activo = require("assets/img/icons/reportes_activo.png");
const bodega = require("assets/img/icons/almacen.png");
const bodega_activo = require("assets/img/icons/almacen_activo.png");
const historico = require("assets/img/icons/historico.png");
const historico_activo = require("assets/img/icons/historico_activo.png");

const MODULO_DASHBOARD = 'DASHBOARD';
const MODULO_RECEPCIONES = 'RECEPCIONES';
const MODULO_DESPACHOS = 'DESPACHOS';
const MODULO_BODEGAS = 'BODEGAS';
const MODULO_PRODUCCION = 'PRODUCCION';
const MODULO_LABORATORIO_LATEX = 'LABORATORIO_LATEX';
const MODULO_LABORATORIO_SOLIDO = 'LABORATORIO_SOLIDO';
const MODULO_LABORATORIO_PILAS = 'LABORATORIO_PILAS';
const MODULO_COMPRAS = 'COMPRAS';
const MODULO_LIQUIDACIONES = 'LIQUIDACIONES';
const MODULO_REPORTES = 'REPORTES';
const MODULO_LABORATORIO_PROVEEDORES = 'LABORATORIO';
const MODULO_ADMINISTRACION = 'ADMINISTRACION';
const MODULO_HISTORICO = 'HISTORICO';

class SideBar extends Component {
    state = {
        isFirstSelected: false,
        isSidebarExpanded: false,
    }
    constructor(props) {
        super(props);
    }
    mouseEnter = () => {
        this.setState({isSidebarExpanded: true})
    }
    mouseLeave = () => {
        this.setState({isSidebarExpanded: false})
    }

    /**
     * En base al path actual se determina si un item se marca como activo o no.
     * @param idModulo Una de las constantes definidas con el prefijo MODULO_
     * @param path la URL a comparar.
     * @param isItemSelected indica si el item en cuestión se le dió clic o no.
     */
    isItemSelected(idModulo, path, isItemSelected) {
        // Cuando no se ha dado el primer clic en cualquier item del Sidebar, todo el 'expanded' del sidebar es false pero
        // se debe evaluar la URL para comprobar si hace math (y por lo tanto marcarla como activo).
        const isFirstSelected = this.state.isFirstSelected;
        isItemSelected && !isFirstSelected && (this.setState({isFirstSelected: true}));
        let isMatch = false;
        const isSidebarExpanded = this.state.isSidebarExpanded;
        switch (idModulo) {
            case MODULO_DASHBOARD:
                isMatch = path === '#/';
                break;
            case MODULO_RECEPCIONES:
                isMatch = path.includes('/ingreso') && !path.includes('/reporte');
                break;
            case MODULO_DESPACHOS:
                isMatch = path.includes('/despacho')
                break;
            case MODULO_BODEGAS:
                isMatch = path.includes('/tanque') || path.includes('/pila') || path.includes('/almacen') || path.includes('/canal');
                break;
            case MODULO_PRODUCCION:
                isMatch = path.includes('/produccion') && !path.includes('/reporte');
                break;
            case MODULO_LABORATORIO_LATEX:
                isMatch = path.includes('/laboratorio/analisis_latex') || path.includes('/laboratorio/validar_drc_latex') || path.includes('/laboratorio/drc_validados_latex');
                break;
            case MODULO_LABORATORIO_SOLIDO:
                isMatch = path.includes('/laboratorio/analisis_solido') || path.includes('/laboratorio/validar_drc_solido') || path.includes('/laboratorio/drc_validados_solido');
                break;
            case MODULO_LABORATORIO_PILAS:
                isMatch = path.includes('/laboratorio/analisis_pilas') || path.includes('/laboratorio/validar_drc_pilas') || path.includes('/laboratorio/drc_validados_pilas');
                break;
            case MODULO_COMPRAS:
                isMatch = path.includes('/compra');
                break;
            case MODULO_LIQUIDACIONES:
                isMatch = path.includes('/liquidacion') && !path.includes('/reporte') && !path.includes('/historico');
                break;
            case MODULO_REPORTES:
                isMatch = path.includes('/reporte/');
                break;
            case MODULO_LABORATORIO_PROVEEDORES:
                isMatch = path.includes('/laboratorio_prov/lab_proveedores') || path.includes('/laboratorio_prov/nh3_drc') || path.includes('/laboratorio_prov/nh3_vfa');
                break;
            case MODULO_HISTORICO:
                isMatch = path.includes('/historico/recepciones') || path.includes('/historico/liquidaciones');
                break;
            case MODULO_ADMINISTRACION:
                isMatch = path.includes('/rol') || path.includes('/usuario') || path.includes('/banco') || path.includes('/propietario') || path.includes('/perfil') || path.includes('/transportista') || path.includes('/tipos_vehiculo') || path.includes('/tipo_vehiculo') || path.includes('/vehiculo') || path.includes('/piloto') || path.includes('/tipos_materia_prima') || path.includes('/tipo_materia_prima') || path.includes('/materias_prima') || path.includes('/materia_prima') || path.includes('/tipos_pago') || path.includes('/tipo_pago') || path.includes('/acopio') || path.includes('/finca') || path.includes('/bodega') || path.includes('/contaminante') || path.includes('/configuracion');
                break;
            default:
                isMatch = false;
                break;
            }
        // La siguiente operación se sacó de un Mapa de Karnaugh que arroja como resultado:
        //     y = A'B'CD' + A'B'CD + AB'CD' + ABC'D + ABCD' + ABCD
        //     y = A'B'C + ACD' + ABD
        // donde A=isFirstSelected, B=isItemSelected, C=isMatch, D=isSidebarExpanded
        return !isFirstSelected&&!isItemSelected&&isMatch || isFirstSelected&&isMatch&&!isSidebarExpanded || isFirstSelected&&isItemSelected&&isSidebarExpanded;
    }

    render() {
        const { toggleOpen, navToggle, user } = this.props;
        let permisos = {};
        let proveedor = false;
        let proveedor_admin = false
        if (user.rol_usuario) {
            permisos = user.rol_usuario;
        }
        else{
            permisos.roles = true;
            permisos.usuarios = true;
        }
        if (user.proveedor) {
            proveedor = true;
        }
        if (user.proveedor_admin) {
            proveedor_admin = true;
        }
        return (
            <aside className={`main-sidebar ${toggleOpen?'':'open'}`} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                <div className="main-navbar">
                    <nav
                        className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light">
                        <a  href="/" className="ml-2 navbar-brand" >
                            {/* <img id="main-logo"
                                 className="d-inline-block align-top mr-1"
                                 src={require('assets/img/entre_rios_isotipo.png')}
                                 alt="Logo" /> */}
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper">
                    <Accordion className="nav--no-borders flex-column nav">
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_DASHBOARD, location.hash, expanded);
                                                return <NavLink to="/" exact className={`nav-link${isSelected ? ' active' : '' }`} activeClassName={isSelected ? 'active' : ''}>
                                                    <img src={isSelected ? dashboard_activo: dashboard}
                                                         className="icon-menu" alt=""/>
                                                    DASHBOARD
                                                </NavLink>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>
                        { ((permisos.recepciones_activas) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_RECEPCIONES, location.hash, expanded);
                                                    return <NavLink to="/ingresos" exact className={`nav-link${isSelected ? ' active' : '' }`} activeClassName={isSelected ? 'active' : ''}>
                                                        <img src={isSelected ? recepciones_activo: recepciones}
                                                            className="icon-menu" alt=""/>
                                                        RECEPCIONES
                                                    </NavLink>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </AccordionItem>
                            </div>
                        )}
                        { ((permisos.despachos) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_DESPACHOS, location.hash, expanded);
                                                    return <NavLink to="/despacho" exact className={`nav-link${isSelected ? ' active' : '' }`} activeClassName={isSelected ? 'active' : ''}>
                                                        <img src={isSelected ? despachos_activo: despachos}
                                                            className="icon-menu" alt=""/>
                                                        DESPACHOS
                                                    </NavLink>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </AccordionItem>
                            </div>
                        )}
                        { ((permisos.tanques || permisos.pilas || permisos.almacenes || permisos.canal) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_BODEGAS, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                        <img src={isSelected ? bodega_activo: bodega}
                                                            className="icon-menu" alt=""/>
                                                        BODEGAS
                                                    </div>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_BODEGAS, location.hash, expanded);
                                            return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                { (permisos.tanques && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/tanques" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('/tanque')}>
                                                            TANQUES
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { (permisos.pilas && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/pilas" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('/pila')}>
                                                            PILAS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { (permisos.almacenes && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/almacenes" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('/almacen')}>
                                                            ALMACENES
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { (permisos.canal && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/canal" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('/canal')}>
                                                            CANAL
                                                        </NavLink>
                                                    </div>
                                                )}
                                            </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                        )}
                        { ((permisos.analisis_laboratorio || permisos.validar_drc || permisos.historial) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_LABORATORIO_LATEX, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                        <img src={isSelected ? laboratorio_activo: laboratorio}
                                                            className="icon-menu" alt=""/>
                                                        LABORATORIO LÁTEX
                                                    </div>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_LABORATORIO_LATEX, location.hash, expanded);
                                            return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                { (permisos.analisis_laboratorio && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio/analisis_latex" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=> location.pathname.includes('laboratorio/analisis_latex')}>
                                                            PENDIENTES DE ANÁLISIS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { (permisos.validar_drc && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio/validar_drc_latex" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('laboratorio/validar_drc_latex')}>
                                                            PENDIENTES DE VALIDAR
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { (permisos.historial && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio/drc_validados_latex" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('laboratorio/drc_validados_latex')}>
                                                            HISTORIAL
                                                        </NavLink>
                                                    </div>
                                                )}
                                            </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                        )}
                        { ((permisos.analisis_laboratorio_solido || permisos.validar_drc_solido || permisos.historial_solido) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_LABORATORIO_SOLIDO, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                        <img src={isSelected ? laboratorio_activo: laboratorio}
                                                            className="icon-menu" alt=""/>
                                                        LABORATORIO SÓLIDO
                                                    </div>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_LABORATORIO_SOLIDO, location.hash, expanded);
                                            return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                { (permisos.analisis_laboratorio_solido && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio/analisis_solido" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=> location.pathname.includes('laboratorio/analisis_solido')}>
                                                            PENDIENTES DE ANÁLISIS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { (permisos.validar_drc_solido && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio/validar_drc_solido" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('laboratorio/validar_drc_solido')}>
                                                            PENDIENTES DE VALIDAR
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { (permisos.historial_solido && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio/drc_validados_solido" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('laboratorio/drc_validados_solido')}>
                                                            HISTORIAL
                                                        </NavLink>
                                                    </div>
                                                )}
                                            </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                        )}
                        { ((permisos.pilas_analisis_laboratorio || permisos.pilas_analisis_laboratorio_validar_drc || permisos.pilas_analisis_laboratorio_historial) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_LABORATORIO_PILAS, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                        <img src={isSelected ? laboratorio_activo: laboratorio}
                                                            className="icon-menu" alt=""/>
                                                        LABORATORIO PILAS
                                                    </div>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_LABORATORIO_PILAS, location.hash, expanded);
                                            return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                { (permisos.pilas_analisis_laboratorio && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio/analisis_pilas" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=> location.pathname.includes('laboratorio/analisis_pilas')}>
                                                            PENDIENTES DE ANÁLISIS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { (permisos.pilas_analisis_laboratorio_validar_drc && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio/validar_drc_pilas" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('laboratorio/validar_drc_pilas')}>
                                                            PENDIENTES DE VALIDAR
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { (permisos.pilas_analisis_laboratorio_historial && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio/drc_validados_pilas" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('laboratorio/drc_validados_pilas')}>
                                                            HISTORIAL
                                                        </NavLink>
                                                    </div>
                                                )}
                                            </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                        )}
                        { ((permisos.produccion) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_PRODUCCION, location.hash, expanded);
                                                        return <NavLink to="/producciones" exact className={`nav-link${isSelected ? ' active' : '' }`} activeClassName={isSelected ? 'active' : ''}>
                                                        <img src={isSelected ? produccion_activo: produccion}
                                                            className="icon-menu" alt=""/>
                                                        PRODUCCIÓN
                                                    </NavLink>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </AccordionItem>
                            </div>
                        )}
                        { ((permisos.crear_compras) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_COMPRAS, location.hash, expanded);
                                                        return <NavLink to="/compras" exact className={`nav-link${isSelected ? ' active' : '' }`} activeClassName={isSelected ? 'active' : ''}>
                                                        <img src={isSelected ? compras_activo: compras}
                                                            className="icon-menu" alt=""/>
                                                        COMPRAS
                                                    </NavLink>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </AccordionItem>
                            </div>
                        )}
                        { ((permisos.liquidaciones_pendientes) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_LIQUIDACIONES, location.hash, expanded);
                                                    return <NavLink to="/liquidaciones" exact className={`nav-link${isSelected ? ' active' : '' }`} activeClassName={isSelected ? 'active' : ''}>
                                                        <img src={isSelected ? liquidaciones_activo: liquidaciones}
                                                            className="icon-menu" alt=""/>
                                                        LIQUIDACIONES
                                                    </NavLink>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                </AccordionItem>
                            </div>
                        )}
                        {/* REPORTES GENERALES */}
                        { ((permisos.reporte_recepciones || permisos.reporte_liquidaciones || permisos.reporte_costo_fletes || permisos.reporte_drc_recepciones || permisos.reporte_kg_secos_recepciones || permisos.reporte_producciones || permisos.reporte_laboratorio || permisos.reporte_vs_drc || permisos.reporte_fluctuacion_precios || permisos.reporte_excedente_amoniaco) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_REPORTES, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                        <img src={isSelected ? reportes_activo : reportes}
                                                            className="icon-menu" alt=""/>
                                                        REPORTES
                                                    </div>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_REPORTES, location.hash, expanded);
                                            return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                { (permisos.reporte_recepciones && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/ingresos" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/ingresos')}>
                                                            RECEPCIONES
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                { (permisos.reporte_recepciones_anual && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/anual_ingresos" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/anual_ingresos')}>
                                                            RECEPCIONES ANUALES
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                { (permisos.reporte_liquidaciones && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/liquidaciones" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/liquidaciones')}>
                                                            LIQUIDACIONES
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                { permisos.reporte_costo_fletes && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/costo_fletes" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/costo_fletes')}>
                                                            COSTO FLETES
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                { permisos.reporte_drc_recepciones && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/drc_ingresos" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/drc_ingresos')}>
                                                            DRC RECEPCIONES
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                { permisos.reporte_kg_secos_recepciones && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/kg_secos_ingresos" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/kg_secos_ingresos')}>
                                                            KG. SECOS RECEPCIONES
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                { permisos.reporte_producciones && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/producciones" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/producciones')}>
                                                            PRODUCCIONES
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                { permisos.reporte_laboratorio && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/laboratorio" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/laboratorio')}>
                                                            LABORATORIO
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                { permisos.reporte_vs_drc && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item text-uppercase">
                                                        <NavLink to="/reporte/drc_lab_vs_drc_prod" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/drc_lab_vs_drc_prod')}>
                                                            DRC Compra VS DRC Lab
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                {/* { permisos.reporte_fluctuacion_precios && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/fluctuacion_precios" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/fluctuacion_precios')}>
                                                            FLUCTUACIÓN PRECIOS
                                                        </NavLink>
                                                    </div>
                                                ) } */}
                                                { permisos.reporte_excedente_amoniaco && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/excedente_amoniaco" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/excedente_amoniaco')}>
                                                            EXCEDENTE AMONIACO
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                { permisos.reporte_ubicacion_fincas && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/ubicacion_geografica_fincas" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/ubicacion_geografica_fincas')}>
                                                            UBICACIÓN DE FINCAS
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                { permisos.reporte_traslados_materia && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/traslados_materia" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/traslados_materia')}>
                                                            TRASLADOS DE MATERIA
                                                        </NavLink>
                                                    </div>
                                                ) }
                                                {/* REPORTES ADICIONALES */}
                                                { permisos.reporte_kg_secos_anual && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/kg_secos_anual" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/kg_secos_anual')}>
                                                            KG. SECOS ANUAL
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.reporte_drc_anual && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/drc_anual" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/drc_anual')}>
                                                            DRC ANUAL
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.reporte_liq_semanal && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/liquidacion_semanal" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/liquidacion_semanal')}>
                                                            LIQUIDACIÓN SEMANAL
                                                            </NavLink>
                                                    </div>
                                                )}
                                                { permisos.reporte_materia_prima_mensual && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/reporte_materia_prima_mensual" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/reporte_materia_prima_mensual')}>
                                                            MATERIA PRIMA MENSUAL
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.reporte_materia_prima_anual && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/reporte_materia_prima_anual" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/reporte_materia_prima_anual')}>
                                                            MATERIA PRIMA ANUAL
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.reporte_materia_prima_mensual && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/reporte_pila_almacen" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/reporte_pila_almacen')}>
                                                            PILAS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.catalogo_proveedores && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/catalogo_proveedores" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/catalogo_proveedores')}>
                                                            C.D. PROVEEDORES
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { (permisos.reporte_canal && proveedor === false && proveedor_admin === false) && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/canal" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/canal')}>
                                                            REPORTE CANAL
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.historialtanques && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/historial_tanques" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/historial_tanques')}>
                                                            HISTORIAL TANQUES
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.bitacora && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/bitacora" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/bitacora')}>
                                                            BITACORA
                                                        </NavLink>
                                                    </div>
                                                )}
                                            </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                        )}
                        {/* REPORTES DE PROVEEDORES */}
                        { (proveedor || proveedor_admin ) && (
                            <React.Fragment>
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_REPORTES, location.hash, expanded);
                                                        return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                            <img src={isSelected ? reportes_activo : reportes}
                                                                className="icon-menu" alt=""/>
                                                            REPORTES
                                                        </div>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_REPORTES, location.hash, expanded);
                                                return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/ingresos_proveedores" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/ingresos_proveedores')}>
                                                            RECEPCIONES
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/liquidaciones_proveedores" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/liquidaciones_proveedores')}>
                                                            LIQUIDACIONES
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/reporte/anual_proveedores" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/anual_proveedores')}>
                                                            ANUAL
                                                        </NavLink>
                                                    </div>
                                                    {/* <div className="sub-nav-item">
                                                        <NavLink to="/reporte/lab_proveedores" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('reporte/lab_proveedores')}>
                                                            LABORATORIO
                                                        </NavLink>
                                                    </div> */}
                                                </AccordionItemPanel>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItem>
                                </div>
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_LABORATORIO_PROVEEDORES, location.hash, expanded);
                                                        return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                            <img src={isSelected ? reportes_activo : reportes}
                                                                className="icon-menu" alt=""/>
                                                            LABORATORIO
                                                        </div>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_LABORATORIO_PROVEEDORES, location.hash, expanded);
                                                return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio_prov/lab_proveedores" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('laboratorio_prov/lab_proveedores')}>
                                                            LABORATORIO
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio_prov/nh3_drc" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('laboratorio_prov/nh3_drc')}>
                                                            NH3 VS DRC
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratorio_prov/nh3_vfa" exact className="nav-link " activeClassName={expanded ? 'active' : ''}
                                                                isActive={(match, location)=>location.pathname.includes('laboratorio_prov/nh3_vfa')}>
                                                            NH3 VS VFA
                                                        </NavLink>
                                                    </div>
                                                </AccordionItemPanel>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItem>
                                </div>
                            </React.Fragment>

                        )}
                        { (permisos.historico_recepciones || permisos.historico_liquidaciones && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_HISTORICO, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                        <img src={isSelected ? historico_activo : historico}
                                                            className="icon-menu" alt=""/>
                                                            HISTÓRICO
                                                    </div>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_HISTORICO, location.hash, expanded)
                                            return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                            {proveedor === false && proveedor_admin === false && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/historico/recepciones" exact className="nav-link" activeClassName={'active'}
                                                        isActive={(match, location) => location.pathname.includes('historico/recepciones')}>
                                                        RECEPCIONES
                                                    </NavLink>
                                                </div>
                                            )}
                                            {permisos.historico_liquidaciones && proveedor === false && proveedor_admin === false && (
                                                <div className="sub-nav-item">
                                                    <NavLink to="/historico/liquidaciones" exact className="nav-link" activeClassName={'active'}
                                                        isActive={(match, location) => location.pathname.match('historico/liquidaciones')}>
                                                        LIQUIDACIONES
                                                    </NavLink>
                                                </div>
                                            )}
                                            </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                        )}
                        { ((permisos.roles || permisos.usuarios || permisos.bancos || permisos.propietarios || permisos.perfiles_fincas || permisos.transportistas || permisos.vehiculos || permisos.pilotos || permisos.materia_prima || permisos.pagos || permisos.fincas || permisos.bodegas || permisos.contaminantes || permisos.configuracion) && proveedor === false && proveedor_admin === false) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_ADMINISTRACION, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                        <img src={isSelected ? configuracion_activo: configuracion}
                                                            className="icon-menu" alt=""/>
                                                        ADMINISTRACIÓN
                                                    </div>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_ADMINISTRACION, location.hash, expanded);
                                            return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                { permisos.roles  && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/roles" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('rol')}>
                                                            ROLES
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.usuarios && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/usuarios" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('usuario')}>
                                                            USUARIOS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.usuarios_proveedor && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/proveedores" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('proveedor')}>
                                                            PROVEEDORES
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.laboratoristas && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/laboratoristas" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('laboratoristas')}>
                                                            LABORATORISTAS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.clientes && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/clientes" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('clientes')}>
                                                            CLIENTES
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.bancos && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/bancos" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('banco')}>
                                                            BANCOS
                                                        </NavLink>
                                                    </div>
                                                )}

                                                { permisos.bancos && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/notificaciones" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('notificaciones')}>
                                                            NOTIFICACIONES
                                                        </NavLink>
                                                    </div>
                                                )}

                                                { permisos.propietarios && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/propietarios" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('propietario')}>
                                                            PROPIETARIOS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.perfiles_fincas && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/perfiles" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('perfil')}>
                                                            PERFILES DE FINCA
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.transportistas && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/transportistas" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('transportista')}>
                                                            TRANSPORTISTAS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.vehiculos && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/tipos_vehiculo" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>
                                                                    ['/tipos_vehiculo', '/tipo_vehiculo/crear'].includes(location.pathname)}>
                                                            TIPOS DE VEHÍCULOS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.vehiculos && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/vehiculos" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>
                                                                    ['/vehiculos', '/vehiculo/crear'].includes(location.pathname)}>
                                                            VEHÍCULOS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.pilotos && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/pilotos" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('piloto')}>
                                                            PILOTOS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.materia_prima && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/tipos_materia_prima" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>
                                                                    ['/tipos_materia_prima', '/tipo_materia_prima/crear'].includes(location.pathname)}>
                                                            TIPOS DE MATERIA PRIMA
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.materia_prima && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/materias_primas" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>
                                                                    ['/materias_primas', '/materia_prima/crear'].includes(location.pathname)}>
                                                            MATERIA PRIMA
                                                        </NavLink>
                                                    </div>

                                                )}
                                                { permisos.pagos && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/tipos_pago" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('pago')}>
                                                            TIPOS DE PAGO
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.acopios && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/acopios" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('acopio')}>
                                                            ACOPIOS
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.fincas && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/fincas" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('finca')}>
                                                            FINCAS
                                                        </NavLink>
                                                    </div>
                                                )}

                                                { permisos.bodegas && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/bodegas" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('bodega')}>
                                                            ALMACÉN/PILA
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.contaminantes && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/contaminantes" exact className="nav-link " activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('contaminante')}>
                                                            CONTAMINANTES
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.anulacion && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/anulacion" exact className="nav-link" activeClassName={'active'}
                                                                isActive={(match, location)=>location.pathname.includes('anulacion')}>
                                                            ANULACIÓN DE RECEPCIONES
                                                        </NavLink>
                                                    </div>
                                                )}
                                                { permisos.configuracion && proveedor === false && proveedor_admin === false && (
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/configuracion" exact className="nav-link" activeClassName='active'>
                                                            CONFIGURACIÓN
                                                        </NavLink>
                                                    </div>
                                                )}

                                            </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                        )}
                    </Accordion>
                </div>
            </aside>
        )
    }
}

export default SideBar;
