export const generos = [
    {value: 10, label: "Hombre"},
    {value: 20, label: "Mujer"}
];
export const regimenes = [
    {value: 10, label: "Pequeño Contribuyente"},
    {value: 20, label: "Factura Especial"},
    {value: 30, label: "Pagos Trimestrales"},
    {value: 40, label: "Pagos Directos"},
    {value: 50, label: "Exportador"},
    {value: 60, label: "Producción Interna"},
];
export const tipos_bodega = [
    {value: 10, label: "Almacén"},
    {value: 20, label: "Pila"},
    {value: 30, label: "Tanque Producción"},
    {value: 40, label: "Tanque Maduración"}
];
export const estado_ingreso = {
    10: "Registro",
    20: "Laboratorio",
    30: "Vaciado",
    25: "Validado Laboratorio",
    35: "Anulado",
    40: "Finalizado",
    50: "A Coagulación",
    60: "Rechazado",
    100: "En orden de compra",
    110: "Liquidado"
};

export const unidades_medida = [
    {value: 1, label: "Kilogramo"},
    {value: 45.3597, label: "Quintal"},
    {value: 0.453597, label: "Libra"},
    {value: 3.7093, label: "Galon"},
    {value: 0.98, label: "Litro"}
];

export const tipoUsoEmail = [
    {value: 10, label: "RECEPCION_ERSA"},
];

export const nivel_contaminacion = [
    {value: 10, label: "Bajo"},
    {value: 20, label: "Medio"},
    {value: 30, label: "Alto"}
];

export const tipo_bd = [
    {value: 'LOCAL', label: "Local"},
    {value: 'EXPORTADOR', label: "Exportador"},
];

export const roles = [
    {value: 10, label: "Administrador"},
    {value: 20, label: "Laboratorista"},
    {value: 30, label: "Encargado de Compras"},
    {value: 40, label: "Encargado de liquidaciones"},
];

export const tipos_medida_finca = [
    {value: 10, label: "cuerda(s)"},
    {value: 20, label: "manzana(s)"},
    {value: 30, label: "caballería(s)"},
    {value: 40, label: "hectárea(s)"},
];

// Constantes para utilizar en la Modal del cambio de DRC
export const DRC_ANTERIOR = 10;
export const DRC_PROMEDIO = 20;
export const LABORATORIO = 30;
export const DEFINIDO = 40;
export const INSUMOS = 100;

// Variables para utilizar en la decisiones entre LATEX o SOLIDO
export const LATEX = 10;
export const SOLIDO = 20;

export const TIPO_COSTO_FLETE = [
    {value: LATEX, label: "LATEX"},
    {value: SOLIDO, label: "SOLIDO"},
];


// Tipos de precios para las fincas
export const PRECIO_INTROSA = 10;
export const PRECIO_ERSA = 20;
export const PRECIO_DEFINIDO = 30;

// Regimenes de finca
export const PEQUEÑO_CONTRIBUYENTE = 10;
export const FACTURA_ESPECIAL = 20;
export const PAGOS_TRIMESTRALES = 30;
export const PAGOS_DIRECTOS = 40;
export const EXPORTADOR = 50;
export const PRODUCCION_INTERNA=60;

// Variables para identificar el estado de los Ingresos
export const INGRESO_ANULADO = 35;
export const INGRESO_FINALIZADO = 40;
export const A_CANAL_COAGULACION = 55;
export const INGRESO_EN_ORDEN_COMPRA = 100;
export const INGRESO_LIQUIDADO = 110;

// Variables para identificar el estado de los Análisis de laboratorio
export const ANALISIS_ESTADO_RECHAZADO = 50;

// Tipos de costo para fletes
/**
 * Indica que el tipo_costo_flete aún no ha sido definido.
 */
export const FLETE_COSTO_NO_DEFINIDO = 0;
/**
 * El costo_flete asociado es por el total del flete y se define en quetzales.
 * Indica que el vehículo asociado a la recepción tiene asignado un transportista, por lo que el costo_flete
 * corre a cuenta de 'ERSA'.
 * Este tipo de costo también se conoce como 'AJENO AL PRODUCTOR' o de 'ERSA'.
 */
export const FLETE_EXTERNO_COSTO_FIJO = 10;
/**
 * El costo_flete asociado es por cada kg_seco y se define en quetzales.
 * Indica que el vehículo asociado a la recepción tiene asignado un trasportista, por lo que el costo_flete
 * corre a cuenta de 'ERSA'.
 * Este tipo de costo también se conoce como 'AJENO AL PRODUCTOR'.
 */
export const FLETE_EXTERNO_COSTO_POR_KG = 20;
/**
 * El costo_flete asociado es por cada kg_seco y se define en dólares (se ha llegado a un acuerdo con la finca
 * para definir la tarifa por cada kg_seco).
 * Indica que el vehículo asociado a la recepción no tiene asignado un transportista, por lo que el costo_flete
 * corre a cuenta de la 'FINCA'.
 * Este tipo de costo también se conoce como 'PROPIO DEL PRODUCTOR'.
 */
export const FLETE_INTERNO_COSTO_POR_KG = 30;

// Tipos de Bodegas
export const BODEGA_ALMACEN = 10;
export const BODEGA_PILA = 20;
export const BODEGA_TANQUE_PRODUCCION = 30;
export const BODEGA_TANQUE_MADURACION = 40;

// Tamaño de paginación definido en la API
export const PAGINATION_SIZE_PER_PAGE = 10;

// Constantes para la evaluacion del paso de producción.
export const REGISTRO = 10;
export const ALMACEN = 20;
export const CIERRE = 30;

// Constante del excedente de amoniaco
export const COSTO_AMONIACO = 8
export const EVAPORACION = 2
export const NH3_IDEAL = 0.5

// Constantes de analisis de laboratorio
export const DRC = 10;
export const TSC = 20;
export const VFA = 30;
export const NH3 = 40;

export const analisis_laboratorio = [
    {value: DRC, label: "Analisis de DRC"},
    {value: TSC, label: "Analisis de TSC"},
    {value: VFA, label: "Analisis de VFA"},
    {value: NH3, label: "Analisis de NH3"},
];

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCteOPje5Zw_L8NHEG4IIKEdil16akdyTk';

export const meses = [
    {value: 1, label: "Enero"},
    {value: 2, label: "Febrero"},
    {value: 3, label: "Marzo"},
    {value: 4, label: "Abril"},
    {value: 5, label: "Mayo"},
    {value: 6, label: "Junio"},
    {value: 7, label: "Julio"},
    {value: 8, label: "Agosto"},
    {value: 9, label: "Septiembre"},
    {value: 10, label: "Octubre"},
    {value: 11, label: "Noviembre"},
    {value: 12, label: "Diciembre"},
];

// export const ANIO_INICIAL = 2020;
export const ANIO_INICIAL = 2015;

// Tipos de reportes para la creacion de documentos Excel
export const KG_SECOS_REPORTES = 10;
export const DRC_PROMEDIO_REPORTE = 20;

// Constantes para el grupo de tipo de pago
export const tipo_pago = [
    {value: 10, label: "Quetzales"},
    {value: 20, label: "Dolares"},
    {value: 30, label: "Trading"}
];

export const tipos_laboratorio = [
    {value: 10, label: 'Laboratorio de Látex'},
    {value: 20, label: 'Laboratorio de Sólidos'},
    {value: 30, label: 'Laboratorio de Pilas'},
]

// Constantes para el estado de los despachos
export const EN_CURSO = 10;
export const FINALIZADO = 20;

// Constantes de límites para los reportes de NH3 vs VFA y DRC
export const NH3_MINIMO = 0.40
export const NH3_MAXIMO = 0.50
export const VFA_MAXIMO = 0.060

// Constantes para reporte anual de recepciones
export const DRC_REPORTE_ANUAL = 10
export const KG_REPORTE_ANUAL = 20
export const reporte_anual = [
    {value: DRC_REPORTE_ANUAL, label: "DRC Promedio"},
    {value: KG_REPORTE_ANUAL, label: 'Kilogramo'}
]