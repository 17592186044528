import React, { Component, useState } from 'react';
import Select from 'react-select';
import Card from '../../../Utils/Cards/Card';
import AnulacionForm from './AnulacionForm';
import Swal from 'sweetalert2';
import LoadMask from '../../../Utils/LoadMask/LoadMask';

class Anulacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anulacion: '',
            open: false,
        }
    }

    componentDidMount() {
        const { listarRecepciones } = this.props;
        listarRecepciones();
    }

    anular(anulacion) {
        const { anularRecepcion, data } = this.props;
        if(anulacion != '') {
            const SwalMod = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-dark mr-5',
                    content: 'd-flex flex-column justify-content-center mb-4',
                    title: 'verde-secundario',
                },
                buttonsStyling: false,
                focusConfirm: false,
                focusCancel: false,
            })
            SwalMod.fire({
                title: '¿Está seguro que desea anular?',
                text: 'Esta recepción puede tener prducto en almacenes y/o pilas, si continúa con esta acción se hará un reajuste en almacén y/o pilas correspondientes',
                input: 'text',
                inputPlaceholder: 'Ingrese una justificación de anulación',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Anular',
                cancelButtonText: 'Cancelar',
                reverseButtons: true,
            }).then(result => {
                if (result.value && result.value != "") {
                    anularRecepcion(this.state.anulacion.value, data, result.value);
                }
                else if(result.value && result.value == "") {
                    Swal.fire('Debe ingresar una justificación', '', 'error');
                }
            })
        }
    }

    closeModal = () => {
        this.setState({open: false})
    }

    render() {
        const { recepciones, getRecepcion, data, loader } = this.props;
        return (
            <LoadMask
                loading={loader}
                blur
            >
                <div className="mb-4 col-12">
                    {/* <AnulacionModal open={this.state.open} closeModal={this.closeModal} recepcion={this.state.anulacion} handleSubmit={this.anular}/> */}
                    <Card
                        icon="configuraciones_activo"
                        titulo="ANULACIÓN DE RECEPCIÓN"
                        subtitulo=""
                        noBorder
                    >
                        <div>
                            <p className="m-0 p-0"># de Recepción</p>
                            <div className="d-flex">
                                <Select
                                    className="col-lg-2 col-4 col-sm-3 col-md-3 m-0 p-0"
                                    backspaceRemovesValue={false}
                                    isSearchable={true}
                                    options={recepciones}
                                    onChange={(e) => {
                                        this.setState({anulacion: e});
                                        getRecepcion(e.value);
                                    }}
                                />
                                <button
                                    type="submit"
                                    className="btn btn-danger ml-3"
                                    style={{width: '150px'}}
                                    onClick={() => {this.anular(this.state.anulacion)}}
                                    // onClick={() => this.setState({open: true})}
                                    disabled={this.state.anulacion ? false : true}
                                >
                                    Anular
                                </button>
                            </div>
                        </div>
                        <section className="d-flex justify-content-center mt-4">
                            <div 
                                className="card p-3"
                                style={{width: '90%'}}
                            >
                                <div className="d-flex justify-content-between mx-5">
                                    <h1>Recepción</h1>
                                    <h2>Recepción ID: {this.state.anulacion.label}</h2>
                                </div>
                                <AnulacionForm
                                    recepcion={data}
                                />
                            </div>
                        </section>
                    </Card>
                </div>
            </LoadMask>
        )
    }
}

export default Anulacion;