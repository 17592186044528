import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderNumberKG, renderTextArea } from '../../../Utils/renderField/renderField';
import AnulacionTableProductos from './AnulacionTableProductos';
import { estado_ingreso } from '../../../../../utility/constants';

class AnulacionForm extends Component {
    getEstadoIngreso(estado) {
        return estado_ingreso[estado];
    }
    
    render() {
        const { recepcion, handleSubmit } = this.props;
        return (
            <div className="mx-5 mt-3">
                <h5>Datos generales de recepción</h5>
                <form onSubmit={handleSubmit} className='d-flex flex-wrap'>
                    <div className="col-6 mt-3">
                        <label htmlFor="finca">FINCA</label>
                        <Field
                            name="finca"
                            component={renderField}
                            className="form-control"
                            input={{
                                value: recepcion.finca
                            }}
                            disabled={true}
                        />
                    </div>
                    <div className="col-6 mt-3">
                        <label htmlFor="piloto">PILOTO</label>
                        <Field
                            name="piloto"
                            component={renderField}
                            className="form-control"
                            input={{
                                value: recepcion.piloto
                            }}
                            disabled={true}
                        />
                    </div>
                    <div className="col-6 mt-3">
                        <label htmlFor="vehiculo">VEHÍCULO</label>
                        <Field
                            name="vehiculo"
                            component={renderField}
                            className="form-control"
                            input={{
                                value: recepcion.vehiculo
                            }}
                            disabled={true}
                        />
                    </div>
                    <div className="col-6 mt-3">
                        <label htmlFor="materia_prima">TIPO DE MATERIA PRIMA</label>
                        <Field
                            name="materia_prima"
                            component={renderField}
                            className="form-control"
                            input={{
                                value: recepcion.materia_prima
                            }}
                            disabled={true}
                        />
                    </div>
                    <div className="col-6 mt-3">
                        <label htmlFor="peso">PESO DE INGRESO</label>
                        <Field
                            name="peso"
                            component={renderNumberKG}
                            className="form-control"
                            input={{
                                value: recepcion.peso
                            }}
                            disabled={true}
                        />
                    </div>
                    <div className="col-6 mt-3">
                        <label htmlFor="recepcion">LUGAR DE RECEPCION</label>
                        <Field
                            name="recepcion"
                            component={renderField}
                            className="form-control"
                            input={{
                                value: recepcion.recepcion
                            }}
                            disabled={true}
                        />
                    </div>
                    <div className="col-6 mt-3">
                        <label htmlFor="estado">ESTADO DE LA RECEPCION</label>
                        <Field
                            name="estado_ingreso"
                            component={renderField}
                            className="form-control"
                            input={{
                                value: recepcion.estado_ingreso ? this.getEstadoIngreso(recepcion.estado_ingreso) : ''
                            }}
                            disabled={true}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <label htmlFor="observaciones">OBSERVACIONES</label>
                        <Field
                            name="observaciones"
                            component={renderTextArea}
                            className="form-control"
                            input={{
                                value: recepcion.observaciones
                            }}
                            disabled={true}
                        />
                    </div>
                </form>
                <AnulacionTableProductos data={recepcion.productos}/>
            </div>
        )
    }
}

export default reduxForm({
    form: 'anulacionForm'
})(AnulacionForm);