import React, { Component } from 'react'
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import Card from '../../../Utils/Cards/Card'
import DespachoEditarForm from './DespachoEditarForm';

class DespachoEditar extends Component {
    state = {
        peso_manual: true
    }

    componentDidMount() {
        this.props.clearItem()
        this.props.leer(this.props.match.params.id)
        const me = this.props.me
        if (me) {
            if (me.acopio) {
                if (me.peso_ingreso_manual_acopio === false) {
                    this.interval = setInterval(() => this.props.pesosBascula(), 500)
                    this.setState({peso_manual: false});
                }
            } else {
                if (me.peso_ingreso_manual === false) {
                    this.interval = setInterval(() => this.props.pesosBascula(), 500)
                    this.setState({peso_manual: false});
                }
            }
        }
    }

    actualizar = data => {
        this.props.editar(this.props.match.params.id, data)
    }
    
    render() {
        const { loader, item } = this.props
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="despachos_activo"
                    titulo="DESPACHOS"
                    subtitulo=""
                    noBorder
                >
                    <LoadMask
                        loading={loader}
                        blur
                    >
                        <section className="d-flex justify-content-center mt-4">
                            <div
                                className="card p-3"
                                style={{width: '100%'}}
                            >
                                <DespachoEditarForm
                                    onSubmit={this.actualizar}
                                    peso_manual={this.state.peso_manual}
                                    item={item}
                                    {...this.props}
                                />
                            </div>
                        </section>
                    </LoadMask>
                </Card>
            </div>
        )
    }
}

export default DespachoEditar