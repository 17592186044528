import React, { Component } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
    renderField,
    SelectField,
    AsyncSelectField,
    renderDatePicker,
    renderTimeField,
    renderNumberKG,
    renderNumber
} from '../../../Utils/renderField/renderField';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { api } from '../../../../../utility/api';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Alerta from '../../../Utils/Alerta/Alerta';
import { connect } from 'react-redux'
import { FINALIZADO } from '../../../../../utility/constants';
import moment from 'moment';

const getClientes = search => {
    return api.get('cliente', {search}).then(data => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getProductos = search => {
    return api.get('materia_prima', {search}).then(data => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getPilotos = search => {
    return api.get('piloto', {search}).then(data => {
        if(data) return data.results
        return []
    }).catch(() => {
        return []
    })
}

class DespachoForm extends Component {
    state = {
        disabled: false,
        cliente: 0,
        peso_tara_equipo: 0,
        peso_tara_empaque: 0,
        peso_bruto_despacho: 0,
        peso_neto: 0,
    }

    render() {
        const { handleSubmit, finalizar } = this.props;
        const { disabled, viendo, creando, item, peso_manual, peso, configuraciones } = this.props;
        const finalizado = item.estado == FINALIZADO
        return (
            <form onSubmit={handleSubmit} className="container">
                <h4>DATOS GENERALES DE DESPACHO</h4>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="fecha">FECHA</label>
                        </div>
                        <div className="col-md-12 form-group">
                            {
                                finalizado ?
                                    <span className="ml-3">{moment(item.fecha).format('DD/MM/YYYY')}</span>
                                :
                                    <Field
                                        name="fecha"
                                        component={renderDatePicker}
                                        disabled={disabled}
                                    />
                            }
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="hora">HORA</label>
                        </div>
                        <div className="col-md-12 form-group">
                            {
                                finalizado ?
                                    <span className="ml-3">{moment(item.hora, 'HH:mm:ss').format('HH:mm A')}</span>
                                :
                                    <Field
                                    name="hora"
                                    component={renderTimeField}
                                    disabled={disabled}
                                    />
                            }
                        </div>
                    </div>
                </section>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="cliente">CLIENTE</label>
                        </div>
                        <div className="col-md-12 form-group">
                            {
                                finalizado ?
                                    <span className="ml-3">{item.cliente.nombre}</span>
                                :
                                    <Field
                                        valueKey="id"
                                        name="cliente"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getClientes}
                                        onChange={e => {
                                            this.setState({cliente: e.id})
                                        }}
                                        disabled={disabled}
                                    />
                            }
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="piloto">PILOTO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            {
                                finalizado ?
                                    <span className="ml-3">{item.piloto.nombre}</span>
                                :
                                    <Field
                                        valueKey="id"
                                        name="piloto"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getPilotos}
                                        disabled={disabled}
                                    />
                            }
                        </div>
                    </div>
                </section>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="producto">PRODUCTO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            {
                                finalizado ?
                                    <span className="ml-3">{item.producto.nombre}</span>
                                :
                                    <Field
                                        valueKey="id"
                                        name="producto"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getProductos}
                                        disabled={disabled}
                                    />
                            }
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="no_placa_cabezal">NO. PLACA DE CABEZAL</label>
                        </div>
                        <div className="col-md-12 form-group">
                            {
                                finalizado ?
                                    <span className="ml-3">{item.no_placa_cabezal}</span>
                                :
                                    <Field
                                        name="no_placa_cabezal"
                                        component={renderField}
                                        disabled={disabled}
                                    />
                            }
                        </div>
                    </div>
                </section>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="codigo_contenedor">CÓDIGO DE CONTENEDOR</label>
                        </div>
                        <div className="col-md-12 form-group">
                            {
                                finalizado ?
                                    <span className="ml-3">{item.codigo_contenedor}</span>
                                :
                                    <Field
                                        name="codigo_contenedor"
                                        component={renderField}
                                        disabled={disabled}
                                    />
                            }
                        </div>
                    </div>
                </section>
                {
                    finalizado ?
                        <section className="d-flex">
                            <div className="col-md-6 p-0">
                                <div className="col-12 pt-2">
                                    {item.peso_tara_empaque == 0 ?
                                        <label htmlFor="peso_tara_equipo">PESO DE ENTRADA DEL EQUIPO</label>
                                    :
                                        <label htmlFor="peso_tara_equipo">PESO DE ENTRADA DEL EQUIPO (SE INCLUYE EL PESO DEL EMPAQUE)</label>
                                    }
                                </div>
                                <div className="col-md-12 form-group">
                                    <span className="ml-3">{item.peso_tara_equipo + item.peso_tara_empaque} kg</span>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-12 pt-2">
                                    <label htmlFor="peso_bruto_despacho">PESO DE SALIDA DEL EQUIPO</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <span className="ml-3">{item.peso_bruto_despacho} kg</span>
                                </div>
                            </div>
                        </section>
                    :
                        <section className="d-flex">
                            <div className="col-md-6 p-0">
                                <CardEmpty noShadow noPadding>
                                    <div className="col-12 pt-2">
                                        <label htmlFor="peso_tara_equipo">PESO DE ENTRADA DEL EQUIPO</label>
                                    </div>
                                    <div className="col-md-12 form-group">
                                    {peso_manual === false && (
                                        <Field name="peso_tara_equipo" type="text" component={renderNumber} decimalScale={0}
                                            suffix="Kg"
                                            disabled={!peso_manual}
                                            input={{value: peso}}
                                            onChange={() => {
                                                this.setState({paeso_tara_equipo: parseInt(peso)})
                                            }}
                                        />
                                    )}
                                    {peso_manual === true && (
                                        <Field name="peso_tara_equipo" type="text" component={renderNumber} decimalScale={0}
                                            suffix=" Kg" parse={(value) => parseFloat(value)}
                                            disabled={viendo || disabled ? true : false} onChange={value => {
                                                let numero = "0";
                                                Object.entries(value).forEach(([key, value]) => {
                                                    if (typeof(value) === "string") {
                                                        numero = numero + value;
                                                    }
                                                })
                                                this.setState({peso_tara_equipo: parseInt(numero)});
                                            }} />
                                    )}
                                    </div>
                                </CardEmpty>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 mt-4 form-group d-flex align-content-center justify-content-center">
                                    <Alerta texto={
                                        configuraciones.peso_ingreso_manual
                                            ? 'El peso se está tomando manualmente. Para tomarlo de báscula vaya a Configuración.'
                                            : 'El peso se está tomando automaticamente de Báscula. Para ingresarlo manualmente se debe ir a Configuración.'
                                        }
                                    />
                                </div>
                            </div>
                        </section>
                }
                {
                    finalizado ?
                        <React.Fragment/>
                    :
                        <section className="d-flex">
                            <div className="col-md-6 p-0">
                                <div className="col-12 pt-2">
                                    <label htmlFor="peso_tara_empaque">PESO TARA DE EMPAQUE (opcional)</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field name="peso_tara_empaque" type="text" component={renderNumber} decimalScale={0}
                                        suffix="Kg"
                                        disabled={viendo || disabled ? true : false}
                                        parse={value => parseFloat(value)}
                                        onChange={value => {
                                            let numero = "0";
                                            Object.entries(value).forEach(([key, value]) => {
                                                if (typeof(value) === "string") {
                                                    numero = numero + value;
                                                }
                                            })
                                            this.setState({peso_tara_empaque: parseInt(numero)});
                                        }}
                                    />
                                </div>
                            </div>
                        </section>
                }
                {
                    finalizado ?
                        <section className="d-flex">
                            <div className="col-md-6 p-0">
                                <div className="col-12 pt-2">
                                    <label htmlFor="peso_neto">PESO NETO</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <span className="ml-3">{item.peso_neto} kg</span>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-12 pt-2">
                                    <label htmlFor="lote">LOTE</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <span className="ml-3">{item.lote}</span>
                                </div>
                            </div>
                        </section>
                    :
                        <section className="d-flex mt-3">
                            <div className="col-md-6 p-0">
                                <CardEmpty noShadow noPadding>
                                    <div className="col-12 pt-2">
                                        <label htmlFor="peso_bruto_despacho">PESO DE SALIDA DEL EQUIPO</label>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <Field name="peso_bruto_despacho" type="text" component={renderNumber} decimalScale={0}
                                            suffix="Kg"
                                            disabled={true}
                                        />
                                    </div>
                                </CardEmpty>
                            </div>
                        </section>
                }
                {
                    finalizado ?
                        <section className="d-flex">
                            <div className="col-md6 p-0">
                                <div className="col-12 pt-2">
                                    <label htmlFor="carta_porte">NO. DE CARTA DE PORTE</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <span className="ml-3">{item.carta_porte}</span>
                                </div>
                            </div>
                        </section>
                    :
                        <section className="d-flex mt-2">
                            <div className="col-md-6 p-0">
                                <div className="col-12">
                                    <label htmlFor="peso_neto">PESO NETO</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    {
                                        viendo == true &&
                                            <Field
                                                name="peso_neto"
                                                component={renderNumberKG}
                                                disabled={disabled}
                                            />
                                    }
                                    {
                                        creando == true &&
                                            <Field
                                                name="peso_neto"
                                                component={renderNumberKG}
                                                disabled={true}
                                                input={{value: this.state.peso_bruto_despacho - this.state.peso_tara_equipo - this.state.peso_tara_empaque}}
                                            />
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-12">
                                    <label htmlFor="lote">LOTE</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="lote"
                                        component={renderField}
                                        disabled={ creando || disabled || viendo ? true: false }
                                    />
                                </div>
                            </div>
                        </section>
                }
                {
                    finalizado ?
                        ''
                    :
                        <section className="d-flex">
                            <div className="col-md-6 p-0">
                            <div className="col-12">
                                    <label htmlFor="carta_porte">N&#9900; DE CARTA DE PORTE</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="carta_porte"
                                        component={renderField}
                                        disabled={ creando || disabled || viendo ? true : false }
                                    />
                                </div>
                            </div>
                        </section>
                }
                {
                    finalizado ?
                        <div className="buttons-box" style={{marginTop: '10%'}}>
                            <Link className="btn btn-outline-dark mr-5" to="/despacho">REGRESAR</Link>
                            <Link className="btn btn-secondary" to={`/despacho/${item.id}/boleta`}>IMPRIMIR BOLETA</Link>
                        </div>
                    :
                        <div className="buttons-box" style={{marginTop: '10%'}}>
                            <Link className="btn btn-outline-dark mr-5" to="/despacho">CANCELAR</Link>
                            <button type="submit" className="btn btn-primary mr-5" disabled={disabled}>GUARDAR</button>
                            <button type="submit" className="btn btn-secondary" disabled={(viendo) ? false : true} onClick={handleSubmit(finalizar)}>FINALIZAR PEDIDO</button>
                        </div>
                }
            </form>
        )
    }
}

DespachoForm = reduxForm({
    form: 'despachoForm',
    validate: data => {
        return validate(data, {
            fecha: validators.exists()('Campo requerido'),
            hora: validators.exists()('Campo requerido'),
            cliente: validators.exists()('Campo requerido'),
            piloto: validators.exists()('Campo requerido'),
            producto: validators.exists()('Campo requerido'),
            no_placa_cabezal: validators.exists()('Campo requerido'),
            codigo_contenedor: validators.exists()('Campo requerido'),
            peso_tara_equipo: validators.exists()('Campo requerido'),
        });
    }
})(DespachoForm);

const selector = formValueSelector('despachoForm')
DespachoForm = connect(state => {
    const form = state.form.despachoForm;
    const peso_bascula = state.despachos;
    let peso = 0
    console.log("Item en vista:", state.despachos.item)

    function getNumbersInString(string) {
        var tmp = string.split("")
        var map = tmp.map(current => {
            if (!isNaN(parseInt(current))) {
                return current
            }
        })
        var numbers = map.filter(value => {
            return value != undefined
        })
        return numbers.join("")
    }

    if (peso_bascula.peso_bascula) {
        if (peso_bascula.peso_bascula) {
            peso = getNumbersInString(peso_bascula.peso_bascula)
            if (form && form.values) {
                form.values.peso_tara_equipo = peso
            }
        }
    }


    let peso_neto = 0;

    if (form && form.values) {
        const values = form.values;
        // if (peso == 0) {
        //     peso_neto = peso - values.peso_tara_empaque
        // }
        // else {
        //     peso_neto = values.peso_bruto_despacho - values.peso_tara_equipo - values.peso_tara_empaque
        // }
        peso_neto = values.peso_bruto_despacho - values.peso_tara_equipo - values.peso_tara_empaque
        values.peso_neto = peso_neto
    }

    return {
        peso,
        peso_neto
    }
})(DespachoForm)

export default DespachoForm