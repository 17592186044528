import React from 'react';
import moment from 'moment/moment';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Grid from '../../../Utils/Grid';
import { RenderKilogramo, RenderCurrency, RenderNumber, RenderDollar, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import { PrintContainer, actions as printActions } from 'Utils/Print';

const Resumen = (props) => {
    const {loader, data, filtro_semanas_reporte_liq_semanal, filtro_anio_reporte_liq_semanal} = props;
    // Constante con la data
    const data_quetzales = data && data.Quetzales ? data.Quetzales : [];
    const data_dolares = data && data.Dolares ? data.Dolares : [];
    const data_trading = data && data.Trading ? data.Trading : [];
    // Totales
    const total_quetzales = data && data.total_quetzales ? data.total_quetzales : [];
    const total_dolares = data && data.total_dolares ? data.total_dolares : [];
    const total_trading = data && data.total_trading ? data.total_trading : [];
    const total_general = data && data.total_general ? data.total_general : [];

    return (
        <div>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <PrintContainer name='reporte-liq-semanal'>
                        <div className="no-mostrar text-center">
                            <h2 className="azul">REPORTE LIQUIDACIÓN SEMANAL CONSOLIDADO</h2>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center">
                            <h6 className="mb-1 mt-1 mr-5 bold">{"Fecha inicio: " + moment().year(filtro_anio_reporte_liq_semanal.value).isoWeekday(7).week(filtro_semanas_reporte_liq_semanal.value-1).format("YYYY-MM-DD")}</h6>
                            <h6 className="mb-1 mt-1 bold">{"Fecha fin: " + moment().year(filtro_anio_reporte_liq_semanal.value).isoWeekday(6).week(filtro_semanas_reporte_liq_semanal.value).format("YYYY-MM-DD")}</h6>
                        </div>
                        <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla impresion-horizontal mt-3">
                            <h5 className="azul bold ml-3">DOLARES</h5>
                            <Grid hover striped loading={loader} data={{ "results": data_dolares }}
                                pagination={false}
                                className="pt-3"
                            >
                                <TableHeaderColumn
                                    dataField="nombre"
                                    dataSort
                                    isKey
                                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                                    width="17"
                                >
                                    TIPO DE PAGO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="recuento"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => cell ? "#"+cell : "#"+0}
                                    width="15"
                                >
                                    RECUENTO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="total_mp"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    TOTAL MP
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="impuestos"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    IMPUESTOS
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="insumos"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    INSUMOS
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="total_liquidar"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    TOTAL LIQUIDAR
                                </TableHeaderColumn>
                            </Grid>
                            <div className="total-final pb-4">
                                <div className="react-bs-table">
                                    <div className="react-bs-container-header table-header-wrapper">
                                        <table className="table table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "17%" }} className="text-left">TOTAL PRODUCCIÓN</th>
                                                    <th style={{ width: "15%" }} className="text-right">{"#"+total_dolares.recuento}</th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_dolares.total_mp}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_dolares.impuestos}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_dolares.insumos}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_dolares.total_liquidar}/></th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla impresion-horizontal mt-4">
                            <h5 className="azul bold ml-3">QUETZALES</h5>
                            <Grid hover striped loading={loader} data={{ "results": data_quetzales }}
                                pagination={false}
                                className="pt-3"
                            >
                                <TableHeaderColumn
                                    dataField="nombre"
                                    dataSort
                                    isKey
                                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                                    width="17"
                                >
                                    TIPO DE PAGO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="recuento"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => cell ? "#"+cell : "#"+0}
                                    width="15"
                                >
                                    RECUENTO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="total_mp"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    TOTAL MP
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="impuestos"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    IMPUESTOS
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="insumos"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    INSUMOS
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="total_liquidar"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    TOTAL LIQUIDAR
                                </TableHeaderColumn>
                            </Grid>
                            <div className="total-final pb-4">
                                <div className="react-bs-table">
                                    <div className="react-bs-container-header table-header-wrapper">
                                        <table className="table table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "17%" }} className="text-left">TOTAL PRODUCCIÓN</th>
                                                    <th style={{ width: "15%" }} className="text-right">{"#"+total_quetzales.recuento}</th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_quetzales.total_mp}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_quetzales.impuestos}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_quetzales.insumos}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_quetzales.total_liquidar}/></th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla impresion-horizontal mt-4">
                            <h5 className="azul bold ml-3">TRADING</h5>
                            <Grid hover striped loading={loader} data={{ "results": data_trading }}
                                pagination={false}
                                className="pt-3"
                            >
                                <TableHeaderColumn
                                    dataField="nombre"
                                    dataSort
                                    isKey
                                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                                    width="17"
                                >
                                    TIPO DE PAGO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="recuento"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => cell ? "#"+cell : "#"+0}
                                    width="15"
                                >
                                    RECUENTO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="total_mp"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    TOTAL MP
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="impuestos"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    IMPUESTOS
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="insumos"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    INSUMOS
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="total_liquidar"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                    width="17"
                                >
                                    TOTAL LIQUIDAR
                                </TableHeaderColumn>
                            </Grid>
                            <div className="total-final-trading">
                                <div className="react-bs-table">
                                    <div className="react-bs-container-header table-header-wrapper">
                                        <table className="table table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "17%" }} className="text-left">TOTAL PRODUCCIÓN</th>
                                                    <th style={{ width: "15%" }} className="text-right">{"#"+total_trading.recuento}</th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_trading.total_mp}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_trading.impuestos}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_trading.insumos}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_trading.total_liquidar}/></th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="total-final fondo-blanco pb-4">
                                <div className="react-bs-table">
                                    <div className="react-bs-container-header table-header-wrapper">
                                        <table className="table table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "17%" }} className="text-left">TOTAL GENERAL</th>
                                                    <th style={{ width: "15%" }} className="text-right">{"#"+total_general.recuento}</th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_general.total_mp}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_general.impuestos}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_general.insumos}/></th>
                                                    <th style={{ width: "17%" }} className="text-right"><RenderCurrency value={total_general.total_liquidar}/></th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PrintContainer>
                </CardEmpty>

            </div>
        </div>
    )
};

export default Resumen;
