import React from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import {TableHeaderColumn} from 'react-bootstrap-table';
import moment from 'moment';
import _ from 'lodash';
import { Legend, ResponsiveContainer, RadialBarChart, RadialBar } from 'recharts';
import Grid from '../../../Utils/Grid';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderKilogramo, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';

export const renderLegend = (props) => {
    const { title, label } = props;
    return (
        <div className="d-flex flex-column align-items-center">
            <h4 className="gris mb-0">{title}</h4>
            <h6 className="gris">{label}</h6>
        </div>
    );
}
export const renderLegendTotales = (props) => {
    const { payload } = props;
    return (
        <div className="">
            <div className="d-flex flex-column align-items-center">
                <ul className="recharts-default-legend pl-0">
                    { payload.map((item, index) => {
                        if (index !== 0) {
                            return <li key={index} className={`recharts-legend-item legend-item-1 mb-2`} style={{display: 'block'}}>
                                <div className="d-flex align-items-center mt-4">
                                    <svg className="recharts-surface" width="12" height="12" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
                                        <path fill={item.payload.fill} cx="12" cy="12" type="circle" className="recharts-symbols" transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
                                    </svg>
                                    <h3 className="recharts-legend-item-text gris mb-0 ml-2">{item.payload.title}</h3>
                                </div>
                            </li>
                        }
                    }) }
                </ul>
            </div>
        </div>
    );
}
export const renderLegendTipos = (props) => {
    const { payload, title } = props;
    return (
        <div className="">
            <div className="d-flex flex-column align-items-center">
            <span className="mb-2 bold">{title}</span>
                <ul className="recharts-default-legend pl-0">
                    { payload.map((item, index) => {
                        if (item.payload.label !== 'NO_RENDER') {
                            return <li key={item.label} className={`recharts-legend-item legend-item-1 mb-2`} style={{display: 'block'}}>
                                <div className="d-flex align-items-center mt-3">
                                    <svg className="recharts-surface" width="16" height="16" viewBox="0 0 32 32" version="1.1" style={{display: 'inline-block', verticalAlign: 'middle', marginRight: '4px'}}>
                                        <path fill={item.payload.fillIndicator} cx="16" cy="16" type="circle" className="recharts-symbols" transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
                                    </svg>
                                    <span className="gris mb-0 ml-0">{item.payload.label}</span>
                                </div>
                            </li>
                        }
                    }) }
                </ul>
            </div>
        </div>
    );
}
const expandComponent = (row) => {
    let _data = _.get(row, 'producciones', []);
    return(
        <div className="row">
            <div className="col-11 ml-5">
                <div className="tabla-adentro" style={{width: "100%"}}>
                    <BootstrapTable
                        headerStyle={ { backgroundColor: '#e24647' } }
                        data={_data}>
                        <TableHeaderColumn width="5%"/>
                        <TableHeaderColumn
                            // hidden
                            isKey
                            dataField="id">
                            ID PRODUCCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero_produccion"
                        >
                            NÚMERO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataFormat={cell => cell ? moment(cell).format("DD/MM/YYYY") : '--'}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="materia_almacen"
                            dataAlign="right"
                            dataFormat={cell => {
                                let totalKG = cell ? cell.reduce((accum, nextVal) => accum + nextVal.peso_extraido, 0) : 0;
                                return <RenderKilogramo value={totalKG} />
                            } }
                        >
                            KG. UTILIZADOS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="drc_produccion"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderNumberPercentage value={cell} /> : "--" }
                        >
                            DRC PRODUCCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn width="5%"/>
                    </BootstrapTable>
                </div>
            </div>
        </div>
    )
};

// DATA:
// - Se toma el mayor como el 100%
// - El 100% se dibuja entre [startAngle, endAngle]
// - El % a mostrar en la gráfica debe el % respecto al mayor valor
const dataLab = [
    { title: '',    label: '',            value: 100, fill: '#fff', },
    { title: '0%', label: 'Laboratorios', value: 0,   fill: '#359d9e', },
];
const dataProd = [
    { title: '',    label: '',          value: 100, fill: '#fff', },
    { title: '0%', label: 'Producción', value: 0,   fill: '#215273', },
];
const dataTipos = [
    { label: 'NO_RENDER',       value: 100,  fill: '#fff', fillIndicator: '#fff' },
    { label: 'DRC LABORATORIO', value: 100,  fill: '#fff', fillIndicator: '#359d9e' },
    { label: 'DRC PRODUCCIÓN',  value: 100,  fill: '#fff', fillIndicator: '#215273' },
];
const dataLabAcumulado = [...dataLab];
const dataProdAcumulado = [...dataProd];

const Reporte = (props) => {
    const {
        pageChange, page,
        loader, reporte_vs_drc: reporte,
        reporte_vs_drc: totales,
    } = props;
    // Procesamiento de la información para generar la gráfica de barras
    const TIPO_MATERIA = reporte.tipo_materia;
    const clearTime = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };
    let fecha_inicial = moment().set({...clearTime}), fecha_final = moment().set({...clearTime});
    switch(TIPO_MATERIA) {
        case 'SOLIDO':
            fecha_inicial = moment(reporte.solido_fecha_inicial).set({...clearTime});
            fecha_final = moment(reporte.solido_fecha_final).set({...clearTime});
            break;
    }
    const DECIMALES_EN_GRAFICA = 0;
    // Promedios de DRC Laboratorio y DRC Producción, acumulados en el año actual. Es independiente del rango de fechas proporcionado
    const promedioDRCLabAcumulado = _.get(reporte, 'totales.por_anio.drc_promedio_laboratorio', 0) * 100;
    const promedioDRCProdAcumulado = _.get(reporte, 'totales.por_anio.drc_promedio_produccion', 0) * 100;
    // Data para los gráficos
    dataLabAcumulado[1] = {...dataLab[1], title: `${promedioDRCLabAcumulado.toFixed(DECIMALES_EN_GRAFICA)}%`, value: promedioDRCLabAcumulado.toFixed(DECIMALES_EN_GRAFICA)};
    dataProdAcumulado[1] = {...dataProd[1], title: `${promedioDRCProdAcumulado.toFixed(DECIMALES_EN_GRAFICA)}%`, value: promedioDRCProdAcumulado.toFixed(DECIMALES_EN_GRAFICA)};
    let mensajeError = '';
    if (!fecha_inicial.isValid() || !fecha_final.isValid()) {
        mensajeError = 'FECHA INICIAL O FINAL NULO';
    } else if (fecha_final.diff(fecha_inicial, 'days') < 0) {
        mensajeError = 'FILTRO DE FECHAS NO VÁLIDO';
    } else {
        // Obtención de los promedios de DRC Laboratorio y DRC Producción
        const movimientos = _.get(reporte, 'vs_drc.results', []);
        movimientos.map(movimiento => {
            // Obteniendo el DRC promedio de todas las producciones asociadas a cada Ingreso
            const totalDRCProd = movimiento.producciones.reduce((accum, nextVal) => accum + nextVal.drc_produccion, 0);
            const promedio_drc = movimiento.producciones.length > 0 ? totalDRCProd / movimiento.producciones.length : 0;
            movimiento.promedio_drc_producciones = promedio_drc;
            return movimiento;
        });
        // Promedios de DRC Laboratorio y DRC Producción
        const promedioDRCLab = _.get(reporte, 'totales.por_fechas.drc_promedio_laboratorio', 0) * 100;
        const promedioDRCProd = _.get(reporte, 'totales.por_fechas.drc_promedio_produccion', 0) * 100;
        // Data para los gráficos
        dataLab[1] = {...dataLab[1], title: `${promedioDRCLab.toFixed(DECIMALES_EN_GRAFICA)}%`, value: promedioDRCLab.toFixed(DECIMALES_EN_GRAFICA)};
        dataProd[1] = {...dataProd[1], title: `${promedioDRCProd.toFixed(DECIMALES_EN_GRAFICA)}%`, value: promedioDRCProd.toFixed(DECIMALES_EN_GRAFICA)};
    }

    return (
        <div>
            <div className="mb-4">
                <CardEmpty>
                    <LoadMask loading={loader} blur>
                        <div className="d-flex flex-column">
                            <div className="flex-fill d-flex justify-content-center">
                                { mensajeError.length === 0 ? (
                                    <div className="d-flex justify-content-center">
                                        <ResponsiveContainer width="10%" height="100%" minWidth={200} minHeight={200}>
                                            <RadialBarChart data={dataTipos}
                                                cx={100} cy={100} innerRadius={60} outerRadius={80} barSize={10}
                                                startAngle={90} endAngle={450}
                                            >
                                                <RadialBar dataKey="value"
                                                    background clockWise
                                                />
                                                <Legend content={renderLegendTipos}
                                                    title="GRÁFICA DE TOTAL GLOBAL, POR FECHAS"
                                                    layout="vertical" verticalAlign="middle" align="left" iconType="circle"
                                                />
                                            </RadialBarChart>
                                        </ResponsiveContainer>
                                        <ResponsiveContainer width="25%" height="100%" minWidth={200} minHeight={200}>
                                            <RadialBarChart data={dataLab}
                                                cx={100} cy={100} innerRadius={60} outerRadius={80} barSize={10}
                                                startAngle={90} endAngle={450}
                                            >
                                                <RadialBar dataKey="value"
                                                    background clockWise
                                                />
                                                <Legend content={renderLegend}
                                                    title={dataLab[1].title} label={dataLab[1].label}
                                                    layout="vertical" verticalAlign="middle" align="center"
                                                />
                                            </RadialBarChart>
                                        </ResponsiveContainer>
                                        <ResponsiveContainer width="25%" height="100%" minWidth={200} minHeight={200}>
                                            <RadialBarChart data={dataProd}
                                                cx={100} cy={100} innerRadius={60} outerRadius={80} barSize={10}
                                                startAngle={90} endAngle={450}
                                            >
                                                <RadialBar dataKey="value"
                                                    background clockWise
                                                />
                                                <Legend content={renderLegend}
                                                    title={dataProd[1].title} label={dataProd[1].label}
                                                    layout="vertical" verticalAlign="middle" align="center"
                                                />
                                            </RadialBarChart>
                                        </ResponsiveContainer>
                                        {totales.totales && totales.totales.por_fechas &&
                                            <div style={{marginTop: '10%'}}>
                                                <h6>DIFERENCIA KG</h6>
                                                <RenderKilogramo value={totales.totales.por_fechas.diferencia_kg}/>
                                            </div>
                                        }
                                    </div>
                                ) : (
                                    <div className="mb-3 rojo bold">{mensajeError}</div>
                                ) }
                            </div>
                            <div className="flex-fill d-flex justify-content-center">
                                <div className="d-flex justify-content-center">
                                    <ResponsiveContainer width="10%" height="100%" minWidth={200} minHeight={200}>
                                        <RadialBarChart data={dataTipos}
                                            cx={100} cy={100} innerRadius={60} outerRadius={80} barSize={10}
                                            startAngle={90} endAngle={450}
                                        >
                                            <RadialBar dataKey="value"
                                                background clockWise
                                            />
                                            <Legend content={renderLegendTipos}
                                                title={`GRÁFICA DE TOTAL ACUMULADO, AÑO ${_.get(reporte, 'totales.por_anio.anio', moment().get('years'))}`}
                                                layout="vertical" verticalAlign="middle" align="left" iconType="circle"
                                            />
                                        </RadialBarChart>
                                    </ResponsiveContainer>
                                    <ResponsiveContainer width="25%" height="100%" minWidth={200} minHeight={200}>
                                        <RadialBarChart data={dataLabAcumulado}
                                            cx={100} cy={100} innerRadius={60} outerRadius={80} barSize={10}
                                            startAngle={90} endAngle={450}
                                        >
                                            <RadialBar dataKey="value"
                                                background clockWise
                                            />
                                            <Legend content={renderLegend}
                                                title={dataLabAcumulado[1].title} label={dataLabAcumulado[1].label}
                                                layout="vertical" verticalAlign="middle" align="center"
                                            />
                                        </RadialBarChart>
                                    </ResponsiveContainer>
                                    <ResponsiveContainer width="25%" height="100%" minWidth={200} minHeight={200}>
                                        <RadialBarChart data={dataProdAcumulado}
                                            cx={100} cy={100} innerRadius={60} outerRadius={80} barSize={10}
                                            startAngle={90} endAngle={450}
                                        >
                                            <RadialBar dataKey="value"
                                                background clockWise
                                            />
                                            <Legend content={renderLegend}
                                                title={dataProdAcumulado[1].title} label={dataProdAcumulado[1].label}
                                                layout="vertical" verticalAlign="middle" align="center"
                                            />
                                        </RadialBarChart>
                                    </ResponsiveContainer>
                                    {totales.totales && totales.totales.por_anio &&
                                        <div style={{marginTop: '10%'}}>
                                            <h6>DIFERENCIA KG</h6>
                                            <RenderKilogramo value={totales.totales.por_anio.diferencia_kg}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </CardEmpty>
            </div>
            <div className="mb-5">
                <CardEmpty>
                    <Grid hover striped loading={loader} data={reporte.vs_drc}
                        pagination onPageChange={pageChange} page={page}
                        expandableRow={() => true} expandComponent={expandComponent}
                        className="pb-4 pt-3"
                    >
                        <TableHeaderColumn dataField="id"
                            isKey
                            width="3%"
                            dataFormat={cell => <div></div>}
                        />
                        <TableHeaderColumn
                            dataField="ingreso"
                            dataFormat={cell => cell && cell.id ? cell.id.toString().padStart(3, 0) : '--'}
                        >
                            ID RECEPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="ingreso"
                            dataFormat={cell => cell && cell.fecha_entrada ? moment(cell.fecha_entrada).format('DD/MM/YYYY') : '--'}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="materia_prima"
                            dataFormat={cell => cell && cell.nombre ? cell.nombre : '--'}
                        >
                            MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="ingreso"
                            dataFormat={cell => cell && cell.finca && cell.finca.nombre ? cell.finca.nombre : '--'}
                        >
                            FINCA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="peso_neto"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderKilogramo value={cell} /> : '--'}
                        >
                            KG. HÚMEDOS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="analisis_laboratorio"
                            dataAlign="right"
                            dataFormat={cell => cell && cell.drc_promedio ? <RenderNumberPercentage value={cell.drc_promedio} /> : '--'}
                        >
                            DRC LABORATORIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="promedio_drc_producciones"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderNumberPercentage value={cell} /> : '--'}
                        >
                            DRC PRODUCCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="promedio_drc_producciones"
                            dataAlign="right"
                            dataFormat={(cell, row) => cell && row.analisis_laboratorio && row.analisis_laboratorio.drc_promedio ? <RenderNumberPercentage value={Math.abs(row.analisis_laboratorio.drc_promedio - cell)} /> : '--'}
                        >
                            DIFERENCIA
                        </TableHeaderColumn>
                        {/* <TableHeaderColumn
                            dataField="promedio_drc_producciones"
                            dataAlign="right"
                            dataFormat={(cell, row) => cell && row.peso_neto ? <RenderKilogramo value={row.peso_neto*cell} /> : '--'}
                        >
                            KG. PRODUCCIÓN
                        </TableHeaderColumn> */}
                        <TableHeaderColumn
                            dataField="id"
                            width="5%"
                            dataFormat={cell => <div></div>}
                        >
                        </TableHeaderColumn>
                    </Grid>
                </CardEmpty>
            </div>
        </div>
    )
};

export default Reporte;
