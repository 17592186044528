import { api } from "api";
import React, { Component } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import AsyncSelect from "react-select/lib/Async";
import { Link } from "react-router-dom";

const logo = require("assets/img/logo.png");
const defaultAvatar = require("assets/img/avatar-usuario.png");

let listaProveedores = [];

const getProveedor = (search) => {
    const params = {};

    search !== null && (params.search = search);
    // params.tipo_usuario = "proveedor";
    params.no_paginar = true;

    return api
        .get("propietario", {...params})
        .then((data) => {
            listaProveedores = data ? data.results : [];
            return listaProveedores;
        })
        .catch(() => []);
};
class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
    }

    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    options = [
        { value: "Spring", label: "Spring" },
        { value: "Summer", label: "Summer" },
        { value: "Autumn", label: "Autumn" },
        { value: "Winter", label: "Winter" },
    ];

    render() {
        const { navToggle, logOut, user, setProveedorValue } = this.props;

        return (
            <nav className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light">
                {user.proveedor_admin && (
                    <AsyncSelect
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                height: 5,
                                minWidth: 200,
                                borderRadius: 20
                            }),
                        }}
                        className={"ml-4"}
                        isSearchable={true}
                        placeholder={"Proveedor"}
                        defaultOptions
                        loadOptions={(search) => getProveedor(search)}   /// <<<<<
                        cacheOptions
                        getOptionValue={(option) => option["id"]}
                        getOptionLabel={(option) => option["nombre"]}
                        onChange={(value) => {
                            setProveedorValue(value.id);
                        }}
                    />
                )}

                <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex justify-content-center">
                    <img src={logo} width="10%" alt="" className="ml-5" />
                    {/*<div className="ml-3 input-group input-group-seamless" />*/}
                </div>
                <ul className="flex-row navbar-nav">
                    <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                    >
                        <DropdownToggle
                            color="light"
                            caret
                            style={{
                                border: "2px solid #ebebeb",
                                backgroundColor: "transparent",
                            }}
                        >
                            <img
                                className="user-avatar rounded-circle mr-3"
                                src={
                                    user && user.avatar
                                        ? user.avatar
                                        : defaultAvatar
                                }
                                alt="User"
                            />
                            <span className="d-none d-md-inline-block">
                                {user.username}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <Link
                                tabIndex="0"
                                to="/user-profile"
                                className="no-underline"
                            >
                                <DropdownItem>Mi perfil</DropdownItem>
                            </Link>
                            <DropdownItem onClick={logOut}>
                                <a tabIndex="0" href="/">
                                    Cerrar sesión
                                </a>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </ul>
                <nav className="nav">
                    <a
                        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
                        onClick={navToggle}
                    >
                        <i className="material-icons"></i>
                    </a>
                </nav>
            </nav>
        );
    }
}

export default Navbar;
