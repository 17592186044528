import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField } from '../../../Utils/renderField/renderField';

let TransportistaForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                {/* Información de la Empresa */}
                <div className="row col-md-12 p-0 pl-5 pr-5">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre_empresa" className="m-0">NOMBRE EMPRESA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre_empresa" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nit" className="m-0">NIT</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nit" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0 pl-5 pr-5">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="direccion" className="m-0">DIRECCIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="direccion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono_empresa" className="m-0">TELÉFONO EMPRESA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono_empresa" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                {/* Información del contacto */}
                <div className="row col-md-12 p-0 pl-5 pr-5">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre_contacto" className="m-0">NOMBRE CONTACTO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre_contacto" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email_contacto" className="m-0">E-MAIL CONTACTO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email_contacto" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0 pl-5 pr-5 mb-2">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono_contacto" className="m-0">TELÉFONO CONTACTO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono_contacto" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/transportistas">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};

TransportistaForm = reduxForm({
    form: 'EditarTransportistaForm',
    validate: data => {
        // Los campos aquí definidos se marcan como requeridos (impiden completar el submit si no tienen valor)
        return validate(data, {
            'nombre_empresa': validators.exists()('Campo requerido.'),
            // 'telefono_empresa': validators.exists()('Campo requerido.'),
            'nit': validators.exists()('Campo requerido.'),
            'nombre_contacto': validators.exists()('Campo requerido.'),
            // 'telefono_contacto': validators.exists()('Campo requerido.'),
            // 'email_contacto': validators.exists()('Campo requerido.'),
            'direccion': validators.exists()('Campo requerido.'),
        })
    }, initialValues: {}
})(TransportistaForm);

const selector = formValueSelector('EditarTransportistaForm');

TransportistaForm = connect(state => {
    const a = 'test';
    return {
        a
    };
})(TransportistaForm);

export const TransportistaUpdateForm = reduxForm({
    form: 'EditarTransportistaForm',
    validate: data => {
        return validate(data, {
            'nombre_empresa': validators.exists()('Campo requerido.'),
            'nit': validators.exists()('Campo requerido.'),
            'nombre_contacto': validators.exists()('Campo requerido.'),
            'direccion': validators.exists()('Campo requerido.'),
        })
    },
    asyncBlurFields: []
})(TransportistaForm);

export default TransportistaForm;
