import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/transportistas/transportistas';
import TransportistaSave from './TransportistaSave';

const mstp = state => {
    return {...state.transportistas}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(TransportistaSave)
