import { api } from "api";
import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Field, FieldArray, formValueSelector, reduxForm, } from 'redux-form';
import {
    renderField,
    renderNumber,
    renderNumberKG,
    renderTagsInput,
    renderTimeField,
    SelectField,
    AsyncSelectField,
    renderNumberG,
    renderNumberPercentage,
    renderTextArea,
    renderDatePicker
} from '../../../Utils/renderField/renderField';
import { RenderKilogramo, RenderGramos, RenderNumberPercentage } from 'Utils/renderField/renderReadField';
import CardEmpty from "Utils/Cards/CardEmpty";
import Modal from 'react-responsive-modal';
import { NotificationManager } from "react-notifications";
import { nivel_contaminacion } from "../../../../../utility/constants";
import Alerta from "../../../Utils/Alerta/Alerta";
import moment from 'moment/moment';

const ver_img = require('assets/img/icons/ver.png');

const getUsuarios = (search) => {
    return api.get("user/listado_laboratoristas", { search }).
        then((data) => {
            if (data){
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
        });
};

const getLaboratoristasSolidos = search => {
    return api.get('laboratorista/listar_laboratoristas_solidos', { search })
        .then(data => {
            if(data) {
                return data.results;
            }
            return [];
        }).catch(() => ([]));
}

const getContaminantes = (search) => {
    return api.get("contaminante", { search, no_paginar: true, }).
        then((data) => {
            if (data){
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
        });
};

export const renderDatosDRC = ({ fields, setDRCTemp, drc_temporal, clearDRCTemp, closeModal, openModal, open_drc,
    loadDataModal, updateData, drc_promedio_1, drc_promedio_2, drc_promedio_3, establecerLaboratorista, alerta, texto_alerta}) => {
    return (
        <div className="col-12 responsive-table-fix">
            <Modal open={open_drc} onClose={() => { closeModal() }} closeOnEsc={false} closeOnOverlayClick={false}>
                <h2>DATOS DRC</h2>
                <div className="form-group grid-container d-flex align-items-center flex-column">
                    {!!alerta && (
                        <Alerta texto={texto_alerta} danger={true}/>
                    )}
                </div>
                <div className="row p-0">
                    <div className="col-12">
                        <div className="row p-0">
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_muestra" className="m-0">PESO MUESTRA (P1)</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_muestra" component={renderNumberKG} type="text"
                                        className="form-control"
                                        input={{
                                            value: drc_temporal['peso_muestra'], onChange: (e) => {
                                                setDRCTemp('peso_muestra', parseFloat(e));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_crepa" className="m-0">PESO CREPA (P2)</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_crepa" component={renderNumberKG} type="text"
                                        className="form-control"
                                        input={{
                                            value: drc_temporal['peso_crepa'], onChange: (e) => {
                                                setDRCTemp('peso_crepa', parseFloat(e));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row p-0">
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_humedo_1" className="m-0">PESO HÚMEDO 1RO.</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_humedo_1" component={renderNumberG} type="text"
                                        className="form-control"
                                        input={{
                                            value: drc_temporal['peso_humedo_1'], onChange: (e) => {
                                                setDRCTemp('peso_humedo_1', parseFloat(e));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_humedo_2" className="m-0">PESO HÚMEDO 2DO.</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_humedo_2" component={renderNumberG} type="text"
                                        className="form-control"
                                        input={{
                                            value: drc_temporal['peso_humedo_2'], onChange: (e) => {
                                                setDRCTemp('peso_humedo_2', parseFloat(e));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_humedo_3" className="m-0">PESO HÚMEDO 3RO.</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_humedo_3" component={renderNumberG} type="text"
                                        className="form-control"
                                        input={{
                                            value: drc_temporal['peso_humedo_3'], onChange: (e) => {
                                                setDRCTemp('peso_humedo_3', parseFloat(e));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_seco_1" className="m-0">PESO SECO 1RO.</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_seco_1" component={renderNumberG} type="text"
                                        className="form-control"
                                        input={{
                                            value: drc_temporal['peso_seco_1'], onChange: (e) => {
                                                setDRCTemp('peso_seco_1', parseFloat(e));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_seco_2" className="m-0">PESO SECO 2DO.</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_seco_2" component={renderNumberG} type="text"
                                        className="form-control"
                                        input={{
                                            value: drc_temporal['peso_seco_2'], onChange: (e) => {
                                                setDRCTemp('peso_seco_2', parseFloat(e));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_seco_3" className="m-0">PESO SECO 3RO.</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_seco_3" component={renderNumberG} type="text"
                                        className="form-control"
                                        input={{
                                            value: drc_temporal['peso_seco_3'], onChange: (e) => {
                                                setDRCTemp('peso_seco_3', parseFloat(e));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="hora_ingreso" className="m-0">HORA INGRESO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="hora_ingreso" component={renderTimeField} type="text"
                                        className="form-control"
                                        input={{
                                            value: drc_temporal['hora_ingreso'], onChange: (e) => {
                                                setDRCTemp('hora_ingreso', e);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="hora_salida" className="m-0">HORA SALIDA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="hora_salida" component={renderTimeField} type="text"
                                        className="form-control"
                                        input={{
                                            value: drc_temporal['hora_salida'], onChange: (e) => {
                                                setDRCTemp('hora_salida', e);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="laboratorista" className="m-0">LABORATORISTA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="laboratorista"
                                        valueKey="id"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        // loadOptions={getUsuarios}
                                        loadOptions={getLaboratoristasSolidos}
                                        input={{
                                            value: drc_temporal['laboratorista'], onChange: (e) => {
                                                setDRCTemp('laboratorista', e);
                                                setDRCTemp('laboratorista_nombre', e.nombre);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <CardEmpty noShadow>
                                    <div className="col-12 row mr-3 mb-3">
                                        <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="drc_promedio_1" className="m-0">DRC 1RO.</label>
                                            </div>
                                            <div className="col-md-12  form-group">
                                                <Field name="drc_promedio_1" disabled component={renderNumberPercentage} type="text"
                                                    className="form-control"
                                                    input={{
                                                        value: drc_promedio_1
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="drc_promedio_2" className="m-0">DRC 2DO.</label>
                                            </div>
                                            <div className="col-md-12  form-group">
                                                <Field name="drc_promedio_2" disabled component={renderNumberPercentage} type="text"
                                                    className="form-control"
                                                    input={{
                                                        value: drc_promedio_2
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="drc_promedio_3" className="m-0">DRC 3RO.</label>
                                            </div>
                                            <div className="col-md-12  form-group">
                                                <Field name="drc_promedio_3" disabled component={renderNumberPercentage} type="text"
                                                    className="form-control"
                                                    input={{
                                                        value: drc_promedio_3
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </CardEmpty>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <button className="btn btn-outline-dark mr-5"
                        onClick={e => {
                            e.preventDefault();
                            closeModal()
                        }}>
                        CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary"
                        disabled={!!alerta ? true : false}
                        onClick={(e) => {
                            e.preventDefault();
                            // if (!drc_temporal.peso_seco) {
                            //     NotificationManager.error('Ingrese el peso seco.', 'ERROR', 3000);
                            // }
                            // if (!drc_temporal.peso_plato) {
                            //     NotificationManager.error('Ingrese el peso de crepa.', 'ERROR', 3000);
                            // }
                            // if (!drc_temporal.peso_humedo) {
                            //     NotificationManager.error('Ingrese el peso húmedo.', 'ERROR', 3000);
                            // }
                            if (!drc_temporal.laboratorista) {
                                    NotificationManager.error('Ingrese el laboratorista', 'ERROR', 3000);
                                }
                            if (!drc_temporal.hora_ingreso) {
                                NotificationManager.error('Ingrese la hora de ingreso.', 'ERROR', 3000);
                            }

                            if (drc_temporal.peso_crepa && drc_temporal.peso_muestra){
                                if (drc_temporal.peso_seco_1 && drc_temporal.peso_humedo_1 && drc_temporal.peso_crepa && drc_temporal.peso_muestra) {
                                    drc_temporal.drc_promedio_1 =
                                        (((drc_temporal.peso_seco_1 / drc_temporal.peso_humedo_1) * drc_temporal.peso_crepa)/drc_temporal.peso_muestra)
                                }
                                if (drc_temporal.peso_seco_2 && drc_temporal.peso_humedo_2 && drc_temporal.peso_crepa && drc_temporal.peso_muestra) {
                                    drc_temporal.drc_promedio_2 =
                                        (((drc_temporal.peso_seco_2 / drc_temporal.peso_humedo_2) * drc_temporal.peso_crepa)/drc_temporal.peso_muestra)
                                }
                                if (drc_temporal.peso_seco_3 && drc_temporal.peso_humedo_3 && drc_temporal.peso_crepa && drc_temporal.peso_muestra) {
                                    drc_temporal.drc_promedio_3 =
                                        (((drc_temporal.peso_seco_3 / drc_temporal.peso_humedo_3) * drc_temporal.peso_crepa)/drc_temporal.peso_muestra)
                                }
                                if(drc_temporal.drc_promedio_1 && drc_temporal.drc_promedio_2 && drc_temporal.drc_promedio_3){
                                    drc_temporal.drc_promedio_total =
                                        (drc_temporal.drc_promedio_1 + drc_temporal.drc_promedio_2 + drc_temporal.drc_promedio_3) / 3
                                }
                                if (drc_temporal.laboratorista) {
                                    if (drc_temporal.hasOwnProperty('index'))
                                        updateData(drc_temporal, 'drc');
                                    else
                                        fields.push(drc_temporal);
                                    clearDRCTemp();
                                    closeModal();
                                }
                            }
                        }}
                    >
                        GUARDAR
                    </button>
                </div>
            </Modal>
            <div className="table-responsive table-no-border-fix mt-3">
                <table className="table table-striped table-bordered table-hover">
                    <thead className="react-bs-container-header table-header">
                        <tr>
                            <th/>
                            <th>PESO MUESTRA</th>
                            <th>PESO CREPA</th>
                            <th>PH1</th>
                            <th>PH2</th>
                            <th>PH3</th>
                            <th>PS1</th>
                            <th>PS2</th>
                            <th>PS3</th>
                            <th>DRC1</th>
                            <th>DRC2</th>
                            <th>DRC3</th>
                            <th>DRC PROMEDIO</th>
                            <th>HORA INGRESO</th>
                            <th>HORA SALIDA</th>
                            <th>LABORATORISTA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((pago, index) => {
                            const dato_actual = fields.get(index); // Pago actual
                            let todos = fields.getAll();
                            todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                            return (
                                <tr key={index} >
                                    <td>
                                        <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                            title="Borrar" className="action_img"
                                            onClick={() => {
                                                if (dato_actual.hasOwnProperty('id')) {
                                                    dato_actual.activo = false;
                                                    updateData(dato_actual, 'drc');
                                                    setDRCTemp('test', false)
                                                } else
                                                    fields.remove(index)
                                            }}
                                        />
                                        <img src={require('assets/img/icons/editar.png')} alt="Editar"
                                            title="Editar" className="action_img"
                                            onClick={() => {
                                                loadDataModal(dato_actual);
                                                openModal("drc");
                                                setDRCTemp('laboratorista', dato_actual.laboratorista)
                                                establecerLaboratorista(dato_actual.laboratorista, dato_actual);
                                            }}
                                        />
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <RenderKilogramo value={dato_actual.peso_muestra} />
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <RenderKilogramo value={dato_actual.peso_crepa} />
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <RenderGramos value={dato_actual.peso_humedo_1} />
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <RenderGramos value={dato_actual.peso_humedo_2} />
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <RenderGramos value={dato_actual.peso_humedo_3} />
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <RenderGramos value={dato_actual.peso_seco_1} />
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <RenderGramos value={dato_actual.peso_seco_2} />
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <RenderGramos value={dato_actual.peso_seco_3} />
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        {dato_actual.drc_promedio_1 ? <RenderNumberPercentage value={parseFloat(dato_actual.drc_promedio_1)} /> : '--'}
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        {dato_actual.drc_promedio_2 ? <RenderNumberPercentage value={parseFloat(dato_actual.drc_promedio_2)} /> : '--'}
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        {dato_actual.drc_promedio_3 ? <RenderNumberPercentage value={parseFloat(dato_actual.drc_promedio_3)} /> : '--'}
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        {dato_actual.drc_promedio_total ? <RenderNumberPercentage value={parseFloat(dato_actual.drc_promedio_total)} /> : '--'}
                                    </td>



                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <span>{dato_actual.hora_ingreso} </span>
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <span>{dato_actual.hora_salida} </span>
                                    </td>
                                    <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                        <span>{dato_actual.laboratorista_nombre ? dato_actual.laboratorista_nombre : dato_actual.laboratorista.nombre} </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

const renderContaminantes = ({fields, updateData}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "20%"}} />
                        <th style={{width: "10%"}} />
                        <th className="text-center" style={{width: "20%"}}>CONTAMINANTE</th>
                        <th className="text-center" style={{width: "20%"}}>NIVEL</th>
                        <th style={{width: "20%"}} />
                    </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div>
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                    {fields.map((contaminante, index) => {
                        let todos = fields.getAll();
                        const dato_actual = fields.get(index); // Pago actual
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        return(
                        <tr key={index}>
                            <td style={{width: "20%"}}></td>
                            <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                     title="Borrar" className="action_img"
                                     onClick={() =>{
                                        fields.remove(index)
                                        // if (dato_actual.hasOwnProperty('id')) {
                                        //     dato_actual.activo = false;
                                        //     updateData(dato_actual, 'contaminantes');
                                        // } else {
                                        // }
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top" style={{width: "20%"}}>
                                <Field
                                    valueKey="id"
                                    name={`${contaminante}.material_contaminante`}
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getContaminantes}
                                />
                            </td>
                            <td style={{width: "20%"}}>
                                <Field
                                    name={`${contaminante}.nivel_contaminacion`}
                                    component={SelectField}
                                    options={nivel_contaminacion}
                                    className="form-control"
                                />
                            </td>
                            <td style={{width: "20%"}}></td>
                        </tr>
                    )})}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-center mt-2">
                <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({})}>
                    AGREGAR CONTAMINANTE
                </button>
            </div>
        </div>
    </div>
);

let LaboratorioSolidoForm = props => {
    const { handleSubmit, item, toogleInfo, ver_info, enviar_a_validacion, drc_promedio,
        openModal, validar, actualizarYEnviarValidacion, clearDRCTemp, drc_minimo, drc_maximo, drc_diferencia } = props;
    return (
        <form onSubmit={handleSubmit}>

            <div className="form-group grid-container">
                <div className="d-flex justify-content-center mb-3">
                    <button className="btn btn-outline-info" onClick={e => {
                        e.preventDefault();
                        toogleInfo()
                    }}>
                        VER DETALLE DE RECEPCIÓN
                        <img src={ver_img} alt="Ver" className="action_img" />
                    </button>
                </div>
                {(item.ingreso && item.ingreso.finca && ver_info) && (
                    <div className="d-flex  justify-content-center mb-4">
                        <div className="col-8">
                            <div className="row col-12">
                                <div className="col-12 d-flex justify-content-between align-items-baseline">
                                    <h6 className="mb-4">DATOS GENERALES DE RECEPCIÓN</h6>
                                </div>
                            </div>
                            <div className="row col-md-12 p-0">
                                <div className="col-md-6 p-0">
                                    <div className="col-12 d-flex flex-column">
                                        <label className="p-0 m-0">FINCA</label>
                                        <span className="mb-1">{item.ingreso.finca.nombre}</span>
                                        <label className="p-0 m-0">VEHÍCULO</label>
                                        <span className="mb-1">{item.ingreso.vehiculo.nombre}</span>
                                        <label className="p-0 m-0">LUGAR</label>
                                        <span className="mb-1">{item.ingreso.acopio ? item.ingreso.acopio.nombre : 'Planta ERSA'}</span>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="col-12 d-flex flex-column">
                                        <label className="p-0 m-0">PILOTO</label>
                                        <span className="mb-1">{item.ingreso.piloto.nombre}</span>
                                        <label className="p-0 m-0">TIPO DE MATERIA</label>
                                        <span className="mb-1">{item.ingreso.tipo_materia.nombre}</span>
                                        <label className="p-0 m-0">FECHA Y HORA DE INGRESO</label>
                                        <span className="mb-1">{moment(item.ingreso.fecha_entrada).format('DD/MM/YYYY hh:mm A')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {item.ingreso && (
                    <div className="d-flex justify-content-between align-items-end verde-secundario mb-3">
                        <div className="d-flex align-items-end"></div>
                        <div className="d-flex align-items-baseline">
                            <span className="bold mr-2">ID RECEPCIÓN</span>
                            <h4 className="gris bold mr-3">{item.ingreso.id}</h4>
                            <span className="bold mr-2">PRODUCTO</span>
                            <h4 className="gris bold">{item.movimiento.materia_prima.nombre}</h4>
                        </div>
                    </div>
                )}
                <div className="row mb-3">
                    <div className="col-12">
                        <CardEmpty noShadow noPadding>
                            <div className="col-12 m-0 py-3 d-flex flex-wrap justify-content-between">
                                <div className="row col-md-12 p-0 d-flex flex-wrap align-items-end">
                                    <div className="col-lg-6 p-0">
                                        <div className="col-12">
                                            <label htmlFor="fecha_analisis" className="m-0">FECHA DE INICIO DE LABORATORIO</label>
                                        </div>
                                        <div className="col-lg-12  form-group">
                                            <Field
                                                name="fecha_analisis"
                                                component={renderDatePicker}
                                                todayButton="Hoy"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 p-0">
                                        <div className="col-12">
                                            <label htmlFor="hora_analisis" className="m-0">HORA DE INICIO DE LABORATORIO</label>
                                        </div>
                                        <div className="col-lg-12  form-group">
                                            <Field
                                                name="hora_analisis"
                                                component={renderTimeField} type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <CardEmpty noShadow noPadding>
                            <div className="row col-12 m-0 py-3">
                                <div className="row col-12 ml-1">
                                    <h6 className="">DATOS DRC DE LABORATORIO</h6>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex justify-content-between my-2">
                                        <button className="btn btn-outline-info mb-2"
                                            onClick={e => {
                                                e.preventDefault();
                                                clearDRCTemp();
                                                openModal('drc')
                                            }}>
                                            AGREGAR DRC
                                        </button>
                                    </div>
                                    <CardEmpty noShadow>
                                        <div className="row col-12 m-0 py-3 mb-3">
                                            <div className="row col-12 mb-3">
                                                <div className="col-1"></div>
                                                <div className="col-11">
                                                    <h6 className="">RESULTADO DE DRC</h6>
                                                </div>

                                            </div>
                                            <div className="row col-12">
                                                <div className="col-3">
                                                    <span className="verde-secundario bold ml-2">PROMEDIO</span>
                                                    <Field name="drc_promedio" component={renderNumberPercentage} disabled type="text" className="form-control"
                                                        input={{ value: (isNaN(drc_promedio) || !isFinite(drc_promedio)) ? 0 : drc_promedio }} />
                                                </div>
                                                <div className="col-3">
                                                    <span className="verde-secundario bold ml-2">MÍNIMO</span>
                                                    <Field name="drc_minimo" component={renderNumberPercentage} disabled type="text" className="form-control"
                                                        input={{ value: (isNaN(drc_minimo) || !isFinite(drc_minimo)) ? 0 : drc_minimo }} />
                                                </div>
                                                <div className="col-3">
                                                    <span className="verde-secundario bold ml-2">MÁXIMO</span>
                                                    <Field name="drc_maximo" component={renderNumberPercentage} disabled type="text" className="form-control"
                                                        input={{ value: (isNaN(drc_maximo) || !isFinite(drc_maximo)) ? 0 : drc_maximo }} />
                                                </div>
                                                <div className="col-3">
                                                    <span className="verde-secundario bold ml-2">DIFERENCIA</span>
                                                    <Field name="drc_diferencia" component={renderNumberPercentage} disabled type="text" className="form-control"
                                                        input={{ value: (isNaN(drc_diferencia) || !isFinite(drc_diferencia)) ? 0 : drc_diferencia }} />
                                                </div>
                                            </div>
                                        </div>
                                    </CardEmpty>
                                </div>
                                <FieldArray name="datos_drc" component={renderDatosDRC} {...props} />
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <CardEmpty noShadow>
                            <div className="row col-12 m-0 py-3">
                                <h6 className="">CONTROL DE SUCIEDAD</h6>
                                <FieldArray name="datos_contaminantes" component={renderContaminantes} {...props} />
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row col-12 m-0 py-3">
                    <div className="col-12 m-0 p-0">
                        <div className="col-12 ml-1">
                            <h6 className="">OBSERVACIONES</h6>
                        </div>
                        <div className="col-12">
                            <Field name="observaciones" component={renderTextArea}/>
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/laboratorio/analisis_solido">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                    <button type="button" className="ml-5 btn btn-secondary" disabled={!validar}
                        onClick={handleSubmit(actualizarYEnviarValidacion)}>
                        ENVIAR DRC A VALIDACIÓN
                    </button>
                </div>
            </div>
        </form>
    )
};
LaboratorioSolidoForm = reduxForm({
    form: 'LaboratorioSolidoForm',
    initialValues: {
        multiple_ingreso: false,
        drc: [{}],
        tsc: [{}],
        vfa: [{}],
        nh3: [{}],
        contaminantes: [{}]
    },
})(LaboratorioSolidoForm);

const selector = formValueSelector('LaboratorioSolidoForm');
LaboratorioSolidoForm = connect(state => {
    const form = state.form.LaboratorioSolidoForm;
    const datos_modal = state.laboratorio.drc_temporal;
    const RANGO_DRC = state.configuracion.configuraciones.variable_rango_drc;
    let drc_promedio = 0;
    let validar = false;
    let array_drcs = [];
    let array_drc_promedios = [];
    let drc_minimo, min = 0;
    let drc_maximo, max = 0;
    let drc_diferencia = 0;
    let drc_promedio_1 = 0;
    let drc_promedio_2 = 0;
    let drc_promedio_3 = 0;
    let alerta = false;
    let texto_alerta = '';
    if (form && form.values) {
        const values = form.values;

        //Calculos de los DRC Promedio del modal
        if (datos_modal.peso_crepa && datos_modal.peso_muestra){
            if (datos_modal.peso_seco_1 && datos_modal.peso_humedo_1 && datos_modal.peso_crepa && datos_modal.peso_muestra) {
                drc_promedio_1 = (((datos_modal.peso_seco_1 / datos_modal.peso_humedo_1) * datos_modal.peso_crepa)/datos_modal.peso_muestra)
            }
            if (datos_modal.peso_seco_2 && datos_modal.peso_humedo_2 && datos_modal.peso_crepa && datos_modal.peso_muestra) {
                drc_promedio_2 = (((datos_modal.peso_seco_2 / datos_modal.peso_humedo_2) * datos_modal.peso_crepa)/datos_modal.peso_muestra)
            }
            if (datos_modal.peso_seco_3 && datos_modal.peso_humedo_3 && datos_modal.peso_crepa && datos_modal.peso_muestra) {
                drc_promedio_3 = (((datos_modal.peso_seco_3 / datos_modal.peso_humedo_3) * datos_modal.peso_crepa)/datos_modal.peso_muestra)
            }
            if (drc_promedio_1 && drc_promedio_2 && drc_promedio_3) {
                array_drc_promedios.push(drc_promedio_1);
                array_drc_promedios.push(drc_promedio_2);
                array_drc_promedios.push(drc_promedio_3);
            }
        }

        //Calculo de Promedio DRC Total por todos los DRC
        let count_drc = 0;
        if (values.datos_drc) {
            let sumatoria_drc = 0;
            values.datos_drc.forEach((dato) => {
                if (dato.drc_promedio_total) {
                    if (dato.hasOwnProperty('activo')) {
                        if (dato.activo === true) {
                            array_drcs.push(dato.drc_promedio_total)
                            count_drc += 1;
                            sumatoria_drc += dato.drc_promedio_total
                        }
                    } else {
                        sumatoria_drc += dato.drc_promedio_total;
                        count_drc += 1;
                        array_drcs.push(dato.drc_promedio_total)
                    }
                }
            });
            drc_promedio = sumatoria_drc / count_drc;
            drc_minimo = Math.min(...array_drcs);
            drc_maximo = Math.max(...array_drcs);
            drc_diferencia = drc_maximo - drc_minimo;

            form.values.drc_promedio = drc_promedio;
            form.values.drc_minimo = drc_minimo;
            form.values.drc_maximo = drc_maximo;
            form.values.drc_diferencia = drc_diferencia;
        }

        if (values.drc_promedio){
            validar = true;
        }

        if (array_drc_promedios.length > 0) {
            min = Math.min(...array_drc_promedios)
            max = Math.max(...array_drc_promedios)

            min = min*100;
            max = max*100;

            // console.log("Min", min, " - Max", max);
            // console.log((max-min) > RANGO_DRC);

            if ((max-min) > RANGO_DRC) {
                // console.log("SI ENTRO");
                alerta = true;
                texto_alerta = 'Los valores de DRC se excedieron por mas de 3 puntos';
                // console.log("ALERTA", alerta, texto_alerta);
            }
        }
    }
    return {
        c: 0,
        drc_promedio_1,
        drc_promedio_2,
        drc_promedio_3,
        validar,
        drc_minimo,
        drc_maximo,
        drc_diferencia,
        drc_promedio,
        alerta,
        texto_alerta,
    }
})(LaboratorioSolidoForm);

export default LaboratorioSolidoForm
