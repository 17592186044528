import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, SelectField, AsyncSelectField } from '../../../Utils/renderField/renderField';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';

class ClienteForm extends Component {
    render() {
        const { handleSubmit, editando, mensaje } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <h4>{mensaje}</h4>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="nombre">NOMBRE</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="nombre"
                                component={renderField}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="dpi">DPI</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="dpi"
                                component={renderField}
                            />
                        </div>
                    </div>
                </section>
                <section className="d-flex" style={{zIndex: '1000'}}>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="telefono">TELÉFONO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="telefono"
                                component={renderField}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="correo">CORREO ELECTRÓNICO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="correo"
                                component={renderField}
                            />
                        </div>
                    </div>
                </section>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/clientes">CANCELAR</Link> 
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'clienteForm',
    validate: data => {
        return validate(data, {
            nombre: validators.exists()('Campo requerido'),
        });
    }
})(ClienteForm);