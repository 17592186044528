import React, {Fragment} from 'react';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import {
    renderField,
    renderFieldCheck,
} from '../../../Utils/renderField/renderField';
import CardEmpty from "Utils/Cards/CardEmpty";

const renderContactos = ({fields, meta: {error, submitFailed }, contactos}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>NOMBRE</th>
                        <th>E-MAIL</th>
                    </tr>

                    </thead>

                </table>

            </div>
            {/*Contenido de la tabla*/}
            <div className="contenido-contactos">
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                    {fields.map((contacto, index) => (
                        <tr key={index}>
                            <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                     title="Borrar" className="action_img"
                                     onClick={() =>{
                                         fields.remove(index)
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.nombre`}
                                    type="text"
                                    component={renderField}
                                />
                            </td>
                            <td className="sin-borde-top">
                                <Field
                                    name={`${contacto}.email`}
                                    type="email"
                                    component={renderField}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-center mt-2">
                <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({})}>
                    AGREGAR E-MAIL
                </button>
            </div>
        </div>
    </div>
)

let PasoCincoForm = props => {
    const { handleSubmit, previousStep } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <h6 className="ml-4 mb-4">DATOS DE CONTACTO DE FINCA</h6>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre_contacto" className="m-0">NOMBRE DE ADMINISTRADOR</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <div className="col-md-12 form-group">
                                <Field name="nombre_contacto" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email_contacto" className="m-0">E-MAIL ADMINISTRADOR</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <div className="col-md-12 form-group">
                                    <Field name="email_contacto" component={renderField} type="email" className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono_contacto" className="m-0">TÉLEFONO ADMINISTRADOR</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <div className="col-md-12 form-group">
                                <Field name="telefono_contacto" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">TÉLEFONO FINCA</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <div className="col-md-12 form-group">
                                <Field name="telefono" component={renderField} type="phone" className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
                <FieldArray name="contactos" component={renderContactos} />
                <CardEmpty noShadow>
                    <label  className="m-0">NOTIFICACIONES</label>
                    <div className="d-flex align-items-center flex-column">
                        <div className="row col-md-10 p-0 d-flex flex-wrap align-items-end">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12  form-group d-flex justify-content-end mb-1">
                                    <Field name="notificacion_recepcion" label="RECEPCIÓN" component={renderFieldCheck}/>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12  form-group d-flex justify-content-end mb-1">
                                    <Field name="notificacion_liquidacion" label="LIQUIDACIÓN" component={renderFieldCheck}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardEmpty>
                <div className="buttons-box mt-5">
                    <button className="btn btn-outline-dark mr-5" onClick={previousStep}>ANTERIOR</button>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
PasoCincoForm = reduxForm({
    form: 'fincaForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    }
})(PasoCincoForm);

export default PasoCincoForm
