import React, { Fragment } from 'react';
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import moment from 'moment/moment';
import { RenderKilogramo, RenderNumberPercentage } from "../../../Utils/renderField/renderReadField";
import Grid from "../../../Utils/Grid";

const estadoFormater = (cell,row) => {
    return <span className={`${cell === 10 || cell === 35 ? 'rojo': cell === 20 ? 'verde': 'azul'}`}>
            {row.estado_ingreso_txt}
        </span>
};

const Finalizadas = props => {
    const { dataFinalizados, eliminar, loader, onSortChange, page_finalizadas, listar: onPageChange } = props;
    return(
        <div style={{minHeight: '275px'}}>
            <Grid hover striped data={dataFinalizados} loading={loader} onSortChange={onSortChange}
                pagination page={page_finalizadas} onPageChange={onPageChange}
                className="pb-3"
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    width='10%'
                    dataSort
                    dataFormat={standardActions({ ver: "produccion"})}
                />
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                >
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="numero_produccion"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                >
                    NO. PRODUCCIÓN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_inicio_produccion"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{moment(cell).format('DD/MM/YYYY')}</span>}
                >
                    FECHA INICIO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_fin_produccion"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{moment(cell).format('DD/MM/YYYY')}</span>}
                >
                    FECHA FIN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="materia_prima"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell ? cell.nombre: ""}</span>}
                >
                    PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="materia_almacen"
                    dataSort
                    dataAlign="right"
                    dataFormat={
                        cell =>{
                            if (cell) {
                                var materia_almacen = 0;
                                for (let i = 0; i < cell.length; i++) {
                                    materia_almacen += cell[i].peso_extraido;
                                }
                                return <RenderKilogramo value={materia_almacen} />
                            }
                        }
                    }
                    >
                    PESO DE ALMACENES
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="peso_total"
                    dataSort
                    dataAlign="right"
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{<RenderKilogramo value={cell}/>}</span>}
                    >
                    PESO TOTAL
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="drc_produccion"
                    dataSort
                    dataAlign="right"
                    // dataFormat={
                    //     (cell,row) =>{
                    //         if (cell) {
                    //             var materia_almacen = 0;
                    //             for (let i = 0; i < cell.length; i++) {
                    //                 materia_almacen += cell[i].peso_extraido;
                    //             }
                    //             if (cell && row) {
                    //                 return <RenderNumberPercentage value={(row.peso_total / materia_almacen)} />
                    //             }
                    //             else{
                    //                 return 0;
                    //             }
                    //         }
                    //         else{
                    //             return 0;
                    //         }
                    //     }
                    // }
                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell ? cell : 0} />}
                    >
                    DRC
                </TableHeaderColumn>
            </Grid>
        </div>
    )
};
export default Finalizadas;
