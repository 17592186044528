import { createAction, handleActions } from "redux-actions";
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import moment from "moment";
import Swal from "sweetalert2";

const LOADER = "LOADER_HISTORIAL_TANQUES";
const LOADER_INIT = "LOADER_INIT_HISTORIAL_TANQUES";
const LOAD_EXCEL = "LOAD_EXCEL_HISTORIAL_TANQUES";
const DATA = "DATA_HISTORIAL_TANQUES";
const PAGE = "PAGE_HISTORIAL_TANQUES";
const TANQUES = "TANQUES_HISTORIAL_TANQUES";
const SELECT_TANQUE = "SELECT_TANQUE_HISTORIAL_TANQUES";
const SELECT_MOVIMIENTO = "SELECT_MOVIMIENTO_HISTORIAL_TANQUES";
const SELECT_FECHA_INICIAL = "SELECT_FECHA_INICIAL_HISTORIAL_TANQUES";
const SELECT_FECHA_FINAL = "SELECT_FECHA_FINAL_HISTORIAL_TANQUES";
const PARAMSUSED = "PARAMSUSED_HISTORIAL_TANQUES";

// const ANIO = "ANIO_HISTORIAL_TANQUES";
// const PRODUCTOS = "PRODUCTOS_HISTORIAL_TANQUES";
// const FINCA = "FINCA_HISTORIAL_TANQUES";
// const SELECT_ANIO = "SELECT_ANIO_HISTORIAL_TANQUES";
// const SELECT_PRODUCTO = "SELECT_PRODUCTO_HISTORIAL_TANQUES";
// const SELECT_FINCA = "SELECT_FINCA_HISTORIAL_TANQUES";
// const LOAD_EXCEL = "LOAD_EXCEL_HISTORIAL_TANQUES";
// const LOAD_PDF = "LOAD_PDF_HISTORIAL_TANQUES";
// const DATA_FULL_TO_PDF = "DATA_FULL_TO_PDF_HISTORIAL_TANQUES";

// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setLoaderReportExcel = (loader_excel) => ({
    type: LOAD_EXCEL,
    loader_excel,
});

const setLoaderInit = (loader_init) => ({
    type: LOADER_INIT,
    loader_init,
});

const setData = (data) => ({
    type: DATA,
    data,
});

const setPage = (page) => ({
    type: PAGE,
    page,
});
const setParamsUsed = (params) => ({
    type: PARAMSUSED,
    params,
});

const setTanques = (tanques) => ({
    type: TANQUES,
    tanques,
});

const setSelectTanque = (select_tanque) => ({
    type: SELECT_TANQUE,
    select_tanque,
});

const setSelectMovimiento = (select_movimiento) => ({
    type: SELECT_MOVIMIENTO,
    select_movimiento,
});

const setSelectFechaInicial = (select_fecha_inicial) => ({
    type: SELECT_FECHA_INICIAL,
    select_fecha_inicial,
});

const setSelectFechaFinal = (select_fecha_final) => ({
    type: SELECT_FECHA_FINAL,
    select_fecha_final,
});

// const setLoaderReportPdf = (loader_pdf) => ({
//     type: LOAD_PDF,
//     loader_pdf,
// });
// const setDataFullToPdf = (data_full_to_pdf) => ({
//     type: DATA_FULL_TO_PDF,
//     data_full_to_pdf,
// });

// const setAnio = (anio) => ({
//     type: ANIO,
//     anio,
// });

// const setProductos = (productos) => ({
//     type: PRODUCTOS,
//     productos,
// });

// const setFinca = (fincas) => ({
//     type: FINCA,
//     fincas,
// });

// const setSelectAnio = (select_anio) => ({
//     type: SELECT_ANIO,
//     select_anio,
// });

// const setSelectProducto = (select_producto) => ({
//     type: SELECT_PRODUCTO,
//     select_producto,
// });

// const setSelectFinca = (select_finca) => ({
//     type: SELECT_FINCA,
//     select_finca,
// });

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [PARAMSUSED]: (state, { params }) => {
        return {
            ...state,
            params,
        };
    },
    [LOADER_INIT]: (state, { loader_init }) => {
        return {
            ...state,
            loader_init,
        };
    },
    [LOAD_EXCEL]: (state, { loader_excel }) => {
        return {
            ...state,
            loader_excel,
        };
    },
    [TANQUES]: (state, { tanques }) => {
        return {
            ...state,
            tanques,
        };
    },
    [SELECT_TANQUE]: (state, { select_tanque }) => {
        return {
            ...state,
            select_tanque,
        };
    },
    [SELECT_MOVIMIENTO]: (state, { select_movimiento }) => {
        return {
            ...state,
            select_movimiento,
        };
    },
    [SELECT_FECHA_INICIAL]: (state, { select_fecha_inicial }) => {
        return {
            ...state,
            select_fecha_inicial,
        };
    },
    [SELECT_FECHA_FINAL]: (state, { select_fecha_final }) => {
        return {
            ...state,
            select_fecha_final,
        };
    },
    // [ANIO]: (state, { anio }) => {
    //     return {
    //         ...state,
    //         anio,
    //     };
    // },
    // [PRODUCTOS]: (state, { productos }) => {
    //     return {
    //         ...state,
    //         productos,
    //     };
    // },
    // [FINCA]: (state, { fincas }) => {
    //     return {
    //         ...state,
    //         fincas,
    //     };
    // },
    // [SELECT_ANIO]: (state, { select_anio }) => {
    //     return {
    //         ...state,
    //         select_anio,
    //     };
    // },
    // [SELECT_PRODUCTO]: (state, { select_producto }) => {
    //     return {
    //         ...state,
    //         select_producto,
    //     };
    // },
    // [SELECT_FINCA]: (state, { select_finca }) => {
    //     return {
    //         ...state,
    //         select_finca,
    //     };
    // },
    // [LOAD_PDF] : (state, { loader_pdf }) => {
    //     return {
    //         ...state,
    //         loader_pdf,
    //     };
    // },
    // [DATA_FULL_TO_PDF] : (state, { data_full_to_pdf }) => {
    //     return {
    //         ...state,
    //         data_full_to_pdf,
    //     };
    // },
};

export const initialState = {
    loader_init: true,
    loader: false,
    loader_excel: false,
    page: 1,
    data: {
        count: 0,
        results: [],
    },
    params: {
        fecha_inicial: moment().startOf("month"),
        fecha_final: moment().endOf("month"),
        tanque: null,
        movimiento: null,
    },
    select_tanque: null,
    select_movimiento: null,
    select_fecha_inicial: moment().startOf("month"),
    select_fecha_final: moment().endOf("month"),
    tanques: [],

    // loader_init: true,
    // loader: false,
    // page: 1,s
    // data: {
    //     count: 0,
    //     results: [],
    // },
    // params: {
    //     anio: null,
    //     producto: null,
    //     finca: null,
    // },
    // select_anio: null,
    // select_producto: null,
    // select_finca: null,
    // anio: [],
    // productos: [],
    // fincas: [],
    // loader_excel: false,
    // loader_pdf: false,
    // data_full_to_pdf: {
    //     results: [],
    //     count: 0,
    // }
};

export default handleActions(reducers, initialState);

// ------------------------------------
// Actions
// ------------------------------------
const obtenerDataInicial = () => (dispatch, getStore) => {
    Promise.all([api.get(`historial_tanques/obtener_tanques`)])
        .then((response) => {
            dispatch(setTanques(response[0]));
        })
        .catch((error) => {
            console.log("error: ", error);
            NotificationManager.error(
                "Ocurrió un error al obtener los datos iniciales",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoaderInit(false));
        });
};

const obtener_data =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const {
            select_tanque,
            select_movimiento,
            select_fecha_inicial,
            select_fecha_final,
        } = getStore().rht;

        let params = {};
        if (select_tanque != null && select_tanque.length > 0) {
            params.tanque = select_tanque.map((tanque) => tanque.value);
        }
        if (select_movimiento != null && select_movimiento.length > 0) {
            params.movimiento = select_movimiento.map(
                (movimiento) => movimiento.value
            );
        }
        // para las fechas validamos el objeto Moment
        if (select_fecha_inicial != null) {
            params.fecha_inicial = select_fecha_inicial.format("YYYY-MM-DD");
        }
        if (select_fecha_final != null) {
            params.fecha_final = select_fecha_final.format("YYYY-MM-DD");
        }
        
        dispatch(setParamsUsed(params));

        params.page = page;

        api.get(`historial_tanques/obtener_reporte_data`, params)
            .then((response) => {
                dispatch(setData(response));
                dispatch(setPage(page));
            })
            .catch((error) => {
                console.log("error: ", error);
                NotificationManager.error(
                    "Ocurrió un error al obtener los datos",
                    "ERROR",
                    0
                );
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

const obtener_reporte_excel = () => (dispatch, getStore) => {
    dispatch(setLoaderReportExcel(true));

    let endpoint = `/api/historial_tanques/obtener_reporte_excel/?is_excel=true`;

    const { params } = getStore().rht;
    //recorremos los parametros para agregarlos al endpoint de la api
    Object.keys(params).forEach((key) => {
        if (
            params[key] != null &&
            params[key] != undefined &&
            params[key].length > 0
        ) {
            if (key === "fecha_inicial" || key === "fecha_final") {
                endpoint += `&${key}=${params[key]}`;
            } else {
                endpoint += `&${key}=${params[key].join(",")}`;
            }
        }
    });

    fetch(endpoint, {
        method: "GET",
        headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    })
        .then((response) => {
            if (response.status === 200) {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = "ReporteAnualProveedor.xlsx";
                    a.click();
                });
            }
        })
        .catch(() => {
            NotificationManager.error(
                "Ocurrió un error al exportar el reporte",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoaderReportExcel(false));
        });
};

export const actions = {
    obtener_reporte_excel,
    obtener_data,
    obtenerDataInicial,
    setSelectFechaFinal,
    setSelectFechaInicial,
    setSelectMovimiento,
    setSelectTanque,
};
