import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";

import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";

export default class TiposMateriaPrima extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;

        return (
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="TIPOS DE MATERIA PRIMA"
                    subtitulo="Del sistema"
                >
                    <Header to="tipo_materia_prima/crear" textBtn="CREAR TIPO DE MATERIA PRIMA" {...this.props} />
                    <Grid
                        page={page}
                        data={data}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        hover
                        striped
                    >
                        <TableHeaderColumn
                            isKey
                            dataSort
                            dataField="id"
                            dataAlign="center"
                            width='20%'
                            dataFormat={standardActions({ editar: "tipo_materia_prima", eliminar })}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                            width='20%'
                        >
                            TIPO DE MATERIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            width='10%'
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn width='50%'/>
                    </Grid>
                </Card>
            </div>
        );
    }
}
