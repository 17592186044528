import React from "react";
import "./alerta.css";
const alerta_img = require("assets/img/icons/alerta.png");
const alerta_img_danger = require("assets/img/icons/alerta_roja.png");

const Alerta = ({
    texto,
    danger,
    isBold,
    noMarginBottom,
    classNameCustom = {},
    isHover,
    customClassText,
}) => (
    <div
        className={`containerAlert ${
            !danger ? "alerta-container" : "alerta-danger"
        }${noMarginBottom ? " mb-0" : ""}`}
        style={classNameCustom}
    >
        <img
            src={!danger ? alerta_img : alerta_img_danger}
            alt="Alerta"
            className={`mr-1 title_img blink-danger`}
        />
        {isHover ? (
            <span
                className={
                    !danger
                        ? "alerta-text showText"
                        : `alerta-danger-text showText ${customClassText}`
                }
            >
                {texto}
            </span>
        ) : (
            <span className={!danger ? "alerta-text" : `alerta-danger-text ${customClassText}` } 
                style={isBold ? { fontWeight: "bold" } : { fontWeight: "normal" }}
            >
                {texto}
            </span>
        )}
    </div>
);

export default Alerta;
