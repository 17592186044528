import React, { Fragment } from 'react';
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import moment from 'moment/moment';
import Grid from "../../../Utils/Grid";

const estadoFormater = (cell,row) => {
    return <span className={`${cell === 10 || cell === 35 ? 'rojo': cell === 20 ? 'verde': 'azul'}`}>
            {row.estado_ingreso_txt}
        </span>
};

const Pendientes = props => {
    const { dataPendientes, eliminar, loader, onSortChange } = props;
    return(
        <Grid hover striped data={dataPendientes} loading={loader} onSortChange={onSortChange} pagination={false}
            className="pb-3"
        >
            <TableHeaderColumn
                dataField="id"
                dataAlign="center"
                isKey
                width='10%'
                dataSort
                dataFormat={standardActions({ editar: "produccion"})}
                // dataFormat={standardActions({ editar: "produccion", ver: "produccion"})}
            />
            <TableHeaderColumn
                dataField="id"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
            >
                ID
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="numero_produccion"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
            >
                NO. PRODUCCIÓN
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="fecha_inicio_produccion"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{moment(cell).format('DD/MM/YYYY HH:mm:ss A')}</span>}
            >
                FECHA INICIO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="materia_prima"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell ? cell.nombre: ""}</span>}
            >
                PRODUCTO
            </TableHeaderColumn>
            {/* <TableHeaderColumn
                dataField="piloto"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell ? cell.nombre: ""}</span>}
            >
                PILOTO
            </TableHeaderColumn> */}
        </Grid>
    )
};
export default Pendientes;
