import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/recepciones';
import ReporteRecepcionesProveedores from './ReporteRecepcionesProveedores';


const ms2p = (state) => {
  return {
    ...state.recepciones,
    proveedor: state.login.proveedor,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteRecepcionesProveedores);
