import React, { Component } from 'react'
import { RenderKilogramo } from '../../../../Utils/renderField/renderReadField'
import Grid from '../../../../Utils/Grid'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { Link } from 'react-router-dom'
import CardEmpty from '../../../../Utils/Cards/CardEmpty'
const alerta_img_danger = require('assets/img/icons/alerta_roja.png')

const getPesoIngreso = recepciones => {
    let sumatoriaPesoEntrada = 0
    recepciones.forEach(recepcion => {
        if (recepcion.pesoentrada !== null) {
            sumatoriaPesoEntrada += recepcion.pesoentrada
        }
    })
    return sumatoriaPesoEntrada
}
class DetalleRecepcionForm extends Component {
    render() {
        const { item_recepcion, detalles_recepcion } = this.props
        const pesoIngreso = getPesoIngreso(detalles_recepcion.results)
        return (
            <div className="container">
                <section className="d-flex align-items-center">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <h6>DATOS DE RECEPCIÓN</h6>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label>ID RECEPCIÓN <span className="gris text-md ml-2">{item_recepcion.codentr ? item_recepcion.codentr.toString().padStart(5, 0) : ''}</span></label>
                        </div>
                    </div>
                </section>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="finca">FINCA</label>
                        </div>
                        <div className="col-12">
                            <span className="ml-3">{item_recepcion.finca}</span>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="nombre_piloto">PILOTO</label>
                        </div>
                        <div className="col-12">
                            <span className="ml-3">{item_recepcion.nombre_piloto}</span>
                        </div>
                    </div>
                </section>
                <section className="d-flex mt-4">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="placa">VEHÍCULO</label>
                        </div>
                        <div className="col-12">
                            <span className="ml-3">{item_recepcion.placa}</span>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="tipo_producto">TIPO DE MATERIA PRIMA</label>
                        </div>
                        <div className="col-12">
                            <span className="ml-3">{
                                detalles_recepcion.results[0] &&
                                    detalles_recepcion.results[0].tipo_producto
                            }</span>
                        </div>
                    </div>
                </section>
                <section className="d-flex mt-4">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="pesoentrada">PESO DE INGRESO</label>
                        </div>
                        <div className="col-12">
                            <span className="ml-3"><RenderKilogramo value={pesoIngreso}/></span>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="lugar">LUGAR DE RECEPCIÓN</label>
                        </div>
                        <div className="col-12">
                            <span className="ml-3">{item_recepcion.lugar}</span>
                        </div>
                    </div>
                </section>
                {item_recepcion.anulado &&
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="mb-1 d-flex align-content-stretch">
                                <img src={alerta_img_danger} height="35" alt="Alerta"
                                    className="mr-1 blink-danger"
                                />
                                <span className="ml-2 text-danger text-md bold">ANULADA</span>
                            </div>
                            <CardEmpty noShadow>
                                <div className="col-12 pl-4 pb-3">
                                    <span className="text-dangar text-xs bold">JUSTIFICACIÓN DE ANULACIÓN</span>
                                </div>
                                <span className="azul text-sm">{item_recepcion.anuladoComentario ? item_recepcion.anuladoComentario : ''}</span>
                            </CardEmpty>
                        </div>
                    </div>
                }
                <div className="row mt-4">
                    <div className="col-12">
                        <h6 className="mb-2">DATOS DE LOS PRODUCTOS</h6>
                    </div>
                </div>
                <Grid
                    data={detalles_recepcion} pagination={false}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="producto"
                    >
                        M. PRIMA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="pesof"
                        dataFormat={cell => {
                            if (cell === null) {
                                return <RenderKilogramo value={0}/>
                            }
                            return <RenderKilogramo value={cell}/>
                        }}
                    >
                        PESO FINCA KG
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataFormat={(cell, row) => <RenderKilogramo value={row.pesoentrada - row.pesosalida}/>}
                    >
                        PESO BRUTO
                    </TableHeaderColumn>
                </Grid>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/historico/recepciones">REGRESAR</Link>
                    <Link className="btn btn-secondary" to={`/historico/recepciones/${item_recepcion.codentr}/boleta`}>IMPRIMIR BOLETA</Link>
                </div>
            </div>
        )
    }
}

export default DetalleRecepcionForm