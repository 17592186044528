import React from 'react';
import NumberFormat from 'react-number-format';
import Popup from "reactjs-popup";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

export const RenderNumber = ({value, decimalScale, className, suffix}) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale || 0}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix=""
            suffix={suffix || ""}
            displayType="text"
        />
    );
};

export const RenderNumberPercentage = ({ value, decimalScale, className, style={} }) => {
  return (
    <NumberFormat
      className={className}
      decimalScale={decimalScale || 2}
      fixedDecimalScale
      thousandSeparator
      value={value * 100}
      prefix=""
      suffix=" %"
      displayType="text"
      style={style}
    />
  );
};

export const RenderCurrency = ({ value, className, decimalScale }) => {
  return (
    <NumberFormat
      className={className}
      decimalScale={decimalScale ? decimalScale : 2}
      fixedDecimalScale
      value={value}
      thousandSeparator
      prefix="Q "
      displayType="text"
    />
  );
};

export const RenderCambio = ({ value, className, decimalScale }) => {
    return (
      <NumberFormat
        className={className}
        decimalScale={decimalScale ? decimalScale : 4}
        fixedDecimalScale
        value={value}
        thousandSeparator
        prefix="Q "
        displayType="text"
      />
    );
  };

export const RenderDollar= ({ value, className, decimalScale }) => {
  return (
    <NumberFormat
      className={className}
      decimalScale={decimalScale ? decimalScale : 2}
      fixedDecimalScale
      value={value}
      thousandSeparator
      prefix="$ "
      displayType="text"
    />
  );
};
export const RenderKilogramo= ({ value, decimalScale, className}) => {
  return (
    <NumberFormat
      className={className}
      decimalScale={decimalScale || 2}
      fixedDecimalScale
      value={value}
      thousandSeparator
      prefix=""
      suffix=" Kg"
      displayType="text"
    />
  );
};


export const RenderGramos = ({value, decimalScale, className}) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale || 4}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix=""
            suffix=" g"
            displayType="text"
        />
    );
};

export const RenderML = ({value, decimalScale, className}) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale || 4}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix=""
            suffix=" ml"
            displayType="text"
        />
    );
};

export const RenderCM = ({value, className}) => {
    if (value !== undefined) {
        return (
            <NumberFormat
                className={className}
                decimalScale={2}
                fixedDecimalScale
                value={value}
                thousandSeparator
                prefix=""
                suffix=" cm"
                displayType="text"
            />
        );
    } else {
        return <span>---</span>
    }
};

export const RenderDateTime = ({value, className}) => {
    if (value) {
        const fecha = new Date(value);
        return (
            <span className={className}>
        {fecha.toLocaleDateString()}
                {' '}
                {fecha.toLocaleTimeString()}
      </span>
        );
    }
    return (<span className={className}>{value}</span>);
};

export const formatActivo = (cell) => {
    if (cell) {
        return <img src={require('assets/img/icons/activo.png')} alt="Activo" className="action_img"/>
    } else {
        return <img src={require('assets/img/icons/inactivo.png')} alt="Inactivo" className="action_img"/>
    }
};

export const TooltipFormat = (cell, row) => {
    if (row.de_baja) {
        return (
            <Popup
                trigger={<span className="tachado-touch">{cell.nombre ? cell.nombre : cell}</span>}
                position="top center"
                on="hover"
            >
                <span className="popup-title">FINCA DADA DE BAJA</span>
                <span className="popup-text">{row.justificacion_baja}</span>
            </Popup>
        )
    } else {
        return <span>{(cell && cell.nombre) ? cell.nombre : cell}</span>
    }
};

export const formatoPorcentaje = (cell, row) => {
    return <div>
        <div className="CircularProgressbarContainer-sm">
            <div className="circular-extra-text">{`${cell}%`}</div>
            <div style={{position: 'absolute'}}>
                <CircularProgressbar
                    value={cell}
                    strokeWidth={12}
                    styles={buildStyles({
                        pathColor: "#035b2f",
                        textColor: "#035b2f",
                    })}
                />
            </div>
        </div>
    </div>
};

export const RenderAlmacen = ({value, className}) => {
    return (<span className={className}>{value}</span>);
};

export const ReadFields = {
    renderCurrency: RenderCurrency,
    renderNumber: RenderNumber,
};
