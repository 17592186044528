import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import { PRECIO_INTROSA, PRECIO_ERSA } from '../../../utility/constants';

const LOADER = 'LOADER_FINCAS';
const DATA = 'DATA_FINCAS';
const ITEM_DATA = 'ITEM_FINCAS';
const PAGE = 'PAGE_FINCAS';
const ORDERING = 'ORDERING_FINCAS';
const SEARCH = 'SEARCH_FINCAS';
const TIPOS_VEHICULOS_FINCAS = 'TIPOS_VEHICULOS_FINCAS';
const STEP_FINCAS = 'STEP_FINCAS';
// Variables para el Reporte de ubicación geográfica de fincas
const REPORTE_UBICACION_FINCA_ID = 'REPORTE_UBICACION_FINCAS_FINCA_ID';
const REPORTE_UBICACION_DATA     = 'REPORTE_UBICACION_FINCAS_DATA';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().fincas;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.incluir_incompletas = true;
    dispatch(setLoader(true));
    api.get('finca', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'finca'}/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('fincaForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    const _data = _.cloneDeep(data);
    _data.banco          !== null && typeof _data.banco          === 'object' && (_data.banco = data.banco.id);
    _data.propietario    !== null && typeof _data.propietario    === 'object' && (_data.propietario = data.propietario.id);
    _data.perfil         !== null && typeof _data.perfil         === 'object' && (_data.perfil = data.perfil.id);
    _data.regimen_fiscal !== null && typeof _data.regimen_fiscal === 'object' && (_data.regimen_fiscal = data.regimen_fiscal.id);
    _data.tipo_pago      !== null && typeof _data.tipo_pago      === 'object' && (_data.tipo_pago = data.tipo_pago.id);
    _data.informacion_completa = true;  // Desde la WebApp se proporciona toda la información
    // if (_data.tipo_precio_latex === PRECIO_INTROSA) {
    //     _data.precio_definido_latex = 0;
    // }else if (_data.tipo_precio_latex === PRECIO_ERSA) {
    //     _data.diferencial_latex = 0;
    // }

    // if (_data.tipo_precio_solido === PRECIO_INTROSA) {
    //     _data.precio_definido_solido = 0;
    // }else if (_data.tipo_precio_solido === PRECIO_ERSA) {
    //     _data.diferencial_solido = 0;
    // }
    dispatch(setLoader(true));
    api.post('finca', _data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/fincas'));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (data, id ) => (dispatch) => {
    const _data = _.cloneDeep(data);
    _data.banco          !== null && typeof _data.banco          === 'object' && (_data.banco = data.banco.id);
    _data.propietario    !== null && typeof _data.propietario    === 'object' && (_data.propietario = data.propietario.id);
    _data.perfil         !== null && typeof _data.perfil         === 'object' && (_data.perfil = data.perfil.id);
    _data.regimen_fiscal !== null && typeof _data.regimen_fiscal === 'object' && (_data.regimen_fiscal = data.regimen_fiscal.id);
    _data.tipo_pago      !== null && typeof _data.tipo_pago      === 'object' && (_data.tipo_pago = data.tipo_pago.id);
    _data.informacion_completa = true;  // Desde la WebApp se proporciona toda la información
    // if (_data.tipo_precio_latex === PRECIO_INTROSA) {
    //     _data.precio_definido_latex = 0;
    // }
    // if (_data.tipo_precio_solido === PRECIO_INTROSA) {
    //     _data.precio_definido_solido = 0;
    // }
    dispatch(setLoader(true));
    api.put(`${'finca'}/${id}`, _data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/fincas'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'finca'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const desactivar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`finca/${id}/desactivar`, data).then(() => {
        dispatch(listar());
        NotificationManager.success('Finca Desactivada', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const activar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`finca/${id}/activar`).then(() => {
        dispatch(listar());
        NotificationManager.success('Finca Activada', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('fincaForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().fincas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};
// Métodos para el Reporte de ubicación geográfica de Fincas
const ubicacionFincaChange = (finca_id) => (dispatch) => {
    dispatch({type: REPORTE_UBICACION_FINCA_ID, finca_id});
    dispatch(getReporteUbicacionFincas(finca_id));
};
const getReporteUbicacionFincas = (finca_id = -1) => (dispatch, getStore) => {
    const params = {
        incluir_incompletas: true,
    };
    finca_id !== -1 && (params.finca_id = finca_id);
    dispatch(setLoader(true));
    api.get('finca/get_info_fincas', params).then((response) => {
        dispatch({ type: REPORTE_UBICACION_DATA, fincas: response });
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    desactivar,
    activar,
    cleanForm,
    // Métodos para el Reporte de ubicación geográfica de Fincas
    ubicacionFincaChange,
    getReporteUbicacionFincas,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    // Reporte de ubicación geográfica de fincas
    [REPORTE_UBICACION_FINCA_ID]: (state, {finca_id}) => {
        return {
            ...state,
            reporte_ubicacion_fincas: {...state.reporte_ubicacion_fincas, finca_id}
        };
    },
    [REPORTE_UBICACION_DATA]: (state, {fincas}) => {
        return {
            ...state,
            reporte_ubicacion_fincas: {...state.reporte_ubicacion_fincas, fincas}
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    reporte_ubicacion_fincas: {
        finca_id: -1,
        fincas: {},
    }
};

export default handleActions(reducers, initialState);
