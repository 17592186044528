import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch } from 'Utils/renderField/renderField';
import Alerta from "Utils/Alerta/Alerta";

let ReajusteForm = props => {
    const { handleSubmit, activar, closeModal } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <Alerta texto="Para reajustar la justificación es requerida." />
                <div className="row col-12 col-md-8 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="cantidad" className="m-0">CANTIDAD</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="cantidad" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="justificacion" className="m-0">INGRESA JUSTIFICACIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="justificacion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12 row buttons-box mt-1">
                    <button className="btn btn-outline-dark mr-md-5" onClick={(e)=> {e.preventDefault(); props.closeModal()}}>CANCELAR</button>
                    <button type="submit" className="btn btn-outline-primary">ACTUALIZAR</button>
                </div>
            </div>
        </form>
    )
};
ReajusteForm = reduxForm({
    form: 'bajaForm',
    validate: data => {
        return validate(data, {
            'justificacion': validators.exists()('Campo requerido.'),
            'cantidad': validators.exists()('Campo requerido.'),
        })
    }
})(ReajusteForm);

export default ReajusteForm
