import React, { Component } from 'react'
import CardEmpty from '../../../../Utils/Cards/CardEmpty'
import LoadMask from '../../../../Utils/LoadMask/LoadMask'
import Grid from '../../../../Utils/Grid'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { standardActions } from '../../../../Utils/Grid/StandardActions'
import moment from 'moment'

class Reporte extends Component {
    render() {
        const { loader, data_liquidaciones, pageLiquidacionesChange, page_liquidaciones } = this.props
        return (
            <LoadMask loading={false} blur>
                <div className="mb-4">
                    <CardEmpty>
                        {data_liquidaciones &&
                            <Grid hover striped loading={loader} data={data_liquidaciones}
                                pagination onPageChange={pageLiquidacionesChange} page={page_liquidaciones}
                            >
                                <TableHeaderColumn
                                    dataField="codliqu"
                                    dataAlign="center"
                                    width="10%"
                                    dataFormat={standardActions({ver: 'liquidaciones'})}
                                />
                                <TableHeaderColumn
                                    dataField="codliqu"
                                    isKey
                                >
                                    ID LIQUIDACIÓN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fechaliquidacion"
                                    dataFormat={cell => moment(cell).format('DD-MM-YYYY')}
                                >
                                    FECHA DE LIQUIDACIÓN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="finca"
                                >
                                    FINCA
                                </TableHeaderColumn>
                            </Grid>
                        }
                    </CardEmpty>
                </div>
            </LoadMask>
        )
    }
}

export default Reporte