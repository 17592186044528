import React, {Component} from 'react'
import Card from "Utils/Cards/Card"
import PendientesLatex from './PendientesLatex';

export default class IngresosLaboratorioList extends Component{
    componentWillMount() {
        const { setTab } = this.props;
        setTab('LÁTEX', 'validar');
    }

    render() {
        const { data_latex_validar:data } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="laboratorio_activo"
                    titulo="PENDIENTES"
                    subtitulo="Validar DRC Látex"
                >
                    <PendientesLatex dataLatex={data} {...this.props}/>
                </Card>
            </div>
        )
    }
}
