import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './TransportistaForm';
import { TransportistaUpdateForm } from './TransportistaForm';

export default class TransportistaSave extends Component {
	state = {
		editar: false,
    };
    componentWillMount() {
		// Para editar el registro se proporciona el ID en la URL
		if (this.props.match.params.id) {
			this.props.leer(this.props.match.params.id);
			this.setState({
				editar: true,
			});
		}
    }
    actualizar = (data) => {
		// Llama a la función encargada de editar el registro
		this.props.editar(this.props.match.params.id, data)
	};
    render() {
        const { crear, updateData, loader } = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="configuraciones_activo"
                    titulo="TRANSPORTISTAS"
                    subtitulo="Nuevo">
                    <LoadMask loading={loader} blur>
                        {
                            this.state.editar ?
                                <TransportistaUpdateForm
                                    onSubmit={this.actualizar}
                                    updateData={updateData}
                                    editando={true} />
                                :
                                <Form onSubmit={crear}/>
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
