import React, { Component } from 'react'
import Card from '../../../Utils/Cards/Card';
import Pendientes from "./Pendientes";

export default class IngresosList extends Component{
    componentWillMount() {
        const { listar, force_update_data } = this.props;
        force_update_data && listar();
    }

    render() {
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="compras_activo"
                    titulo="COMPRAS"
                    subtitulo="Liquidaciones"
                >
                    <Pendientes {...this.props} />
                </Card>
            </div>
        )
    }
}
