import React, { Component } from 'react'
import { FiltrosFecha } from '../../../Utils/Filters'
import Grid from '../../../Utils/Grid'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { standardActions } from '../../../Utils/Grid/StandardActions';
import { EN_CURSO, FINALIZADO } from '../../../../../utility/constants';
import moment from 'moment';

class Finalizados extends Component {
    render() {
        const { fecha_inicial_finalizados, fecha_final_finalizados, page, loader, data } = this.props
        const { fechaInicialFinalizadosChange, fechaFinalFinalizadosChange } = this.props
        const { listar: onPageChange, onSortChange} = this.props
        return (
            <React.Fragment>
                <FiltrosFecha {...this.props} fecha_inicial={fecha_inicial_finalizados} fecha_final={fecha_final_finalizados} fechaInicioChange={fechaInicialFinalizadosChange} fechaFinChange={fechaFinalFinalizadosChange} />
                <Grid
                    striped
                    data={data}
                    loading={loader}
                    pagination
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                    page={page}
                >
                    <TableHeaderColumn
                        dataField="id"
                        isKey
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({ver: 'despacho'})}
                    />
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        width="5%"
                    >
                        ID
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="producto"
                        dataSort
                        dataFormat={cell => cell.nombre}
                    >
                            PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cliente"
                        dataSort
                        dataFormat={cell => cell.nombre}
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="piloto"
                        dataSort
                        dataFormat={cell => cell.nombre}
                    >
                        PILOTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="peso_tara_equipo"
                        dataSort
                        dataFormat={cell => `<div>${cell} kg</div>`}
                    >
                        PESO DE ENTRADA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="peso_bruto_despacho"
                        dataSort
                        dataFormat={cell => `<div>${cell} kg</div>`}
                    >
                        PESO DE SALIDA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="peso_neto"
                        dataSort
                        dataFormat={cell => `<div>${cell} kg</div>`}
                    >
                        PESO NETO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="estado"
                        dataFormat={cell => {
                            switch(cell) {
                                case EN_CURSO:
                                    return '<div style="color: #b73333;">En curso</div>';
                                case FINALIZADO:
                                    return '<div>Finalizado</div>';
                            }
                        }}
                    >
                        ESTADO
                    </TableHeaderColumn>
                </Grid>
            </React.Fragment>
        )
    }
}

export default Finalizados