import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteLaboratorio extends Component {
    componentWillMount() {
        const { listarDataReportes, page } = this.props;
        listarDataReportes(page);
    }

    render() {
        const {
            tipoMateriaChange,
            tipo_materia_reporte,
            // LATEX
            pageReporteChangeLatex,
            filtroFechaInicioLatex,
            filtroFechaFinLatex,
            filtroFincaLatex,
            filtroMateriaPrimaLatex,
            page_reporte_latex,
            fecha_inicio_reporte_latex,
            fecha_fin_reporte_latex,
            finca_id_reporte_latex,
            materia_prima_id_reporte_latex,
            filtrarData,
            // SOLIDO
            pageReporteChangeSolido,
            filtroFechaInicioSolido,
            filtroFechaFinSolido,
            filtroFincaSolido,
            filtroMateriaPrimaSolido,
            page_reporte_solido,
            fecha_inicio_reporte_solido,
            fecha_fin_reporte_solido,
            finca_id_reporte_solido,
            materia_prima_id_reporte_solido,
            // GRAFICA
            graficaReporte,
            grafica_reporte
        } = this.props;

        let fechaInicialChange = null, fechaFinalChange = null, fincaChange = null, materiaPrimaChange = null, pageChange = null;
        let fecha_inicial = null, fecha_final = null, finca_id = null, materia_prima_id = null, page = null;
        switch(tipo_materia_reporte) {
            case 'LATEX':
                pageChange = pageReporteChangeLatex;
                fechaInicialChange = filtroFechaInicioLatex;
                fechaFinalChange = filtroFechaFinLatex;
                fincaChange = filtroFincaLatex;
                materiaPrimaChange=filtroMateriaPrimaLatex;
                page = page_reporte_latex;
                fecha_inicial = fecha_inicio_reporte_latex;
                fecha_final = fecha_fin_reporte_latex;
                finca_id = finca_id_reporte_latex;
                materia_prima_id = materia_prima_id_reporte_latex;
                break;
            case 'SOLIDO':
                pageChange = pageReporteChangeSolido;
                fechaInicialChange = filtroFechaInicioSolido;
                fechaFinalChange = filtroFechaFinSolido;
                fincaChange = filtroFincaSolido;
                materiaPrimaChange=filtroMateriaPrimaSolido;
                page = page_reporte_solido;
                fecha_inicial = fecha_inicio_reporte_solido;
                fecha_final = fecha_fin_reporte_solido;
                finca_id = finca_id_reporte_solido;
                materia_prima_id = materia_prima_id_reporte_solido;
                break;
        }
        console.log(tipo_materia_reporte);

        return(
            <div className="mb-4 col-12">
                <Card
                    icon="laboratorio_activo"
                    titulo="LABORATORIO"
                    noBorder
                    subtitulo="Reporte"
                >
                    <Tabs
                        activeKey={tipo_materia_reporte}
                        tabBarPosition="top"
                        onChange={tipo_materia_reporte =>{tipoMateriaChange(tipo_materia_reporte)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="LÁTEX" key="LATEX"><div></div></TabPane>
                        <TabPane tab="SÓLIDOS" key="SOLIDO"><div></div></TabPane>
                    </Tabs>
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial}
                            fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                            filtroFincaChange={fincaChange} finca_id={finca_id}
                            filtroMateriaChange={materiaPrimaChange} materia_id={materia_prima_id}
                            tipo_materia={tipo_materia_reporte} filtrar_data_laboratorio={filtrarData}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} pageChange={pageChange} page={page} graficaReporte={graficaReporte} grafica_reporte={grafica_reporte} ></Reporte>
                </Card>
            </div>
        )
    }
}
