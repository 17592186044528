import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import {renderField, renderSwitch, SelectField} from '../../../Utils/renderField/renderField';
import {generos} from "../../../../../utility/constants";

let TipoVehiculoForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">NOMBRE DE TIPO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                        <div className="row">
                            <div className="col-md-8 text-gris">
                                <label htmlFor="validar_placa" className="m-0">VALIDAR PLACA</label>
                            </div>
                            <div className="col-md-4">
                                <Field name="validar_placa" component={renderSwitch} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="prefijo_placa" className="m-0">PREFIJO PLACA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="prefijo_placa" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/tipos_vehiculo">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
TipoVehiculoForm = reduxForm({
    form: 'TipoVehiculoForm',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    },
    initialValues: {
        validar_placa: false,
    }
})(TipoVehiculoForm);

export default TipoVehiculoForm
