import React, { Component } from 'react'
import Grid from '../../../../Utils/Grid'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { RenderKilogramo, RenderNumber, RenderNumberPercentage, RenderDollar, RenderCurrency, RenderCambio } from '../../../../Utils/renderField/renderReadField'
import { PrintContainer, actions as printActions } from '../../../../Utils/Print'
import CardEmpty from '../../../../Utils/Cards/CardEmpty'
import LoadMask from '../../../../Utils/LoadMask/LoadMask'
import moment from 'moment'

const getTotales = recepciones => {
    let totales = {
        totalKilosHumedos: 0,
        totalKilosSecos: 0,
        totalDolares: 0,
        totalQuetzales: 0
    }
    recepciones.forEach(recepcion => {
        if (recepcion.pesokh !== null) {
            totales.totalKilosHumedos += recepcion.pesokh
        }
        if (recepcion.pesoks !== null) {
            totales.totalKilosSecos += recepcion.pesoks
        }
        if (recepcion.totald !== null) {
            totales.totalDolares += recepcion.totald
        }
        if (recepcion.totalq !== null) {
            totales.totalQuetzales += recepcion.totalq
        }
    })
    return totales
}

const sumaDescuentos = (retencion, retencionISR, descuentos) => (parseFloat(retencion) + parseFloat(retencionISR) + parseFloat(descuentos))

class LiquidacionHistoricoBoleta extends Component {
    state = {
        fechaHoraImpresion: moment().format('DD/MM/YYYY hh:mm:ss A')
    }

    componentDidMount() {
        this.props.leerLiquidacion(this.props.match.params.id)
    }

    imprimirBoleta = event => {
        this.setState({
            fechaHoraImpresion: moment().format('DD/MM/YYYY hh:mm:ss A')
        }, () => printActions.print('boleta-liquidacion-historico'))
    }
    
    render() {
        const { loader, item_liquidacion, recepciones_liquidacion } = this.props;
        const totales = getTotales(recepciones_liquidacion.results)
        const totalDescuento = sumaDescuentos(item_liquidacion.retencion, item_liquidacion.retencionISR, item_liquidacion.descuentos)
        return (
            <div>
                <LoadMask loading={loader} blur>

                    <CardEmpty>
                        <div className="print-only mt-0">
                            <PrintContainer name="boleta-liquidacion-historico">
                            <div className="col-12 p-0 mt-0 media-carta-arriba">
                                <div className="contenido-impresion verde-secundario mt-0">
                                    <div className="col-12 mt-0">
                                        <div className="col-12 d-flex justify-content-center m-0 p-0">
                                            <div className="col-12 pl-2">
                                                <div className="col-12 d-flex justify-content-center mt-0 mb-1">
                                                    <div className="col-12 pl-0 m-0">
                                                        <div className="text-left mb-2 m-0 p-0">
                                                            <div className="col-12">
                                                                <img src={require('../../../../../../../assets/img/logo.png')}
                                                                    className="d-inline-block align-top"
                                                                    alt="Logo"
                                                                    height="60px"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0 mt-0 d-flex justify-content-between align-items-end">
                                                            <div className="d-flex align-items-center col-3">
                                                                <img src={require('../../../../../../../assets/img/icons/historico_activo.png')} alt="Historico" className="title_img mr-2"/>
                                                                <img src={require('../../../../../../../assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2"/>
                                                                <h6 className="text-black mb-0">BOLETA DE LIQUIDACIÓN (HISTÓRICO)</h6>
                                                            </div>
                                                            <div className="d-flex flex-column text-left col-3">
                                                                <span className="text-black pl-2">Fecha liquidación:</span>
                                                                <span className="text-black pl-2">{item_liquidacion && item_liquidacion.fechaliquidacion ? moment(item_liquidacion.fechaliquidacion).format('DD/MM/YYYY') : ''}</span>
                                                            </div>
                                                            <div className="d-flex flex-column text-left col-3">
                                                                <span className="text-black pl-2">Fecha de impresión</span>
                                                                <span className="text-black pl-2">{this.state.fechaHoraImpresion}</span>
                                                            </div>
                                                            <div className="d-flex flex-column text-left col-3">
                                                                <span className="text-black pl-2">Semana</span>
                                                                <span className="text-black pl-2 bold">{item_liquidacion && item_liquidacion.fechaliquidacion ? moment(item_liquidacion.fechaliquidacion).isoWeek() : '--'}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0 mt-2 d-flex justify-content-between align-items-start">
                                                            <div className="d-flex flex-column col-3">
                                                                <label className="pl-2 m-0 mt-1">ID LIQUIDACIÓN</label>
                                                                <h5 className="text-black bold pl-2 mb-0">{item_liquidacion ? item_liquidacion.codliqu : ''}</h5>
                                                                <label className="pl-2 m-0 mt-1">FINCA</label>
                                                                <span className="text-black pl-2 mb-0">{item_liquidacion ? item_liquidacion.finca : ''}</span>
                                                            </div>
                                                            <div className="d-flex flex-column col-3">
                                                                <label className="pl-2 m-0 mt-1">BENEFICIARIO</label>
                                                                <span className="text-black pl-2 mb-0">{item_liquidacion && item_liquidacion.txtNombreCuenta ? item_liquidacion.txtNombreCuenta : 'No especifica'}</span>
                                                            </div>
                                                            <div className="d-flex flex-column col-3">
                                                                <label className="pl-2 m-0 mt-1">LUGAR DE RECEPCIÓN</label>
                                                                <span className="text-black pl-2 mb-0">{item_liquidacion && item_liquidacion.lugar ? item_liquidacion.lugar : 'No especifica'}</span>
                                                                <label className="pl-2 m-0 mt-1">TIPO DE PAGO</label>
                                                                <span className="text-black pl-2 mb-0">{item_liquidacion && item_liquidacion.txtTipoPago ? item_liquidacion.txtTipoPago : 'No especifica'}</span>
                                                            </div>
                                                            <div className="d-flex flex-column col-3">
                                                                <label className="pl-2 m-0 mt-1">CUENTA NO.</label>
                                                                <span className="text-black pl-2 mb-0">{item_liquidacion && item_liquidacion.txtCuentaBanco ? item_liquidacion.txtCuentaBanco : 'No especifica'}</span>
                                                                <label className="pl-2 m-0 mt-1">BANCO</label>
                                                                <span className="text-black pl-2 mb-0">{item_liquidacion && item_liquidacion.txtBanco ? item_liquidacion.txtBanco : 'No especifica'}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-2 columna-derecha m-0">
                                                            <div className="col-12 form-group np-r p-0 mb-1 inicio-tabla">
                                                                <Grid striped bordered={false} data={recepciones_liquidacion} pagination={false}>
                                                                    <TableHeaderColumn
                                                                        dataField="codentr"
                                                                        isKey
                                                                    >
                                                                        RECEPCIÓN NO.
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="fecharegistro"
                                                                        dataFormat={cell => moment(cell).format('DD/MM/YYYY')}
                                                                    >
                                                                        FECHA
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="producto"
                                                                    >
                                                                        PRODUCTO
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="pesokh"
                                                                        dataFormat={cell => {
                                                                            if (cell === null) {
                                                                                return <RenderKilogramo value={0}/>
                                                                            }
                                                                            return <RenderKilogramo value={cell}/>
                                                                        }}
                                                                    >
                                                                        KG HÚMEDOS
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="drcPromedio"
                                                                        dataFormat={cell => <RenderNumberPercentage value={cell}/>}
                                                                    >
                                                                        DRC %
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="pesoks"
                                                                        dataFormat={cell => {
                                                                            if (cell === null) {
                                                                                return <RenderKilogramo value={0}/>
                                                                            }
                                                                            return <RenderKilogramo value={cell}/>
                                                                        }}
                                                                    >
                                                                        KG SECOS
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="precioK"
                                                                        dataFormat={cell => <RenderDollar value={cell}/>}
                                                                    >
                                                                        PRECIO
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="totald"
                                                                        dataFormat={cell => <RenderDollar value={cell}/>}
                                                                    >
                                                                        SUBTOTAL $
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataFormat={(cell, row) => ((row.totalq / row.totald).toFixed(5))}
                                                                    >
                                                                        CAMBIO
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="totalq"
                                                                        dataFormat={cell => <RenderCurrency value={cell}/>}
                                                                    >
                                                                        SUBTOTAL Q
                                                                    </TableHeaderColumn>
                                                                </Grid>
                                                                <div className="react-bs-table-container descuentos-table">
                                                                    <div className="react-bs-table">
                                                                        <div className="react-bs-container-header table-header-wrapper">
                                                                            <table className="table table-sriped table-hover table-bordered">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>PAGOS ADICIONALES</th>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <table className="table table-striped table-bordered table-hover mb-0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{width: '90%'}} className="bold">FLETES (+)</td>
                                                                            <td style={{width: '10%'}} className="bold"><RenderCurrency value={item_liquidacion.otrosPagos}/></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{width: '90%'}} className="bold">OTROS PAGOS (+)</td>
                                                                            <td style={{width: '10%'}} className="bold"><RenderCurrency value={item_liquidacion.otrosPagos}/></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className="react-bs-table-container total-final">
                                                                    <div className="react-bs-table">
                                                                        <div className="react-bs-container-header table-header-wrapper">
                                                                            <table className="table table-hover table-bordered">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th className="text-left">TOTAL</th>
                                                                                        <th/>
                                                                                        <th/>
                                                                                        <th><RenderKilogramo value={totales.totalKilosSecos}/></th>
                                                                                        <th/>
                                                                                        <th><RenderKilogramo value={totales.totalKilosHumedos}/></th>
                                                                                        <th/>
                                                                                        <th><RenderDollar value={totales.totalDolares}/></th>
                                                                                        <th/>
                                                                                        <th><RenderCurrency value={totales.totalQuetzales}/></th>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="col-9 m-0 p-0 mt-3">
                                                                <CardEmpty noPadding noShadow>
                                                                    <div className="col-12 mb-1 mt-1">
                                                                        <div className="col-12 p-0 mt-3 m-0 d-flex justify-content-between flex-wrap">
                                                                            <div className="col-12">
                                                                                <h6 className="text-black mb-0 ml-2">DATOS PARA FACTURAR</h6>
                                                                            </div>
                                                                            <div className="col-3 d-flex flex-column">
                                                                                <label className="pl-2 m-0 mt-1">A NOMBRE DE</label>
                                                                                <span className="pl-2 text-black">ENTRE RIOS S.A.</span>
                                                                            </div>
                                                                            <div className="col-3 d-flex flex-column">
                                                                                <label className="pl-2 m-0 mt-1">NIT</label>
                                                                                <span className="pl-2 text-black">564260-4</span>
                                                                            </div>
                                                                            <div className="col-6 d-flex flex-column">
                                                                                <label className="pl-2 m-0 mt-1">DIRECCIÓN</label>
                                                                                <span className="pl-2 text-black">3 Calle 21-81 Zona 15 Vista Hermosa I</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CardEmpty>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center columna-izquierda mt-2 p-0 m-0 mb-5">
                                            <div className="col-11 p-0m-0">
                                                <div className="col-12 p-0 mt-3">
                                                    <div className="col-12 m-0">
                                                        <span className="text-black">Retención de ISR de 0 a  30,000 se retiene el 5% - Mayor a 30,000 se retiene el 7%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0 mt-0 media-carta-abajo">
                                <div className="contenido-impresion azul mt-0">
                                    <div className="col-12 mt-0">
                                        <div className="col-12 d-flex justify-content-center m-0 p-0">
                                            <div className="col-12 pl-2">
                                                <div className="col-12 d-flex justify-content-center mt-0 mb-1">
                                                    <div className="col-12 pl-0 m-0">
                                                        <div className="text-left mb-2 m-0 p-0">
                                                            <div className="col-12">
                                                                <img src={require('../../../../../../../assets/img/logo.png')}
                                                                    className="d-inline-block align-top"
                                                                    alt=""
                                                                    height="60px"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0 mt-0 d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center col-6">
                                                                <img src={require('../../../../../../../assets/img/icons/historico_activo.png')} alt="Historico" className="title_img mr-2"/>
                                                                <img src={require('../../../../../../../assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2"/>
                                                                <h6 className="text-black mb-0">BOLETA DE INTEGRACIÓN DE PAGO (HISTÓRICO)</h6>
                                                            </div>
                                                            <div className="d-flex flex-column text-left col-3">
                                                                <span className="text-black pl-2">Fecha de liquidación:</span>
                                                                <span className="text-black pl-2">{item_liquidacion && item_liquidacion.fechaliquidacion ? moment(item_liquidacion.fechaliquidacion).format('DD/MM/YY') : '--'}</span>
                                                            </div>
                                                            <div className="d-flex flex-column text-left col-3">
                                                                <span className="text-black pl-2">Fecha de impresión:</span>
                                                                <span className="text-black pl-2">{this.state.fechaHoraImpresion}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0 mt-2 d-flex justify-content-between align-items-start">
                                                            <div className="d-flex flex-column col-8">
                                                                <div className="d-flex mb-1">
                                                                    <div className="col-3">
                                                                        <label htmlFor="" className="mb-0 pl-3">FINCA:</label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="text-black pl-2">{item_liquidacion ? item_liquidacion.finca : "No especifica"}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center col-3">
                                                                <label className="pr-2 pl-0">INTEGRACIÓN</label>
                                                                <h5 className="text-black bold pr-2">{item_liquidacion ? item_liquidacion.codliqu : ''}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 mt-3 m-0 d-flex justify-content-center">
                                                    <div className="col-11 p-0 columna-derecha">
                                                        <div className="col-12 form-group np-r p-0 mb-1 inicio-tabla">
                                                            <div className="react-bs-table-container">
                                                                <div className="react-bs-table">
                                                                    <div className="react-bs-container-header table-header-wrapper">
                                                                        <table className="table table-hover table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th style={{width: '15%'}}>DOCUMENTO</th>
                                                                                    <th style={{width: '12%'}}>NÚMERO</th>
                                                                                    <th style={{width: '15%'}}>BANCO</th>
                                                                                    <th style={{width: '13%'}}>FECHA</th>
                                                                                    <th style={{width: '15%'}}>VALOR FACTURA</th>
                                                                                    <th style={{width: '15%'}}>VALOR DESCUENTO</th>
                                                                                    <th style={{width: '15%'}}>TOTAL</th>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className="table table-striped table-bordered table-hover mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{width: '15%'}} className="bold">Factura</td>
                                                                        <td style={{width: '12%'}} className="bold">{item_liquidacion && item_liquidacion.FacturaEspecialCodigo ? item_liquidacion.FacturaEspecialCodigo : '--'}</td>
                                                                        <td style={{width: '15%'}} className="bold">{item_liquidacion && item_liquidacion.banco ? item_liquidacion.banco : ''}</td>
                                                                        <td style={{width: '13%'}} className="bold">{item_liquidacion && item_liquidacion.fechaliquidacion ? moment(item_liquidacion.fechaliquidacion).format('DD/MM/YYYY') : '--'}</td>
                                                                        <td style={{width: '15%'}} className="bold">{<RenderCurrency value={totales.totalQuetzales}/>}</td>
                                                                        <td style={{width: '30%'}} className="bold"/>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="react-bs-table-container total">
                                                                <div className="react-bs-table">
                                                                    <div className="react-bs-container-header table-header-wrapper">
                                                                        <table className="table table-hover table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th style={{width: '15%'}}>DESCUENTOS</th>
                                                                                    <th style={{width: '85%'}}/>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className="table table-striped table-bordered table-hover mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{width: '70%'}} className="bold">Retención</td>
                                                                        <td style={{width: '30%'}} className="bold">{item_liquidacion ? <RenderCurrency value={item_liquidacion.retencion}/> : 0}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{width: '70%'}} className="bold">Retención ISR</td>
                                                                        <td style={{width: '30%'}} className="bold">{item_liquidacion ? <RenderCurrency value={item_liquidacion.retencionISR}/> : 0}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{width: '70%'}} className="bold">Descuentos varios</td>
                                                                        <td style={{width: '30%'}} className="bold">{item_liquidacion ? <RenderCurrency value={item_liquidacion.descuentos}/> : 0}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="react-bs-table-container total-final">
                                                                <div className="react-bs-table">
                                                                    <div className="react-bs-container-header table-header-wrapper">
                                                                        <table className="table table-hover table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th style={{width: '55%'}} className="bold">TOTALES</th>
                                                                                    <th style={{width: '15%'}} className="gris-total">{<RenderCurrency value={totales.totalQuetzales}/>}</th>
                                                                                    <th style={{width: '15%'}} className="gris-total">{item_liquidacion ? <RenderCurrency value={totalDescuento}/> : 0}</th>
                                                                                    <th style={{width: '15%'}} className="total-gris">{item_liquidacion ? <RenderCurrency value={totales.totalQuetzales - totalDescuento}/> : 0}</th>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center columna-izquierda mt-2 p-0 m-0 mb-5">
                                            <div className="col-11 p-0 m-0">
                                                <div className="d-flex col-12 p-0 mt-3">
                                                    <div className="col-3 p-0">
                                                        <div className="col-12">
                                                            <span className="text-black">Preparado por: Liquidaciones</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 p-0">
                                                        <div className="col-12 text-center">
                                                            <span className="text-black">Revisado por: Depto. de Compras</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 p-0">
                                                        <div className="col-12 text-center">
                                                            <span className="text-black">Vo. Bo. Contabilidad</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </PrintContainer>
                        </div>
                    </CardEmpty>
                </LoadMask>
                <div className="mb-4 col-12 p-0">
                    <div className="d-flex mb-5">
                        <div className="d-flex col-12">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <button type="button" className="btn btn-outline-dark mr-5" onClick={e => {
                                        e.preventDefault()
                                        this.props.history.goBack()
                                    }}>
                                        CERRAR
                                    </button>
                                    <button className="btn btn-primary" onClick={this.imprimirBoleta}>IMPRIMIR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LiquidacionHistoricoBoleta