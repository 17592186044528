import React from 'react';
import GoogleMapReact from 'google-map-react';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import MarkerLogo from '../../../../../../assets/img/icons/google_map_marker.svg';
import { GOOGLE_MAPS_API_KEY } from '../../../../../utility/constants';

const InformacionFinca = (props) => {
    const { finca } = props;
    return (
        <div className="d-flex flex-column google-maps-marker">
            <img src={MarkerLogo} alt="Marcador" width={18} height={20} className="marker_indicator"/>
            <div className="marker_content">
                <CardEmpty noPadding noShadow>
                    <div className="d-flex flex-column justify-content-start align-items-start mx-3 my-3">
                        <label className="pl-0 azul">{finca.nombre ? finca.nombre.toUpperCase() : '--'}</label>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            <span className="gris">{finca.departamento_text} / {finca.municipio_text}</span>
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <span className="gris">Área cultivada:</span>
                                    <span className="gris">Área total:</span>
                                </div>
                                <div className="d-flex flex-column justify-content-end align-items-end ml-1">
                                    <span className="gris">{finca.area_cultivada} {`${finca.medida_area_cultivada_text}${parseFloat(finca.area_cultivada) === 1 ? '' : 's'}`}</span>
                                    <span className="gris">{finca.area_finca} {`${finca.medida_area_finca_text}${parseFloat(finca.area_finca) === 1 ? '' : 's'}`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardEmpty>
            </div>
        </div>
    );
}

const Reporte = (props) => {
    const {
        reporte_ubicacion_fincas: reporte,
    } = props;
    const fincas = reporte && reporte.fincas && reporte.fincas.results ? reporte.fincas.results.slice() : [];
    const center = {
        lat: 14.973960,
        lng: -90.420665,
    };
    const zoom = 8;
    return (
        <div className="flex-1" style={{
            width: '100%', height: '100%',
        }}
        >
            <CardEmpty noPadding noShadow>
                <div style={{ height: '70vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                        defaultCenter={center}
                        defaultZoom={zoom}
                    >
                        { fincas && fincas.map(finca => (
                            <InformacionFinca key={finca.id} lat={finca.lat} lng={finca.long} finca={finca} />
                        )) }
                    </GoogleMapReact>
                </div>
            </CardEmpty>
        </div>
    )
};

export default Reporte;