import React, { Component } from 'react'
import CardEmpty from '../../../../Utils/Cards/CardEmpty'
import LoadMask from '../../../../Utils/LoadMask/LoadMask'
import Grid from '../../../../Utils/Grid'
import { standardActions } from '../../../../Utils/Grid/StandardActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment'
import { RenderKilogramo } from '../../../../Utils/renderField/renderReadField'

class Reporte extends Component {
    render() {
        const { loader, data_recepciones, pageRecepcionesChange, page_recepciones } = this.props
        return (
            <LoadMask loading={false} blur>
                <div className="mb-4">
                    <CardEmpty>
                        {data_recepciones &&
                            <Grid hover striped loading={loader} data={data_recepciones}
                                pagination onPageChange={pageRecepcionesChange} page={page_recepciones}
                            >
                                <TableHeaderColumn
                                    dataField="codentr"
                                    dataAlign="center"
                                    dataFormat={standardActions({ver: 'recepciones'})}
                                />
                                <TableHeaderColumn
                                    dataField="codentr"
                                    isKey
                                    dataFormat={(cell, row) => <span className={row.anulado ? 'tachado rojo' : ''}>{cell}</span>}
                                >
                                    ID RECEPCIÓN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecharegistro"
                                    dataFormat={(cell, row) => <span className={row.anulado ? 'tachado rojo' : ''}>{moment(cell).format('DD-MM-YYYY')}</span>}
                                >
                                    FECHA DE RECEPCION
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn
                                    dataField="pesoentrada"
                                    dataFormat={(cell, row) => <span className={row.anulado ? 'tachado rojo' : ''}>{cell ? <RenderKilogramo value={cell}/> : '0.00 kg'}</span>}
                                >
                                    PESO FINCA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="pesosalida"
                                    dataFormat={(cell, row) => <span className={row.anulado ? 'tachado rojo' : ''}>{cell ? <RenderKilogramo value={cell}/> : '0.00 kg'}</span>}
                                >
                                    PESO TARA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataFormat={(cell, row) => {
                                        if (row.pesoentrada && row.pesosalida) {
                                            return <span className={row.anulado ? 'tachado rojo' : ''}>{<RenderKilogramo value={row.pesoentrada - row.pesosalida}/>}</span>
                                        }
                                        else return <span className={row.anulado ? 'tachado rojo' : ''}>{<RenderKilogramo value={0}/>}</span>
                                    }}
                                >
                                    PESO NETO
                                </TableHeaderColumn> */}
                                <TableHeaderColumn
                                    dataField="finca"
                                    dataFormat={(cell, row) => <span className={row.anulado ? 'tachado rojo' : ''}>{cell}</span>}
                                >
                                    FINCA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="placa"
                                    dataFormat={(cell, row) => <span className={row.anulado ? 'tachado rojo' : ''}>{cell}</span>}
                                >
                                    VEHÍCULO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombre_piloto"
                                    dataFormat={(cell, row) => <span className={row.anulado ? 'tachado rojo' : ''}>{cell}</span>}
                                >
                                    PILOTO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fechatransaccion"
                                    dataFormat={(cell, row) => <span className={row.anulado ? 'tachado rojo' : ''}>{moment(cell).format('DD-MM-YYYY')}</span>}
                                >
                                    FECHA DE SALIDA
                                </TableHeaderColumn>
                            </Grid>
                        }
                    </CardEmpty>
                </div>
            </LoadMask>
        )
    }
}

export default Reporte