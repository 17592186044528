import { connect } from 'react-redux'
import { actions } from '../../../../../../redux/modules/historico'
import HistoricoLiquidaciones from './HistoricoLiquidaciones'

const ms2p = state => {
    return {
        ...state.historico
    }
}

const md2p = {
    ...actions
}

export default connect(ms2p, md2p)(HistoricoLiquidaciones)