import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import ComprasForm from './ComprasForm';
import CambioModal from './CambioDRC/cambioModal';
import { v4 as uuidv4 } from 'uuid';

export default class ComprasCrear extends Component {
    state= {
        open: false,
        enableOne: true,
        uuid: uuidv4()
    };
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id,false,true);
            this.setState({id_compra: this.props.match.params.id})
        }
    }

    openModal = (tipo) => {
        if (tipo === "actualizar_drc"){
            this.setState({open:  true})
        }
    };
    closeModal = (tipo) => {
        this.setState({open:  false})
    };
    crearCompra = async (data) =>{
        // Si la Orden compra se crea con éxito, se deshabilitará el botón de GUARDAR
        if (this.state.enableOne) {
            const ordenCompraCreada = await this.props.crear(data, this.state.uuid);
            this.setState({ enableOne: !ordenCompraCreada });
        }
    }

    render() {
        const { updateData, loader, configuraciones, datos_drc, crear, fecha_inicio, fecha_final} = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="compras_activo"
                    titulo="COMPRAS"
                    subtitulo="Nuevo"
                    col="12"
                    noPaddingXContent
                >
                    <LoadMask loading={loader} blur>
                        <CambioModal open={this.state.open} closeModal={this.closeModal} {...this.props} {...this.state} item={datos_drc}/>
                        <ComprasForm
                            onSubmit={this.crearCompra}
                            openModal={this.openModal}
                            closeModal={this.closeModal}
                            updateData={updateData}
                            habilitarGuardar={this.state.enableOne}
                            {...this.props}
                            {...this.state}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
