import { api } from "api";
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { renderField, renderSwitch, renderDatePicker, AsyncSelectField, renderNumberKG, renderNumber } from '../../../Utils/renderField/renderField';
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import Alerta from "../../../Utils/Alerta/Alerta";
import DatePicker from "react-datepicker";
import moment from "moment";
import validador from './validate';
import { RenderKilogramo } from "../../../Utils/renderField/renderReadField";


const getProductos = (search) => {
    return api.get("materia_prima/productos_produccion", { search }).
        then((data) => {
            if (data){
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
    });
};

const getSupervisores = (search) => {
    return api.get("user/listado_supervisores", { search }).
        then((data) => {
            if (data){
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
    });
};

const renderPallets = ({fields, updateData, fechaProduccion, establecerPesoTotalProduccion, variable_cambio}) => {
    return(
    <div className="col-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "10%"}} />
                        <th className="text-center" style={{width: "25%"}}>NO. SERIE</th>
                        <th className="text-center" style={{width: "25%"}}>NO. PACAS</th>
                        <th className="text-center" style={{width: "30%"}}>PESO TOTAL</th>
                        <th style={{width: "10%"}} />
                    </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div>
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                    {fields.map((turno, index) => {
                        let todos = fields.getAll();
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        const dato_actual = fields.get(index); //Obtiene el producto actual
                        return(
                        <tr key={index}>
                            <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                     title="Borrar" className="action_img"
                                     onClick={() =>{
                                        fields.remove(index)
                                        // if (dato_actual.hasOwnProperty('id')) {
                                        //     dato_actual.activo = false;
                                        //     updateData(dato_actual, 'turnos');
                                        // } else {
                                        // }
                                     }}
                                />
                            </td>
                            <td className="sin-borde-top" style={{width: "25%"}}>
                                <Field
                                    name={`${turno}.numero_serie`}
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    onCambio={() => establecerPesoTotalProduccion(index)}
                                />
                            </td>
                            <td style={{width: "25%"}}>
                                <Field
                                    name={`${turno}.numero_pacas`}
                                    component={renderNumber}
                                    type="number"
                                    className="form-control"
                                    onCambio={() => establecerPesoTotalProduccion(index)}
                                    decimalScale={0}
                                    parse={val => parseFloat(val)}
                                />
                            </td>
                            <td style={{width: "30%"}}>
                                <h6 className="bold gris"><RenderKilogramo value={dato_actual.peso_total ? dato_actual.peso_total : 0}/></h6>
                            </td>
                            <td style={{width: "10%"}}></td>
                        </tr>
                    )})}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-center mt-2">
                <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({})}>
                    AGREGAR PALLET
                </button>
            </div>
        </div>
    </div>
)};

let CierreForm = props => {
    const { handleSubmit, fecha_inicio_form, fechaProduccion, no_produccion, editando, establecerPesoTotalProduccion, item, fin_produccion, cerrarProduccion} = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <div className="col-12 col-lg-8 d-flex flex-wrap justify-content-between align-items-baseline">
                    <h6 className="mb-3 ml-5">DATOS DE CIERRE</h6>
                    <label className="mb-3 mr-5">NO. PRODUCCIÓN <span className="gris text-md">{no_produccion}</span></label>
                </div>
                <div className="col-12 col-lg-8 mb-3">
                    <CardEmpty noShadow>
                        <div className="row col-12 p-0 m-0">
                            <div className="col-12 col-lg-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fecha_fin_produccion" className="m-0">FECHA DE CIERRE</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="fecha_fin_produccion"
                                        component={renderDatePicker}
                                        todayButton="Hoy"
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_total" className="m-0">PESO DE PRODUCCIÓN</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="peso_total"
                                        component={renderNumberKG}
                                        type="text"
                                        className="form-control"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </CardEmpty>
                </div>
                <div className="row col-12 col-lg-10">
                    <div className="col-12">
                        <div className="row col-12 m-0 py-3">
                            <h6 className="">DATOS DE PALLETS</h6>
                            <FieldArray name="pallets" component={renderPallets} {...props} establecerPesoTotalProduccion={establecerPesoTotalProduccion}/>
                        </div>
                    </div>
                </div>
                {editando && (
                    <div className="row col-12 col-lg-10 d-flex flex-wrap justify-content-center mt-3">
                        <Alerta danger texto={`Recuerde guardar antes de cerrar la producción.`}/>
                    </div>
                )}
                <div className="mt-3">
                    {editando && (
                        <Link className="btn btn-outline-dark mr-5" to="/producciones">REGRESAR</Link>
                    )}
                    {!editando &&(
                        <Link className="btn btn-outline-dark mr-5" to="/producciones">CANCELAR</Link>
                    )}
                    <button type="submit" className="btn btn-primary mr-5">GUARDAR</button>
                    <button type="submit" className="btn btn-secondary mr-5" disabled={!fin_produccion}
                        onClick={handleSubmit(cerrarProduccion)}>
                        CERRAR PRODUCCIÓN
                    </button>
                </div>
            </div>
        </form>
    )
};
CierreForm = reduxForm({
    form: 'CierreForm',
    initialValues: {
        fecha_fin_produccion: moment(),
    },
})(CierreForm);

const selector = formValueSelector('CierreForm');
CierreForm = connect(state => {
    const form = state.form.CierreForm;

    let no_produccion = "";
    let peso_produccion = 0;
    let fin_produccion = false;

    if (form && form.values) {
        const values = form.values;
        if (values.numero_produccion) {
            no_produccion = values.numero_produccion;
        }
        if (values.pallets) {
            for (let i = 0; i < values.pallets.length; i++) {
                if (values.pallets[i].peso_total) {
                    peso_produccion += values.pallets[i].peso_total;
                }
            }
            form.values.peso_total = peso_produccion;
        }
        if (values.numero_produccion && values.fecha_inicio_produccion && values.fecha_fin_produccion && values.peso_total && values.pallets) {
            fin_produccion = true;
        }
    }
    return {
        no_produccion,
        fin_produccion
    }
})(CierreForm);

export default CierreForm
