import { connect } from 'react-redux';
import {actions} from '../../../../redux/modules/configuracion';
import ConfiguracionEditar from './ConfiguracionEditar';

const mstp = state => {
    return {...state.configuracion}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ConfiguracionEditar)
