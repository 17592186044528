import React, {Component} from 'react'
import Grid from "Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import {RenderCM, RenderKilogramo} from "../../../Utils/renderField/renderReadField"
import { BODEGA_ALMACEN } from '../../../../../utility/constants';

export default class BodegasList extends Component {
    componentWillMount() {
        const {listar, page} = this.props;
        listar(page);
    }

    render() {
        const {listar: onPageChange, onSortChange, eliminar, filtro} = this.props;
        const {data, loader, page, onFiltroChange} = this.props;
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="ALMACÉN / PILA"
                    subtitulo="Materia prima">
                    <Header to="bodega/crear" textBtn="CREAR ALMACÉN / PILA" {...this.props}
                            filtro={filtro} onChange={onFiltroChange}/>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange}
                          onSortChange={onSortChange} page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            width='20%'
                            dataSort
                            dataFormat={standardActions({editar: "bodega", eliminar, ver_bodega: "bodega"})}
                        />
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="materia_prima"
                            dataFormat={cell => cell ? cell.nombre : '--'}
                        >
                            MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_text"
                            dataSort
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="altura_maxima"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell, row) => row.tipo > 10 ? <RenderCM value={cell}/> :
                                <RenderKilogramo value={cell}/>}
                        >
                            ALTURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="acopio"
                            width='12%'
                            dataFormat={(cell) => cell ? cell.nombre : 'Planta ERSA'}
                        >
                            LUGAR
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
