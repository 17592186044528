import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/pilotos/pilotos';
import PilotosList from './Pilotos';


const ms2p = (state) => {
  return {
    ...state.pilotos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PilotosList);
