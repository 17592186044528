import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteCostoFletes extends Component {
    componentWillMount() {
        const { getCostoFletesParaReporte } = this.props;
        // getCostoFletesParaReporte();
    }

    render() {
        const {
            reporte_costo_fletes, tipoTransporteChange,
            pageReporteErsaChange, fechaInicialErsaChange, fechaFinalErsaChange, fincaErsaChange, transportistaErsaChange,
            pageReporteExternoChange, fechaInicialExternoChange, fechaFinalExternoChange, fincaExternoChange, transportistaExternoChange,
        } = this.props;
        let fechaInicialChange = null, fechaFinalChange = null, fincaChange = null, transportistaChange = null, pageChange = null;
        let fecha_inicial = null, fecha_final = null, finca_id = null, transportista_id = null, page = null;
        switch(reporte_costo_fletes.tipo_transporte) {
            case 'PROPIO':
                // Los vehículos de una finca son aquellos que NO TIENEN asignado un transportista (por lo que este filtro no aplica)
                pageChange = pageReporteErsaChange;
                fechaInicialChange = fechaInicialErsaChange;
                fechaFinalChange = fechaFinalErsaChange;
                fincaChange = fincaErsaChange;
                // transportistaChange = transportistaErsaChange;
                page = reporte_costo_fletes.ersa_page;
                fecha_inicial = reporte_costo_fletes.ersa_fecha_inicial;
                fecha_final = reporte_costo_fletes.ersa_fecha_final;
                finca_id = reporte_costo_fletes.ersa_finca_id;
                // transportista_id = reporte_costo_fletes.ersa_transportista_id;
                break;
            case 'EXTERNO':
                pageChange = pageReporteExternoChange;
                fechaInicialChange = fechaInicialExternoChange;
                fechaFinalChange = fechaFinalExternoChange;
                fincaChange = fincaExternoChange;
                transportistaChange = transportistaExternoChange;
                page = reporte_costo_fletes.externo_page;
                fecha_inicial = reporte_costo_fletes.externo_fecha_inicial;
                fecha_final = reporte_costo_fletes.externo_fecha_final;
                finca_id = reporte_costo_fletes.externo_finca_id;
                transportista_id = reporte_costo_fletes.externo_transportista_id;
                break;
        }
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="COSTO DE FLETES"
                    noBorder
                    subtitulo="Reporte">
                    <Tabs
                        activeKey={reporte_costo_fletes.tipo_transporte}
                        tabBarPosition="top"
                        onChange={tipo_transporte => {tipoTransporteChange(tipo_transporte)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}>
                        <TabPane tab="Fletes pagados por FINCA" key="PROPIO">
                            <div></div>
                        </TabPane>
                        <TabPane tab="Fletes pagados por ERSA" key="EXTERNO">
                            <div></div>
                        </TabPane>
                    </Tabs>
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial} fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                            filtroFincaChange={fincaChange} finca_id={finca_id}
                            filtroTransportistaChange={transportistaChange} transportista_id={transportista_id}
                            filtrarXReporte={()=>{this.props.getCostoFletesParaReporte()}}
                            labelXReporte={"Filtrar"}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} pageChange={pageChange} page={page}></Reporte>
                </Card>
            </div>
        )
    }
}
