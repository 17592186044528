import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/recepciones';
import PesoEditar from './PesoEditar';

const mstp = state => {
    return {...state.recepciones}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PesoEditar)
