import React, { Component } from 'react'
import CardEmpty from '../../../../Utils/Cards/CardEmpty'
import Card from '../../../../Utils/Cards/Card'
import Grid from '../../../../Utils/Grid'
import { TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment'
import { RenderKilogramo, RenderNumberPercentage } from '../../../../Utils/renderField/renderReadField'
import { Link } from 'react-router-dom'
import LoadMask from '../../../../Utils/LoadMask/LoadMask'

class DetallePilaAlmacen extends Component {
    componentDidMount() {
        this.props.getDetalleReportePilas(this.props.match.params.id)
    }
    
    render() {
        const { reporte_pila_almacen: reporte } = this.props
        let data = reporte.detalle_item
        console.log(data)
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="PILAS"
                    subtitulo="Reporte"
                    noBorder
                >
                    <div className="mb-4">
                        <LoadMask
                            blur
                            loading={reporte.loader}
                        >
                            <CardEmpty noShadow>
                                <div className="col-12 form-group np-r p-0 mb-1 inicio-tabla">
                                    <Grid striped hover data={data} loading={reporte.loader}>
                                        <TableHeaderColumn
                                            dataField="ingreso_mov_padre"
                                            isKey
                                            dataFormat={cell => cell ? cell.id : '--'}
                                        >
                                            ID RECEPCIÓN
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="ingreso_mov_padre"
                                            dataFormat={cell => cell && cell.fecha_entrada ? moment(cell.modificado).format('DD-MM-YYYY') : '--'}
                                        >
                                            FECHA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="finca"
                                            dataFormat={cell => cell && cell.nombre ? cell.nombre : ''}
                                        >
                                            FINCA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="materia_prima"
                                            dataFormat={cell => cell && cell.nombre ? cell.nombre : ''}
                                        >
                                            PRODUCTO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="peso_neto"
                                            dataAlign="right"
                                            dataFormat={cell => cell ? <RenderKilogramo value={cell}/> : '--'}
                                        >
                                            KG. HÚMEDOS BÁSCULA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="analisis_mov_padre"
                                            dataAlign="right"
                                            dataFormat={cell => cell ? <RenderNumberPercentage value={cell.drc_promedio}/> : '--'}
                                        >
                                            DRC
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="analisis_mov_padre"
                                            dataAlign="right"
                                            dataFormat={(cell, row) => cell ? <RenderKilogramo value={row.peso_neto * cell.drc_promedio}/> : '--'}
                                        >
                                            KG. SECOS
                                        </TableHeaderColumn>
                                    </Grid>
                                </div>
                                <div className="buttons-box mb-2">
                                    <Link className="btn btn-secondary" to="/reporte/reporte_pila_almacen">REGRESAR</Link>
                                </div>
                            </CardEmpty>
                        </LoadMask>
                    </div>
                </Card>
            </div>
        )
    }
}

export default DetallePilaAlmacen