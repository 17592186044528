import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/materia_prima';
import MateriaPrima from './MateriaPrima';


const ms2p = state => ({
    ...state.materia_prima,
});

const md2p = { ...actions };

export default connect(ms2p, md2p)(MateriaPrima);
