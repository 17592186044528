import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { RenderNumber, RenderNumberPercentage, RenderKilogramo } from 'Utils/renderField/renderReadField';
import CardEmpty from 'Utils/Cards/CardEmpty';
import Grid from '../../../Utils/Grid';
import { PrintContainer, actions as printActions } from 'Utils/Print';
import 'react-step-progress-bar/styles.css';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { LATEX as TIPO_LATEX } from '../../../../../utility/constants';
import LoadMask from '../../../Utils/LoadMask/LoadMask';

export default class BoletaSalida extends Component {
    state = {
        title: '',
        message: '',
        fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A'),
        name_print_component: 'boleta-recepcion-salida',
    }
    componentDidMount(){
        if (!this.props.item.boleta_salida) {
            this.crearPDF();
        }
        else{
            console.log("La boleta ya fue guardada y enviada");
        }
    }
    imprimirBoleta = (event) => {
        const {item, setBoletaSalidaImpresa} = this.props;
        this.setState({
            fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A')
        }, () => {
            setBoletaSalidaImpresa(item.id);
            printActions.print(this.state.name_print_component);
        });
    }
    crearPDF() {
        printActions.exportToPDF(this.state.name_print_component).then(output => {
            this.props.guardarBoletaPDF(this.props.match.params.id, btoa(output), 'boleta_salida');
            this.setState({ title: 'ESPERE...', message: 'Se esta enviando la boleta de salida por email', });
        });
    }

    render() {
        const { item, loader } = this.props;
        const productos = item.productos ? item.productos : [];
        const data = {
            results: productos
        };
        // Obtención de todos los Marchamos (que sólo se registran a Látex)
        let marchamosStr = '';
        productos.forEach((prod) => prod.marchamos.forEach((marchamo) => marchamosStr+= (marchamosStr.length === 0 ? '' : ', ' ) + marchamo));
        return (
            <div>
                <LoadMask loading={loader} blur title={this.state.title} message={this.state.message}>
                    <CardEmpty>
                        <PrintContainer name={this.state.name_print_component}>
                            <div className="col-12 p-0">
                                <div className="row contenido-impresion verde-secundario d-flex justify-content-center">
                                    <div className="col-12 row">
                                    <div className="col-12 d-flex justify-content-center mt-0 mb-3">
                                            <div className="col-11 pl-2">
                                                <div className="row col-12 p-0 mt-3 d-flex justify-content-between align-items-center">
                                                    <img
                                                        className="d-inline-block align-top"
                                                        src={require('../../../../../../assets/img/logo.png')}
                                                        alt="Logo"
                                                        height="50px"
                                                    />
                                                    <label className="p-0 m-0">ACOPIO ERSA KM. 7.5 CARRETERA A TULATE</label>
                                                    <div className="ml-4 mr-4"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center mt-0">
                                            <div className="col-11">
                                                <div className="row col-12 p-0 mt-2 justify-content-center align-items-start">
                                                    <div className="col-8 p-0 fondo">
                                                        <div className="col-12 d-flex flex-column pl-0">
                                                            <div className="d-flex align-items-center">
                                                                <img src={require(`../../../../../../assets/img/icons/recepciones_activo.png`)} alt="Usuario" className="title_img mr-2"/>
                                                                <img src={require('../../../../../../assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2"/>
                                                                <h6 className="text-black mb-0">BOLETA DE SALIDA</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <span className="gris">Fecha de impresión:</span>
                                                            <span className="gris">{this.state.fechaHoraImpresion}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row col-12 p-0 pt-2">
                                                    <div className="col-8 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">FINCA</label>
                                                            <span className="gris">{item.finca ? item.finca.nombre : ''}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex align-items-baseline">
                                                            <label className="p-0 m-0">ID RECEPCIÓN</label>
                                                            <h5 className="gris bold pl-2">{item.id ? item.id.toString().padStart(5, 0) : ''.padStart(5, 0)}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row col-12 p-0 mt-3">
                                                    <div className="col-8 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">VEHICULO</label>
                                                            <span className="gris">{item.vehiculo ? item.vehiculo.nombre : ''}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">PILOTO</label>
                                                            <span className="gris">{item.piloto ? item.piloto.nombre : ''}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row col-12 p-0 mt-3">
                                                    <div className="col-8 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">ENTRADA</label>
                                                            <span className="gris">{moment(item.fecha_entrada).format('DD/MM/YYYY HH:mm:ss A')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="p-0 m-0">SALIDA</label>
                                                            <span className="gris">{item.fecha_salida ? moment(item.fecha_salida).format('DD/MM/YYYY HH:mm:ss A') : ''}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center p-0">
                                            <div className=" col-11 form-group np-r p-0 mt-3 mb-1">
                                                <Grid striped data={data} loading={loader} pagination={false} bordered={false} >
                                                    <TableHeaderColumn
                                                        dataField="id"
                                                        isKey
                                                        width="15px"
                                                        dataFormat={cell => ''}
                                                    />
                                                    <TableHeaderColumn
                                                        dataField="materia_prima"
                                                        dataFormat={cell => cell.nombre}
                                                    >
                                                        MATERIA
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="peso_bruto"
                                                        dataFormat={cell => cell ? <RenderKilogramo value={cell} decimalScale={2}/> : '--'}
                                                        width="15%"
                                                    >
                                                        <div className="d-flex flex-column">
                                                            PESO ENTRADA
                                                            <small className="bold">(PESO BRUTO)</small>
                                                        </div>
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="peso_tara"
                                                        dataFormat={cell => cell ? <RenderKilogramo value={cell} decimalScale={2}/> : '--'}
                                                    >
                                                        <div className="d-flex flex-column">
                                                            PESO SALIDA
                                                            <small className="bold">(PESO TARA)</small>
                                                        </div>
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="peso_neto"
                                                        dataFormat={cell => cell ? <RenderKilogramo value={cell} decimalScale={2}/> : '--'}
                                                        width="12%"
                                                    >
                                                        PESO NETO
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="peso_finca_kg"
                                                        dataFormat={cell => cell ? <RenderKilogramo value={cell} decimalScale={2}/> : '--'}
                                                        width="13%"
                                                    >
                                                        PESO FINCA
                                                    </TableHeaderColumn>
                                                    <TableHeaderColumn
                                                        dataField="peso_neto"
                                                        dataFormat={(cell, row) => <RenderKilogramo value={row.peso_neto - row.peso_finca_kg} decimalScale={2}/>}
                                                        width="11%"
                                                    >
                                                        DIFERENCIA
                                                    </TableHeaderColumn>
                                                    {
                                                        item.tipo_materia && item.tipo_materia.tipo == TIPO_LATEX &&
                                                            <TableHeaderColumn
                                                                dataField="analisis"
                                                                dataFormat={cell => {
                                                                    const analisis = cell ? cell : [];
                                                                    const total = analisis.map(analisis => analisis.vfa).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                                                    return <RenderNumberPercentage value={total} decimalScale={2}/>;
                                                                }}
                                                                width="10%"
                                                            >
                                                                VFA
                                                            </TableHeaderColumn>
                                                    }
                                                    {
                                                        item.tipo_materia && item.tipo_materia.tipo == TIPO_LATEX &&
                                                            <TableHeaderColumn
                                                                dataField="analisis"
                                                                dataFormat={cell => {
                                                                    const analisis = cell ? cell : [];
                                                                    const total = analisis.map(analisis => analisis.nh3).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                                                    return <RenderNumberPercentage value={total} decimalScale={2}/>;
                                                                }}
                                                                width="10%"
                                                            >
                                                                NH3
                                                            </TableHeaderColumn>
                                                    }
                                                </Grid>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center mt-3 p-0">
                                            <div className="col-11">
                                                <div className="row col-12 p-0 d-flex align-items-center">
                                                    <div className="col-6 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label className="m-0">MARCHAMO</label>
                                                            <span className="pl-3 gris">{marchamosStr}</span>
                                                            <div className="d-flex flex-row align-items-end mt-0">
                                                                <label className="mb-0 mr-3">BÁSCULA</label>
                                                                <input className="espacio-firma mb-1" type="text" readOnly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <label>OBSERVACIONES</label>
                                                            <input
                                                                type="text"
                                                                name="observaciones"
                                                                className="form-control"
                                                                readOnly
                                                                width="75%"
                                                                value={""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center mt-3 mb-5">
                                            <div className="col-11">
                                                <div className="row col-12 p-0 mt-3">
                                                    <div className="col-8 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            {
                                                                item.boleta_salida_impresa && <label className="p-0 m-0 text-black">ESTA BOLETA ES UNA COPIA</label>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div className="col-12 d-flex flex-column">
                                                            <span className="gris">ERSA-F-GO-025-06</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PrintContainer>
                    </CardEmpty>
                </LoadMask>
                <div className="mb-4 col-12 p-0">
                    <div className="row mb-5">
                        <div className="col-12 row">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <button type="button" className="btn btn-outline-dark mr-5" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.goBack();
                                        this.props.history.goBack();
                                    }}>CERRAR</button>
                                    <button type="submit" className="btn btn-primary" onClick={this.imprimirBoleta}>IMPRIMIR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
