import React, { Component } from 'react'
import Card from '../../../Utils/Cards/Card'
import TabBar from 'rc-tabs/lib/TabBar'
import TabContent from 'rc-tabs/lib/TabContent'
import Tabs, { TabPane } from 'rc-tabs'
import CardEmpty from '../../../Utils/Cards/CardEmpty'
import FiltrosHeader from '../FiltrosHeader'
import Reporte from './Reporte'

class ReporteRecepcionesAnual extends Component {
    componentDidMount() {
        const { tipoMateriaAnualChange } = this.props
        tipoMateriaAnualChange('LATEX')
    }
    
    render() {
        const {
            reporte_anual, tipoMateriaAnualChange,
            pageReporteAnualLatexChange, aniosLatexChange,
            pageReporteAnualSolidoChange, aniosSolidoChange,
            materiaAnualLatexChange, materiaAnualSolidoChange,
            getRecepcionesParaReporteAnual
        } = this.props
        let anioInicialChange = null, anioFinalChange = null, aniosChange = null, fincaChange = null, materiaChange = null,acopioChange = null, pageChange = null
        let fecha_inicial = null, fecha_final = null, anio_reporte_anual = null, finca_id = null, acopio_id = null, materia_id = null, page = null
        switch(reporte_anual.tipo_materia) {
            case 'LATEX':
                pageChange = pageReporteAnualLatexChange
                materiaChange = materiaAnualLatexChange
                aniosChange = aniosLatexChange
                page = reporte_anual.latex_page
                anio_reporte_anual = reporte_anual.solido_anios
                materia_id = reporte_anual.latex_materia_id
                break
            case 'SOLIDO':
                pageChange = pageReporteAnualSolidoChange
                materiaChange = materiaAnualSolidoChange
                aniosChange = aniosSolidoChange
                page = reporte_anual.solido_page
                anio_reporte_anual = reporte_anual.solido_anios
                materia_id = reporte_anual.solido_materia_id
                break
        }
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="RECEPCIONES ANUALES"
                    noBorder
                    subtitulo="Reporte"
                >
                    <Tabs
                        activeKey={reporte_anual.tipo_materia}
                        tabBarPosition="top"
                        onChange={tipo_materia => {tipoMateriaAnualChange(tipo_materia)}}
                        renderTabBar={() => <TabBar/>}
                        renderTabContent={() => <TabContent/>}
                    >
                        <TabPane tab="LÁTEX" key="LATEX">
                            <div/>
                        </TabPane>
                        <TabPane tab="SÓLIDOS" key="SOLIDO">
                            <div/>
                        </TabPane>
                    </Tabs>
                    <CardEmpty>
                        <FiltrosHeader {...this.props}

                            aniosChange={aniosChange}
                            filtroMateriasChange={materiaChange}
                            
                            materias_ids={materia_id}
                            tipo_materia={reporte_anual.tipo_materia}

                            // Boton de filtrar
                            filtrar_recepciones={getRecepcionesParaReporteAnual}


                            precioReporteAmoniaco={undefined}
                            concentracionReporteAmoniaco={undefined}
                            styleCustomContainer={"justify-content-around"}
                        />
                    </CardEmpty>
                    <div className="mb-4"/>
                    <Reporte {...this.props} pageChange={pageChange} page={page}/>
                </Card>
            </div>
        )
    }
}

export default ReporteRecepcionesAnual