import React, {Component} from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardEmpty from 'Utils/Cards/CardEmpty';
import "react-step-progress-bar/styles.css";
import {TableHeaderColumn} from "react-bootstrap-table";
import Grid from "Utils/Grid";
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import ReajusteForm from "./ReajusteForm";
import Modal from "react-responsive-modal";
import {RenderKilogramo, RenderNumber} from "../../../../Utils/renderField/renderReadField";
import Link from "react-router-dom/Link";

export default class AlmacenVer extends Component {
    state = {
        editar: false,
        step: 1,
        open: false,
    };

    componentWillMount() {
        this.props.obtenerBodega(this.props.match.params.id);
        this.props.getProductosAlmacen(this.props.match.params.id);
    }

    openModal = (activar) => {
        this.setState({open: true})
    };
    closeModal = () => {
        this.setState({open: false})
    };

    expandComponent = (row) => {
        let data = row && row.fincas ? row.fincas : [];
        let _data = _.cloneDeep(data);
        return (
            <div className=" tabla-adentro row">
                <div className="col-1"></div>
                <div className="col-10">
                    <BootstrapTable
                        headerStyle={{backgroundColor: '#e24647'}}
                        data={_data}>
                        <TableHeaderColumn
                            hidden
                            isKey
                            dataField="id">
                            Id
                        </TableHeaderColumn>
                        <TableHeaderColumn width="5%" />
                        <TableHeaderColumn
                            dataField="cantidad_total"
                            dataFormat={cell => <RenderKilogramo value={cell}/>}
                        >
                            INGRESADO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="finca"
                            dataFormat={cell => cell ? cell.nombre : '---'}>
                            FINCA
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        )
    };
    reajustarInventario = (data) => {
        this.setState({open: false});
        this.props.reajustarInventarioSolido(this.props.match.params.id, data);
    }

    render() {
        const {item, loader, productos_almacen, getProductosAlmacen} = this.props;
        const contenedores = item && item.contenedores ? parseInt(item.contenedores) : 0;
        const _className = `${contenedores <= 50 ? 'barras-contador' : 'barras-contador-pequeñas'} mb-1`;
        const imagenes = new Array(contenedores).fill(0).map((element, index) => <img key={index} src={require('assets/img/icons/linea.png')} alt="Linea" className={_className}/>);
        return (
            <div className="mb-4 col-12">
                <Modal open={this.state.open} onClose={this.closeModal}>
                    <div className="pb-4 titulo-modal d-flex justify-content-center">
                        <h2>REAJUSTE EN {item && item.nombre ? item.nombre.toUpperCase() : ''}</h2>
                    </div>
                    <ReajusteForm onSubmit={this.reajustarInventario} closeModal={this.closeModal} productos_almacen={productos_almacen} />
                </Modal>
                <CardForm
                    icon="bodega_activo"
                    titulo={item ? (item.nombre ? item.nombre.toUpperCase() : "") : ""}
                    col="12"
                    subtitulo="Inventario">
                    <LoadMask loading={loader} blur>
                        <div className=" row col-12 d-flex flex-column flex-sm-row justify-content-between">
                            <div className="mb-1 mb-md-4 d-flex flex-wrap">
                                <Link className="btn btn-primary mb-2 mb-md-0 mr-md-4" to={`salida/`}>CREAR
                                    SALIDA</Link>
                                <button className="btn btn-secondary" onClick={this.openModal}>REAJUSTE</button>
                            </div>
                            <div className="d-flex align-items-baseline">
                                <h6 className="mr-2">{item.acopio ? item.acopio.nombre : 'Planta ERSA'}</h6>
                                <img src={require('assets/img/icons/linea.png')} alt="Linea"
                                     className="title_img mr-2"/>
                                <h2>{item.materia_prima ? item.materia_prima.nombre : '--'}</h2>
                            </div>
                        </div>

                        <div className="row col-12">
                            <div className="my-2 w-100 d-flex align-items-center justify-content-between flex-wrap col-12">
                                <div>
                                    <CardEmpty noPadding noShadow>
                                        <div className="mt-1 mb-1 ml-2 mr-2">
                                            <span className="gris">PESO TOTAL</span>
                                            <span className="verde-primario text-md"> {<RenderNumber value={item.peso_total}
                                                                                        decimalScale={4}/>}</span>
                                            <span className="gris"> KG</span>
                                        </div>
                                    </CardEmpty>
                                </div>
                                <img src={require('assets/img/icons/linea.png')} alt="Linea"
                                     className="title_img mr-2"/>
                                <div>
                                    <span>LLENADO</span>
                                    <span className="gris text-md"> # 1</span>
                                </div>
                                <img src={require('assets/img/icons/linea.png')} alt="Linea"
                                     className="title_img mr-2"/>
                                <div className="d-flex align-items-end text-right col-12 col-md-6 pl-0">
                                    <span className="mr-2">CONTENEDORES</span>
                                        {imagenes}
                                    <span className="gris text-md ml-2"> {item.contenedores}</span>
                                </div>
                            </div>

                        </div>
                        <Grid hover striped data={productos_almacen.data}
                              loading={loader}
                              page={1} onPageChange={(page) => getProductosAlmacen(this.props.match.params.id, page)}
                              expandableRow={() => true}
                              expandComponent={this.expandComponent}
                        >
                            <TableHeaderColumn width="5%" />
                            <TableHeaderColumn
                                dataField="existencias"
                                dataSort
                                dataFormat={cell => <RenderKilogramo value={cell}/>}
                            >
                                CANTIDAD
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="materia"
                                dataSort
                                isKey
                                dataFormat={(cell, row) => cell ? cell.nombre : '---'}
                            >
                                MATERIA PRIMA
                            </TableHeaderColumn>
                        </Grid>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
