import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/fincas';
import FincaCrear from './FincaCrear';
import {MUNICIPIOS} from 'Utils/departamentos_municipios'

const mstp = state => {
    let municipios = [];
    if (state.form.fincaForm && state.form.fincaForm.values.departamento){
        municipios = _.filter(MUNICIPIOS, {departamento: state.form.fincaForm.values.departamento});
    }
    return {
        ...state.fincas,
        municipios
    };
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(FincaCrear)
