import React, { useCallback, useEffect, useRef } from "react";
import Card from "Utils/Cards/Card";
import Filtros from "./Filtros";
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import TablaFinca from "./TablaFinca";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
// PrintPDF
import { useReactToPrint } from "react-to-print";

const ReporteAnualProveedores = ({
    loader,
    obtenerDataInicial,
    loader_init,
}) => {
    const componentRef = useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loadingPrint, setLoadingPrint] = React.useState(false);
    const handleOnBeforeGetContent = React.useCallback(() => {
        setLoadingPrint(true);
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoadingPrint(false);
                resolve();
            }, 1000);
        });
    }, [setLoadingPrint]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: handleOnBeforeGetContent,
    });

    useEffect(() => {
        obtenerDataInicial();
    }, []);

    useEffect(() => {
        if (typeof onBeforeGetContentResolve.current === "function") {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current]);


    const handlePrintExecute = () => {
        handlePrint();
    };

    return (
        <div className="mb-4 col-12">
            <Card
                icon="reportes_activo"
                titulo="ANUAL PROVEEDORES"
                noBorder
                subtitulo="Reporte"
            >
                <LoadMask loading={loader || loader_init} blur>
                    <CardEmpty>
                        <Filtros handlePrint={handlePrintExecute} />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <CardEmpty>
                        <TablaFinca
                            ref={componentRef}
                            loadingPrint={loadingPrint}
                        />
                    </CardEmpty>
                </LoadMask>
            </Card>
        </div>
    );
};

export default ReporteAnualProveedores;
