import React, { Component } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import _ from 'lodash';
import { api } from 'api';
import {regimenes, LATEX, SOLIDO} from '../../../../../utility/constants';
import LoadMask from '../../LoadMask/LoadMask';
import CardEmpty from '../../Cards/CardEmpty';
import SearchInput from '../../SearchInput';

const getFincas = (search) => {
    return api.get('finca', {search, es_externa: true})
        .then((data) => data ? data.results : [])
        .catch(() => []);
};
const getTiposPagoFinca = (search) => {
    return api.get('tipo_pago', {search})
        .then((data) => data ? data.results : [])
        .catch(() => []);
}
const getPerfilesFinca = (search) => {
    return api.get('perfil_finca', {search})
        .then((data) => data ? data.results : [])
        .catch(() => []);
}
const filtrarRegimenes = (id_perfil_finca) => {
    switch(id_perfil_finca) {
        case -1:    return regimenes;
        case 1:     return regimenes.filter((item) => item.value === 50);
        default:    return regimenes.filter((item) => item.value !== 50);
    }
}

export default class HeaderLiquidaciones extends Component {
    render() {
        const { search, searchChange, dataTotales,
            filtroFincaChange, fechaFinalChange, fechaInicialChange, fecha_inicial, fecha_final
        } = this.props;
        const { filtroFincaTipoPagoChange, filtroFincaPerfilChange, filtroFincaRegimenFiscalChange, getDataLiquidaciones } = this.props;
        const { id_perfil_finca, disableButton = false } = this.props;

        // Variables para mostrar los resultados de los totales de kilos secos
        var kilos_secos_latex = 0;
        var kilos_secos_solidos = 0;
        var kilos_secos_totales = 0;
        // Variables para mostrar los resultados de los totales de dinero
        var total_latex = 0;
        var total_solidos = 0;
        var total = 0;
        // Totales en kg_secos y en quetzales, clasificados por tipo de materia
        kilos_secos_solidos = _.get(dataTotales, 'data.solido.total_kg_secos', 0);
        kilos_secos_latex   = _.get(dataTotales, 'data.latex.total_kg_secos', 0);
        kilos_secos_totales = kilos_secos_latex + kilos_secos_solidos;
        total_solidos = _.get(dataTotales, 'data.solido.total_quetzales', 0);
        total_latex   = _.get(dataTotales, 'data.latex.total_quetzales', 0);
        total = total_latex + total_solidos;

        return (
            <div className="d-flex flex-wrap">
                <div className="d-flex flex-wrap flex-1">
                    <div className="row col-12">
                        <div className="col-12 row">
                            {/* ESPACIO DE FILTROS */}
                            <div className="col-lg-4 col-12">
                                <div className="col-12 mb-3 row mr-0 pr-0">
                                    <div className="col-6 mr-0 pr-0 d-flex flex-column flex-wrap">
                                        <label className="mb-1 mt-0">Fecha inicio</label>
                                        <DatePicker
                                            showPopperArrow={false}
                                            dateFormat="DD/MM/YYYY"
                                            placeholderText="Fecha de inicio"
                                            onChange={(value) => {fechaInicialChange(value)}}
                                            selected={fecha_inicial}
                                        />
                                    </div>
                                    <div className="col-6 mr-0 pr-0 d-flex flex-column flex-wrap">
                                        <label className="mb-1 mt-0">Fecha fin</label>
                                        <DatePicker
                                            showPopperArrow={false}
                                            dateFormat="DD/MM/YYYY"
                                            placeholderText="Fecha final"
                                            onChange={(value) => {fechaFinalChange(value)}}
                                            selected={fecha_final}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mb-3 row mr-0 pr-0">
                                    <div className="col-6 mr-0 pr-0">
                                        <label className="mb-1 mt-0">Finca</label>
                                        <AsyncSelect
                                            className={classNames('react-select-container')}
                                            backspaceRemovesValue={false}
                                            isSearchable={true}
                                            isClearable
                                            placeholder="Todas"
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={getFincas}
                                            onChange={selected => { filtroFincaChange(selected ? selected.id : -1); }}
                                            getOptionValue={(option) => (option["id"])}
                                            getOptionLabel={(option) => (option["nombre"])}
                                        />
                                    </div>
                                    <div className="col-6 mr-0 pr-0">
                                        <label className="mb-1 mt-0">Perfil finca</label>
                                        <AsyncSelect
                                            className={classNames('react-select-container')}
                                            backspaceRemovesValue={false}
                                            isSearchable={true}
                                            isClearable
                                            placeholder="Todos"
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={getPerfilesFinca}
                                            onChange={selected => { filtroFincaPerfilChange(selected ? selected.id : -1); }}
                                            getOptionValue={(option) => (option['id'])}
                                            getOptionLabel={(option) => (option['nombre'])}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mb-3 row mr-0 pr-0 d-flex flex-wrap align-items-end">
                                    <div className="col-6 mr-0 pr-0">
                                        <label className="mb-1 mt-0">Régimen</label>
                                        <Select
                                            className={classNames('react-select-container')}
                                            backspaceRemovesValue={false}
                                            isSearchable={true}
                                            isClearable
                                            placeholder="Todos"
                                            cacheOptions
                                            defaultOptions
                                            // options={regimenes}
                                            options={filtrarRegimenes(id_perfil_finca)}
                                            onChange={selected => { filtroFincaRegimenFiscalChange(selected ? selected.value : -1); }}
                                        />
                                    </div>
                                    <div className="col-6 mr-0 pr-0">
                                        <label className="mb-1 mt-0">Tipo de pago</label>
                                        <AsyncSelect
                                            className={classNames('react-select-container')}
                                            backspaceRemovesValue={false}
                                            isSearchable={true}
                                            isClearable
                                            placeholder="Todos"
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={getTiposPagoFinca}
                                            onChange={selected => { filtroFincaTipoPagoChange(selected ? selected.id : -1); }}
                                            getOptionValue={(option) => (option['id'])}
                                            getOptionLabel={(option) => (option['nombre'])}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mb-3 row mr-0 pr-0 d-flex flex-wrap align-items-end justify-content-center">
                                <button className="btn btn-primary" onClick={() => getDataLiquidaciones && getDataLiquidaciones()} disabled={disableButton}>FILTRAR</button>
                                </div>
                            </div>
                            {/* ESPACIO DE TOTALES */}
                            <div className="col-lg-4 col-12 d-flex align-items-center mb-4">
                                <div className="flex-fill">
                                    <LoadMask loading={_.get(dataTotales, 'loader', false)} blur noShowLoaderInfo>
                                        <div className="text-center">
                                            <h6>TOTALES KILOS SECOS</h6>
                                        </div>
                                        <CardEmpty noShadow>
                                            <div className="fondo-totales mr-2 mb-2">
                                                <div className="mb-1">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <label className="gris">Solidos</label>
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <span className="text-md verde-secundario">
                                                                {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2, maximumFractionDigits:2}).format(kilos_secos_solidos)}
                                                            </span>
                                                            <span className="gris text-sm mb-1">KG</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-1">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <label className="gris">Latex</label>
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <span className="text-md verde-secundario">
                                                                {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2, maximumFractionDigits:2}).format(kilos_secos_latex)}
                                                            </span>
                                                            <span className="gris text-sm mb-1">KG</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-1">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <label>TOTAL</label>
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <span className="text-lg gris">
                                                                {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2, maximumFractionDigits:2}).format(kilos_secos_totales)}
                                                            </span>
                                                            <span className="verde-secundario text-sm mb-1">KG</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardEmpty>
                                    </LoadMask>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 d-flex align-items-center mb-4">
                                <div className="flex-fill">
                                    <LoadMask loading={_.get(dataTotales, 'loader', false)} blur noShowLoaderInfo>
                                        <div className="text-center">
                                            <h6>TOTALES MONETARIOS</h6>
                                        </div>
                                        <CardEmpty noShadow>
                                            <div className="fondo-totales mr-2 mb-2">
                                                <div className="mb-1">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <label className="gris mr-3 ">Solidos</label>
                                                            <span className="gris text-sm mb-1">Q.</span>
                                                        </div>
                                                        <span className="text-md verde-secundario">
                                                            {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2}).format(total_solidos)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="mb-1">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <label className="gris mr-3 ">Latex</label>
                                                            <span className="gris text-sm mb-1">Q.</span>
                                                        </div>
                                                        <span className="text-md verde-secundario">
                                                            {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2}).format(total_latex)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="mb-1">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <div className="d-flex justify-content-between align-items-end">
                                                            <label className="mr-3 ">TOTAL</label>
                                                            <span className="verde-secundario text-sm mb-1">Q.</span>
                                                        </div>
                                                        <span className="text-lg gris">
                                                            {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2}).format(total)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardEmpty>
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    {(search !== undefined) && (
                        <SearchInput search={search} searchChange={searchChange} />
                    )}
            </div>
        );
    }
}
