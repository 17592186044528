import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/recepciones';
import IngresoVerBoleta from './IngresoVerBoleta';

const mstp = state => {
    const recepcion = state.recepciones.item;
    const profile = state.login.me;
    return {
        recepcion,
        profile,
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(IngresoVerBoleta)
