import React, { Fragment } from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import {TableHeaderColumn} from 'react-bootstrap-table';
import moment from 'moment/moment';
import Grid from '../../../Utils/Grid';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderKilogramo, RenderCurrency, RenderNumber, RenderDollar, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { SOLIDO, LATEX } from '../../../../../utility/constants';
import { renderLegend } from '../components';

const GENERAL = {};
GENERAL[SOLIDO] = 'SOLIDO';
GENERAL[LATEX] = 'LATEX';

const renderTooltip = (props) => {
    const { active, payload, label } = props;

    if (active && payload) {
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-2">Fecha: {label}</span>
                    { payload.map(item => {
                        return (<div className="d-flex flex-row justify-content-between">
                            {item.dataKey === 'precio_introsa'&&(
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="mr-2">{'Precio Introsa'}:</span>
                                    <span className="mr-3"><RenderCurrency value={item.payload.precio_introsa} /></span>
                                </div>
                            )}
                            {item.dataKey === 'precio_ersa'&&(
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="mr-2">{'Precio ERSA'}:</span>
                                    <span className="mr-3"><RenderCurrency value={item.payload.precio_ersa} /></span>
                                </div>
                            )}
                        </div>);
                    }) }
                </div>
            </CardEmpty>
        );
    }
    return null;
};

const Reporte = (props) => {
    const {pageChange, page, loader, data,fecha_inicio_reporte_fluctuacion, fecha_fin_reporte_fluctuacion} = props;
    let mensajeError = '';
    let diferenciaDias = 0;
    let listaObjs = [], dataChart = [];
    let fecha_inicial = null, fecha_final = null;
    let precio_introsa = 0, precio_ersa = 0, drc_promedio = 0;
    fecha_inicial = moment(moment(fecha_inicio_reporte_fluctuacion).format("YYYY-MM-DD")+' 00:00:00');
    fecha_final = moment(moment(fecha_fin_reporte_fluctuacion).format("YYYY-MM-DD")+' 00:00:00');

    if (fecha_inicial === null || fecha_final === null) {
        mensajeError = 'FECHA INICIAL O FINAL NULO';
    } else if (fecha_final.diff(fecha_inicial, 'days') < 0) {
        mensajeError = 'FILTRO DE FECHAS NO VÁLIDO';
    } else {
        // Procesamiento de la información para generar la gráfica de barras
        diferenciaDias = fecha_final.diff(fecha_inicial, 'days');
        listaObjs = Array(diferenciaDias + 1);
        let iterFecha = moment(fecha_inicial).clone();
        // Construcción del array con los datos para el gráfico, inicialmente todos con cero
        for (let index = 0; index < listaObjs.length; index++) {
            listaObjs[index] = {
                fecha: iterFecha.format('D/M/YY'),
                num_dia: 'Día '+ (index + 1),
                precio_introsa: 0,
                precio_ersa: 0,
            };
            iterFecha.add(1, 'days');
        }
        // Cuando la data esté realmente cargada
        const detalles_ordem_all = data.all_results;
        let cpFechaInicio = moment(fecha_inicial.format("YYYY-MM-DD")+' 00:00:00');
        diferenciaDias >= 0 && detalles_ordem_all && detalles_ordem_all.forEach((detalle) => {
            let cpFechaOrden = (detalle && detalle.orden_compra && detalle.orden_compra.fecha_liquidacion) ? moment(moment(detalle.orden_compra.fecha_liquidacion).format("YYYY-MM-DD")+' 00:00:00') : moment();
            const index = cpFechaOrden.diff(cpFechaInicio, 'days');
            // La Orden compra puede tener varios detalles y pueden ser SÓLIDO o LÁTEX, por lo que hay que 'filtrarlos'
            // Sumatoria del valor monetario del tipo de materia seleccionado
            if (index < listaObjs.length && index >= 0) {
                listaObjs[index].precio_ersa+= detalle.total_quetzales;
                listaObjs[index].precio_introsa+= (
                    ((detalle.precio_kilogramo_introsa && detalle.precio_kilogramo_introsa.precio_kilogramo ? detalle.precio_kilogramo_introsa.precio_kilogramo : 0) * detalle.kilos_secos)*detalle.cambio_dia
                );
            }
        });
        listaObjs.forEach(obj => {
            if (obj.precio_ersa != 0 || obj.precio_introsa != 0) {
                const data = {
                    ...obj,
                    num_dia: obj.num_dia,
                    x_value: obj.fecha,
                    precio_ersa: obj.precio_ersa,
                    precio_introsa: obj.precio_introsa,
                }
                data[`y_value`] = obj.precio_ersa;
                dataChart.push(data);
                precio_ersa+= obj.precio_ersa;
                precio_introsa+= obj.precio_introsa;    
            }
        });
    }

    return(
        <LoadMask loading={loader} blur>
            <div className="mb-4">
                <CardEmpty>
                    <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                        <div className="col-12 col-md-12 d-flex flex-wrap justify-content-center align-items-center">
                            <div className="col-12 col-md-2 p-0 d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                                <h6 className="m-0 mb-3 mb-md-0 bold">TOTALES</h6>
                            </div>
                            <div className="col-12 row col-md-10 d-flex flex-row flex-wrap justify-content-center align-items-center p-0">
                                <div className="col-12 col-md-6 col-sm-6 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                    <span className="m-0 ml-0 ml-md-1 bold">TOTAL PRECIO INTROSA:</span>
                                    <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderCurrency value={precio_introsa} />}</h3>
                                </div>
                                <div className="col-12 col-md-6 col-sm-6 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                    <span className="m-0 ml-0 ml-md-1 bold">TOTAL PRECIO ERSA:</span>
                                    <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderCurrency value={precio_ersa} />}</h3>
                                </div>
                                {/* <div className="col-12 col-md-4 col-sm-4 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                    <span className="m-0 ml-0 ml-md-1 bold">DRC PROMEDIO:</span>
                                    <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderNumberPercentage value={drc_promedio} />}</h3>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </CardEmpty>
            </div>
            <div className="mb-4">
                <CardEmpty>
                    <div className="d-flex flex-column">
                        <div className="flex-fill d-flex justify-content-center">
                            {
                                mensajeError.length === 0
                                ? <ResponsiveContainer width="85%" height="100%" minWidth={500} minHeight={300}>
                                    <BarChart width={730} height={250} data={dataChart}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="fecha" />
                                        <YAxis />
                                        <Tooltip content={renderTooltip} />
                                        <Legend content={renderLegend} align="left" verticalAlign="middle" layout="vertical" iconType="circle" title="GRÁFICA DE TOTAL GLOBAL" />
                                        <Bar dataKey={`precio_introsa`} value={'PRECIO INTROSA'} fill={'#359d9e'} />
                                        <Bar dataKey={`precio_ersa`} value={'PRECIO ERSA'} fill={'#215273'} />
                                    </BarChart>
                                </ResponsiveContainer>
                                : <div className="mb-3 rojo bold">{mensajeError}</div>
                            }
                        </div>
                    </div>
                </CardEmpty>
            </div>
            {/* TABLA CON LOS RESÚMENES */}
            <div className="mb-5">
                <CardEmpty>
                    <Grid hover striped loading={loader} data={data}
                        pagination onPageChange={pageChange} page={page}
                        className="pb-4 pt-3"
                    >
                        {/* <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                            width="10"
                        >
                            ID
                        </TableHeaderColumn> */}
                        <TableHeaderColumn
                            dataField="id"
                            isKey
                            hidden
                        />
                        <TableHeaderColumn
                            dataField="orden_compra"
                            dataSort
                            dataFormat={(cell,row) => cell && cell.fecha_liquidacion ? moment(cell.fecha_liquidacion).format('DD/MM/YYYY') : ""}
                            // width="12"
                        >
                            FECHA LIQUIDACION
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="materia_prima"
                            dataSort
                            dataFormat={(cell,row) => cell && cell.nombre ? cell.nombre : ""}
                            // width="15"
                        >
                            TIPO DE MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="ingreso"
                            dataSort
                            dataFormat={(cell,row) => cell && cell.finca && cell.finca.nombre ? cell.finca.nombre : ""}
                            // width="15"
                        >
                            FINCA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="kilos_secos"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell,row) => cell ? <RenderKilogramo value={cell} /> : "" }
                            // width="10"
                        >
                            KILOS SECOS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio_venta"
                            dataSort
                            dataAlign="right"
                            // dataFormat={(cell,row) => cell ? <RenderCurrency value={cell} /> : "" }
                            dataFormat={cell => cell
                                ? <div className="row justify-content-end">
                                    <div className="col-xl-3 col-sm-2"></div>
                                    <div className="col-xl-9 col-sm-10 text-right">{
                                        <div className="d-flex justify-content-between">
                                            <span>$.</span>{<RenderNumber value={cell} decimalScale={2} />}
                                        </div>
                                    }</div>
                                </div>
                                : '--'
                            }
                            // width="13"
                        >
                            PRECIO ERSA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio_kilogramo_introsa"
                            dataSort
                            dataAlign="right"
                            // dataFormat={(cell,row) => cell ? <RenderCurrency value={cell} /> : "" }
                            dataFormat={cell => cell
                                ? <div className="row justify-content-end">
                                    <div className="col-xl-3 col-sm-2"></div>
                                    <div className="col-xl-9 col-sm-10 text-right">{
                                        <div className="d-flex justify-content-between">
                                            <span>$.</span>{<RenderNumber value={cell && cell.precio_kilogramo ? cell.precio_kilogramo : 0} decimalScale={2} />}
                                        </div>
                                    }</div>
                                </div>
                                : '--'
                            }
                            // width="13"
                        >
                            PRECIO INTROSA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total_quetzales"
                            dataSort
                            dataAlign="right"
                            // dataFormat={(cell,row) => cell ? <RenderCurrency value={cell} /> : "" }
                            dataFormat={cell => cell
                                ? <div className="row justify-content-end">
                                    <div className="col-xl-3 col-sm-2"></div>
                                    <div className="col-xl-9 col-sm-10 text-right">{
                                        <div className="d-flex justify-content-between">
                                            <span>Q.</span>{<RenderNumber value={cell} decimalScale={2} />}
                                        </div>
                                    }</div>
                                </div>
                                : '--'
                            }
                            // width="13"
                        >
                            PRECIO LIQUIDACIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio_kilogramo_introsa"
                            dataSort
                            dataAlign="right"
                            // width="15"
                            dataFormat={(cell,row) => (cell && cell.precio_kilogramo && row && row.cambio_dia && row.kilos_secos)
                                ? <div className="row justify-content-end">
                                    <div className="col-xl-3 col-sm-2"></div>
                                    <div className="col-xl-9 col-sm-10 text-right">{
                                        <div className="d-flex justify-content-between">
                                            <span>Q.</span>{<RenderNumber value={((row.kilos_secos * cell.precio_kilogramo)*row.cambio_dia)} decimalScale={2} />}
                                        </div>
                                    }</div>
                                </div>
                                : '--'
                            }
                            >
                            PRECIO INTROSA
                        </TableHeaderColumn>
                        {/* <TableHeaderColumn
                            dataField="peso_total"
                            dataSort
                            dataAlign="right"
                            width="15"
                            dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{<RenderKilogramo value={cell}/>}</span>}
                            >
                            PESO PRODUCIDO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="drc_produccion"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell,row) => <RenderNumberPercentage value={cell ? cell : 0} />}
                            width="10"
                            >
                            DRC
                        </TableHeaderColumn> */}
                    </Grid>
                </CardEmpty>
            </div>
        </LoadMask>
    )
};

export default Reporte;
