import React, { Component } from 'react'
import CardForm from '../../../../Utils/Cards/CardForm'
import LoadMask from '../../../../Utils/LoadMask/LoadMask'
import DetalleLiquidacionForm from './DetalleLiquidacionForm'

class DetalleLiquidacionHistorico extends Component {
    componentDidMount() {
        this.props.leerLiquidacion(this.props.match.params.id)
    }
    
    render() {
        const { loader } = this.props
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="historico_activo"
                    titulo="DATOS HISTÓRICOS"
                    subtitulo="Liquidaciones"
                    col="12"
                >
                    <LoadMask loading={loader} blur>
                        <DetalleLiquidacionForm {...this.props}/>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default DetalleLiquidacionHistorico