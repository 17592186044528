import React from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";

const rowExpandable = (row) => {
    const fincas = row.fincas || [];
    const data = {
        results: fincas,
        count: fincas.length,
    };
    if (fincas.length === 0) {
        return <div className="text-center">Sin fincas</div>;
    }
    return (
        <Grid data={data} pagination={false} className="px-2">
            <TableHeaderColumn
                dataField="id"
                isKey
                width="1%"
                dataFormat={(cell) => <div></div>}
            />
            <TableHeaderColumn
                dataField="activo"
                width="75px"
                dataFormat={(cell) => (cell ? "Si" : "No")}
            >
                Activo
            </TableHeaderColumn>
            <TableHeaderColumn dataField="nombre" width="auto">
                Nombre de finca
            </TableHeaderColumn>
            <TableHeaderColumn dataField="municipio_nombre" width="auto">
                Municipio
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="area_cultivada"
                dataFormat={(cell) => {
                    if (cell === null) {
                        return "Sin área cultivada";
                    }
                    return cell;
                }}
                width="auto"
            >
                Area cultivada
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="productos"
                dataFormat={(cell) => {
                    if (cell === null) {
                        return "Sin productos";
                    } else if (cell.length === 0) {
                        return "Sin productos";
                    } else {
                        // const nombresProductos = cell.map(producto => producto.nombre).join(', ');
                        // return nombresProductos;
                        const listaProductos = cell.map((producto, index) => (
                            <li key={index}>
                                {producto.nombre} (
                                {producto.peso ? producto.peso : 0} kg)
                            </li>
                        ));
                        return <div>{listaProductos}</div>;
                    }
                }}
                width="auto"
            >
                Productos
            </TableHeaderColumn>
        </Grid>
    );
};

export default rowExpandable;
