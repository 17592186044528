import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, renderFieldRadio, renderCurrency } from '../../../Utils/renderField/renderField';

let AcopioForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                {/* Información de la Empresa */}
                <div className="row col-md-12 p-0 pl-5 pr-5">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">NOMBRE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="direccion" className="m-0">DIRECCIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="direccion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0 pl-5 pr-5 mb-2">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">TELÉFONO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/acopios">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};

AcopioForm = reduxForm({
    form: 'EditarAcopioForm',
    validate: data => {
        // Los campos aquí definidos se marcan como requeridos (impiden completar el submit si no tienen valor)
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    }, initialValues: {}
})(AcopioForm);

const selector = formValueSelector('EditarAcopioForm');

AcopioForm = connect(state => {
    const a = 'test';
    return {
        a
    };
})(AcopioForm);

export const AcopioUpdateForm = reduxForm({
    form: 'EditarAcopioForm',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    },
    asyncBlurFields: []
})(AcopioForm);

export default AcopioForm;
