import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";

export default class PerfilesFincasList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="PERFILES"
                    subtitulo="Fincas">
                    <Header to="perfil/crear" textBtn="CREAR PERFIL" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                          page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='20%'
                            dataFormat={standardActions({ editar: "perfil", eliminar })}
                        />
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                            width='15%'
                        >
                            NOMBRE
                        </TableHeaderColumn>
                         <TableHeaderColumn
                            dataField="tipo_db"
                            dataSort
                            width='20%'
                        >
                            TIPO BASE DE DATOS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            width='20%'
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn width='25%'/>
                    </Grid>
                </Card>
            </div>
        )
    }
}
