import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "regimenes_fiscales",
    "regimen_fiscal",
    "RegimenForm",
    "/regimenes_fiscales",
);

export default handleActions(reducers, initialState);
