import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/acopios/acopios';
import AcopiosList from './Acopios';

const ms2p = (state) => {
  return {
    ...state.acopios,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(AcopiosList);
