import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/compras';
import ReporteCostoFletes from './ReporteCostoFletes';


const ms2p = (state) => {
  return {
    ...state.compras,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteCostoFletes);
