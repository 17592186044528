import React, { Component } from 'react';

import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './TipoMateriaPrimaForm';

export default class PilotoCrear extends Component {
    state = {
        editar: false,
    };

    componentWillMount() {
        const { leer, match } = this.props;
        if (match.params.id) {
            leer(match.params.id);
            this.setState({ editar: true });
        }
    }

    actualizar = (data) => {
        const { editar, match } = this.props;
        editar(match.params.id, data);
    };

    render() {
        const { crear, updateData, loader } = this.props;
        const { editar } = this.state;

        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="configuraciones_activo"
                    titulo="TIPO MATERIA PRIMA"
                    subtitulo="Nuevo">
                    <LoadMask loading={loader} blur>
                        {editar ? (
                            <Form
                                editando
                                updateData={updateData}
                                onSubmit={this.actualizar}
                            />
                        ) : (
                            <Form onSubmit={crear} />
                        )}
                    </LoadMask>
                </CardForm>
            </div>
        );
    }
}
