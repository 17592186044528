import React, {Component} from 'react'
import Card from "Utils/Cards/Card"
import LaboratoriosLatex from './laboratoriosLatex';

export default class LaboratoriosList extends Component{
    componentWillMount() {
        const { setTab } = this.props;
        setTab('LÁTEX');
    }

    render() {
        const { data_latex_analisis: data } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="laboratorio_activo"
                    titulo="ANÁLISIS"
                    subtitulo="De laboratorio Látex"
                >
                    <LaboratoriosLatex dataLatex={data} {...this.props}/>
                </Card>
            </div>
        )
    }
}

