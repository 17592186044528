import React, {Component} from 'react'
import Card from '../../../../Utils/Cards/Card';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import "react-step-progress-bar/styles.css";
import Form from "./SalidaForm";

export default class AlmacenSalida extends Component {
    state = {
        editar: false,
        step: 1,
        open: false,
    };

    componentWillMount() {
        this.props.setTypeSalida('ALMACENES');
        this.props.initialLoad();
    }

    openModal = () => {
        this.setState({open: true})
    };
    closeModal = () => {
        this.setState({open: false})
    };

    render() {
        const {item, loader, crearSalida, setAlturaFinalOrigen} = this.props;
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="bodega"
                    titulo="SALIDA"
                    col="12"
                    subtitulo={item.nombre}>
                    <LoadMask loading={loader} blur>
                        <Form onSubmit={crearSalida}
                              openModal={this.openModal}
                              closeModal={this.closeModal}
                              open={this.state.open} {...this.props}/>

                    </LoadMask>
                </Card>
            </div>
        )
    }
}
