import React, { Component } from 'react'
import { TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import Card from '../../../Utils/Cards/Card';
import Grid from '../../../Utils/Grid';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import {RenderNumber, RenderNumberPercentage} from '../../../Utils/renderField/renderReadField';
import { FiltrosFecha } from '../../../Utils/Filters';

const estadoFormater = (cell, row) => {
    let texto = <span>{row.estado_text}</span>;
    (cell === 20) ?
        texto = <span className="rojo">{row.estado_text}</span>
        :
        (cell === 30) ?
            texto = <span className="verde">{row.estado_text}</span>
            :
            texto = <span className="azul">{row.estado_text}</span>;
    return texto;
};

export default class IngresosLaboratorioList extends Component{
    componentWillMount() {
        const { setTab, page } = this.props;
        setTab('FINALIZADAS');
    }

    render() {
        const { data_pilas_historico:data, loader, page_finalizados } = this.props;
        const {
            fecha_inicial_finalizados, fecha_final_finalizados,
            fechaInicialFinalizadosChange, fechaFinalFinalizadosChange,
            onSortChange, pageFinalizadosChange,
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="laboratorio_activo"
                    titulo="FINALIZADOS"
                    subtitulo="De pilas/tanques"
                >
                    <FiltrosFecha {...this.props}
                        fecha_inicial={fecha_inicial_finalizados} fecha_final={fecha_final_finalizados}
                        fechaInicioChange={fechaInicialFinalizadosChange} fechaFinChange={fechaFinalFinalizadosChange}
                    />
                    <div style={{minHeight: '250px'}}>
                        <Grid hover striped data={data} loading={loader} onSortChange={onSortChange}
                            pagination page={page_finalizados} onPageChange={pageFinalizadosChange}
                        >
                            <TableHeaderColumn dataField="id"
                                dataAlign="center"
                                isKey
                                width='20%'
                                dataFormat={standardActions({verValidado: "drc_validados_pilas"})}
                            />
                            <TableHeaderColumn dataField="estado"
                                dataFormat={estadoFormater}
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="drc_promedio"
                                dataFormat={cell => <RenderNumberPercentage value={cell}/>}
                            >
                                DRC
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="tsc_promedio"
                                dataFormat={cell => <RenderNumber value={cell} decimalScale={4}/>}
                            >
                                TSC
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="movimiento"
                                dataFormat={cell => cell.bodega.nombre}
                            >
                                BODEGA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="creado"
                                dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                            >
                                FECHA
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </Card>
            </div>
        )
    }
}