import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/despachos';
import DespachosList from './DespachosList';

const ms2p = state => {
    return {
        ...state.despachos,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DespachosList);