import React, { Component } from 'react'
import Header from 'Utils/Grid/Header'
import Card from 'Utils/Cards/Card'
import CardEmpty from '../../../Utils/Cards/CardEmpty'
import TabBar from "rc-tabs/lib/TabBar"
import TabContent from "rc-tabs/lib/TabContent"
import Tabs, {TabPane} from "rc-tabs"
import EnCurso from './EnCurso'
import Finalizados from './Finalizados'

class DespachosList extends Component {
    componentDidMount() {
        const { setTab, listar, page } = this.props;
        setTab('EN CURSO')
        listar(page)
    }
    
    render() {
        const { setTab, tab } = this.props;
        const { data, loader } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="despachos_activo"
                    titulo="DESPACHOS"
                    subtitulo=""
                    noBorder
                >
                    <Tabs
                        activeKey={tab}
                        tabBarPosition="top"
                        onChange={tab =>{setTab(tab)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="EN CURSO" key="EN CURSO">
                            {/* <EnCurso {...this.props}/> */}
                            <div className="mb-4 col-12">
                                <section className="d-flex justify-content-center">
                                    <div
                                        className="card p-3"
                                        style={{width: '100%', borderTopLeftRadius: '0'}}
                                    >
                                        <EnCurso {...this.props}/>
                                    </div>
                                </section>
                            </div>
                        </TabPane>
                        <TabPane tab="FINALIZADO" key="FINALIZADO">
                        <div className="mb-4 col-12">
                                <section className="d-flex justify-content-center" style={{minHeight: '450px'}}>
                                    <div
                                        className="card p-3"
                                        style={{width: '100%', borderTopLeftRadius: '0'}}
                                    >
                                        <Finalizados {...this.props} />
                                    </div>
                                </section>
                            </div>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        )
    }
}

export default DespachosList