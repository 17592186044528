import React, { Fragment, useEffect, useState } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";
import Grid from "../../../Utils/Grid";
import { actions } from "../../../../../redux/modules/reportehistorialtanques";

export const TablaMovimientosTanques = (props) => {
    const { page, data, obtener_data } = props;
    useEffect(() => {
        obtener_data();
    }, []);

    return (
        <Grid
            hover
            striped
            data={data}
            className="pb-4 pt-3"
            page={page}
            pagination={true}
            onPageChange={obtener_data}
        >
            <TableHeaderColumn
                dataField="id"
                isKey
                width="2%"
                dataFormat={(cell) => <div></div>}
            />
            <TableHeaderColumn dataField="fecha_movimiento">
                FECHA MOVIMIENTO
            </TableHeaderColumn>
            <TableHeaderColumn dataField="tipo_movimiento">
                TIPO MOVIMIENTO
            </TableHeaderColumn>
            <TableHeaderColumn dataField="usuario">USUARIO</TableHeaderColumn>
            <TableHeaderColumn dataField="origen">ORIGEN</TableHeaderColumn>
            <TableHeaderColumn dataField="destino">DESTINO</TableHeaderColumn>
            <TableHeaderColumn
                dataField="cm_inciales"
                dataAlign="right"
                dataFormat={(cell) => {
                    return cell ? cell + " CM" : "0 CM";
                }}
            >
                CM INICIALES
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="kg_iniciales"
                dataAlign="right"
                dataFormat={(cell) => {
                    return cell ? cell + " KG" : "0 KG";
                }}
            >
                KG INICIALES
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="cm_movimiento"
                dataAlign="right"
                dataFormat={(cell) => {
                    return cell ? cell + " CM" : "0 CM";
                }}
            >
                CM MOVIMIENTO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="kg_movimiento"
                dataAlign="right"
                dataFormat={(cell) => {
                    return cell ? cell + " KG" : "0 KG";
                }}
            >
                KG MOVIMIENTO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="cm_finales"
                dataAlign="right"
                dataFormat={(cell) => {
                    return cell ? cell + " CM" : "0 CM";
                }}
            >
                CM FINALES
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="kg_finales"
                dataAlign="right"
                dataFormat={(cell) => {
                    return cell ? cell + " KG" : "0 KG";
                }}
            >
                KG FINALES
            </TableHeaderColumn>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    ...state.rht,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TablaMovimientosTanques);
