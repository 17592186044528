import React, {Component} from 'react'
import Grid from "Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import CardEmpty from "Utils/Cards/CardEmpty";
import Header from "Utils/Grid/Header";
import {RenderCM} from "Utils/renderField/renderReadField"
import {RenderKilogramo, RenderNumber} from "../../../../Utils/renderField/renderReadField";

export default class AlmacenesList extends Component{
    componentWillMount() {
        const { get_almacenes, page_almacenes, getAlmacenesDataTotales } = this.props;
        getAlmacenesDataTotales();
        get_almacenes(page_almacenes);
    }
    renderContenedores = (cell, row) => {
        return(
            <span className="verde-secundario"><span className="text-md bold">{cell}</span> Cont.</span>
        )

    };

    render() {
        const { get_almacenes: onPageChange, onSortChange } = this.props;
        const { almacenes, loader, page_almacenes, almacenes_totales: { contenedores, existencias, existencias_submaterias, contenedores_submaterias } } = this.props;
        // Construcción de los totales
        const _className = `${contenedores <= 50 ? 'barras-contador' : 'barras-contador-pequeñas'} mb-1`;
        const _className_submaterias = `${contenedores_submaterias <= 50 ? 'barras-contador' : 'barras-contador-pequeñas'} mb-1`;
        const imagenes = new Array(contenedores).fill(0).map((element, index) => <img key={index} src={require('assets/img/icons/linea bateria.png')} alt="Linea" className={_className}/>);
        const imagenes_submaterias = new Array(contenedores_submaterias).fill(0).map((element, index) => <img key={index} src={require('assets/img/icons/linea bateria.png')} alt="Linea" className={_className_submaterias}/>);
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="bodega_activo"
                    titulo="ALMACENES"
                    subtitulo="Sólido"
                    noShadow
                >
                    <div className="col-12 row p-3 d-flex flex-wrap align-items-center justify-content-between mb-3">
                        <div className="col-12 col-lg-2 mt-3">
                            <h5 className="bold">TOTALES: </h5>
                        </div>
                        <div className="col-12 col-lg-4 mt-2">
                            <div className="d-flex flex-wrap align-items-end mb-1">
                                <span className="gris mr-2 bold">Peso total materias</span>
                                <h4 className="mx-2 m-md-0" style={{whiteSpace: 'nowrap'}}>{<RenderKilogramo value={existencias}/>}</h4>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-flex flex-wrap justify-content-start">
                            <div className="d-flex flex-wrap align-items-end">
                                <span className="gris mr-1 text-lg"> {contenedores}</span>
                                <span className="gris mr-2 mb-2 bold">CONT.</span>
                            </div>
                            <div>
                                <div className="contorno-externo">
                                    <div className="interior-contorno p-1">
                                        <div className="bateria-almacenes">
                                            <div className="interior-bateria p-1">
                                                <div className="d-flex align-items-end pr-1 pl-1 pb-0 pt-1">
                                                {imagenes}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-3"></div>

                        <div className="col-12 col-lg-2 mt-3">
                        </div>
                        <div className="col-12 col-lg-4 mt-2">
                            <div className="d-flex flex-wrap align-items-end mb-1">
                                <span className="gris mr-2 bold">Peso total submaterias</span>
                                <h4 className="mx-2 m-md-0" style={{whiteSpace: 'nowrap'}}>{<RenderKilogramo value={existencias_submaterias}/>}</h4>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-flex flex-wrap justify-content-start">
                            <div className="d-flex flex-wrap align-items-end">
                                <span className="gris mr-1 text-lg"> {contenedores_submaterias}</span>
                                <span className="gris mr-2 mb-2 bold">CONT.</span>
                            </div>
                            <div>
                                <div className="contorno-externo">
                                    <div className="interior-contorno p-1">
                                        <div className="bateria-almacenes">
                                            <div className="interior-bateria p-1">
                                                <div className="d-flex align-items-end pr-1 pl-1 pb-0 pt-1" style={{
                                                    filter: 'invert(1)'
                                                }}>
                                                {imagenes_submaterias}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <CardEmpty >
                    <Grid hover striped data={almacenes} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} page={page_almacenes} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            width='10%'
                            dataSort
                            dataFormat={standardActions({ver_bodega: "almacen"})}
                        />
                        <TableHeaderColumn
                            dataField="contenedores"
                            dataSort
                            dataFormat={this.renderContenedores}
                        >
                            CONTENEDORES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="materia_prima"
                            dataFormat={cell => cell ? cell.nombre : '--'}
                        >
                            MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="peso_total"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell) => <RenderKilogramo value={cell} />}
                        >
                            PESO TOTAL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataField="id"
                            width='8%'
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="acopio"
                            dataFormat={(cell) => cell ? cell.nombre : 'Planta ERSA'}
                        >
                            LUGAR
                        </TableHeaderColumn>
                    </Grid>
                </CardEmpty>
            </div>
        )
    }
}
