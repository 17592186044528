import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, SelectField } from '../../../Utils/renderField/renderField';
import { generos } from '../../../../../utility/constants';

let PropietarioForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">NOMBRE DE PROPIETARIO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">TELÉFONO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="genero" className="m-0">GÉNERO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="genero" component={SelectField} options={generos}  type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">E-MAIL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/propietarios">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
PropietarioForm = reduxForm({
    form: 'PropietarioForm',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    }
})(PropietarioForm);

export default PropietarioForm
